









import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import DcwSimpleIcon from '@/components/Atoms/SimpleIcon/DcwSimpleIcon.vue';
import { Locales, SVGIcon } from '@/types';
import { isProduction } from '@/helpers';
import { useLocales } from '@/composables';

export const DcwLink = defineComponent({
  name: 'DcwLink',
  components: {
    DcwSimpleIcon
  },
  props: {
    path: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    icon: {
      type: Object as PropType<SVGIcon>,
      required: false
    }
  },
  setup() {
    const { locale } = useLocales();

    const prefix = computed(() => {
      if ((locale === Locales.DE || locale === Locales.FR) && isProduction) {
        return 'https://' + process.env.DOMAIN_EN;
      } else {
        return '';
      }
    });

    return { prefix };
  }
});

export default DcwLink;
