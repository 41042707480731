
























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwLabel, DcwNewHeadline, DcwButton } from '@atoms';
import { BlogPostWithId, ButtonVariants, HeadlineTypes, PagesLinks } from '@/types';
import { useLocales } from '@/composables';
import { desktopNavigateTo } from '@/helpers';

export const DcwBlogPost = defineComponent({
  name: 'DcwBlogPost',
  components: { DcwLabel, DcwNewHeadline, DcwButton },
  props: {
    post: {
      type: Object as PropType<BlogPostWithId>,
      required: true
    }
  },
  setup(props) {
    const postLink = `${PagesLinks.BLOG}/${props.post.id}`;
    const { translation } = useLocales();

    const buttonProps = {
      text: translation.phrases.readArticle,
      isLink: true,
      href: postLink,
      variant: ButtonVariants.TERTIARY
    };

    const headlineProps = {
      type: HeadlineTypes.H3,
      before: props.post.name,
      styledAs: HeadlineTypes.H4
    };

    const clickHandler = () => {
      desktopNavigateTo(postLink, false);
    };

    return { headlineProps, buttonProps, clickHandler };
  }
});

export default DcwBlogPost;
