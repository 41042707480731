





































import { computed, defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { CustomEvents, VariantsTogglerItem } from '@/types';

export const DcwVariantsToggler = defineComponent({
  name: 'DcwVariantsToggler',
  props: {
    name: {
      type: String,
      required: true
    },
    firstOption: {
      type: Object as PropType<VariantsTogglerItem>,
      required: true
    },
    secondOption: {
      type: Object as PropType<VariantsTogglerItem>,
      required: true
    },
    defaultValue: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isIndicatorShowed: {
      type: Boolean,
      default: true
    }
  },
  emits: [CustomEvents.CHANGE_VARIANT],
  setup(props, { emit }) {
    const selected = ref(props.defaultValue);

    const changeHandler = (evt: Event) => {
      if (props.isDisabled) {
        return;
      }

      const target = evt.target as HTMLInputElement;
      if (!target) {
        return;
      }
      selected.value = target.value;
    };

    const anotherOption = computed(() => {
      if (selected.value === props.firstOption.value) {
        return props.secondOption;
      }
      return props.firstOption;
    });

    const clickHandler = () => {
      if (props.isDisabled) {
        return;
      }

      selected.value = anotherOption.value.value;
    };

    watch(selected, (newValue) => emit(CustomEvents.CHANGE_VARIANT, newValue));

    return { selected, changeHandler, anotherOption, clickHandler };
  }
});

export default DcwVariantsToggler;
