export const phrases = {
  technologies: 'Technologies',
  industry: 'Industry',
  checkOn: 'Check on:',
  technologyUsed: 'Technologies used:',
  menu: 'Menu',
  job: 'Job',
  jobs: 'Jobs',
  available: 'Available',
  backToCareer: 'Go back to career',
  readFullReview: 'Read full review',
  readAllReviews: 'Read all reviews',
  company: 'Company',
  digitalColliersHomepage: 'Digital Colliers Homepage',
  new: 'New',
  readMore: 'Find out more',
  oneActiveJobOffer: 'active job',
  manyActiveJobOffers: 'active jobs',
  project: 'Project',
  projects: 'Projects',
  netB2B: 'net B2B',
  joinUs: 'Would you like to join us?',
  share: 'Share:',
  coreTechnologies: 'Core technologies',
  jobDescription: 'Job description',
  success: 'Success',
  stories: 'Stories',
  story: 'Story',
  shareVia: 'Share via',
  why: 'Why',
  searchForIndustry: 'Search for the industry',
  searchForTeam: 'Search for the Team',
  searchForTechnology: 'Search for the Technology',
  seeAlsoProjects: 'See also our projects',
  allIndustries: 'All industries',
  allTeams: 'All Teams',
  allTechnologies: 'All Technologies',
  fullyRemote: 'Fully Remote',
  all: 'all',
  filterBy: 'Filter by',
  readArticle: 'Read article',
  recruitmentProcess: 'Recruitment Process',
  techStack: 'Tech Stack',
  neededSkills: 'The below skills are essential when applying for this position.'
};
