/* eslint-disable max-len */
import { offices } from '../offices';
import { AboutOfficesSectionContent } from '@/types';

const imgProperties = {
  width: 464,
  height: 242
};

export const ourOffices: AboutOfficesSectionContent = {
  headline: {
    before: 'Shared',
    highlight: 'Workspace',
    subtitle:
      "Our office's location was chosen based on the potential partnerships, business inquiries, and current market needs.\nEach of them provides a creative and positive environment satisfying for both clients and employees needs."
  },
  items: [
    {
      model: offices.cracow,
      image: {
        ...imgProperties,
        src: '/about/offices/office-1.png',
        alt: 'office in Cracow'
      }
    },
    {
      model: offices.warsaw,
      image: {
        ...imgProperties,
        src: '/about/offices/office-2.png',
        alt: 'office in Warszawa'
      }
    },
    {
      model: offices.gliwice,
      image: {
        ...imgProperties,
        src: '/about/offices/office-3.png',
        alt: 'office in Gliwice'
      }
    },
    {
      model: offices.paris,
      image: {
        ...imgProperties,
        src: '/about/offices/office-4.png',
        alt: 'office in Paris'
      }
    },
    {
      model: offices.hamburg,
      image: {
        ...imgProperties,
        src: '/about/offices/office-5.png',
        alt: 'office in Hamburg'
      }
    },
    {
      model: offices.berlin,
      image: {
        ...imgProperties,
        src: '/about/offices/office-6.png',
        alt: 'office in Berlin'
      }
    },
    {
      model: offices.vienna,
      image: {
        ...imgProperties,
        src: '/about/offices/office-7.png',
        alt: 'office in Vienna'
      }
    },
    {
      model: offices.toronto,
      image: {
        ...imgProperties,
        src: '/about/offices/office-8.png',
        alt: 'office in Toronto'
      }
    }
  ]
};
