/* eslint-disable max-len */
import { CountryNames, OfficeModel } from '@/types';

export const cracow: OfficeModel = {
  city: 'Cracow',
  country: CountryNames.POLAND,
  name: 'Cracow Main Square',
  address: {
    first: 'Rynek Główny 8',
    second: '31-042'
  },
  map:
    'https://www.google.com/maps/place/Rynek+G%C5%82%C3%B3wny+8,+31-042+Krak%C3%B3w/@50.060806,19.9359902,17z/data=!3m1!4b1!4m5!3m4!1s0x47165b12058e99ef:0x87b869282e0b2a3d!8m2!3d50.060806!4d19.9381789'
};
