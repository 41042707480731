




































import { defineComponent, onBeforeUnmount, PropType, ref, watch } from '@nuxtjs/composition-api';
import { DcwNewHeadline } from '@atoms';
import { HeadlineTypes, SidebarData } from '@/types';
import { useLocales } from '@/composables';

export const DcwTextPageSidebar = defineComponent({
  name: 'DcwTextPageSidebar',
  components: { DcwNewHeadline },
  props: {
    sections: {
      type: Object as PropType<SidebarData>,
      required: true
    },
    contentRef: {
      type: process.server ? Object : HTMLElement,
      default: null
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const currentSection = ref<string>('');
    const items = ref<HTMLElement[]>([]);
    const headlineProps = {
      type: HeadlineTypes.P,
      styledAs: HeadlineTypes.H3,
      before: translation.phrases.menu
    };

    const scrollTo = (id: string): void => {
      const { contentRef } = props;
      if (!contentRef) {
        return;
      }

      const neededSection = contentRef.querySelector(`#${id}`);

      if (!neededSection) {
        return;
      }

      const sectionEl = neededSection as HTMLElement;
      window.scrollTo({ top: sectionEl.offsetTop, behavior: 'smooth' });
    };

    const scrollHandler = () => {
      const scrollY = window.scrollY;
      if (items.value.length < 0) {
        return;
      }

      items.value.forEach((el) => {
        if (scrollY >= el.offsetTop && scrollY <= el.offsetTop + el.scrollHeight && currentSection.value !== el.id) {
          currentSection.value = el.id;
        }
      });
    };

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', scrollHandler);
    });

    watch(
      () => props.contentRef,
      (newValue, oldValue) => {
        if (oldValue === null && newValue === null) {
          return;
        }

        items.value = [...newValue.querySelectorAll('[id]')].filter((el) => el.tagName === 'SECTION' || el.tagName === 'H2');

        window.addEventListener('scroll', scrollHandler);
      }
    );

    return { headlineProps, scrollTo, currentSection };
  }
});

export default DcwTextPageSidebar;
