export const desktopNavigateTo = (href: string, external = true): void => {
  if (window.innerWidth < 1024) {
    return;
  }

  if (external) {
    open(href);
  } else {
    location.href = href;
  }
};
