/* eslint-disable max-len */
import { videos } from '../../videos';
import { VideoListingInterviewsContent } from '~/types';

export const interviews: VideoListingInterviewsContent = {
  headline: {
    highlight: 'Office',
    after: 'Interviews',
    subtitle: `Our story, expertise, and culture.
      Straight from #DCpeople, because who could introduce us better?`
  },
  videos: [
    {
      ...videos.interviewAndrii,
      description: {
        before: 'Andrii Nyzhnyk',
        subtitle: 'Frontend Developer'
      }
    },
    {
      ...videos.interviewFilip,
      description: {
        before: 'Filip Cembrzyński',
        subtitle: 'UI/UX Designer'
      }
    },
    {
      ...videos.interviewWojciech,
      description: {
        before: 'Wojciech Cywiński',
        subtitle: 'Frontend Developer'
      }
    },
    {
      ...videos.interviewBartosz,
      description: {
        before: 'Bartosz Cieśla',
        subtitle: 'Head of Recruitment'
      }
    },
    {
      ...videos.interviewSzymon,
      description: {
        before: 'Szymon Nowak',
        subtitle: 'Chief Technology Officer'
      }
    }
  ]
};
