import { SeniorityLevels } from '@/types';

export const getSeniorityLevels = (level: SeniorityLevels, architectFlag = false): SeniorityLevels[] => {
  const seniorityLevels = Object.values(SeniorityLevels);
  const offerIsMidOrLower = seniorityLevels.indexOf(level) < seniorityLevels.indexOf(SeniorityLevels.SENIOR);

  if (!architectFlag || offerIsMidOrLower) {
    return seniorityLevels.filter((item) => item !== SeniorityLevels.ARCHITECT);
  }

  return seniorityLevels.filter((item) => item !== SeniorityLevels.JUNIOR);
};
