import { CaseTechnologies, CaseViews, CaseStudiesSectionContent } from '@/types';
import TNWIcon from '@/assets/common/showcases/showcase-1.svg';
import TCIcon from '@/assets/common/showcases/showcase-3.svg';
import BusInsider from '@/assets/common/showcases/showcase-4.svg';
import AwwwIcon from '@/assets/common/showcases/showcase-2.svg';
import FranAngIcon from '@/assets/common/showcases/showcase-5.svg';
import Newsweek from '@/assets/common/showcases/showcase-6.svg';

const defaultHeight = 333;

export const caseStudies: CaseStudiesSectionContent = {
  headline: {
    before: 'See Our',
    highlight: 'Case Studies'
  },
  slides: [
    {
      title: 'Innovative Gaming B2B\nSaaS Platform',
      ongoing: true,
      subtitle: `We designed and built a complete software solution that
      will enable enterprises around the globe to open to new markets.`,
      technologies: [
        CaseTechnologies.VUE,
        CaseTechnologies.NUXT,
        CaseTechnologies.TYPESCRIPT,
        CaseTechnologies.GRAPHQL,
        CaseTechnologies.KOTLIN,
        CaseTechnologies.KTOR,
        CaseTechnologies.MONGODB,
        CaseTechnologies.KUBERNETES
      ],
      background: '#4F8DF7',
      image: {
        width: 1122,
        height: defaultHeight,
        alt: 'Greenfield Project of Innovative\nLottery SaaS-Based Platform',
        src: '/home/case-studies/case-1.png'
      }
    },
    {
      title: 'Web Application for Heavy\nMachinery Parts Supplier',
      ongoing: false,
      subtitle: `We built a platform that allows managing and
      trading spare parts on-demand with a multitude of partnered companies.`,
      technologies: [CaseTechnologies.LARAVEL, CaseTechnologies.MY_SQL, CaseTechnologies.ANGULAR, CaseTechnologies.SYMFONY],
      link: 'https://www.behance.net/gallery/105918549/Internal-Web-Application-for-Glimat',
      background: '#606160',
      views: {
        [CaseViews.CLUTCH]: 'https://clutch.co/profile/digital-colliers#review-1703480',
        [CaseViews.BEHANCE]: 'https://www.behance.net/gallery/105918549/Internal-Web-Application-for-Glimat'
      },
      image: {
        width: 1103,
        height: defaultHeight,
        alt: 'Web Application for Heavy\nMachinery Parts Supplier',
        src: '/home/case-studies/case-2.png'
      }
    },
    {
      title: 'Web Application for Electronics\nSystem Installation Company',
      ongoing: false,
      subtitle: `Custom made, elegant solution integrated with ERP,
      location-based tool for an electronic systems installation and retail company.`,
      technologies: [
        CaseTechnologies.REACT,
        CaseTechnologies.REDUX,
        CaseTechnologies.GRAPHQL,
        CaseTechnologies.MY_SQL,
        CaseTechnologies.SYMFONY
      ],
      link: 'https://www.behance.net/gallery/106982637/CT-Group-Company-UIUX',
      background: '#6EA921',
      views: {
        [CaseViews.CLUTCH]: 'https://clutch.co/profile/digital-colliers#review-1733403',
        [CaseViews.BEHANCE]: 'https://www.behance.net/gallery/106982637/CT-Group-Company-UIUX'
      },
      image: {
        width: 1032,
        height: defaultHeight,
        alt: 'Web Application for Electronics\nSystem Installation Company',
        src: '/home/case-studies/case-3.png'
      }
    },
    {
      title: 'Our Very Own Company\nOrganisation App',
      ongoing: false,
      subtitle: `A web application developed during the COVID-19 pandemic
      in order to make up for the lack of face-to-face interaction and to better organize our remote work.`,
      technologies: [CaseTechnologies.NODEJS, CaseTechnologies.REDUX, CaseTechnologies.REACT],
      link: 'https://www.behance.net/gallery/105657037/Digital-Colliers-Company-Organisation-App',
      background: '#4368EA',
      views: {
        [CaseViews.BEHANCE]: 'https://www.behance.net/gallery/105657037/Digital-Colliers-Company-Organisation-App'
      },
      image: {
        width: 1056,
        height: defaultHeight,
        alt: 'Our Very Own Company\nOrganisation App',
        src: '/home/case-studies/case-6.png'
      }
    },
    {
      title: 'Quality Control System for\nExotic Wood Importer',
      ongoing: false,
      subtitle: `End to end internal quality control management system for
      one of the largest exotic wood importers in Poland.`,
      technologies: [CaseTechnologies.REACT, CaseTechnologies.LARAVEL, CaseTechnologies.MY_SQL],
      link: 'https://www.behance.net/gallery/107427761/Drewnostyl-Company-UIUX',
      background: '#BD281A',
      views: {
        [CaseViews.BEHANCE]: 'https://www.behance.net/gallery/107427761/Drewnostyl-Company-UIUX'
      },
      image: {
        width: 1071,
        height: defaultHeight,
        alt: 'Quality Control System for\nExotic Wood Importer',
        src: '/home/case-studies/case-4.png'
      }
    },
    {
      title: 'File Management Software\nfor an Architectural Firm',
      ongoing: false,
      subtitle: `Simple file management App for an architectural company.
      Using minimalistic design, location based automation and personalized user functions.`,
      technologies: [CaseTechnologies.VUE, CaseTechnologies.NODEJS, CaseTechnologies.JAVA, CaseTechnologies.REDUX],
      link: 'https://www.behance.net/gallery/106627011/Architekci-MR-UIUX',
      background: '#333333',
      views: {
        [CaseViews.CLUTCH]: 'https://clutch.co/profile/digital-colliers#review-1882487',
        [CaseViews.BEHANCE]: 'https://www.behance.net/gallery/106627011/Architekci-MR-UIUX'
      },
      image: {
        width: 1057,
        height: defaultHeight,
        alt: 'File Management Software\nfor an Architectural Firm',
        src: '/home/case-studies/case-5.png'
      }
    }
  ],
  features: {
    headline: {
      before: 'Our Work Was',
      highlight: 'Showcased',
      after: 'by'
    },
    icons: [AwwwIcon, TNWIcon, BusInsider, TCIcon, FranAngIcon, Newsweek]
  }
};
