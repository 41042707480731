












import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { RequestStatus, CustomEvents } from '@/types';

import ArrowIcon from '@/assets/custom-dc-icons/ArrowRight.vue';
import CheckIcon from '@/assets/icons/checkmark.svg';
import CrossIcon from '@/assets/icons/streamline-icons/cross-mark.svg';
import SpinnerIcon from '@/assets/icons/spinner.svg';

export const DcwFormButton = defineComponent({
  name: 'DcwFormButton',
  components: { ArrowIcon, CheckIcon, CrossIcon, SpinnerIcon },
  props: {
    status: {
      type: String as PropType<RequestStatus>,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    hasIcon: {
      type: Boolean,
      default: true
    }
  },
  emits: [CustomEvents.ICON_ANIMATION_FINISHED],
  setup(props, { emit }) {
    const animationStatus = ref<RequestStatus>(RequestStatus.DEFAULT);

    watch(
      () => props.status,
      () => {
        if (props.status === RequestStatus.PENDING) {
          animationStatus.value = RequestStatus.PENDING;
        }
      }
    );

    watch(animationStatus, (newValue, oldValue) => {
      if (newValue === RequestStatus.PENDING && oldValue === RequestStatus.DEFAULT) {
        const interval = setInterval(() => {
          if (props.status === RequestStatus.ERROR || props.status === RequestStatus.SUCCESS) {
            clearInterval(interval);
            animationStatus.value = props.status;

            setTimeout(() => {
              animationStatus.value = RequestStatus.DEFAULT;
              emit(CustomEvents.ICON_ANIMATION_FINISHED);
            }, 2000);
          }
        }, 2000);
      }
    });

    return { animationStatus };
  }
});

export default DcwFormButton;
