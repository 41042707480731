













import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { TextPageListType } from '@/types';
import { DcwText } from '@atoms';
import CheckMark from '@/assets/icons/checkmark.svg';

export const DcwList = defineComponent({
  name: 'DcwList',
  components: { DcwText, CheckMark },
  props: {
    bullets: {
      type: String as PropType<TextPageListType>,
      required: false,
      default: TextPageListType.STANDARD
    },
    items: {
      type: Array as PropType<string[]>,
      required: true
    }
  }
});

export default DcwList;
