/* eslint-disable max-len */
import { GalleryItemType, SectionWithGalleryContent } from '@/types';

export const description: SectionWithGalleryContent = {
  headline: {
    before: 'We Make a',
    highlight: 'Difference',
    subtitle:
      'Setting up new standards in high-tech software development, we believe that redefining the IT industry is a collective effort. We express the necessity of sharing our expertise by publishing articles, partnering with world-leading companies, giving public speeches at conferences, and hosting events for tech communities.'
  },
  columns: [
    {
      before: 'Top Tech Events',
      subtitle: 'We are consistent in promoting new solutions by active involvement at conferences in London, Berlin, Miami.'
    },
    {
      before: 'Professional Speakers',
      subtitle: 'We are committed to engaging with the tech community to build trust, share knowledge, and exchange ideas.'
    }
  ],
  gallery: [
    {
      type: GalleryItemType.IMAGE,
      img: {
        width: 587,
        height: 478,
        src: '/about/events/conference-1.png',
        alt: 'our speaker'
      }
    }
  ]
};
