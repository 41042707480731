var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"timetableSliderRef",staticClass:"dcw-explore-our-story-timeline",class:[{ 'dcw-timetable-timeline--center-card-wrapper': _vm.calibrateCardToBeInCenter }],style:({ paddingLeft: _vm.paddingValue, paddingRight: _vm.paddingValue })},[_c('client-only',[(_vm.isMobile)?_vm._l((_vm.events),function(event,index){return _c('DcwTimetableCard',{key:index,staticClass:"dcw-explore-our-story-timeline__event mobile-cards",attrs:{"data":event,"card-variant":_vm.TimetableCardVariant.SINGLE_COLUMN,"observer":{ observe: _vm.observer },"event-dot":{
          index: index,
          closestEventIndex: _vm.closestEventIndex,
          activeEventIndex: _vm.activeCardIndex,
          dateProgress: _vm.dateProgressInPixels
        }}})}):_vm._l((_vm.events),function(event,index){return _c('DcwExploreOurStoryCard',{key:("dcw-cluster-card-" + (event.id)),staticClass:"dcw-explore-our-story-timeline__event",attrs:{"card-index":index,"data":event,"state":_vm.cardState(index),"event-dot":{
          index: index,
          closestEventIndex: _vm.closestEventIndex,
          activeEventIndex: _vm.activeCardIndex,
          dateProgress: _vm.dateProgressInPixels
        },"observer":{ observe: _vm.observer },"card-variant":event.variant,"is-last-one":index + 1 === _vm.events.length},on:{"transitionDone":function($event){return _vm.changeCardTransitionState(index)}}})})],2),_vm._v(" "),_c('div',{staticClass:"dcw-explore-our-story-timeline__line",style:({width: _vm.timetableSliderRefWidth + 'px'})})],1)}
var staticRenderFns = []

export { render, staticRenderFns }