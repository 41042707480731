export enum EventCardStates {
  ACTIVE = 'active',
  PREVIOUS = 'previous',
  INACTIVE = 'inactive'
}

export enum TimetableCardImagesLayout {
  COLUMN_ORIENTATION = 'column-orientation',
  FIRST_PHOTO_BIGGER = 'first-photo-bigger'
}

export enum TimetableCardVariant {
  TWO_COLUMNS = 'two-columns',
  SINGLE_COLUMN = 'single-column'
}
