































import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import ArrowUp from '@/assets/icons/streamline-icons/arrow-up.svg';

export const DcwAccordion = defineComponent({
  name: 'DcwAccordion',
  components: { ArrowUp },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      required: false
    }
  },
  emits: ['dcw-open-accordion'],
  setup(props, context) {
    const status = ref(props.isOpen);

    const hideBody = (el: HTMLElement) => (el.style.height = '0px');
    const showBody = (el: HTMLElement) => (el.style.height = el.scrollHeight + 'px');

    const accordionHandler = () => {
      context.emit('dcw-open-accordion');
      status.value = !status.value;
    };

    watch(
      () => props.isOpen,
      (newValue) => {
        status.value = newValue;
      }
    );

    return { status, hideBody, showBody, accordionHandler };
  }
});

export default DcwAccordion;
