import Vue from 'vue';
import { ValidationObserver, ValidationProvider, localize, extend, setInteractionMode } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { Plugin } from '@nuxt/types';
import { TRANSLATIONS } from '@/composables';
import { Locales } from '@/types';

const VeeValidate: Plugin = ({ i18n }) => {
  setInteractionMode('passive');
  const locale = i18n.locale as Locales;

  localize(locale, TRANSLATIONS[locale].validations);

  const isPhoneNumber = (value: string) => {
    return /^\d{7,}$/.test(value.replace(/[\s()+\-.]|ext/gi, ''));
  };

  extend('email', email);
  extend('mobile', { validate: isPhoneNumber });
  extend('required', required);
  extend('checked', { validate: (value) => !!value });
  extend('fileReq', {
    validate: (value: File[] | null[]) => {
      if (!value[0]) {
        return 'This field is required';
      }

      return true;
    }
  });
  extend('file', {
    validate: (value: File[] | null[], params: unknown) => {
      if (Array.isArray(params) && params.length && value[0]) {
        const fileType = value[0].name.slice(value[0].name.lastIndexOf('.') + 1);
        return !!value[0] && params.includes(fileType);
      }

      return true;
    }
  });
  extend('maxSize', {
    validate: (value: File[] | null[], params: unknown) => {
      if (Array.isArray(params) && params.length && value[0]) {
        return value[0].size < params[0];
      }

      return true;
    }
  });

  Vue.component('ValidationObserver', ValidationObserver);
  Vue.component('ValidationProvider', ValidationProvider);
};

export default VeeValidate;
