/* eslint-disable max-len */
import { testimonials } from '../ui/testimonials';
import {
  TestimonialSectionContent
} from '@/types';

export const testimonial: TestimonialSectionContent = {
  headline: {
    before: 'What Our',
    highlight: 'Clients Say',
    subtitle: 'Individually we are weak like a single twig, but as a bundle we form a mighty faggot!'
  },
  slides: testimonials
};
