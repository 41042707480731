import { PositionAboutSection } from '@/types';

/* eslint-disable max-len */
export const about: PositionAboutSection = {
  headline: {
    before: 'About Digital Colliers'
  },
  paragraphs: [
    'We are a Cracow-based software house that provides web development services to customers worldwide. The technologies we mainly use are Java and JavaScript. The flat structure in our company makes you feel at home.',
    'We will fully support your career path and help you identify both your potential and areas for improvement. Most importantly, we expect from you eagerness and willingness to learn and grow.',
    'We do not cut your wings, we help you fly!'
  ],
  footer: {
    headline: {
      before: 'Get To Know Us'
    },
    paragraphs: [
      'We will play it to anybody who ever wondered what is it like to work with us. \nEverybody in here is just genuinely himself and we believe you can tell it from the look on our faces. We are a team of hard-workers but fun and endorphins are just in our blood.'
    ],
    image: {
      src: '/job-offer/about/image-1.jpg',
      alt: 'About Digital Colliers Video',
      width: 804,
      height: 452
    },
    videoId: '671092459'
  }
};
