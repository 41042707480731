


































import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import Arrow from '@/assets/icons/caret-default.svg';
import Cross from '@/assets/icons/cross.svg';

export const DcwSelect = defineComponent({
  name: 'DcwSelect',
  components: { Arrow, Cross },
  props: {
    label: {
      type: String,
      required: false
    },
    length: {
      type: Number,
      required: true
    },
    defaultValue: {
      type: Number,
      default: 0
    },
    isMulti: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const isFocused = ref(false);
    const activeOption = ref([props.defaultValue]);

    const setDefaultOption = () => {
      activeOption.value = [props.defaultValue];
    };

    const selectHandler = (index: number) => {
      const target = document.activeElement;

      activeOption.value = [index];

      target instanceof HTMLElement && target.blur();
    };

    const multiSelectHandler = (index: number) => {
      if (index === props.defaultValue) {
        setDefaultOption();
        return;
      }

      const selectedOptions = [...activeOption.value];

      if (activeOption.value.includes(index)) {
        activeOption.value = selectedOptions.filter((option) => option !== index);

        if (activeOption.value.length === 0) {
          setDefaultOption();
        }

        return;
      }

      activeOption.value = selectedOptions.filter((option) => option !== props.defaultValue);

      activeOption.value.push(index);
    };

    const setActiveOption = (index: number) => {
      if (!props.isMulti) {
        selectHandler(index);
        return;
      }

      multiSelectHandler(index);
    };

    const clickHandler = (evt: PointerEvent) => {
      const target = evt.target as HTMLElement;

      if (isFocused.value) {
        target.blur();
      }
    };

    const blurHandler = () => {
      isFocused.value = false;
    };

    const focusHandler = () => {
      setTimeout(() => {
        isFocused.value = true;
      }, 400);
    };

    watch(activeOption, (newValue) => {
      if (!props.isMulti) {
        emit('change', newValue[0]);
        return;
      }

      emit('change', activeOption.value);
    });

    return { activeOption, setActiveOption, clickHandler, blurHandler, focusHandler };
  }
});

export default DcwSelect;
