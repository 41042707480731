/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

const ID = 13;
const NAME = 'Krzysztof';

export const story: SuccessStory = {
  name: `${NAME}`,
  images: {
    og: `/covers/success-story/story-${ID}.jpg`,
    avatar: {
      src: `/success-stories/story-${ID}/avatar.png`,
      alt: `${NAME}`,
      width: 32,
      height: 32
    },
    cover: {
      src: `/success-stories/story-${ID}/cover.jpg`,
      alt: `${NAME} cover`,
      height: 608
    },
    slider: {
      src: `/success-stories/story-${ID}/slider.png`,
      alt: `${NAME}`,
      width: 317,
      height: 224
    },
    listing: {
      src: `/success-stories/story-${ID}/listing.png`,
      alt: `${NAME}`,
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'August 28, 2022',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'Product Owner',
  details:
    'I knew I’d like to engage in a company looking for people in mainly senior positions cause that meant a more experienced team for me. Fortunately, Digital Colliers turned out to be much more than just good marketing and the team I’m working with is delivering without any problems.',
  shortStory:
    'I knew I’d like to engage in a company looking for people in mainly senior positions cause that meant a more experienced team for me.',
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer:
        'Everything started with Information Technology studies at the Silesian University of Technology in Gliwice. During that time I had a chance to participate in the Erasmus program in Budapest where I got a chance to also try out some more economic and sociology-related subjects. After I came back I took on regular work as a developer and shortly after I transferred to a company where I’ve spent 6 years. I started in the frontend, switched to full-stack and after some time I also got to slowly manage the team. At some point, I became a Product Manager where apart from being responsible for application development I was also offering it to clients. It was a big application responsible for internet distribution for leading companies in the Polish market. I gained a lot of experience but at the same time, I’ve exploited all growth possibilities. It was a time for me to make a change and so I transferred to Digital Colliers.'
    },
    {
      type: 'text',
      title: 'What do you do in DC?',
      answer:
        'On the basic level, my job is to make sure that projects go in the right direction and get developed in accordance with some pre-defined plan. I need to assure the inflow of new features without simultaneous growth of technical debt. I am also responsible for communication with the stakeholders so that the team at all time knows exactly what to do. I collect ideas, propose some of mine and do market research related to the latest trends and possibilities to make sure that the project is attractive. I am just responsible for every broad aspect of the project’s optimization.'
    },
    {
      type: 'text',
      title: 'How did the recruitment process look like?',
      answer:
        'I found the offer on one of the recruitment job boards. It was presented really well during the first recruitment call. I also got a chance for a very good talk with the Head of Engineering. I prioritized my focus not only on the offers I got themselves but I was also analyzing what other kinds of people are my potential employers looking for. I knew I’d like to engage in a company looking for people in mainly senior positions cause that meant a more experienced team for me. Fortunately, Digital Colliers turned out to be much more than just good marketing and the team I’m working with is delivering without any problems. There is no better option for a PO. I also really appreciate that I got to participate in a company retreat even before I signed a contract because I just got to make sure that I get along with people.'
    },
    {
      type: 'image',
      details: 'Fabryka Wełny intergration trip',
      img: {
        src: `/success-stories/story-${ID}/image-1.jpg`,
        alt: 'Fabryka Wełny intergration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.PABIANICE_INTEGRATION_TRIP
    },
    {
      type: 'text',
      title: 'What is the biggest challenge of working in DC so far?',
      answer:
        'I joined the team around the end of a quarter and so from the very beginning, I had to act very quickly to ensure that we go smoothly into the next one. Also, I do have some experience and it’s a natural rule of things for me to use it. Sometimes I propose some solutions that are not familiar to everybody and it’s a natural challenge to convince the team to trust me with them. There is denial, rebellion, acceptance, experimentation, and only after some time do we know that it worked out fine. It’s always a challenge to implement something but maybe more important it’s a big responsibility that I have for the team.'
    },
    {
      type: 'text',
      title: 'What have you learned in DC so far?',
      answer:
        'First of all, I had a chance to focus on the actual PO work. It made me grow in this particular direction which was not always a thing in the past. In my previous work apart from managing the team I had to do presentations for the clients, valuations, prepare roadmaps, code, do code reviews, and at the top look after students working as graphics. Doing all at once I could never properly pay attention to any of them. Right now I have one important thing to do so I can really master my performance.'
    },
    {
      type: 'text',
      title: 'What would do you like about work in DC?',
      answer:
        "Mainly some kind of chill. It's super cool that we have flexible working hours and we can be wherever we want during the work, which really makes sense for the work that we do. Also, you can tell that during the recruitment personal traits are being taken into consideration cause every single person starting work really fits into the team right away. And so far I can tell that the company retreats are an outstanding time for all of us."
    },
    {
      type: 'text',
      title: 'Where do you see yourself in 10 years?',
      answer:
        'I hope I won’t HAVE TO do anything at that time. I just really wish I could be everywhere. I don’t see myself in one place for a long time even during retirement. I definitely want to travel. Maybe at that time, you will be able to go to Mars… I will start saving up for that right away!'
    },
    {
      type: 'image',
      details: 'London Networking video',
      img: {
        src: `/success-stories/story-${ID}/image-2.jpg`,
        alt: 'London Networking video',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.LONDON_TECH_EVENT
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Metallica - Master of Puppets',
          img: {
            src: `/success-stories/story-${ID}/song-1.png`,
            alt: 'Metallica - Master of Puppets',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/3dyuqD1AQNS1xLiHthQx8N?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=6xjJ2XIbGRk'
        },
        {
          title: 'The Cult - Fire Woman (LA Rock Mix)',
          img: {
            src: `/success-stories/story-${ID}/song-2.png`,
            alt: 'The Cult - Fire Woman (LA Rock Mix)',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/2rbcCco3PrSyMinVJdh61J',
          ytLink: 'https://www.youtube.com/watch?v=HQVwmVCiOog'
        },
        {
          title: 'Batman Begins Theme: Macrotus',
          img: {
            src: `/success-stories/story-${ID}/song-3.png`,
            alt: 'Batman Begins Theme: Macrotus',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/0CXqfoIfhkxNej5VGjphPM?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=bg8HF-20CFo'
        }
      ]
    }
  ]
};
