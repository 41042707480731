import { Ref, ref } from '@nuxtjs/composition-api';
import { useScrollSlideIntoView } from './use-scroll-into-view';

interface UseSlider {
  selectOption: (index: number) => void;
  sliderElement: Ref<HTMLElement | null>;
  onNextClicked: () => void;
  onPreviousClicked: () => void;
  currentIndex: Ref<number>;
}
interface UseSliderProps<T> {
  collection: T[];
  scrollCenter?: boolean | (() => boolean);
  activeIndex?: number;
}

export const useSlider = <T>({ activeIndex = 0, collection, scrollCenter }: UseSliderProps<T>): UseSlider => {
  const sliderElement = ref<HTMLElement | null>(null);
  const currentIndex = ref(activeIndex);

  const selectOption = (index: number) => {
    currentIndex.value = index;

    useScrollSlideIntoView(sliderElement, index, typeof scrollCenter === 'function' ? scrollCenter() : scrollCenter);
  };

  const onNextClicked = () => {
    if (currentIndex.value + 1 < collection.length) {
      selectOption(currentIndex.value + 1);
    }
  };

  const onPreviousClicked = () => {
    if (currentIndex.value > 0) {
      selectOption(currentIndex.value - 1);
    }
  };

  return {
    selectOption,
    sliderElement,
    onNextClicked,
    onPreviousClicked,
    currentIndex
  };
};
