/* eslint-disable max-len */
import { SectionWithItemsContent } from '@/types';

export const ourSolutionFor: SectionWithItemsContent = {
  headline: {
    before: 'Solution',
    highlight: 'at Every Scale'
  },
  items: [
    {
      title: 'Startups',
      text:
        'Building agile product teams in weeks is our spécialité de la maison. You choose the degree of our ownership in your project.'
    },
    {
      title: 'Product Companies',
      text:
        'A wide range of world-class specialists engaged in long-term projects is the core of what we offer to our corporate clients.'
    },
    {
      title: 'Enterprises',
      text:
        'Years of hands-on experience in software development and our ownership philosophy makes us the right fit for startup companies.'
    }
  ]
};
