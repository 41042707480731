import { MapSectionContent } from '@/types';

export const map: MapSectionContent = {
  headline: {
    highlight: 'We Work',
    after: 'Across Borders',
    subtitle: 'We’ve worked with clients from all over the world.'
  },
  map: {
    alt: 'Ours locations',
    src: '/home/map/image-1.png',
    width: 1738,
    height: 692
  }
};
