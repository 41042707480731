
























import { defineComponent } from '@nuxtjs/composition-api';
import RatingStar from '@/assets/icons/streamline-icons/rating-star.svg';
import ClutchLogo from '@/assets/brands/clutch_logo.svg';
import CaretRightIcon from '@/assets/icons/caret-right.svg';

export const DcwClutchWidget = defineComponent({
  name: 'DcwClutchWidget',
  components: {
    RatingStar,
    ClutchLogo,
    CaretRightIcon
  }
});

export default DcwClutchWidget;
