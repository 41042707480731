import { SenioritySalary, SeniorityLevels } from '../../../types';

type salaryRange = [number, number];
type GetSenioritySalaryArg = [salaryRange, salaryRange, salaryRange, salaryRange];

export const getSenioritySalary = (salariesArr: GetSenioritySalaryArg, currency = 'pln'): SenioritySalary => {
  const salaryNames = Object.values(SeniorityLevels) as SeniorityLevels[];
  const salaries = {} as SenioritySalary;
  salaryNames.forEach((name, index) => {
    salaries[name] = {
      from: salariesArr[index][0],
      to: salariesArr[index][1],
      currency
    };
  });

  return salaries;
};
