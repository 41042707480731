import { ServicesSectionContent, PagesLinks } from '@/types';

export const services: ServicesSectionContent = {
  headline: {
    highlight: 'Our Services',
    after: '& Expertise'
  },
  items: [
    {
      path: PagesLinks.CONTACT,
      title: 'Frontend Development',
      subtitle: 'We build pixel-perfect frontend web applications based on solid and scalable architecture solutions.'
    },
    {
      path: PagesLinks.CONTACT,
      title: 'Backend Development',
      subtitle: 'We design, build and maintain high-performing secure backend solutions that serve your business needs.'
    },
    {
      path: PagesLinks.TEAM_AUGMENTATION,
      title: 'Team Augmentation',
      subtitle: `We provide our clients with world-class specialists at ease by building dedicated
      development teams and taking HR and recruitment out of their shoulders.`,
      img: {
        width: 480,
        height: 288,
        alt: 'Picture of two members of digital colliers smiling.',
        src: '/home/services/image-1.png'
      }
    },
    {
      path: PagesLinks.CONTACT,
      title: 'DevOps\n&\xA0Infrastructure',
      subtitle: 'We build reliable software infrastructure solutions and maintain them in sync with DevOps philosophy.'
    },
    {
      path: PagesLinks.CONTACT,
      title: 'Product\nDesign',
      subtitle: 'We analyze and visualize business concepts by creating prototypes and design complete products.'
    }
  ]
};
