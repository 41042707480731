/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from 'axios';
import { getReferrer } from '@/helpers';

export interface Params {
  positionId: string;
  name: string;
  mail: string;
  mobile?: string;
  attachment?: [File];
  captchaToken: string;
  futureRecruitment: boolean;
}

export type Response = void;

export type DomainModel = Response;

const mapper = (): void => undefined;

export const apply = (client: AxiosInstance, API_URL: string, params: Params) => {
  const referrer = getReferrer();
  const paramEntries = Object.entries(params) as [keyof Params, Params[keyof Params]][];

  const requestEntries = [...paramEntries, ...referrer ? [['referrer', referrer] as const] : []];
  const formEntries = requestEntries.map(([key, val]) => [key, Array.isArray(val) ? val[0] : `${val}`] as const);

  const payload = formEntries.reduce((form, [key, value]) => { form.append(key, value); return form; }, new FormData());

  return client.post<Response>(API_URL, payload).then(mapper);
};
