import { SliderData, SlideCount } from '@/types';

export const getSlidePosition = (slideIndex: number, slidesData: SliderData): string => {
  const { length, index: activeIndex } = slidesData;

  let index = '';
  switch (slideIndex) {
    case activeIndex:
      index = SlideCount.FIRST;
      break;
    case activeIndex - 1:
      index = SlideCount.PREV;
      break;
    case activeIndex - 2:
      index = SlideCount.PREV_PREV;
      break;
    case activeIndex + 1:
      index = SlideCount.SECOND;
      break;
    case activeIndex + 2:
      index = SlideCount.THIRD;
      break;
    case activeIndex + 3:
      index = SlideCount.FOURTH;
      break;
  }

  if (activeIndex === 0) {
    if (slideIndex === length - 1) {
      index = SlideCount.PREV;
    }

    if (slideIndex === length - 2) {
      index = SlideCount.PREV_PREV;
    }
  }

  if (activeIndex === length - 3 && slideIndex === 0) {
    index = SlideCount.FOURTH;
  }

  if (activeIndex === length - 2) {
    if (slideIndex === 0) {
      index = SlideCount.THIRD;
    }

    if (slideIndex === 1) {
      index = SlideCount.FOURTH;
    }
  }

  if (activeIndex === length - 1) {
    if (slideIndex === 0) {
      index = SlideCount.SECOND;
    }
    if (slideIndex === 1) {
      index = SlideCount.THIRD;
    }

    if (slideIndex === 2) {
      index = SlideCount.FOURTH;
    }
  }

  if (activeIndex === 1 && slideIndex === length - 1) {
    index = SlideCount.PREV_PREV;
  }

  return index;
};
