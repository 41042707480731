/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: false,
  categories: [JobOfferCategories.OTHER],
  projects: [],
  name: 'Senior DevOps Engineer',
  salaryRanges: salaries.devOps,
  seniorityLevel: SeniorityLevels.MID,
  referrers: {
    [Referrers.DC_WEBSITE]: 168594849,
    [Referrers.OTHER]: 168594899,
    [Referrers.CARROT_WORK]: 150539817,
    [Referrers.JUST_JOIN_IT]: 78528545,
    [Referrers.NO_FLUFF_JOBS]: 168594832,
    [Referrers.BULLDOG_JOB_PL]: 78528546,
    [Referrers.FACEBOOK_ADS]: 192832788,
    [Referrers.GOOGLE_ADS]: 192832795
  },
  description: [
    {
      title: 'Description',
      text: [
        'We are currently looking for an experienced DevOps to join our team, and help us create a scalable and secure infrastructure for our international projects in the lottery and gambling industry.',
        'We are looking for an independent DevOps that can start the creation of an AWS infrastructure-as-a-code from day one. You will be building scalable Kubernetes Clusters and AWS Resources with Terraform.',
        'For great development, we expect that you will be sharing your knowledge with our developers and will become a part of our Digital Colliers Family.'
      ]
    },
    {
      title: 'Your Role and Responsibilities',
      items: [
        'Build Kubernetes Clusters and AWS Resources with Terraform',
        'Manage and scale our SaaS applications',
        'Design and implement deployment tooling and improved data security strategies',
        'Work with AWS cloud using automation tools at daily basis',
        'Keep up to date with best practices in application hosting and continuous deployment',
        'Update or produce documents to describe changes to the platform'
      ]
    }
  ],
  technologies: [
    { label: 'AWS', level: TechnologyLevels.REGULAR },
    { label: 'Docker', level: TechnologyLevels.REGULAR },
    { label: 'Kubernetes', level: TechnologyLevels.REGULAR },
    { label: 'Terraform', level: TechnologyLevels.REGULAR }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First, short interview to know each other and talk about yours and ours expectations'
    },
    {
      title: 'Team Lead Interview',
      description: 'The second step will be the interview with your future Team Leader'
    },
    {
      title: 'Tech Interview',
      description: 'The last step is a one-hour call with our developer'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process in one week'
    }
  ]
};
