






import { defineComponent } from '@nuxtjs/composition-api';
import { DcwScheduleModalVariant } from './components';
import { DcwModal } from '@atoms';

export const DcwScheduleModal = defineComponent({
  name: 'DcwScheduleModal',
  components: {
    DcwModal,
    DcwScheduleModalVariant
  },
  props: {
    isShown: {
      type: Boolean,
      default: false
    },
    person: {
      type: String,
      required: true
    }
  },
  emits: ['close-modal']
});

export default DcwScheduleModal;
