



































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { DcwFormError } from '../FormError/DcwFormError.vue';
import CheckMark from '@/assets/icons/checkmark.svg';

export const DcwFormCheckbox = defineComponent({
  name: 'DcwFormCheckbox',
  components: { DcwFormError, CheckMark },
  props: {
    name: {
      type: String
    },
    value: {
      type: Boolean,
      required: true
    },
    rules: {
      type: String,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const isRequired = computed(() => props.rules?.includes('checked'));
    const checkboxValidationProviderRef = ref();

    const handleInputChange = (e: Event) => {
      const target = e.target as HTMLInputElement;
      emit('input', target.checked);
      checkboxValidationProviderRef.value.reset();
    };

    return { CheckMark, isRequired, handleInputChange, checkboxValidationProviderRef };
  }
});

export default DcwFormCheckbox;
