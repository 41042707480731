/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { getBasicJobsData } from './get-basic-jobs-data';
export * from './get-basic-jobs-data';

const API_KEY = process.env.CMS_API_TOKEN;
const CMS_URL = process.env.CMS_API_URL;

const axiosInstance = axios.create({ headers: { Authorization: `Bearer ${API_KEY}` } });

export const cmsServiceFactory = () => ({
  getBasicJobsData: getBasicJobsData.bind(null, axiosInstance, `${CMS_URL}`)
});
