/* eslint-disable max-len */
import { people } from '../people';
import IconJunior from '@/assets/career/career-path/career-path-1.svg';
import IconMiddle from '@/assets/career/career-path/career-path-2.svg';
import IconSenior from '@/assets/career/career-path/career-path-3.svg';
import IconArchitect from '@/assets/career/career-path/career-path-4.svg';
import { CareerPathSectionContent } from '@/types';

export const careerPath: CareerPathSectionContent = {
  headline: {
    before: 'Develop Your',
    highlight: 'Career',
    subtitle:
      'Wherever you are in your career, Digital Colliers provides opportunities to learn, develop and apply your talents at the highest level. Join us and see for yourself.'
  },
  hrSpecialist: {
    model: people.wiktorS,
    text:
      'If you want to hear more about  current development opportunities. I will guide you through the process and make sure you are on board!',
    image: {
      full: {
        src: '/career/career-path/career-path-hr.png',
        alt: 'Wiktor Stefański, ours hr specialist',
        height: 352,
        width: 332
      },
      small: {
        src: '/career/career-path/career-path-hr-small.png',
        alt: 'Wiktor Stefański, ours hr specialist',
        height: 64,
        width: 63
      }
    }
  },
  positions: [
    {
      headline: {
        before: 'Junior',
        subtitle:
          'Junior indicates a level of responsibility, rather than a level of skill or experience. You will have code reviews and possibly pair programming.'
      },
      image: IconJunior
    },
    {
      headline: {
        before: 'Middle',
        subtitle:
          'Mid-level Developer provides an insightful knowledge of programming and has already spent at least 2-4 years in this profession.'
      },
      image: IconMiddle
    },
    {
      headline: {
        before: 'Senior',
        subtitle:
          'Senior Developer can handle the entire software development life cycle, end to end while sharing the expertise with others.'
      },
      image: IconSenior
    },
    {
      headline: {
        before: 'Architect',
        subtitle:
          'Software Architect interprets business requirements and makes high-level structural decisions about the direction of the project.'
      },
      image: IconArchitect
    }
  ]
};
