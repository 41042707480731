import { JobsOffersSectionContent, ScheduleModalPeople } from '@/types';
import { offersService } from '@/service/model';

export const offers: JobsOffersSectionContent = {
  headline: {
    before: 'Featured',
    highlight: 'Jobs'
  },
  cta: {
    headline: {
      before: "Let's Talk",
      subtitle: 'We are looking for creative, talented people with a collaborative mindset to create the finest digital products.'
    },
    image: {
      alt: 'Picture of Bartosz Cieśla smiling',
      height: 303,
      width: 370,
      src: '/career/offers/bartosz-bust.png'
    },
    modal: ScheduleModalPeople.bartoszC
  },
  offers: offersService.offers
};
