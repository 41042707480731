export const pagesNames = {
  jobs: 'Jobs',
  home: 'Hauptseite',
  projectListing: 'Project Listing',
  contact: 'Contacter',
  about: 'À propos',
  career: 'Carrière',
  termsOfService: "Conditions d'utilisation",
  privacyPolicy: 'Politique de confidentialité',
  sitemap: 'Sitemap',
  terms: "Termes et conditions d'utilisation",
  projects: 'Projekt',
  successStory: 'Success Story',
  successStories: 'Success Stories',
  teamAugmentation: 'Outstaffing',
  technologies: 'Technologies',
  blog: 'Blog',
  videoListing: 'Video Listing'
};
