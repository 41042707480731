/* eslint-disable max-len */
import { IntegrationsSectionContent } from '@/types';
import { CHRISTMAS_TREE_EMOJI, ITALY_FLAG_EMOJI, MOUNTAIN_EMOJI } from '@/consts';

const imageAttributes = {
  width: 1115,
  height: 474
};

export const integrations: IntegrationsSectionContent = {
  headline: {
    before: 'Regular',
    highlight: 'Integration Trips',
    subtitle: "Strengthen bonds, flourish relationships, and experience more.\n That's what our corporate retreats are about."
  },
  trips: [
    {
      description: 'Digital Colliers in Bologna, Italy',
      videoId: '671092459',
      thumbnail: {
        src: '/career/integrations/trip-1.jpg',
        alt: 'Bologna trip',
        ...imageAttributes
      },
      uuid: 1,
      emoji: ITALY_FLAG_EMOJI
    },
    {
      description: 'Company Adventure in the Tatra Mountains!',
      videoId: '615553876',
      thumbnail: {
        src: '/career/integrations/trip-2.jpg',
        alt: 'Tatras Mountains trip',
        ...imageAttributes
      },
      uuid: 3,
      emoji: MOUNTAIN_EMOJI
    },
    {
      description: 'Christmas Eve With Digital Colliers Family',
      videoId: '671099894',
      thumbnail: {
        src: '/career/integrations/trip-4.jpg',
        alt: 'Beskids, Poland trip',
        ...imageAttributes
      },
      uuid: 4,
      emoji: CHRISTMAS_TREE_EMOJI
    },
    {
      description: 'Tech Event in heart of London',
      videoId: '725255719',
      thumbnail: {
        src: '/career/integrations/trip-5.png',
        alt: 'London, Tech Event',
        ...imageAttributes
      },
      uuid: 5
    },
    {
      description: 'Office, Golf & Casino',
      videoId: '732454129',
      thumbnail: {
        src: '/career/integrations/trip-6.jpg',
        alt: 'Office, Golf & Casino',
        ...imageAttributes
      },
      uuid: 6
    }
  ]
};
