export const pagesNames = {
  jobs: 'Jobs',
  home: 'Home',
  projectListing: 'Project Listing',
  contact: 'Contact',
  about: 'About',
  career: 'Career',
  termsOfService: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
  sitemap: 'Sitemap',
  projects: 'Project Listing',
  successStory: 'Success Story',
  successStories: 'Success Stories',
  teamAugmentation: 'Team Augmentation',
  technologies: 'Technologies',
  blog: 'Blog',
  videoListing: 'Video Listing'
};
