









import { computed, defineComponent } from '@nuxtjs/composition-api';
import { DcwSliderArrow } from '@atoms';

export const DcwSwiperPagination = defineComponent({
  name: 'DcwSwiperPagination',
  components: { DcwSliderArrow },
  props: {
    progress: {
      type: Number,
      required: true
    },
    length: {
      type: Number,
      required: true
    },
    isCarousel: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['dcw-to-next-slide', 'dcw-to-prev-slide'],
  setup(props) {
    const paginationStyles = computed(() => ({
      width: Math.round(100 / props.length) + '%',
      marginLeft: `calc((100% - ${100 / props.length}%) * ${Math.round(props.progress * 100)} * 0.01)`
    }));

    return { paginationStyles };
  }
});

export default DcwSwiperPagination;
