/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

export const story: SuccessStory = {
  name: 'Ramune',
  images: {
    og: '/covers/success-story/story-11.jpg',
    avatar: {
      src: '/success-stories/story-11/avatar.png',
      alt: 'Ramune',
      width: 32,
      height: 32
    },
    cover: {
      src: '/success-stories/story-11/cover.jpg',
      alt: 'Ramune cover',
      height: 608
    },
    slider: {
      src: '/success-stories/story-11/slider.png',
      alt: 'Ramune',
      width: 317,
      height: 224
    },
    listing: {
      src: '/success-stories/story-11/listing.png',
      alt: 'Ramune',
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'May 12, 2022',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'Business Development Manager',
  details: 'I’ve learned to adapt to very different people quite fast. Before that I didn’t really know how to communicate with high-level positions. I am much better at focusing on people’s needs which I need to do to actually understand them. There is always a different scenario which often requires deep cultural intelligence that I am constantly acquiring.',
  shortStory: 'The best salesman is not the one that talks a lot and intimidates you but the one that really listens.',
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer: 'Before DC I did many things. I even tried working in sales before. It was one of my first jobs but I was working in a B2C setup where I was cold calling old people and offering them mattresses that they didn’t need. I would get so many rejections throughout a day that it completely demotivated me to work in sales. After that I tried to work in startups and the last job before DC was a fintech company in Vilnius where I was responsible for basically everything. I was a designer, office manager, recruiter and an employee. I decided however that I want to have a remote job with cool people where I could take advantage of my communication skills. I knew that my job can’t be to sit in front of the computer the whole day. I just needed to be creative and involved with people because I couldn\'t stand a monotonous job.'
    },
    {
      type: 'text',
      title: 'What do you do in DC?',
      answer:
        'I am a Business Development Manager. My responsibility is finding clients and projects for our developers. It requires a lot of networking and commuting between conferences to build a network. Then the next task is to keep the network alive. On a daily basis I need to conduct a lot of communication with people from big companies, small companies, startups and all sorts of other very distinguished areas.'
    },
    {
      type: 'text',
      title: 'How did you first get in contact with DC? What did the recruitment process look like?',
      answer:
        'It’s a funny story of total coincidence. I was standing in the line trying to get to the networking afterparty in Malta and I didn’t have an internet connection to tell my friends where I am. I turned around and there were a few guys that offered to help me. Those guys were DC’s management board. We started talking and it turned into an over an hour conversation. We met again the next day at the conference and eventually we kept networking together. When I was back in Lithuania Karol wrote to me, we met again and I was offered a job!'
    },
    {
      type: 'image',
      details: 'Bologna intergration trip',
      img: {
        src: '/success-stories/story-11/image-1.png',
        alt: 'Bologna integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.BOLOGNA_INTEGRATION_TRIP
    },
    {
      type: 'text',
      title: 'What is the biggest challenge of working in DC so far?',
      answer:
        "It’s probably learning the technical side of the IT world. I need to have a lot of knowledge when I'm attending in conferences, since I am having meetings and general discussions with potential clients. My work involves selling, so I have to be sure what I am selling. "
    },
    {
      type: 'text',
      title: 'What have you learned in DC so far?',
      answer:
        'I’ve learned to adapt to very different people quite fast. Before that I didn’t really know how to communicate with high-level positions. I am much better at focusing on people’s needs which I need to do to actually understand them. There is always a different scenario which often requires deep cultural intelligence that I am constantly acquiring. I am also much more confident and I’ve learned to put on bad-ass attitude but still I try to always remember that the best salesman is not the one that talks a lot and intimidates you but the one that really listens and tries to understand your position.'
    },
    {
      type: 'text',
      title: 'Where do you see yourself in 10 years?',
      answer:
        'I have really no idea. Even one year ago I had a completely different vision of my current life. As for my dreams I wish that I still will be travelling and that I will be trying out a lot of water sports. I love water sports. I am super afraid of them but I just can’t resist the adrenaline. I would also like to be successful in my carrer. I dont’t need to be on a high position but I want to feel that I’m realizing my full potential in the things that I’m doing.'
    },
    {
      type: 'image',
      details: 'Żywiec integration trip',
      img: {
        src: '/success-stories/story-11/image-2.png',
        alt: 'Żywiec integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.ZYWIEC_INTEGRATION_TRIP
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Mia - Paper planes',
          img: {
            src: '/success-stories/story-11/song-1.png',
            alt: 'Mia - Paper planes',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/1ixbwbeBi5ufN4noUKmW5a?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=ewRjZoRtu0Y'
        },
        {
          title: 'Rufus du Sol - Innerbloom',
          img: {
            src: '/success-stories/story-11/song-2.png',
            alt: 'Rufus du Sol - Innerbloom',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/5GXeNbxOEbd7sKrbsVLVVx?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=Tx9zMFodNtA'
        },
        {
          title: 'Baby Bash, Frankie J - Suga Suga',
          img: {
            src: '/success-stories/story-11/song-3.png',
            alt: 'Baby Bash, Frankie J - Suga Suga',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/6Nq5lE0CeOAAAqN4qhkbZU?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=6rgStv12dwA'
        }
      ]
    }
  ]
};
