







































import { defineComponent, computed, useRoute, inject } from '@nuxtjs/composition-api';
import { DcwBreadcrumbs, DcwFooterAbout, DcwFooterLinks, DcwFooterLocations } from './components';
import { DcwLink, DcwGridWrapper, DcwSocialMedia } from '@atoms';
import { useLocales } from '@/composables';
import { BreadcrumbItem, MainGridWrapperElements, PagesLinks } from '@/types';
import EUFlag from '@/assets/flags/eu.svg';
import { BreadcrumbItemsKey } from '@/plugins/BreadcrumbContext';

export const DcwFooter = defineComponent({
  name: 'DcwFooter',
  components: {
    EUFlag,
    DcwBreadcrumbs,
    DcwFooterAbout,
    DcwFooterLinks,
    DcwGridWrapper,
    DcwSocialMedia,
    DcwFooterLocations,
    DcwLink
  },
  setup() {
    const route = useRoute();
    const {
      translation: {
        ui: { footer },
        pagesNames,
        socialMedia
      },
      locale
    } = useLocales();
    const breadcrumbs = inject<BreadcrumbItem[]>(BreadcrumbItemsKey);

    const pagesLinks = [
      { label: pagesNames.termsOfService, href: PagesLinks.TERMS_OF_SERVICES },
      { label: pagesNames.privacyPolicy, href: PagesLinks.PRIVACY_POLICY }
    ];

    const isRouteOtherThanHomepage = computed(() => route.value.name !== 'index');

    const socialMediaLinks = [
      socialMedia.clutch,
      socialMedia.facebook,
      socialMedia.linkedIn,
      socialMedia.vimeo,
      socialMedia.instagram
    ];

    return {
      locale,
      MainGridWrapperElements,
      breadcrumbs,
      isRouteOtherThanHomepage,
      route,
      PagesLinks,
      EUFlag,
      footer,
      pagesLinks,
      pagesNames,
      socialMediaLinks
    };
  }
});

export default DcwFooter;
