















import { computed, defineComponent, nextTick, PropType, ref } from '@nuxtjs/composition-api';
import { DcwSliderArrow } from '@atoms';
import { useBreakpoints } from '@/composables';
import { Breakpoints } from '@/types';

export const DcwSwiperButtons = defineComponent({
  name: 'DcwSwiperButtons',
  components: { DcwSliderArrow },
  props: {
    breakpoint: {
      type: Number as PropType<Breakpoints & number>,
      default: 768
    },
    viewType: {
      type: String as PropType<'mobile' | 'desktop' | 'auto'>,
      default: 'auto'
    }
  },
  emits: ['prev-slide-button-click', 'next-slide-button-click'],
  setup(props) {
    const { currentWidth } = useBreakpoints();

    const wrapperKey = ref('initialKey');

    nextTick(() => {
      wrapperKey.value = 'nextTick';
    });

    const shouldBreak = computed(() => {
      if (props.viewType === 'mobile') {
        return false;
      }

      if (props.viewType === 'desktop') {
        return true;
      }

      if (props.viewType === 'auto') {
        return currentWidth.value >= props.breakpoint;
      }
    });

    return { shouldBreak, wrapperKey };
  }
});

export default DcwSwiperButtons;
