export enum RequestStatus {
  DEFAULT = '',
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'error'
}

export enum SalesMailType {
  salesLeopoldG = 'salesLeopoldG',
  salesLukaszS = 'salesLukaszS',
  salesSzymonN = 'salesSzymonN',
  salesPrzemyslawD = 'salesPrzemyslawD'
}

export enum MailType {
  contact = 'contact',
  apply = 'apply',
  applyFeedback = 'applyFeedback',
  recruitment = 'recruitment',
  clickupTaskError = 'clickupTaskError'
}
