import { HeadlineContent, ImageElementAttributes, LinkContent, PersonModel } from '@/types';

export interface CompanyDetails {
  name: string;
  email: string;
  address: { secondLine: string; firstLine: string };
  taxiIdentifiers: { vatId: string; krs: string };
}

export interface JoinUsCardContent {
  headline: HeadlineContent;
  buttonText: string;
  mainImage: ImageElementAttributes;
  miniature: ImageElementAttributes;
  name: string;
  position: string;
  linkedinLink: string;
  link: string;
}

export enum CompanyEventGalleryGrid {
  ONE_IMAGE = 'one-image',
  TWO_IN_ROWS = 'two-in-rows',
  THREE_MASONRY = 'three-masonry',
  FOUR_MASONRY = 'four-masonry',
  TWO_IN_BOX = 'two-in-box',
  STORY = 'story'
}

export interface CompanyEvent {
  category: string[];
  date: string;
  headline: HeadlineContent;
  gallery: {
    grid: CompanyEventGalleryGrid;
    images: ImageElementAttributes[];
  };
  button?: LinkContent;
}

export interface CtaCardProps {
  headline: HeadlineContent;
  button: {
    text: string;
    href: string;
  };
  person: {
    model: PersonModel;
    images: {
      full: ImageElementAttributes;
      min: ImageElementAttributes;
    };
  };
}
