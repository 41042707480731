import { convertDataModelToContent } from '@/helpers';

type ContentWithId<T> = T & { id: string };

export const modelService = <T>(data: Record<string, T>) => () => {
  const content: ContentWithId<T>[] = convertDataModelToContent(data);

  const getContentById = (id: string): ContentWithId<T> => {
    const neededItem = content.find((item) => item.id === id);

    if (!neededItem) {
      throw new Error('Could not find content');
    }

    return neededItem;
  };

  const getOtherContent = (id: string) => content.filter((item) => item.id !== id);

  return { content, getContentById, getOtherContent };
};
