






























import { computed, defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { videoContext } from './DcwVideo.const';
import { DcwImage } from '@atoms';
import { ImageElementAttributes, VideoElementSource } from '@/types';
import { useBreakpoints, usePageLoad } from '@/composables';

export const DcwVideo = defineComponent({
  name: 'DcwVideo',
  components: {
    DcwImage
  },
  props: {
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    srcset: {
      type: Array as PropType<VideoElementSource[]>,
      required: false
    },
    alt: {
      type: String,
      required: true
    },
    thumbnail: {
      type: [String, Object] as PropType<(string | ImageElementAttributes)[]>,
      required: true
    },
    isAutoplay: {
      type: Boolean,
      required: false
    },
    hasControls: {
      type: Boolean,
      required: false
    },
    isLoop: {
      type: Boolean,
      required: false
    },
    isMuted: {
      type: Boolean,
      required: false
    },
    isInline: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }) {
    const isVideoLoaded = ref(false);
    const videoRef = ref<HTMLVideoElement | null>(null);
    const { isPageLoaded } = usePageLoad();
    const { currentWidth } = useBreakpoints();

    const onVideoLoaded = () => {
      isVideoLoaded.value = true;
      emit('video-loaded');
    };

    const onVideoChange = () => {
      isVideoLoaded.value = false;
      videoRef.value?.load();
    };

    const viewportSources = computed(() => {
      const isDensitySupported = ({ density }: VideoElementSource) => {
        return !density || (process.browser ? density <= devicePixelRatio : true);
      };

      const descendingWidthDensity = (a: VideoElementSource, b: VideoElementSource) => {
        return b.width - a.width || (b.density || 0) - (a.density || 0);
      };

      const sortedSet = props.srcset?.filter(isDensitySupported).sort(descendingWidthDensity);
      const target = sortedSet?.find((item) => item.width <= currentWidth.value);

      return !target
        ? []
        : [target.src].flat().map((src) => ({ src: videoContext(`.${src}`), type: `video/${src.split('.').pop()}` }));
    });

    watch(viewportSources, onVideoChange);

    return {
      videoRef,
      currentWidth,
      isPageLoaded,
      isVideoLoaded,
      viewportSources,
      onVideoLoaded
    };
  }
});

export default DcwVideo;
