/* eslint-disable max-len */
import { TextPageContent, PagesLinks } from '@/types';

export const dataProcessing: TextPageContent = {
  title: 'Data Processing',
  metaDescription:
    'In order to fulfill the obligations resulting from GDPR, the Service provider hereby presents to you all sufficient information about scope and legal basis...',
  sections: [
    {
      title: 'Contact Form Personal Data Processing Information',
      header: { before: 'Contact Form Personal Data Processing Information' },
      id: 'contact-form-personal-data-processing-information',
      html: `
      <p>
        By clicking on the "Send message" button you send us your personal data, which will be processed in order to answer your question. The administrator of your personal data is Digital Colliers Sp. z o.o. located in Gliwice. For more information on the processing of your personal data, see the
          <a href="${PagesLinks.PRIVACY_POLICY}">Privacy Policy.</a>
      </p>
      `
    },
    {
      title: 'Application Form Personal Data Processing Information',
      header: { before: 'Application Form Personal Data Processing Information' },
      id: 'application-form-personal-data-processing-information',
      html: `
      <p>
        By clicking on the "Apply" button you provide your information, which will be processed in order to take steps at your request prior to entering into a contract and, in order to any not-required information provided by you or in order to conduct future recruitments, based on your consent to process given personal data. The administrator of your personal data is Digital Colliers Sp. z o.o. located in Gliwice. For more information on the processing of your personal data, see the
          <a href="${PagesLinks.PRIVACY_POLICY}">Privacy Policy.</a>
      </p>
      `
    },
    {
      title: 'Recruitment Call Form Personal Data Processing Information',
      header: { before: 'Recruitment Call Form Personal Data Processing Information' },
      id: 'recruitment-call-form-personal-data-processing-information',
      html: `
      <p>
        By clicking on the "Schedule a call" button you provide your information, which will be processed in order to contact you, to take steps at your request prior to entering into a contract and, in order to any not-required information provided by you or in order to conduct future recruitments, based on your consent to process given personal data. The administrator of your personal data is Digital Colliers Sp. z o.o. located in Gliwice. For more information on the processing of your personal data, see the
          <a href="${PagesLinks.PRIVACY_POLICY}">Privacy Policy.</a>
      </p>
      `
    },
    {
      title: 'Business Call Form Personal Data Processing Information',
      header: { before: 'Business Call Form Personal Data Processing Information' },
      id: 'business-call-form-personal-data-processing-information',
      html: `
      <p>
        By clicking on the "Schedule a call button you send us your personal data, which will be processed in order to contact you. The administrator of your personal data is Digital Colliers Sp. z o.o. located in Gliwice. For more information on the processing of your personal data, see the
          <a href="${PagesLinks.PRIVACY_POLICY}">Privacy Policy.</a>
      </p>
      `
    }
  ]
};
