/* eslint-disable max-len */
import { PersonModel, ScheduleModalPeople } from '@/types';

export const person: PersonModel = {
  name: 'Łukasz',
  surname: 'Sobieraj',
  position: 'Co-Founder & COO',
  linkedinLink:
    'https://www.linkedin.com/in/lukaszsobieraj/?lipi=urn%3Ali%3Apage%3Acompanies_company_people_index%3B8f32c1f3-8ba2-4f2b-9761-208453fcff4a',
  modal: ScheduleModalPeople.lukaszS
};
