































import { computed, defineComponent, ref, toRef, watch } from '@nuxtjs/composition-api';
import { DateTime } from 'luxon';
import { DcwSimpleIcon } from '@atoms';
import CircleClock from '@/assets/icons/streamline-icons/circle-clock.svg';
import { useInjectStrict, useLocales } from '@/composables';
import { Locales, RequestStatus, ScheduleMeetingFormData, UpdateScheduleMeetingFormData } from '@/types';
import { ScheduleMeetingFormDataKey, ScheduleMeetingRequestStatusKey, UpdateScheduleMeetingFormKey } from '@/consts';

export const DcwScheduleModalTimePicker = defineComponent({
  name: 'DcwScheduleModalTimePicker',
  components: {
    DcwSimpleIcon
  },
  setup() {
    const formData = useInjectStrict<Readonly<ScheduleMeetingFormData>>(ScheduleMeetingFormDataKey);
    const updateFormData = useInjectStrict<UpdateScheduleMeetingFormData>(UpdateScheduleMeetingFormKey);
    const status = useInjectStrict<RequestStatus>(ScheduleMeetingRequestStatusKey);
    const timeModel = toRef(formData, 'time');
    const { locale } = useLocales();

    watch(timeModel, () => {
      updateFormData('time', timeModel.value)(formData);
    });

    const getTimeInterval = () => {
      let startDate = DateTime.fromFormat('09:00', 'HH:mm');
      const endDate = DateTime.fromFormat('17:00', 'HH:mm');

      const availableHours = [];

      while (startDate < endDate) {
        const isGerman = locale === Locales.DE;
        const formattedHour = startDate.setLocale(locale).toFormat('t');

        availableHours.push(isGerman ? `${formattedHour} Uhr` : formattedHour);
        startDate = startDate.plus({ minutes: 15 });
      }

      return availableHours;
    };

    const timeIntervals = ref(getTimeInterval());

    const formattedDate = computed(() => {
      return formData.date
        ? DateTime.fromISO(formData.date.toISOString()).setLocale(locale).toFormat('cccc, LLLL d')
        : 'Select a date';
    });

    return { timeIntervals, formattedDate, timeModel, CircleClock, status, RequestStatus };
  }
});

export default DcwScheduleModalTimePicker;
