/* eslint-disable max-len */
import { people } from '../people';
import { PagesLinks, CtaSectionContent } from '@/types';

export const textPageCta: CtaSectionContent = {
  title: 'Any Questions? Talk to Wiktor!',
  headline: {
    before: 'Any Questions?',
    highlight: 'Talk to Wiktor',
    subtitle:
      'We are looking for creative, talented people with a collaborative mindset.\n We give you the freedom to express your creativity and develop\n professionally as an individual.'
  },
  firstButton: {
    text: 'Schedule a call'
  },
  secondButton: {
    text: 'Career',
    to: PagesLinks.CAREER
  },
  image: {
    src: '/pjp/placeholder.png',
    alt: 'Wiktor Stefański',
    width: 334,
    height: 328
  },
  extraInfo: {
    model: people.wiktorS,
    description:
      'If you want to hear more about  current development opportunities. I will guide you through the process and make sure you are on board!'
  }
};
