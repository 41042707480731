















import { defineComponent } from '@nuxtjs/composition-api';

export const DcwNavigationToggle = defineComponent({
  name: 'DcwNavigationToggle',
  props: {
    isOpen: {
      type: Boolean,
      required: false
    }
  }
});

export default DcwNavigationToggle;
