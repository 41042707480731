import { ErrorPage, PagesLinks } from '@/types';
import WrongImg from '@/assets/errors/oh_noo.svg';

export const error500: ErrorPage = {
  subtitle: '‘Der Server hat einem Fehler gemeldet',
  description: 'Haben Sie eine Idee, wie man den Fehler beheben kann?',
  firstLink: {
    text: 'Zurück zur Startseite',
    link: PagesLinks.HOME
  },
  secondLink: {
    text: 'Bewerben Sie sich bei uns und um den Fehler zu beheben',
    link: PagesLinks.JOBS
  },
  image: WrongImg
};
