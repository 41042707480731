import { offices } from '../offices';
import { people } from '../people';
import { HEADPHONES_EMOJI, LAPTOP_EMOJI, HAND_EMOJI, WAVE_EMOJI } from '@/consts';
import { HWSAsideContentType, HWSSectionContent } from '@/types';
import DiscordLogo from '@/assets/career/hws/discord-logo.svg';

const contentImageSize = {
  width: 156,
  height: 104
};

const mapSize = {
  width: 601,
  height: 520
};

export const hws: HWSSectionContent = {
  headline: {
    highlight: 'Tools &',
    after: 'Work Structure',
    subtitle: 'The most advanced communication tools allow us to work\n100% effectively, whether from the office or remotely.'
  },
  tabs: {
    communication: {
      label: 'Communication',
      asideContent: [
        {
          type: HWSAsideContentType.HEADLINE,
          headline: {
            before: 'Log in to Our\n',
            highlight: 'Online',
            after: 'Office',
            subtitle: 'Discord facilitates interaction\nbetween our international teams.\nSimply join our server to find out.'
          }
        },
        {
          type: HWSAsideContentType.BUTTON,
          button: {
            ariaLabel: 'Link to discord server',
            href: 'https://discord.gg/GxE6fneW',
            icon: DiscordLogo
          }
        },
        {
          type: HWSAsideContentType.TEXT,
          text: "Jump into our guest room and say\n'hello' to our teammates!"
        }
      ],
      content: {
        channelGroups: [
          {
            label: 'Teams',
            channels: [
              {
                label: 'General',
                emoji: LAPTOP_EMOJI
              },
              {
                label: 'Guest room',
                emoji: HAND_EMOJI,
                active: true
              }
            ]
          },
          {
            label: 'Meeting Rooms',
            channels: [
              {
                label: 'Meeting Room 1',
                emoji: WAVE_EMOJI
              },
              {
                label: 'Meeting Room 2',
                emoji: WAVE_EMOJI
              },
              {
                label: 'Chess Virgins',
                emoji: WAVE_EMOJI
              }
            ]
          },
          {
            label: 'Voice channels',
            channels: [
              {
                label: 'Dev Talks',
                emoji: HEADPHONES_EMOJI
              }
            ]
          }
        ],
        users: [
          {
            modifier: 'active',
            tooltip: 'Welcome to guest room',
            image: {
              src: '/career/hws/communication/stefanski.png',
              width: 103,
              height: 103,
              alt: 'Image of Wiktor Stefanski'
            }
          },
          {
            image: {
              src: '/career/hws/communication/morawiec.png',
              width: 100,
              height: 100,
              alt: 'Image of Szymon Morawiec'
            }
          },
          {
            image: {
              src: '/career/hws/communication/przystal.png',
              width: 100,
              height: 100,
              alt: 'Image of Dominik Przystał'
            }
          },
          {
            modifier: 'discord',
            tooltip: 'Join DC Server',
            image: {
              src: '/career/hws/communication/discord.png',
              width: 80,
              height: 80,
              alt: "Discord's logo"
            }
          }
        ]
      }
    },
    organization: {
      label: 'Organization',
      asideContent: [
        {
          type: HWSAsideContentType.HEADLINE,
          headline: {
            before: 'High Level\n',
            highlight: 'Communication',
            subtitle: 'We believe that open\ncommunication is crucial to\n effective cooperation. '
          }
        },
        {
          type: HWSAsideContentType.HEADLINE,
          headline: {
            highlight: '70',
            after: 'Channels',
            subtitle: 'Divided into working and social space.'
          }
        },
        {
          type: HWSAsideContentType.HEADLINE,
          headline: {
            highlight: '215',
            after: 'Members',
            subtitle: 'With unique vision to exceed possibilities.'
          }
        }
      ],
      content: {
        channelGroups: [
          {
            label: 'Channels',
            channels: [
              {
                label: '#development'
              },
              {
                label: '#onboarding',
                active: true
              },
              {
                label: '#design-team'
              },
              {
                label: '#quarterly-planning'
              }
            ]
          },
          {
            label: 'Direct Messages',
            channels: [
              {
                label: 'Place for You',
                notification: true
              },
              {
                label: 'Bartosz Cieśla',
                notification: true
              },
              {
                label: 'Szymon Morawiec',
                notification: true
              },
              {
                label: 'Wiktor Stefański',
                notification: true
              }
            ]
          }
        ],
        messages: [
          {
            image: {
              src: '/career/hws/organization/message-author.png',
              width: 64,
              height: 64,
              alt: 'Image of Paweł Kozera'
            },
            name: 'Paweł Kozera',
            position: 'HR Specialists',
            content: `Hi mate, How are you doing? I know that starting a new job can be stressful.
                We've all been in your position, so we want to help you to get you on board smoothly.

                On the first day, you will meet with me at the onboarding stage.
                I will introduce our company vision and guide you through the process
                of using tools like slack, discord, ClickUp and others.`
          }
        ],
        input: {
          string: 'Btw, take a look at our custom emojis:',
          emoji: [
            {
              width: 32,
              height: 32,
              alt: 'emoji',
              src: '/career/hws/organization/emoji-1.png'
            },
            {
              width: 32,
              height: 32,
              alt: 'emoji',
              src: '/career/hws/organization/emoji-2.png'
            },
            {
              width: 32,
              height: 32,
              alt: 'emoji',
              src: '/career/hws/organization/emoji-3.png'
            }
          ]
        }
      }
    },
    coworking: {
      label: 'Coworking',
      asideContent: [
        {
          type: HWSAsideContentType.HEADLINE,
          headline: {
            before: 'We Work in\n',
            highlight: 'Hybrid Model',
            subtitle:
              'Building digital future from Toronto\nto Berlin, we work around the clock\nto deliver innovative IT solutions.'
          }
        },
        {
          type: HWSAsideContentType.HEADLINE,
          headline: {
            highlight: '8',
            after: 'Cities',
            subtitle: 'Of leading expertise.'
          }
        },
        {
          type: HWSAsideContentType.HEADLINE,
          headline: {
            highlight: '5',
            after: 'Countries',
            subtitle: 'Of rich and diverse opportunities.'
          }
        }
      ],
      content: {
        headline: {
          before: 'Choose your',
          highlight: 'Working Place',
          subtitle: 'You can choose to join any of our 10 offices.'
        },
        cities: [
          {
            office: offices.cracow,
            member: people.wiktorS,
            images: {
              member: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-0.png',
                alt: 'Picture of Wiktor Stefański'
              },
              office: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-1.png',
                alt: 'Picture of our office at Rynek Główny 8, 31-042, Kraków'
              },
              map: {
                ...mapSize,
                src: '/career/hws/coworking/map-0.png',
                alt: 'Map of Europe with highlighted Kraków'
              }
            }
          },
          {
            office: offices.warsaw,
            member: people.szymonM,
            images: {
              office: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-3.png',
                alt: 'Picture of our office at Prosta 20, 00-850, Warsaw'
              },
              member: { ...contentImageSize, src: '/career/hws/coworking/content-2.png', alt: 'Picture of Szymon Morawiec' },
              map: {
                ...mapSize,
                src: '/career/hws/coworking/map-1.png',
                alt: 'Map of Europe with highlighted Warsaw'
              }
            }
          },
          {
            office: offices.gliwice,
            member: people.szymonN,
            images: {
              office: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-5.png',
                alt: 'Picture of our office at Konarskiego 18C, 44-100, Gliwice'
              },
              member: { ...contentImageSize, src: '/career/hws/coworking/content-4.png', alt: 'Picture of Szymon Nowak' },

              map: {
                ...mapSize,
                src: '/career/hws/coworking/map-2.png',
                alt: 'Map of Europe with highlighted Gliwice'
              }
            }
          },
          {
            office: offices.paris,
            member: people.leopoldG,
            images: {
              office: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-7.png',
                alt: 'Picture of our office at 106 Haussmann, 75008 Paris'
              },
              member: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-6.png',
                alt: 'Picture of Wiktor Stefański'
              },
              map: {
                ...mapSize,
                src: '/career/hws/coworking/map-3.png',
                alt: 'Map of Europe with highlighted Paris'
              }
            }
          },
          {
            office: offices.hamburg,
            member: people.szymonN,
            images: {
              office: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-9.png',
                alt: 'Picture of our office at 43 Gänsemarkt, 20354 Hamburg'
              },
              member: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-8.png',
                alt: 'Picture of Szymon Nowak'
              },
              map: {
                ...mapSize,
                src: '/career/hws/coworking/map-4.png',
                alt: 'Map of Europe with highlighted Hamburg'
              }
            }
          },
          {
            office: offices.berlin,
            member: people.janE,
            images: {
              office: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-11.png',
                alt: 'Picture of our office at Kurfürstendamm 11, 10719 Berlin'
              },
              member: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-10.png',
                alt: 'Picture of Jan Ellwitz'
              },
              map: {
                ...mapSize,
                src: '/career/hws/coworking/map-5.png',
                alt: 'Map of Europe with highlighted Berlin'
              }
            }
          },
          {
            office: offices.vienna,
            member: people.lukaszS,
            images: {
              office: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-13.png',
                alt: 'Picture of our office at Graben 19, 1010 Wien'
              },
              member: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-12.png',
                alt: 'Picture of Łukasz Sobieraj'
              },
              map: {
                ...mapSize,
                src: '/career/hws/coworking/map-6.png',
                alt: 'Map of Europe with highlighted Vienna'
              }
            }
          },
          {
            office: offices.toronto,
            member: people.karolS,
            images: {
              office: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-15.png',
                alt: 'Picture of our office at 292 Adelaide Street, ON M5V 1P6 Toronto'
              },
              member: {
                ...contentImageSize,
                src: '/career/hws/coworking/content-14.png',
                alt: 'Picture of Karol Sobieraj'
              },
              map: {
                ...mapSize,
                src: '/career/hws/coworking/map-7.png',
                alt: 'Map of Europe with highlighted Toronto'
              }
            }
          }
        ]
      }
    }
  }
};
