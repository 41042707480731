import { Ref } from '@nuxtjs/composition-api';

export const useScrollSlideIntoView =
(slidesWrapper: Ref<HTMLElement | null>, clickedElementIndex: number, center = true): void => {
  if (slidesWrapper.value) {
    const target = slidesWrapper.value.children[clickedElementIndex] as HTMLElement;

    const targetOffset = target.offsetLeft - slidesWrapper.value.offsetLeft;
    const widthOffset = (slidesWrapper.value.clientWidth - target.clientWidth) / 2;
    const left = targetOffset - (center ? widthOffset : 0);

    slidesWrapper.value.scrollTo({ left, behavior: 'smooth' });
  }
};
