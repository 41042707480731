import { onGlobalSetup, ref, Ref, provide, defineNuxtPlugin } from '@nuxtjs/composition-api';
import { BreadcrumbItem } from '@/types';

export const BreadcrumbItemsKey = Symbol('BreadcrumbItemsKey');
export const BreadcrumbSetKey = Symbol('BreadcrumbChangeKey');

export type InjectBreadcrumbItems = Ref<BreadcrumbItem[]>;
export type SetBreadcrumbs = (crumbs: BreadcrumbItem[]) => void;

export default defineNuxtPlugin(() => {
  const breadcrumbs = ref<BreadcrumbItem[]>([]);

  const setBreadcrumbs: SetBreadcrumbs = (crumbs: BreadcrumbItem[]) => {
    breadcrumbs.value = crumbs;
  };

  onGlobalSetup(() => {
    provide(BreadcrumbItemsKey, breadcrumbs);
    provide(BreadcrumbSetKey, setBreadcrumbs);
  });
});
