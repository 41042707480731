import { jobBanner } from './jobBanner';
import { about } from './about';
import { applyNowCard } from './applyNowCard';
import { benefits } from './benefits';
import { faq } from './faq';
import { reasonsToJoin } from './reasonsToJoin';
import { team } from './team';
import { overview } from './overview';

const staticSections = { about, applyNowCard, benefits, faq, reasonsToJoin, team, overview };

export const jobOffer = {
  jobBanner,
  staticSections
};
