var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dcw-offers-list",class:{
    'dcw-offers-list--cards': _vm.itemsType === _vm.JobOfferItemVariant.CARD,
    'dcw-offers-list--list': _vm.itemsType === _vm.JobOfferItemVariant.LIST_ITEM
  }},[_c('ul',{staticClass:"dcw-offers-list__wrapper"},[_vm._l((_vm.list),function(item,index){
  var _obj;
return [_c('li',{key:item.id,staticClass:"dcw-offers-list__grid-element",class:( _obj = {
          'dcw-offers-list__grid-element--double': _vm.isMultipleOf(2, index) && _vm.itemsType === _vm.JobOfferItemVariant.CARD,
          'dcw-offers-list__grid-element--triple': _vm.isMultipleOf(3, index) && _vm.itemsType === _vm.JobOfferItemVariant.CARD
        }, _obj[("dcw-offers-list__grid-element--part-" + ((index + 1) % 3))] = _vm.isOneOfTheLatest(index) && _vm.itemsType === _vm.JobOfferItemVariant.CARD, _obj )},[_c('DcwOfferItem',{staticClass:"dcw-offers-list__grid-item",attrs:{"variant":_vm.itemsType,"content":item,"has-hover-effect":false}})],1)]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }