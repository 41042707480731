








import { defineComponent } from '@nuxtjs/composition-api';

export const DcwWideGrid = defineComponent({
  name: 'DcwWideGrid'
});

export default DcwWideGrid;
