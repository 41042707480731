
















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SeniorityLevels } from '@/types';
import { getSeniorityLevels } from '@/helpers';

export const DcwSeniorityLevels = defineComponent({
  name: 'DcwSeniorityLevels',
  props: {
    level: {
      type: String as PropType<SeniorityLevels>,
      required: true
    }
  },
  setup(props) {
    const filteredLevels = getSeniorityLevels(props.level, props.level === SeniorityLevels.ARCHITECT);

    return { filteredLevels };
  }
});

export default DcwSeniorityLevels;
