import { onGlobalSetup, ref, provide, defineNuxtPlugin, Ref } from '@nuxtjs/composition-api';
import { HeaderColors } from '~/types';

export type SetWhiteHeader = (value: boolean) => void;

export interface HeaderStore {
  color: Ref<HeaderColors>;
  tabletColor: Ref<HeaderColors>;
  mobileColor: Ref<HeaderColors>;
}

export interface HeaderActions {
  setMobileHeaderColor: (color: HeaderColors) => void;
  setTabletHeaderColor: (color: HeaderColors) => void;
  setHeaderColor: (color: HeaderColors) => void;
}

export const HeaderContextKey = Symbol('HeaderKey');
export const HeaderActionsKey = Symbol('HeaderActionsKey');

export default defineNuxtPlugin(() => {
  const color = ref(HeaderColors.DEFAULT);
  const tabletColor = ref(HeaderColors.DEFAULT);
  const mobileColor = ref(HeaderColors.DEFAULT);

  const store: HeaderStore = {
    color,
    tabletColor,
    mobileColor
  };

  const setHeaderColor = (c: HeaderColors) => {
    color.value = c;
  };

  const setTabletHeaderColor = (c: HeaderColors) => {
    tabletColor.value = c;
  };

  const setMobileHeaderColor = (c: HeaderColors) => {
    mobileColor.value = c;
  };

  const headerActions: HeaderActions = { setHeaderColor, setTabletHeaderColor, setMobileHeaderColor };

  onGlobalSetup(() => {
    provide(HeaderContextKey, store);
    provide(HeaderActionsKey, headerActions);
  });
});
