/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

export const story: SuccessStory = {
  name: 'Wojciech',
  videoId: '710735110',
  images: {
    og: '/covers/success-story/story-9.jpg',
    avatar: {
      src: '/success-stories/story-9/avatar.png',
      alt: 'Wojciech',
      width: 32,
      height: 32
    },
    cover: {
      src: '/success-stories/story-9/cover.jpg',
      alt: 'Wojciech cover',
      height: 608
    },
    slider: {
      src: '/success-stories/story-9/slider.png',
      alt: 'Wojciech',
      width: 317,
      height: 224
    },
    listing: {
      src: '/success-stories/story-9/listing.png',
      alt: 'Wojciech',
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'May 12, 2022',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'Frontend Developer',
  details: `At least from the frontend side, a good architecture would be one that allows many
            developers to cooperate simultaneously without blocking each other. I would probably go
            in the direction of a modular monolith. It’s a term that’s
            lately used in terms of our project very often.`,
  shortStory:
    'Creating own digital products and introducing them to the market allows the “developer” for a whole new dimension.',
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer:
        'Before DC I was dealing with many things but in general, it was mainly electronic business, creation of internet-based products, and development of all kinds of applications. Applications in fact are products that most big companies make a living from. Creating those products, and at some point, I also had my own ones, is the biggest accomplishment in IT and the biggest chance for development. Not only does one master the profession itself but also introduce them to the market. This allows the “developer” for a whole new dimension.'
    },
    {
      type: 'text',
      title: 'Why did you choose DC?',
      answer:
        'Everything started with a head-hunter. It was mainly a coincidence that I replied to her on LinkedIn. She told me about DC and it was a point where I was ready to make a change. The things that convinced me were a seemingly good atmosphere, positive organizational culture, and the marketing that just makes everything visible from the outside. Later I found out that one of my friends already works there so I felt that it’s a safe ground for me too.'
    },
    {
      type: 'text',
      title: 'What is a good architecture for you?',
      answer:
        'At least from the frontend side, a good architecture would be one that allows many developers to cooperate simultaneously without blocking each other. I would probably go in the direction of a modular monolith. It’s a term that’s lately used in terms of our project very often. The essence is that micro frontends are useful for scaling in terms of big-sized teams. Our teams are never bigger than 10 people. Having a monolith that’s one app being bidded in one time and where everybody is adding some code in real-time is just very effective and time-saving in many cases.'
    },
    {
      type: 'image',
      details: 'Digital Colliers in Office',
      img: {
        src: '/success-stories/story-9/image-1.png',
        alt: 'Digital Colliers in Office',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.DC_IN_OFFICE
    },
    {
      type: 'text',
      title: 'What was the biggest challenge you had to overcome?',
      answer:
        'The biggest challenge was migration from Vue 2 to Vue 3 and from Vue CLI 4 to 5, which includes Webpack 5. In the last few years, the changes had cascaded nature. The final release candidate for Vue CLI 5 has been happening in just the last 2-3 months so the full integration of the latest technology stack with Vue 3 with Webpack 5 is still very fresh for us. That was the biggest challenge because there are some real changes in the configuration of both Vue 3 and Webpack 5 and they required many code solutions for Vue 2 that had to be worked over.'
    },
    {
      type: 'text',
      title: 'What have you learned in DC?',
      answer:
        "For sure I’ve learned a lot about micro frontends in the configuration that we had while working with the German team. Firstly I’ve learned a lot just about working strictly with Germans. Secondly, I’ve learned that micro frontends in the case of small teams just make very little sense and they might be essentially a waste of time for my team as we don’t have much to scale. I’ve also learned about a new nice hexagonal architecture, which has been introduced by my colleagues and which is very useful for scaling. In the course of development, we also managed to develop Feature Environments per Feature. It’s a very original solution where the copy of the given environment is being created for the purpose of one task and which is automatically deleted when the code review is accepted and enters the codebase. Those Feature Envs are the top-tier of development arts where we can create a separate environment for each task. Creating something like that in big companies is usually impossible as it requires lots of time. It's usually only possible for new products or when the product is quite fresh. We were able to create a dream stack and dream configuration."
    },
    {
      type: 'text',
      title: 'What are the technologies that you’d like to learn but you lacked the opportunity of so far?',
      answer:
        "I am recently quite fascinated with blockchain technology and the way the IT industry starts using cryptocurrencies. My recent project is based on this sphere and the way payments can be concluded via crypto. It's something completely new that we are able to develop. I am really happy that I’m able to get in this direction. API for handling crypto payments, API for handling in-game payments connected to a blockchain is something that most companies are not yet using while this reality is growing a lot. The latest conference in London which we were attending proved how important this development path is."
    },
    {
      type: 'text',
      title: 'Where do you see yourself in 10 years?',
      answer:
        'I assume that I would have a few technological copartnerships. Some of them would probably be online products and we will be talking millions. It’s a long 10 years journey ahead but it’s the path to owning software. I believe however that with the appropriate effort it’s possible and feasible.'
    },
    {
      type: 'video',
      details: 'Interview with Wojciech',
      thumbnail: {
        src: '/success-stories/story-9/image-2.jpg',
        alt: 'Interview with Wojciech',
        width: 952,
        height: 404
      },
      videoId: '710735110',
      ytLink: YOUTUBE_LINKS.WOJCIECH_INTERVIEW
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Holy Molly - Shot a friend',
          img: {
            src: '/success-stories/story-9/song-1.png',
            alt: 'Holy Molly - Shot a friend',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/27yUNsQo2CPPokNw69M23X?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=tk_vew5zA5c'
        },
        {
          title: 'Sanah & Dawid Podsiadło - Ostatnia Nadzieja',
          img: {
            src: '/success-stories/story-9/song-2.png',
            alt: 'Sanah & Dawid Podsiadło - Ostatnia Nadzieja',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/09oLypr5y21Yc63sYU59nt',
          ytLink: 'https://www.youtube.com/watch?v=5Iwy_xeJi7c'
        },
        {
          title: 'James Horner - Becoming of “The People” Becoming one with Neytiri',
          img: {
            src: '/success-stories/story-9/song-3.png',
            alt: 'James Horner - Becoming of “The People” Becoming one with Neytiri',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/1UBd7aWHZCz8yirI9KCvAU?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=qo7LNrzb7_E'
        }
      ]
    }
  ]
};
