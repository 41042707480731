import { formatMessage } from '@/helpers';
import { HeadlineContent } from '@/types';

export const getHeadlineWithExtraContent = (headline: HeadlineContent, content?: number | string): HeadlineContent => {
  if (!content) {
    return headline;
  }

  const target = typeof content === 'number' ? content.toString() : content;

  return {
    ...headline,
    before: headline.before ? formatMessage(headline.before, { target }) : undefined,
    highlight: headline.highlight ? formatMessage(headline.highlight, { target }) : undefined,
    after: headline.after ? formatMessage(headline.after, { target }) : undefined,
    subtitle: headline.subtitle ? formatMessage(headline.subtitle, { target }) : undefined
  };
};
