/* eslint-disable max-len */
import { CountryNames, OfficeModel } from '@/types';

export const gliwice: OfficeModel = {
  city: 'Gliwice',
  country: CountryNames.POLAND,
  name: 'Technopark Gliwice',
  address: {
    first: 'Konarskiego 18C',
    second: '44-100'
  },
  map:
    'https://www.google.com/maps/place/Konarskiego+18C,+44-100+Gliwice/@50.2919493,18.6802414,17z/data=!3m1!4b1!4m5!3m4!1s0x47113100e7de86fd:0xa7fe9031baf7acef!8m2!3d50.2919459!4d18.6824301'
};
