import { ImageElementAttributes } from '@/types';

export const jobBanner: ImageElementAttributes[] = [
  {
    src: '/job-banner/banner-1.png',
    alt: 'Kamil and Szymon',
    height: 380,
    width: 503
  },
  {
    src: '/job-banner/banner-2.png',
    alt: 'Kamil and Maciej',
    height: 377,
    width: 546
  },
  {
    src: '/job-banner/banner-3.png',
    alt: 'Jan and Maciej',
    height: 381,
    width: 522
  },
  {
    src: '/job-banner/banner-4.png',
    alt: 'Mateusz and Maciej',
    height: 389,
    width: 558
  },
  {
    src: '/job-banner/banner-5.png',
    alt: 'Kacper and Kamil',
    height: 384,
    width: 503
  },
  {
    src: '/job-banner/banner-6.png',
    alt: 'Mateusz and Maciej',
    height: 382,
    width: 615
  },
  {
    src: '/job-banner/banner-7.png',
    alt: 'Arkadiusz and Kamil',
    height: 373,
    width: 486
  },
  {
    src: '/job-banner/banner-8.png',
    alt: 'Leopold and young man',
    height: 381,
    width: 503
  }
];
