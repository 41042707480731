import { hero } from './banner';
import { testimonial } from './testimonial';
import { management } from './management';
import { ourOffices } from './ourOffices';
import { numbers } from './numbers';
import { description } from './description';
import { culture } from './culture';
import { values } from './values';
import { story } from './story';

export const aboutPage = {
  management,
  ourOffices,
  testimonial,
  numbers,
  description,
  culture,
  values,
  story,
  hero
};
