/* eslint-disable max-len */
import { GalleryItemType, SectionWithGalleryContent } from '@/types';

export const culture: SectionWithGalleryContent = {
  headline: {
    before: 'Our Creative',
    highlight: 'Culture',
    subtitle:
      'Our studios may be separated geographically, but our interest unites us in a creative and collaborative working environment. Our team represents innovation, professionalism, personal growth, and fun. We embrace a wide range of cultural backgrounds, knowledge, and experiences to create truly unique working spaces. '
  },
  columns: [
    {
      before: 'Co-working Offices',
      subtitle: 'We are currently renting multiple co-working office spaces around the world to meet the needs of our teams.'
    },
    {
      before: 'Exclusive Community',
      subtitle: 'Digital Colliers is a network of future oriented professionals that inspire themselves and those around them. '
    }
  ],
  gallery: [
    {
      type: GalleryItemType.IMAGE,
      img: {
        width: 586,
        height: 227,
        src: '/about/culture/image-1.jpg',
        alt: 'developers in natural habitat'
      }
    },
    {
      type: GalleryItemType.IMAGE,
      img: {
        width: 281,
        height: 227,
        src: '/about/culture/image-2.jpg',
        alt: 'developers in natural habitat'
      }
    },
    {
      type: GalleryItemType.IMAGE,
      img: {
        width: 281,
        height: 227,
        src: '/about/culture/image-3.jpg',
        alt: 'developers in natural habitat'
      }
    }
  ]
};
