/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, ProjectIDs, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: false,
  categories: [JobOfferCategories.BACKEND],
  projects: [ProjectIDs.LOTTERY_PLATFORM],
  name: 'Senior Backend Developer (Java / Kotlin)',
  salaryRanges: salaries.backend,
  seniorityLevel: SeniorityLevels.SENIOR,
  referrers: {
    [Referrers.DC_WEBSITE]: 168595391,
    [Referrers.OTHER]: 78447475,
    [Referrers.CARROT_WORK]: 78447342,
    [Referrers.JUST_JOIN_IT]: 78446981,
    [Referrers.NO_FLUFF_JOBS]: 78451562,
    [Referrers.BULLDOG_JOB_PL]: 78447448,
    [Referrers.FACEBOOK_ADS]: 192884868,
    [Referrers.GOOGLE_ADS]: 192884874
  },
  description: [
    {
      title: 'Description',
      text: [
        'We are currently looking for a \bSenior Backend Developer\b  to join our team, and help us develop one of the verticals in our core project - \bThe innovative Lottery SaaS Platform.\b',
        'We write the project in Kotlin, but we believe that for a good programmer, a language is only a tool, so we do not require you to know Kotlin in-depth.',
        "However, if you are an unrepentant confesser of Java and you do not want to develop in other technologies, we have a job on another project to offer. Leave us your CV, let's call you and tell you about all the details of the project."
      ]
    },
    {
      title: 'Your role and responsibilities will focus on these key areas',
      items: [
        "Working on an innovative lottery SaaS platform that's made up of a complex ecosystem of web applications",
        'Developing new features, solving technical problems, performing code reviews',
        'At the beginning of our cooperation develop Shopfront - a generic frontend that renders dynamically based on the configuration provided from the backend.'
      ]
    },
    {
      title: "We're Looking for Someone Who Has",
      items: [
        'Proactive attitude and approach to solving problems',
        'At least 5 years of experience in developing advanced web applications for commercial projects',
        'Commercial experience in Kotlin or willingness to learn it',
        'Experience in a microservice architecture',
        'Good understanding of hexagonal architecture',
        'Good understanding and skills in creating modern API based on REST',
        'Willingness to be up to date with the newest technologies'
      ]
    },
    {
      title: 'An additional plus will be',
      items: [
        'Knowledge of Kotlin, Ktor, Koin, GraphQL, and REST',
        'Experience with Kubernetes, Helm, GitLab CI/CD, AWS',
        'The ability to write E2E tests using Cypress',
        'The ability to write unit-test using Jest'
      ]
    }
  ],
  technologies: [
    { label: 'Java', level: TechnologyLevels.MASTER },
    { label: 'Kotlin', level: TechnologyLevels.MASTER },
    { label: 'AWS', level: TechnologyLevels.REGULAR },
    { label: 'Microservices', level: TechnologyLevels.REGULAR },
    { label: 'Hexagonal Architecture', level: TechnologyLevels.REGULAR },
    { label: 'Ktor', level: TechnologyLevels.JUNIOR }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'A half-hour call with our recruiter'
    },
    {
      title: 'Dev Call',
      description: 'One-hour technical assessment'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process in \b1 week\b'
    }
  ]
};
