import { gsap } from 'gsap';

interface UseGsap {
  timeLine: gsap.core.Timeline;
  resetInlineStyles: (elements: (HTMLElement | null)[]) => void;
  setStyles: (elements: (HTMLElement | null)[], properties: gsap.TweenVars) => void;
}

export const useGsap = (): UseGsap => {
  const timeLine = gsap.timeline();

  const forAllElements = (elements: (HTMLElement | null)[], cb: (el: HTMLElement) => void) => {
    elements.forEach((element) => {
      if (element) {
        cb(element);
      }
    });
  };

  const resetInlineStyles = (elements: (HTMLElement | null)[]) => {
    forAllElements(elements, (element: HTMLElement) => {
      element.style.cssText = '';
    });
  };

  const setStyles = (elements: (HTMLElement | null)[], properties: gsap.TweenVars) => {
    forAllElements(elements, (element: HTMLElement) => {
      timeLine.set(element, properties);
    });
  };

  return { timeLine, resetInlineStyles, setStyles };
};
