const colorsHEXOpacity = {
  zero: '00',
  five: '0D',
  ten: '1A',
  fifteen: '26',
  twenty: '33',
  thirty: '4D',
  forty: '66',
  fifty: '7F',
  sixty: '99',
  seventy: 'B2',
  eighty: 'CC',
  ninety: 'E5',
  hundred: 'FF'
};

type KeysOfColorsHEXOpacity = keyof typeof colorsHEXOpacity;

type GetHEXOpacityColorReturn = { [key in KeysOfColorsHEXOpacity]: string };

export const getHEXOpacityColor = (color: string): GetHEXOpacityColorReturn => {
  const createAlphaColor = ([key, value]: [string, string]) => [key, `${color}${value}`];

  return Object.fromEntries(Object.entries(colorsHEXOpacity).map(createAlphaColor)) as GetHEXOpacityColorReturn;
};
