







import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwButton } from '@atoms';
import {
  LinkContent,
  HeadlineTypes,
  ButtonVariants,
  HeadlineContent,
  HeadlineSubtitleSizes,
  HeadlineSubtitleSpacing
} from '@/types';

interface SmSubItemProps {
  headline: HeadlineContent;
  link: LinkContent;
}

export const DcwSmSubItem = defineComponent({
  name: 'DcwSmSubItem',
  components: { DcwNewHeadline, DcwButton },
  props: {
    content: {
      type: Object as PropType<SmSubItemProps>,
      required: true
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      type: HeadlineTypes.P,
      styledAs: HeadlineTypes.H4,
      isTinted: true,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.EXTRA_LARGE
    };

    const buttonProps = {
      ...props.content.link,
      isLink: true,
      variant: ButtonVariants.TERTIARY
    };

    return { headlineProps, buttonProps };
  }
});

export default DcwSmSubItem;
