import { TeamArgumentationBannerContent } from '@/types';
import TNWIcon from '@/assets/common/showcases/showcase-1.svg';
import TCIcon from '@/assets/common/showcases/showcase-3.svg';
import BusInsider from '@/assets/common/showcases/showcase-4.svg';
import AwwwIcon from '@/assets/common/showcases/showcase-2.svg';
import FranAngIcon from '@/assets/common/showcases/showcase-5.svg';
import Newsweek from '@/assets/common/showcases/showcase-6.svg';

export const hero: TeamArgumentationBannerContent = {
  headline: {
    before: 'Build or',
    highlight: 'Scale Up\n',
    after: 'Your Development Teams',
    subtitle: 'We build dedicated software development teams created specifically for your project.'
  },
  items: [
    {
      highlight: '100+',
      after: 'Specialists',
      subtitle: 'Working as distributed teams for 15+ clients all over the world.'
    },
    {
      highlight: '20+',
      after: 'Technologies',
      subtitle: 'Handled by our staff, working in a variety of different industries.'
    }
  ],
  images: {
    large: {
      src: '/team-augmentation/banner/large.png',
      width: 693,
      height: 421,
      alt: 'Our team'
    },
    small: {
      src: '/team-augmentation/banner/small.png',
      width: 501,
      height: 421,
      alt: 'Our team'
    }
  },
  features: [AwwwIcon, TNWIcon, BusInsider, TCIcon, FranAngIcon, Newsweek]
};
