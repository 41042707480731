
































import { defineComponent, onMounted, PropType, ref } from '@nuxtjs/composition-api';
import Arrow from '@/assets/arrow.svg';
import { BreadcrumbItem } from '@/types';
import { isProduction } from '@/helpers';
import { useLocales } from '@/composables';

export const DcwBreadcrumbs = defineComponent({
  name: 'DcwBreadcrumbs',
  components: { Arrow },
  props: {
    breadcrumbs: {
      type: Array as PropType<BreadcrumbItem[]>,
      required: true
    }
  },
  setup(props) {
    const breadcrumbsList = ref<HTMLOListElement | null>(null);
    const isLast = (index: number): boolean => index === props.breadcrumbs.length - 1;
    const { domain } = useLocales();
    const baseUrl = isProduction ? 'https://' + domain : '';

    onMounted(() => {
      if (!breadcrumbsList.value) {
        return;
      }
      const wrapperWidth = breadcrumbsList.value.scrollWidth;
      const clientWidth = breadcrumbsList.value.clientWidth;

      if (wrapperWidth > clientWidth) {
        breadcrumbsList.value.scrollLeft = wrapperWidth;
      }
    });

    return { breadcrumbsList, baseUrl, isLast };
  }
});

export default DcwBreadcrumbs;
