













import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { HtmlInlineElements } from '@/types';

export const DcwText = defineComponent({
  name: 'DcwText',
  props: {
    as: {
      type: String as PropType<keyof HTMLElementTagNameMap>,
      default: HtmlInlineElements.P
    },
    text: {
      type: String,
      required: true
    }
  }
});

export default DcwText;
