







import { PropType, defineComponent, computed } from '@nuxtjs/composition-api';
import { DcwHeader } from '@organisms';
import Errors from '@/components/Pages/Errors/DcwErrors.vue';
import { CssVariables, NuxtError, ProjectSelectors } from '@/types';
import { useLocales, usePage } from '@/composables';

export default defineComponent({
  name: 'DcwError',
  components: {
    Errors,
    DcwHeader
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>
    }
  },
  setup(props) {
    const { translation } = useLocales();

    const content = computed(() => {
      if (props.error) {
        return translation.errorPages[`error${props.error.statusCode}`];
      }
    });

    usePage({
      styleSheet: [
        {
          selectors: [ProjectSelectors.HEADER],
          properties: [{ key: CssVariables.COLOR_BACKGROUND, value: `var(${CssVariables.COLOR_SKY_BLUE})` }]
        }
      ]
    });
    return { content };
  },
  head: {}
});
