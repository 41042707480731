




































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import DcwButton from '@/components/Atoms/Button/DcwButton.vue';
import { ButtonVariants, ContentBlockSpacings, ContentBlockData } from '@/types';
import { isInEnum } from '@/helpers';

export const DcwContentBlock = defineComponent({
  name: 'DcwContentBlock',
  components: { DcwButton },
  props: {
    content: {
      type: Object as PropType<ContentBlockData>,
      required: false
    },
    spacing: {
      type: String as PropType<ContentBlockSpacings>,
      validator: (value: ContentBlockSpacings) => isInEnum(value, ContentBlockSpacings),
      default: ContentBlockSpacings.SMALL
    }
  },
  emits: ['first-button-clicked', 'second-button-clicked'],
  setup(props) {
    const firstButtonExtraProps =
      !!props.content?.firstButton?.to && props.content?.firstButton?.hasTargetBlank ? { target: '_blank', rel: 'noopener' } : {};

    const secondButtonExtraProps =
      !!props.content?.secondButton?.to && props.content?.secondButton?.hasTargetBlank
        ? { target: '_blank', rel: 'noopener' }
        : {};

    return { ButtonVariants, secondButtonExtraProps, firstButtonExtraProps };
  }
});

export default DcwContentBlock;
