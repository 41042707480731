/* eslint-disable max-len */
import { CountryNames, OfficeModel } from '@/types';

export const hamburg: OfficeModel = {
  name: 'Gänsemarkt Office',
  country: CountryNames.GERMANY,
  city: 'Hamburg',
  address: {
    first: '43 Gänsemarkt',
    second: '20354'
  },
  map:
    'https://www.google.com/maps/place/G%C3%A4nsemarkt+43,+20354+Hamburg,+%D0%93%D0%B5%D1%80%D0%BC%D0%B0%D0%BD%D0%B8%D1%8F/@53.5557986,9.98578,17z/data=!3m1!4b1!4m5!3m4!1s0x47b18f19cb92d0d9:0x3b6814d891bc8182!8m2!3d53.5557954!4d9.9879687'
};
