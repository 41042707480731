













import { defineComponent, toRef, watch } from '@nuxtjs/composition-api';
import { useBreakpoints, useInjectStrict, useLocales } from '@/composables';
import { RequestStatus, ScheduleMeetingFormData, UpdateScheduleMeetingFormData } from '@/types';
import { ScheduleMeetingFormDataKey, ScheduleMeetingRequestStatusKey, UpdateScheduleMeetingFormKey } from '@/consts';
import { getMinDate } from '@/helpers';

export const DcwScheduleModalDatePicker = defineComponent({
  name: 'DcwScheduleModalDatePicker',
  setup() {
    const { isMobile } = useBreakpoints();
    const { locale } = useLocales();

    const formData = useInjectStrict<Readonly<ScheduleMeetingFormData>>(ScheduleMeetingFormDataKey);
    const updateFormData = useInjectStrict<UpdateScheduleMeetingFormData>(UpdateScheduleMeetingFormKey);
    const status = useInjectStrict<RequestStatus>(ScheduleMeetingRequestStatusKey);
    const dateModel = toRef(formData, 'date');

    watch(dateModel, () => {
      updateFormData('date', dateModel.value)(formData);
    });

    const minDate = getMinDate();

    const datePickerAttributes = [
      {
        highlight: {
          fillMode: 'light'
        },
        dates: [
          {
            start: minDate,
            weekdays: [2, 3, 4, 5, 6]
          }
        ]
      }
    ];

    return { locale, datePickerAttributes, minDate, dateModel, status, RequestStatus, isMobile };
  }
});

export default DcwScheduleModalDatePicker;
