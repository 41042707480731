










import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwPeopleSlide } from './components/DcwPeopleSlide.vue';
import { DcwSwiper } from '@atoms';
import { Breakpoints, ButtonVariants, PersonCard, SwiperPropsOptions } from '@/types';
import { SwiperInfo } from '@/composables';

export const DcwPeopleSlider = defineComponent({
  name: 'DcwPeopleSlider',
  components: { DcwSwiper, DcwPeopleSlide },
  props: {
    sliderInfo: {
      type: Object as PropType<SwiperInfo>,
      required: true
    },
    slides: {
      type: Array as PropType<PersonCard[]>,
      required: true
    },
    isCarousel: {
      type: Boolean,
      default: false
    },
    sliderHandler: {
      type: Function,
      required: true
    },
    hasOneSlide: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const swiperOptions: SwiperPropsOptions<PersonCard> = {
      swiperConfig: {
        slidesPerView: 1,
        speed: 300,
        loop: props.isCarousel,
        spaceBetween: 0,
        breakpoints: {
          [Breakpoints.EXTRA_SMALL]: {
            slidesPerView: 1.2,
            spaceBetween: 24
          },
          [Breakpoints.SMALL_MOBILE]: {
            slidesPerView: 1.25,
            spaceBetween: 24
          },
          400: {
            slidesPerView: 1.3,
            spaceBetween: 24
          },
          [Breakpoints.MOBILE]: {
            slidesPerView: 2,
            slidesPerGroup: props.hasOneSlide ? 1 : 2,
            spaceBetween: 0
          },
          991: {
            slidesPerView: 2,
            slidesPerGroup: props.hasOneSlide ? 1 : 2,
            allowTouchMove: false,
            spaceBetween: 0
          },
          [Breakpoints.MEDIUM_DESKTOP]: {
            slidesPerView: 3,
            slidesPerGroup: props.hasOneSlide ? 1 : 3,
            allowTouchMove: false,
            spaceBetween: 0
          }
        }
      },
      slides: props.slides,
      onSlideChange: props.sliderHandler
    };

    return { swiperOptions, ButtonVariants };
  }
});

export default DcwPeopleSlider;
