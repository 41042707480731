
















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwLink } from '@atoms';
import { UpHeadingContent } from '@/types';

export const DcwUpHeading = defineComponent({
  name: 'DcwUpHeading',
  components: { DcwLink },
  props: {
    content: {
      type: Object as PropType<UpHeadingContent>,
      required: true
    }
  }
});

export default DcwUpHeading;
