import { isUnfinishedSectionEnabled } from '@/helpers/environment';
import { offers } from '@/translations/offers';
import { successStory } from '@/translations/successStory';
import { projects } from '@/translations/projects';
import { technologies } from '@/translations/technologies';
import { blogPosts } from '@/translations/blog';

export const isDynamicRouteInvalid = (path: string): boolean => {
  const routeInfo = path.split('/');

  const pageIsJobOffer = routeInfo[1] === 'career' && routeInfo[2] === 'jobs' && !!routeInfo[3];
  const pageIsProject = routeInfo[1] === 'career' && routeInfo[2] === 'projects' && !!routeInfo[3];
  const pageIsSuccessStory = routeInfo[1] === 'career' && routeInfo[2] === 'success-stories' && !!routeInfo[3];
  const pageIsTechnology = routeInfo[1] === 'technologies' && !!routeInfo[2];
  const pageIsBlog = routeInfo[1] === 'blog' && !!routeInfo[2];

  const isJobOfferInvalid = pageIsJobOffer && !Object.keys(offers).includes(routeInfo[3]);
  const isProjectInvalid = pageIsProject && !Object.keys(projects).includes(routeInfo[3]);
  const isStoryInvalid = pageIsSuccessStory && !Object.keys(successStory).includes(routeInfo[3]);
  const isTechnologyInvalid = pageIsTechnology && !Object.keys(technologies).includes(routeInfo[2]);
  const isBlogInvalid = pageIsBlog && !Object.keys(blogPosts).includes(routeInfo[2]);

  return [isJobOfferInvalid, isProjectInvalid, isTechnologyInvalid, isBlogInvalid, isStoryInvalid].some((item) => !!item);
};

export const isRouteUnfinished = (path: string) => {
  if (isUnfinishedSectionEnabled) {
    return false;
  }

  const routeInfo = path.split('/');

  const isTechnologiesPage = routeInfo[1] === 'technologies';
  const isBlogPage = routeInfo[1] === 'blog';

  return [isTechnologiesPage, isBlogPage].some((item) => !!item);
};
