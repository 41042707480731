import { YOUTUBE_LINKS } from '@/consts';
import { PagesLinks, OneOfUsSectionContent } from '@/types';

const height = 331;

export const oneOfUs: OneOfUsSectionContent = {
  headline: {
    before: 'Become One of Us,\nOne of',
    highlight: 'Colliers',
    subtitle: 'We welcome speculative applications from talented people keen to join our rapidly growing community.'
  },
  slides: [
    [
      {
        kind: 'image',
        priority: 4,
        offsets: [],
        image: {
          height,
          width: 210,
          src: '/career/one-of-us/us-member-0.webp',
          alt: 'Image of a Collier #1'
        }
      },
      {
        kind: 'image',
        priority: 6,
        offsets: [],
        image: {
          height,
          width: 215,
          src: '/career/one-of-us/us-member-1.webp',
          alt: 'Image of a Collier #2'
        }
      },
      {
        kind: 'image',
        priority: 5,
        offsets: [0, 0, 0, 50, 32],
        image: {
          height,
          width: 305,
          src: '/career/one-of-us/us-member-2.webp',
          alt: 'Image of a Collier #3'
        }
      },
      {
        kind: 'image',
        priority: 2,
        offsets: [0, 2, -8, 0],
        image: {
          height,
          width: 322,
          src: '/career/one-of-us/us-member-3.webp',
          alt: 'Image of a Collier #4'
        }
      },
      {
        kind: 'content',
        mobile: true,
        buttonType: 'positions',
        headline: {
          before: 'Exceptional',
          highlight: 'Offers',
          subtitle: 'Find a job that matches your interests and start progressing your career'
        },
        button: {
          text: 'Open positions',
          to: PagesLinks.JOBS
        }
      },
      {
        kind: 'image',
        priority: 3,
        offsets: [0, 10, 0],
        image: {
          height,
          width: 253,
          src: '/career/one-of-us/us-member-4.webp',
          alt: 'Image of a Collier #5'
        }
      }
    ],
    [
      {
        kind: 'image',
        priority: 6,
        offsets: [],
        image: {
          height,
          width: 249,
          src: '/career/one-of-us/us-member-5.webp',
          alt: 'Image of a Collier #6'
        }
      },
      {
        kind: 'image',
        priority: 5,
        offsets: [],
        image: {
          height,
          width: 229,
          src: '/career/one-of-us/us-member-6.webp',
          alt: 'Image of a Collier #7'
        }
      },
      {
        kind: 'image',
        priority: 0,
        offsets: [-20, -20, 0],
        image: {
          height,
          width: 207,
          src: '/career/one-of-us/us-member-7.webp',
          alt: 'Image of a Collier #8'
        }
      },
      {
        kind: 'content',
        mobile: false,
        buttonType: 'projects',
        headline: {
          before: 'Innovative',
          highlight: 'Projects',
          subtitle: 'Choose your stack and industry from a selection of projects across Europe.'
        },
        button: {
          text: 'See open projects',
          to: PagesLinks.PROJECTS
        }
      },
      {
        kind: 'image',
        priority: 1,
        offsets: [],
        image: {
          height,
          width: 202,
          src: '/career/one-of-us/us-member-8.webp',
          alt: 'Image of a Collier #9'
        }
      },
      {
        kind: 'image',
        priority: 4,
        offsets: [0, 0, 0, 20],
        image: {
          height,
          width: 297,
          src: '/career/one-of-us/us-member-9.webp',
          alt: 'Image of a Collier #10'
        }
      }
    ],
    [
      {
        kind: 'image',
        priority: 4,
        offsets: [],
        image: {
          height,
          width: 201,
          src: '/career/one-of-us/us-member-10.webp',
          alt: 'Image of a Collier #11'
        }
      },
      {
        kind: 'image',
        priority: 2,
        offsets: [0, 0, -8, 0, 5],
        image: {
          height,
          width: 210,
          src: '/career/one-of-us/us-member-11.webp',
          alt: 'Image of a Collier #12'
        }
      },
      {
        kind: 'content',
        mobile: true,
        buttonType: 'video',
        headline: {
          before: 'Inclusive',
          highlight: 'Community',
          subtitle: 'The value of long-term relationships built on honesty, professionalism and dedication.'
        },
        button: {
          text: 'Watch video',
          to: YOUTUBE_LINKS.BOLOGNA_INTEGRATION_TRIP
        }
      },
      {
        kind: 'image',
        priority: 5,
        offsets: [0, 0, 0, 15, -10],
        image: {
          height,
          width: 409,
          src: '/career/one-of-us/us-member-12.webp',
          alt: 'Image of a Collier #13'
        }
      },
      {
        kind: 'image',
        priority: 3,
        offsets: [0, 0, 0, 64, 68],
        image: {
          height,
          width: 284,
          src: '/career/one-of-us/us-member-13.webp',
          alt: 'Image of a Collier #14'
        }
      },
      {
        kind: 'image',
        priority: 6,
        offsets: [0, 0, 0, 0, 5],
        image: {
          height,
          width: 275,
          src: '/career/one-of-us/us-member-14.webp',
          alt: 'Image of a Collier #15'
        }
      }
    ],
    [
      {
        kind: 'image',
        priority: 6,
        offsets: [],
        image: {
          height,
          width: 283,
          src: '/career/one-of-us/us-member-15.webp',
          alt: 'Image of a Collier #16'
        }
      },
      {
        kind: 'image',
        priority: 0,
        offsets: [-25, 0],
        image: {
          height,
          width: 187,
          src: '/career/one-of-us/us-member-16.webp',
          alt: 'Image of a Collier #17'
        }
      },
      {
        kind: 'content',
        mobile: false,
        buttonType: 'positions',
        headline: {
          before: 'Exceptional',
          highlight: 'Offers',
          subtitle: 'Find a job that matches your interests and start progressing your career'
        },
        button: {
          text: 'Open positions',
          to: PagesLinks.JOBS
        }
      },
      {
        kind: 'image',
        priority: 1,
        offsets: [],
        image: {
          height,
          width: 222,
          src: '/career/one-of-us/us-member-17.webp',
          alt: 'Image of a Collier #18'
        }
      },
      {
        kind: 'image',
        priority: 4,
        offsets: [0, 0, 0, 0, 5],
        image: {
          height,
          width: 260,
          src: '/career/one-of-us/us-member-18.webp',
          alt: 'Image of a Collier #19'
        }
      },
      {
        kind: 'image',
        priority: 5,
        offsets: [],
        image: {
          height,
          width: 248,
          src: '/career/one-of-us/us-member-19.webp',
          alt: 'Image of a Collier #20'
        }
      }
    ],
    [
      {
        kind: 'image',
        priority: 6,
        offsets: [],
        image: {
          height,
          width: 307,
          src: '/career/one-of-us/us-member-20.webp',
          alt: 'Image of a Collier #21'
        }
      },
      {
        kind: 'image',
        priority: 2,
        offsets: [32, 0, 0, 5, 0],
        image: {
          height,
          width: 204,
          src: '/career/one-of-us/us-member-21.webp',
          alt: 'Image of a Collier #22'
        }
      },
      {
        kind: 'content',
        mobile: true,
        buttonType: 'projects',
        headline: {
          before: 'Innovative',
          highlight: 'Projects',
          subtitle: 'Choose your stack and industry from a selection of projects across Europe.'
        },
        button: {
          text: 'See open projects',
          to: PagesLinks.PROJECTS
        }
      },
      {
        kind: 'image',
        priority: 3,
        offsets: [],
        image: {
          height,
          width: 200,
          src: '/career/one-of-us/us-member-22.webp',
          alt: 'Image of a Collier #23'
        }
      },
      {
        kind: 'image',
        priority: 5,
        offsets: [0, 0, 0, 15, 0],
        image: {
          height,
          width: 382,
          src: '/career/one-of-us/us-member-23.webp',
          alt: 'Image of a Collier #24'
        }
      },
      {
        kind: 'image',
        priority: 4,
        offsets: [],
        image: {
          height,
          width: 189,
          src: '/career/one-of-us/us-member-24.webp',
          alt: 'Image of a Collier #25'
        }
      }
    ],
    [
      {
        kind: 'image',
        priority: 5,
        offsets: [],
        image: {
          height,
          width: 206,
          src: '/career/one-of-us/us-member-25.webp',
          alt: 'Image of a Collier #26'
        }
      },
      {
        kind: 'image',
        priority: 4,
        offsets: [],
        image: {
          height,
          width: 254,
          src: '/career/one-of-us/us-member-26.webp',
          alt: 'Image of a Collier #27'
        }
      },
      {
        kind: 'image',
        priority: 0,
        offsets: [28, 30, 0],
        image: {
          height,
          width: 280,
          src: '/career/one-of-us/us-member-27.webp',
          alt: 'Image of a Collier #28'
        }
      },
      {
        kind: 'content',
        mobile: false,
        buttonType: 'video',
        headline: {
          before: 'Inclusive',
          highlight: 'Community',
          subtitle: 'The value of long-term relationships built on honesty, professionalism and dedication.'
        },
        button: {
          text: 'Watch video',
          to: YOUTUBE_LINKS.BOLOGNA_INTEGRATION_TRIP
        }
      },
      {
        kind: 'image',
        priority: 6,
        offsets: [],
        image: {
          height,
          width: 271,
          src: '/career/one-of-us/us-member-28.webp',
          alt: 'Image of a Collier #29'
        }
      },
      {
        kind: 'image',
        priority: 1,
        offsets: [],
        image: {
          height,
          width: 204,
          src: '/career/one-of-us/us-member-29.webp',
          alt: 'Image of a Collier #30'
        }
      }
    ],
    [
      {
        kind: 'image',
        priority: 5,
        offsets: [0, 0, 0, -65, 10],
        image: {
          height,
          width: 223,
          src: '/career/one-of-us/us-member-30.webp',
          alt: 'Image of a Collier #31'
        }
      },
      {
        kind: 'image',
        priority: 4,
        offsets: [0, 0, -30, 0, 10],
        image: {
          height,
          width: 199,
          src: '/career/one-of-us/us-member-31.webp',
          alt: 'Image of a Collier #32'
        }
      },
      {
        kind: 'image',
        priority: 1,
        offsets: [0, 0, 0, 0, 7],
        image: {
          height,
          width: 227,
          src: '/career/one-of-us/us-member-32.webp',
          alt: 'Image of a Collier #33'
        }
      },
      {
        kind: 'content',
        mobile: false,
        buttonType: 'projects',
        headline: {
          before: 'Innovative',
          highlight: 'Projects',
          subtitle: 'Choose your stack and industry from a selection of projects across Europe.'
        },
        button: {
          text: 'See open projects',
          to: PagesLinks.PROJECTS
        }
      },
      {
        kind: 'image',
        priority: 6,
        offsets: [0, 0, 0, 0, 7],
        image: {
          height,
          width: 205,
          src: '/career/one-of-us/us-member-33.webp',
          alt: 'Image of a Collier #34'
        }
      },
      {
        kind: 'image',
        priority: 0,
        offsets: [0, 0, 0, 0, 25],
        image: {
          height,
          width: 243,
          src: '/career/one-of-us/us-member-34.webp',
          alt: 'Image of a Collier #35'
        }
      }
    ]
  ]
};
