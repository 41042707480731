/* eslint-disable max-len */
import { CountryNames, OfficeModel } from '@/types';

export const warsaw: OfficeModel = {
  name: 'Mennica Legacy Tower',
  country: CountryNames.POLAND,
  city: 'Warsaw',
  address: {
    first: 'Prosta 20',
    second: '00-850'
  },
  map:
    'https://www.google.com/maps/place/Prosta+20,+00-001+Warszawa/@52.2323788,20.9905691,17z/data=!3m1!4b1!4m5!3m4!1s0x471ecd784d1f0dd3:0xe9d7e3d4aba17642!8m2!3d52.2323755!4d20.9927578'
};
