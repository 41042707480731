/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, ProjectIDs, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: true,
  categories: [JobOfferCategories.FRONTEND],
  projects: [ProjectIDs.STOREFRONT, ProjectIDs.LOTTERY_PLATFORM],
  name: 'Vue Developer',
  salaryRanges: salaries.vue,
  seniorityLevel: SeniorityLevels.MID,
  referrers: {
    [Referrers.DC_WEBSITE]: 156503310,
    [Referrers.OTHER]: 168486577,
    [Referrers.CARROT_WORK]: 78451162,
    [Referrers.JUST_JOIN_IT]: 78451163,
    [Referrers.NO_FLUFF_JOBS]: 78451561,
    [Referrers.BULLDOG_JOB_PL]: 78451160,
    [Referrers.FACEBOOK_ADS]: 192884954,
    [Referrers.GOOGLE_ADS]: 192884956
  },
  description: [
    {
      title: 'Description',
      text: [
        'we are currently looking for an experienced \bVue.js Developer\b to join our team, and help us develop one of the verticals in our core project - The innovative Lottery SaaS Platform'
      ]
    },
    {
      title: 'Your Role and Responsibilities',
      items: [
        "Working on an innovative lottery SaaS platform that's made up of a complex ecosystem of web applications",
        'Developing new features, solving technical problems, performing code reviews',
        'At the beginning of our cooperation develop Shopfront - a generic frontend that renders dynamically based on the configuration provided from the backend'
      ]
    },
    {
      title: 'We’re Looking for Someone Who Has',
      items: [
        'Proactive attitude and approach to solving problems',
        'At least 2 years of experience in developing advanced web applications',
        'Great knowledge of both Vue 2 and Vue 3, with an emphasis on Composition API',
        'Great Knowledge of Typescript',
        'Experience in a microservice architecture',
        'Willingness to be up to date with the newest technologies'
      ]
    },
    {
      title: 'An additional plus will be',
      items: [
        'Knowledge of Nuxt 2 ',
        'Experience with GraphQL and Apollo',
        'The ability to write E2E tests using Cypress',
        'The ability to write unit-test using Jest'
      ]
    }
  ],
  technologies: [
    { label: 'Vue.js', level: TechnologyLevels.ADVANCED },
    { label: 'Typescript', level: TechnologyLevels.ADVANCED },
    { label: 'GraphQL', level: TechnologyLevels.JUNIOR },
    { label: 'Microservices', level: TechnologyLevels.REGULAR }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'A half-hour call with our recruiter'
    },
    {
      title: 'Dev Call',
      description: 'One-hour technical assessment with'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process in \b1 week\b'
    }
  ]
};
