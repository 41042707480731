




import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { TechnologyNames } from '@/types';
import angular from '@/assets/technologies/angular.svg';
import aws from '@/assets/technologies/aws.svg';
import dotNet from '@/assets/technologies/dot-net.svg';
import flutter from '@/assets/technologies/flutter.svg';
import googleCloud from '@/assets/technologies/google-cloud.svg';
import graphQL from '@/assets/technologies/graph-ql.svg';
import java from '@/assets/technologies/java.svg';
import javascript from '@/assets/technologies/javascript.svg';
import kotlin from '@/assets/technologies/kotlin.svg';
import ktor from '@/assets/technologies/ktor.svg';
import kubernetes from '@/assets/technologies/kubernetes.svg';
import laravel from '@/assets/technologies/laravel.svg';
import mongoDB from '@/assets/technologies/mongo-db.svg';
import mySQL from '@/assets/technologies/my-sql.svg';
import nodeJS from '@/assets/technologies/node-js.svg';
import nuxt from '@/assets/technologies/nuxt.svg';
import php from '@/assets/technologies/php.svg';
import rabbitMQ from '@/assets/technologies/rabbit-mq.svg';
import react from '@/assets/technologies/react.svg';
import redux from '@/assets/technologies/redux.svg';
import rxJs from '@/assets/technologies/rx-js.svg';
import solidity from '@/assets/technologies/solidity.svg';
import spring from '@/assets/technologies/spring.svg';
import symfony from '@/assets/technologies/symfony.svg';
import tensorflow from '@/assets/technologies/tensorflow.svg';
import typescript from '@/assets/technologies/typescript.svg';
import vue from '@/assets/technologies/vue-js.svg';
import cSharp from '@/assets/technologies/c-sharp.svg';
import jest from '@/assets/technologies/jest.svg';
import helm from '@/assets/technologies/helm.svg';
import docker from '@/assets/technologies/docker.svg';
import redis from '@/assets/technologies/redis.svg';
import scss from '@/assets/technologies/scss.svg';
import postgres from '@/assets/technologies/postgres-sql.svg';
import python from '@/assets/technologies/python.svg';
import go from '@/assets/technologies/go.svg';
import rest from '@/assets/technologies/rest.svg';
import dynamoDB from '@/assets/technologies/dynamo-db.svg';
import mlAi from '@/assets/technologies/ml-ai.svg';
import terraform from '@/assets/technologies/terraform.svg';
import ethers from '@/assets/technologies/ethers.svg';
import hardhat from '@/assets/technologies/hardhat.svg';
import typeChain from '@/assets/technologies/type-chain.svg';
import web3 from '@/assets/technologies/web-3.svg';
import swift from '@/assets/technologies/swift.svg';
import json from '@/assets/technologies/json.svg';
import jetpack from '@/assets/technologies/jetpack.svg';

const TechnologyIcons = {
  [TechnologyNames.ANGULAR]: angular,
  [TechnologyNames.JAVA]: java,
  [TechnologyNames.JAVASCRIPT]: javascript,
  [TechnologyNames.TYPESCRIPT]: typescript,
  [TechnologyNames.SYMFONY]: symfony,
  [TechnologyNames.GRAPH_QL]: graphQL,
  [TechnologyNames.KUBERNETES]: kubernetes,
  [TechnologyNames.SPRING]: spring,
  [TechnologyNames.KTOR]: ktor,
  [TechnologyNames.DOT_NET]: dotNet,
  [TechnologyNames.NUXT_JS]: nuxt,
  [TechnologyNames.MONGO_DB]: mongoDB,
  [TechnologyNames.KOTLIN]: kotlin,
  [TechnologyNames.FLUTTER]: flutter,
  [TechnologyNames.SOLIDITY]: solidity,
  [TechnologyNames.AWS]: aws,
  [TechnologyNames.GOOGLE_CLOUD]: googleCloud,
  [TechnologyNames.TENSORFLOW]: tensorflow,
  [TechnologyNames.RX_JS]: rxJs,
  [TechnologyNames.RABBIT_MQ]: rabbitMQ,
  [TechnologyNames.LARAVEL]: laravel,
  [TechnologyNames.REACT]: react,
  [TechnologyNames.PHP]: php,
  [TechnologyNames.VUE_JS]: vue,
  [TechnologyNames.NODE_JS]: nodeJS,
  [TechnologyNames.REDUX]: redux,
  [TechnologyNames.MY_SQL]: mySQL,
  [TechnologyNames.C_SHARP]: cSharp,
  [TechnologyNames.JEST]: jest,
  [TechnologyNames.HELM]: helm,
  [TechnologyNames.DOCKER]: docker,
  [TechnologyNames.SCSS]: scss,
  [TechnologyNames.REDIS]: redis,
  [TechnologyNames.POSTGRES_SQL]: postgres,
  [TechnologyNames.REACT_NATIVE]: react,
  [TechnologyNames.PYTHON]: python,
  [TechnologyNames.GO]: go,
  [TechnologyNames.REST]: rest,
  [TechnologyNames.DYNAMO_DB]: dynamoDB,
  [TechnologyNames.ML_AI]: mlAi,
  [TechnologyNames.TERRAFORM]: terraform,
  [TechnologyNames.ETHERS]: ethers,
  [TechnologyNames.HARDHAT]: hardhat,
  [TechnologyNames.TYPE_CHAIN]: typeChain,
  [TechnologyNames.WEB_3]: web3,
  [TechnologyNames.SWIFT]: swift,
  [TechnologyNames.SWIFT_UI]: swift,
  [TechnologyNames.JSON]: json,
  [TechnologyNames.JETPACK_COMPOSE]: jetpack
};

export const DcwTechnology = defineComponent({
  name: 'DcwTechnology',
  props: {
    name: { type: String as PropType<TechnologyNames>, required: true }
  },
  setup() {
    return { TechnologyIcons };
  }
});

export default DcwTechnology;
