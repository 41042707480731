






























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import ArrowLeft from '@/assets/custom-dc-icons/ArrowLeft.vue';
import ArrowRight from '@/assets/custom-dc-icons/ArrowRight.vue';
import PlayIcon from '@/assets/icons/streamline-icons/play-icon.svg';
import { isInEnum } from '@/helpers';
import { ButtonVariants } from '@/types';

export const DcwButton = defineComponent({
  name: 'DcwButton',
  props: {
    text: {
      type: String,
      required: false
    },
    icon: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String as PropType<ButtonVariants>,
      default: ButtonVariants.PRIMARY,
      validator: (value: ButtonVariants) => isInEnum(value, ButtonVariants)
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isLink: {
      type: Boolean,
      default: false
    },
    isLeft: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    ariaLabel: {
      type: String,
      required: false
    },
    isPlay: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { ButtonVariants, ArrowRight, ArrowLeft, PlayIcon };
  }
});

export default DcwButton;
