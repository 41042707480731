/* eslint-disable max-len */
import { ProjectsSectionContent } from '@/types';
import { projectsService } from '@/service/model';

export const projects: ProjectsSectionContent = {
  headline: {
    before: 'Our',
    highlight: 'Projects',
    subtitle:
      'At Digital Colliers you can choose to join any industry, technology or project you are interested in. \n Instead of applying for a specific role, choose directly the project that suits you most.'
  },
  projects: projectsService.content
};
