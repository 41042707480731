/* eslint-disable max-len */
import { pagesNames } from '../../pagesNames';
import { people } from '../../people';
import { TechnologyCtaSectionContent, PagesLinks } from '@/types';

export const technologyCta: Omit<TechnologyCtaSectionContent, 'bg'> = {
  headline: {
    before: 'Build Global Team',
    subtitle:
      'Become a member of our growing team,\nand build the digital future with us. Become a member of our growing team and.'
  },
  button: {
    text: pagesNames.teamAugmentation,
    href: PagesLinks.TEAM_AUGMENTATION
  },
  card: {
    button: {
      href: PagesLinks.CONTACT,
      text: pagesNames.contact
    },
    headline: {
      before: 'Scale Your Business',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque in neque justo.'
    },
    person: {
      model: people.janE,
      images: {
        full: {
          height: 290,
          width: 265,
          alt: "Jan's photo",
          src: '/technology-page/cta/photo-full.png'
        },
        min: {
          width: 64,
          height: 64,
          alt: "Jan's photo",
          src: '/technology-page/cta/photo-min.png'
        }
      }
    }
  }
};
