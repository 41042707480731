/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, ProjectIDs, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: false,
  categories: [JobOfferCategories.FRONTEND],
  projects: [ProjectIDs.ECO_SYSTEM],
  name: 'React Developer',
  salaryRanges: salaries.react,
  seniorityLevel: SeniorityLevels.MID,
  referrers: {
    [Referrers.DC_WEBSITE]: 168574519,
    [Referrers.OTHER]: 168574531,
    [Referrers.CARROT_WORK]: 162384763,
    [Referrers.JUST_JOIN_IT]: 162360138,
    [Referrers.NO_FLUFF_JOBS]: 168574451,
    [Referrers.BULLDOG_JOB_PL]: 162384999,
    [Referrers.FACEBOOK_ADS]: 192884805,
    [Referrers.GOOGLE_ADS]: 192884807
  },
  description: [
    {
      title: 'Description',
      text: [
        'We are currently looking for an experienced \bReact Developer\b to join our team, and help us develop an \bFin-Tech App\b for American start-up'
      ]
    },
    {
      title: 'Your Role and Responsibilities',
      items: [
        "Working on an innovative \bFin-Tech App\b that's made up of a complex ecosystem of web applications deployed on AWS",
        'Develop a web application to fulfill specific business needs and requirements',
        'Striving for the \bsuperior quality\b of the product',
        'Participate in the project in \bAgile\b methodology and conform to coding standards including \bGIT\b workflow',
        'Sharing your ideas, experience with us, and take a voice in discussions on \btechnical and business matters\b'
      ]
    },
    {
      title: "We're Looking for Someone Who Has",
      items: [
        '2 years experience in \bcore JavaScript\b and \bReact.JS\b',
        'Strong experience in \bGraphQL\b',
        'Ability to create custom complex components',
        'Good understanding of \bGraphQL/REST APIs\b',
        'Proactive attitude and approach to solving problems',
        'Very good \bEnglish\b skills (min. B2/C1)'
      ]
    }
  ],
  technologies: [
    { label: 'JavaScript', level: TechnologyLevels.ADVANCED },
    { label: 'React', level: TechnologyLevels.ADVANCED },
    { label: 'GraphQL', level: TechnologyLevels.REGULAR },
    { label: 'REST API', level: TechnologyLevels.REGULAR },
    { label: 'Microservice Architecture', level: TechnologyLevels.REGULAR },
    { label: 'Agile', level: TechnologyLevels.JUNIOR }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First, \binterview\b to get to know each other and talk about both your and our expectations'
    },
    {
      title: 'Tech Interview',
      description: 'A 2-hours \btechnical assessment\b'
    },
    {
      title: 'Team Lead Interview',
      description: 'Recruitment Final Stage - \binterview\b with your future Team Leader'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process within \b2 weeks\b'
    }
  ]
};
