export const pagesNames = {
  jobs: 'Jobs',
  home: 'Hauptseite',
  projectListing: 'Project Listing',
  contact: 'Kontakt',
  about: 'Über uns',
  career: 'Karriere',
  termsOfService: 'Nutzungsbedingunen',
  privacyPolicy: 'Datenschutzerklärung',
  sitemap: 'Sitemap',
  terms: 'Terms and Conditions of Use',
  projects: 'Projekt',
  successStory: 'Success Story',
  successStories: 'Success Stories',
  teamAugmentation: 'Teamerweiterung',
  technologies: 'Technologies',
  blog: 'Blog',
  videoListing: 'Video Listing'
};
