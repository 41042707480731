import { ErrorPage, PagesLinks } from '@/types';
import WrongImg from '@/assets/errors/oh_noo.svg';

export const error500: ErrorPage = {
  subtitle: 'The server has responded with an error',
  description: 'Do you have an idea how to fix it?',
  firstLink: {
    text: 'Back to Home',
    link: PagesLinks.HOME
  },
  secondLink: {
    text: 'Join us and fix it',
    link: PagesLinks.JOBS
  },
  image: WrongImg
};
