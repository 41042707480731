












import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { FooterContent } from '@/types';

export const DcwSingleLocation = defineComponent({
  name: 'DcwSingleLocation',
  props: {
    location: {
      type: Object as PropType<FooterContent['locations']['list'][number]>,
      required: true
    }
  }
});

export default DcwSingleLocation;
