export const validations = {
  messages: {
    required: 'Dieses Feld ist erforderlich',
    email: 'Die E-Mail-Adresse ist ungültig',
    mobile: 'Please enter a valid phone number',
    checked: 'Dieses Feld ist erforderlich',
    file(_: string, params: Record<string, unknown>): string {
      let formats = '';

      Object.entries(params).forEach(([key]) => {
        if (isNaN(Number(key))) {
          return;
        }
        formats += formats ? `, ${params[key]}` : `${params[key]}`;
      });

      return `Datei hinzufügen${formats ? ' (' + formats + ')' : ''}`;
    }
  }
};
