import { SectionWithPeopleContent } from '@/types';

export const team: SectionWithPeopleContent = {
  headline: {
    before: 'Meet Our Team'
  },
  people: [
    {
      name: 'Szymon',
      position: 'Chief Technology Officer',
      linkedinLink: 'https://www.linkedin.com/in/szymnwk/',
      img: {
        src: '/positions/position-1.png',
        alt: 'Szymon photo',
        width: 236,
        height: 224
      }
    },
    {
      name: 'Maciej',
      position: 'Senior Team Leader',
      linkedinLink: 'https://www.linkedin.com/in/maciej-tu%C5%82aza-b43999110/',
      img: {
        src: '/positions/position-2.png',
        alt: 'Maciej photo',
        width: 236,
        height: 224
      }
    },
    {
      name: 'Bartosz',
      position: 'Head of Recruitment',
      linkedinLink: 'https://www.linkedin.com/in/b-ciesla/',
      img: {
        src: '/positions/position-3.png',
        alt: 'Bartosz photo',
        width: 236,
        height: 224
      }
    },
    {
      name: 'Wiktor',
      position: 'Head of People & Operations',
      linkedinLink: 'https://www.linkedin.com/in/w-stefanski/',
      img: {
        src: '/positions/position-4.png',
        alt: 'Wiktor photo',
        width: 236,
        height: 224
      }
    },
    {
      name: 'Wojciech',
      position: 'Senior Frontend Developer',
      linkedinLink: 'https://www.linkedin.com/in/wojciech-cywinski/',
      img: {
        src: '/positions/position-5.png',
        alt: 'Wojciech photo',
        width: 236,
        height: 224
      }
    },
    {
      name: 'Dominik',
      position: 'Head of Design',
      linkedinLink: 'https://www.linkedin.com/in/dominik-przysta%C5%82/',
      img: {
        src: '/positions/position-6.png',
        alt: 'Dominik photo',
        width: 236,
        height: 224
      }
    },
    {
      name: 'Szymon',
      position: 'Head of Marketing',
      linkedinLink: 'https://www.linkedin.com/in/s-morawiec/',
      img: {
        src: '/positions/position-7.png',
        alt: 'Szymon photo',
        width: 236,
        height: 224
      }
    },
    {
      name: 'Leopold',
      position: 'Business Partner',
      linkedinLink: 'https://www.linkedin.com/in/l-gaillard/',
      img: {
        src: '/positions/position-8.png',
        alt: 'Leopold photo',
        width: 236,
        height: 224
      }
    },
    {
      name: 'Place for you',
      position: 'Your position',
      img: {
        src: '/positions/placeholder.png',
        alt: 'You!',
        width: 236,
        height: 224
      }
    }
  ]
};
