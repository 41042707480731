import { DefaultSectionContent, QandAImage, QandAText, QandISongs, ImageElementAttributes } from '@/types';
import { QandAVideo } from '~/types/models';

export interface OthersStoriesSectionContent extends DefaultSectionContent {}

export interface StoryBannerContent {
  name: string;
  position: string;
  description: string;
  cover: ImageElementAttributes;
  avatar: ImageElementAttributes;
}

export interface StoryBodyContent {
  avatar: ImageElementAttributes;
  body: (QandAText | QandISongs | QandAImage | QandAVideo)[];
}

export function isStoryContent(object: unknown): object is QandAText {
  return typeof object === 'object' && object !== null && 'title' in object;
}
