/* eslint-disable max-len */
import { people } from '../people';
import { ManagementSectionContent } from '@/types';

export const management: ManagementSectionContent = {
  headline: {
    before: 'Meet Our',
    highlight: 'Management',
    subtitle: 'We are a group of professionals delivering solutions for the needs of tomorrow.'
  },
  items: [
    {
      model: people.karolS,
      img: {
        src: '/about/management/karol-s.png',
        alt: 'Karol Sobieraj',
        width: 120,
        height: 120
      }
    },
    {
      model: people.szymonN,
      img: {
        src: '/about/management/szymon-n.png',
        alt: 'Szymon Nowak',
        width: 120,
        height: 120
      }
    },
    {
      model: people.lukaszS,
      img: {
        src: '/about/management/lukasz-s.png',
        alt: 'Łukasz Sobieraj',
        width: 120,
        height: 120
      }
    },
    {
      model: people.wiktorS,
      img: {
        src: '/about/management/wiktor-s.png',
        alt: 'Wiktor Stefański',
        width: 120,
        height: 120
      }
    },
    {
      model: people.szymonM,
      img: {
        src: '/about/management/szymon-m.png',
        alt: 'Szymon Morawiec',
        width: 120,
        height: 120
      }
    },
    {
      model: people.bartoszC,
      img: {
        src: '/about/management/bartosz-c.png',
        alt: 'Bartosz Cieśla',
        width: 120,
        height: 120
      }
    },
    {
      model: people.dominikP,
      img: {
        src: '/about/management/dominik-p.png',
        alt: 'Dominik Przystał',
        width: 120,
        height: 120
      }
    },
    {
      model: people.leopoldG,
      img: {
        src: '/about/management/leopold-g.png',
        alt: 'Leopold Gaillard',
        width: 120,
        height: 120
      }
    }
  ]
};
