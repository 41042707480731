










import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwSuccessStoryItem } from '@molecules';
import { SuccessStory } from '@/types';

export const DcwSuccessStoriesList = defineComponent({
  name: 'DcwSuccessStoriesList',
  components: { DcwSuccessStoryItem },
  props: {
    list: {
      type: Array as PropType<(SuccessStory & { id: string })[]>,
      required: true
    }
  }
});

export default DcwSuccessStoriesList;
