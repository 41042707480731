/* eslint-disable @typescript-eslint/no-var-requires */

import { TechnologyModel } from '@/types';

export const technologies = {
  get react(): TechnologyModel {
    return require('./react').technology;
  },
  get vue(): TechnologyModel {
    return require('./vue').technology;
  },
  get angular(): TechnologyModel {
    return require('./angular').technology;
  },
  get node(): TechnologyModel {
    return require('./node').technology;
  },
  get java(): TechnologyModel {
    return require('./java').technology;
  },
  get kotlin(): TechnologyModel {
    return require('./kotlin').technology;
  },
  get 'dot-net'(): TechnologyModel {
    return require('./dot-net').technology;
  },
  get flutter(): TechnologyModel {
    return require('./flutter').technology;
  },
  get solidity(): TechnologyModel {
    return require('./solidity').technology;
  },
  get 'ml-ai'(): TechnologyModel {
    return require('./ml-ai').technology;
  },
  get aws(): TechnologyModel {
    return require('./aws').technology;
  },
  get terraform(): TechnologyModel {
    return require('./terraform').technology;
  }
};
