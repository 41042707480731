























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwButton, DcwSelect } from '@atoms';
import { ButtonVariants, CustomEvents, ListingHeadProps } from '@/types';
import { getSelectOptionLabel } from '@/helpers';

export const DcwListingHead = defineComponent({
  name: 'DcwListingHead',
  components: { DcwButton, DcwSelect },
  props: {
    content: {
      type: Object as PropType<ListingHeadProps>,
      required: true
    }
  },
  emits: [CustomEvents.CHANGE_FILTERS],
  setup(props) {
    const buttonProps = {
      ...props.content.button,
      isLink: !!props.content.button.href,
      variant: ButtonVariants.TERTIARY
    };

    return { buttonProps, CustomEvents, getSelectOptionLabel };
  }
});

export default DcwListingHead;
