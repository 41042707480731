export const scrollToSection = (hash = 'top') => {
  if (hash === 'top') {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return;
  }

  const el: HTMLElement | null = document.getElementById(hash);
  if (!el) {
    return;
  }

  el.scrollIntoView({ behavior: 'smooth' });
};
