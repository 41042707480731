export enum ScheduleModalPeople {
  wiktorS = 'Wiktor Stefański',
  szymonN = 'Szymon Nowak',
  szymonM = 'Szymon Morawiec',
  karolS = 'Karol Sobieraj',
  lukaszS = 'Łukasz Sobieraj',
  janE = 'Jan Ellwitz',
  leopoldG = 'Léopold Gaillard',
  bartoszC = 'Bartosz Cieśla',
  przemyslawD = 'Przemysław Draga'
}
