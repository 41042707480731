import { useMeta, useContext } from '@nuxtjs/composition-api';
import { useLocales } from './use-locales';
import { Pages } from '@/types';

export type PageMetaData = {
  title: string;
  description: string;
  ogCover?: string;
};

const PAGE_TITLE_MODIFICATION = 'Digital Colliers';

export const useMetaDescription = (data: Pages | PageMetaData): void => {
  const meta = useMeta();
  const { route } = useContext();
  const { translation, domain } = useLocales();
  let title = '';
  let description = '';
  let cover = `https://${domain}/covers/boost.png`;

  if (typeof data === 'string') {
    if (data === Pages.HOME) {
      title = PAGE_TITLE_MODIFICATION + ' | ' + translation.pagesTitles[data];
    } else {
      title = translation.pagesTitles[data] + ' | ' + PAGE_TITLE_MODIFICATION;
    }

    description = translation.pagesDescription[data];
  } else {
    title = data.title + ' | ' + PAGE_TITLE_MODIFICATION;
    description = data.description;

    if (data.ogCover) {
      cover = `https://${domain}${data.ogCover}`;
    }
  }

  meta.title.value = title;
  meta.meta.value = [
    {
      hid: 'description',
      name: 'description',
      content: description
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: title
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: description
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: 'https://' + domain + route.value.fullPath
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: cover
    },
    {
      hid: 'twitter:description',
      name: 'twitter:description',
      content: description
    },
    {
      hid: 'twitter:title',
      name: 'twitter:title',
      content: title
    },
    {
      hid: 'twitter:image',
      name: 'twitter:image',
      content: cover
    },
    {
      hid: 'twitter:card',
      name: 'twitter:card',
      content: 'summary_large_image'
    }
  ];
};
