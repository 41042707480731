import { reactive, Ref, onMounted } from '@nuxtjs/composition-api';
import * as SwiperClass from 'swiper';
import { SliderDirection, SwiperRef } from '@/types';
import { debounce } from '@/helpers';

interface UseSwiperProps {
  length?: number;
  isCarousel?: boolean;
  initialSlide?: number;
  sliderRef?: Ref<SwiperRef | null>;
}

export interface SwiperInfo {
  activeSlide: number;
  activeIndex: number;
  direction: string;
  progress: number;
}

export interface UseSwiper {
  onSlideChange: (instance: SwiperClass.Swiper & { swipeDirection?: string }) => void;
  nextSlideHandler: () => void;
  prevSlideHandler: () => void;
  swiperInfo: SwiperInfo;
  onProgressChange: (progress: number) => void;
  setActiveSlide: (slide: number) => void;
}

export const useSwiper = (props?: UseSwiperProps): UseSwiper => {
  const swiperInfo = reactive({
    activeSlide: 0,
    activeIndex: 0,
    direction: '',
    progress: 0
  });

  const setProgress = (instance: SwiperClass.Swiper): void => {
    if (props?.length && !props.isCarousel) {
      swiperInfo.progress = instance.realIndex * (1 / (props.length - 1));
    }

    if (props?.isCarousel) {
      swiperInfo.progress = instance.progress;
    }
  };

  const onSlideChange = (instance: SwiperClass.Swiper & { swipeDirection?: string }) => {
    swiperInfo.activeIndex = instance.realIndex;

    setProgress(instance);

    if (instance.swipeDirection) {
      swiperInfo.direction = instance.swipeDirection;
    }
  };

  const nextSlideHandler = () => {
    swiperInfo.direction = SliderDirection.NEXT;
    swiperInfo.activeSlide += 1;
  };

  const prevSlideHandler = () => {
    swiperInfo.direction = SliderDirection.PREV;
    swiperInfo.activeSlide -= 1;
  };

  onMounted(() => {
    if (props?.initialSlide && props.sliderRef?.value) {
      swiperInfo.activeIndex = props.initialSlide;
      swiperInfo.activeSlide = props.initialSlide;
      setProgress(props.sliderRef.value.$children[0].$swiper);
    }
  });

  const onProgressChange = debounce((progress: number) => {
    swiperInfo.progress = progress;
  }, 100);

  const setActiveSlide = (slide: number) => {
    swiperInfo.activeSlide = slide;
    swiperInfo.direction = swiperInfo.activeSlide > swiperInfo.activeIndex ? SliderDirection.NEXT : SliderDirection.PREV;
  };

  return {
    onSlideChange,
    nextSlideHandler,
    prevSlideHandler,
    swiperInfo,
    onProgressChange,
    setActiveSlide
  };
};
