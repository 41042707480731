













import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { DcwGridWrapper } from '@atoms';
import { Breakpoints, MainGridWrapperElements } from '@/types';
import { useBreakpoints, useGsap } from '@/composables';

export const DcwSubMenu = defineComponent({
  name: 'DcwSubMenu',
  components: { DcwGridWrapper },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const { timeLine: tl, resetInlineStyles } = useGsap();
    const { currentWidth } = useBreakpoints();
    const componentRef = ref<HTMLDivElement | null>(null);
    const listRef = ref<Vue | null>(null);

    const resetStyles = () => {
      if (!listRef.value || !componentRef.value) {
        return;
      }

      resetInlineStyles([componentRef.value, listRef.value.$el as HTMLElement]);
    };

    const mobileAnimation = (open: boolean) => {
      if (!componentRef.value) {
        return;
      }

      const element = componentRef.value;
      const elementHeight = element.scrollHeight;

      if (!open) {
        tl.fromTo(element, { height: elementHeight, duration: 1, ease: 'Power2.easeInOut' }, { height: 0 });
        return;
      }

      resetStyles();
      tl.fromTo(element, { height: 0, duration: 1, ease: 'Power2.easeInOut' }, { height: elementHeight }).to(element, {
        height: '100%'
      });
    };

    watch(
      () => props.isOpen,
      (newValue) => {
        if (currentWidth.value > Breakpoints.MEDIUM) {
          return;
        }

        mobileAnimation(newValue);
      }
    );

    return { componentRef, MainGridWrapperElements, listRef };
  }
});

export default DcwSubMenu;
