/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, ProjectIDs, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: false,
  categories: [JobOfferCategories.BACKEND],
  projects: [ProjectIDs.MONITORING_PLATFORM],
  name: 'Senior .NET Developer',
  salaryRanges: salaries.dotNet,
  seniorityLevel: SeniorityLevels.SENIOR,
  referrers: {
    [Referrers.DC_WEBSITE]: 168574909,
    [Referrers.OTHER]: 144531786,
    [Referrers.CARROT_WORK]: 150454442,
    [Referrers.JUST_JOIN_IT]: 134567682,
    [Referrers.NO_FLUFF_JOBS]: 168574872,
    [Referrers.BULLDOG_JOB_PL]: 134568090,
    [Referrers.FACEBOOK_ADS]: 192898021,
    [Referrers.GOOGLE_ADS]: 192898026
  },
  description: [
    {
      title: 'Description',
      text: [
        'We are currently looking for software engineers with strong \bCraftsmanship\b to join our team and help us create an application that we are developing for a French startup in the \bfin-tech\b industry.',
        'The client is a relatively new, Cash flow management company, founded in 2016 in the \bheart of France\b. With 120 million euros raised, since 2019 they have grown up to 200 employees across 5 countries, and more than 3500 clients, and a target of 10 000 clients by the end of 2022.',
        'They are developing two new products (\bPayment integration\b) in which you will have a huge impact on the final product, helping to define its success making it a great place to work and grow along with the company.',
        "They have established a true \bculture of software quality\b. They made sure to develop this culture from the beginning, and that it is now present at all levels of the team. This is why, even during sprints, they don't rush delivery and allocate 20% of their time to \bcontinuous improvement\b projects."
      ]
    },
    {
      title: 'Our Values',

      items: [
        '\bReach for excellence\b | We aim for excellence every day',
        '\bHave an impact\b | We strive to make a difference',
        '\bThrive together\b | We want to become the European leader together',
        '\bBe humble\b | There is always room to learn:s from each other and from our clients, and we are eager to improve ourselves individually and collectively'
      ]
    },
    {
      title: 'Essential Skills and Expertise',
      items: [
        '\bTesting\b: Commercial Experience working with TDD and wide knowledge of automated tests (unitary, EndToEnd, functional, integration, acceptance (based on SpecFlow)',
        '\bBuilder\b: Knowledge of back architecture respecting the CQRS (Command Query Responsibility Segregation) pattern and DDD (Domain-Driven Design) oriented',
        '\bBest practices\b: You are an organized worker who follows best practices regarding code quality, testing, and code reviews (SOLID principles, clean code, code review, pair programming)',
        '\bProblem solver\b: You are a pragmatic and solution-oriented engineer who is interested in hard problems and delivering value, you are able to understand business requirements and transform them',
        '\bImpact maker\b: You want to help build an innovative product used by tens of thousands of users and leave your mark, a desire to own a project from beginning to end, with a strong sense of pride in a job exceptionally done',
        '\bCuriosity\b: You are a curious and open-minded developer who is eager to learn a new language, banking domain and introduce new tools and services where it can help us grow',
        '\bTeam player\b: You have good communication skills, are comfortable working in a team, and can easily work with non-technical colleagues',
        '\bChallenger\b: Programming languages are just a tool for you to achieve a goal. You can find our current tech stack here: .Net core, Angular 12, Typescript, SQL, NoSQL, cloud Azure, Azure DevOps',
        '\bLanguage\b: Excellent English skills (min. C1)'
      ]
    },
    {
      title: 'Your Role and Responsibilities',
      items: [
        'Developing new features and solving technical problems',
        'Guarantee the proper integration of current and future functionalities',
        'Guarantee quality through the application of tests and code reviews',
        'Prioritize the tasks and functionalities to be developed as a team',
        'Participate in the design functional and technical production taking into account the end-user needs',
        'Develop ergonomics and adapt technologies to usage',
        'Be proactive and propose new technological solutions to the team'
      ]
    }
  ],
  technologies: [
    { label: 'TDD', level: TechnologyLevels.MASTER },
    { label: '.NET Core', level: TechnologyLevels.ADVANCED },
    { label: 'C#', level: TechnologyLevels.REGULAR },
    { label: 'DDD', level: TechnologyLevels.REGULAR },
    { label: 'SOLID Principles', level: TechnologyLevels.REGULAR },
    { label: 'SQL', level: TechnologyLevels.JUNIOR }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First, short interview to know each other and talk about yours and ours expectations'
    },
    {
      title: 'Screening Call',
      description: '30 min screening call with one of the recruiters'
    },
    {
      title: 'Code Review',
      description: '1,5h Code Review'
    },
    {
      title: 'Offer',
      description: 'We can complete the entire process in two weeks'
    }
  ]
};
