



























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwLink, DcwAccordion } from '@atoms';
import { LinkColumn } from '@/types';

export const DcwLinkColumn = defineComponent({
  name: 'DcwLinkColumn',
  components: {
    DcwLink,
    DcwAccordion
  },
  props: {
    data: {
      type: Object as PropType<LinkColumn>,
      required: true
    }
  }
});

export default DcwLinkColumn;
