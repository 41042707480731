






















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { HtmlWrappers, ScrollSnapAlign } from '@/types';

export const DcwScrollSnap = defineComponent({
  name: 'DcwScrollSnap',
  props: {
    isList: {
      type: Boolean,
      default: true
    },
    align: {
      type: String as PropType<ScrollSnapAlign>,
      default: ScrollSnapAlign.START
    },
    items: {
      type: Array as PropType<unknown[]>,
      required: true
    },
    isGrid: {
      type: Boolean,
      default: false
    },
    itemClass: {
      type: String,
      required: false
    }
  },
  setup() {
    return { HtmlWrappers };
  }
});

export default DcwScrollSnap;
