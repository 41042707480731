




















import { defineComponent } from '@nuxtjs/composition-api';
import { ImageObject } from 'sharp-loader/src/types';
import { getSources } from './DcwImage.const';
import { useBreakpoints } from '@/composables';

export const DcwImage = defineComponent({
  name: 'DcwImage',
  props: {
    height: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      required: false
    },
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    },
    noThumbnail: {
      type: Boolean,
      default: false
    },
    noThumbnailBlur: {
      type: Boolean,
      default: false
    },
    isMobileOptimized: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isMobile } = useBreakpoints();

    const sources = getSources(props.src);

    const sourceSet = (images: Partial<ImageObject>[]) => {
      return images.map(({ url }, index) => `${url}${index > 0 ? ` ${index + 1}x` : ''}`).join(', ');
    };

    return { sources, sourceSet, isMobile };
  }
});

export default DcwImage;
