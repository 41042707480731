
























import { defineComponent } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwLink, DcwTechnology } from '@atoms';
import ArrowRight from '@/assets/custom-dc-icons/ArrowRight.vue';
import { useLocales } from '@/composables';
import { HeadlineTypes, PagesLinks } from '@/types';
import { technologiesService } from '@/service/model';

export const DcwTechnologiesSubItem = defineComponent({
  name: 'DcwTechnologiesSubItem',
  components: { DcwNewHeadline, DcwTechnology, DcwLink, ArrowRight },
  setup() {
    const { translation } = useLocales();

    const technologies = technologiesService.content;

    const headlineProps = {
      type: HeadlineTypes.P,
      styledAs: HeadlineTypes.H4,
      before: translation.phrases.technologies
    };

    return { technologies, translation, PagesLinks, headlineProps };
  }
});

export default DcwTechnologiesSubItem;
