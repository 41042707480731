export const buttons = {
  letsTalk: "Let's talk",
  joinUs: 'Join us',
  viewCaseStudy: 'View case study',
  aboutUs: 'About us',
  career: 'Career',
  readMore: 'Read more',
  seeMore: 'See more',
  contact: 'Contact',
  scheduleACall: 'Schedule a call',
  allJobOffers: 'See all jobs',
  jobDescription: 'Job description',
  seeAllProjects: 'See all projects',
  findOutMore: 'Find out more',
  readSuccessStory: 'Read success story',
  seeTheGalleryOnFacebook: 'See the gallery on facebook',
  openPositions: 'Open Positions',
  sendMessage: 'Send message',
  openProjectPositions: 'See open positions',
  openProjectJobs: 'See open jobs',
  contactUs: 'Contact',
  applyNow: 'Apply',
  applyVia: 'Apply via',
  hireUs: 'Hire us',
  watchOnYt: 'Watch YouTube',
  watchOnVimeo: 'Watch video on Vimeo',
  checkOpenOffers: 'See available jobs',
  ListenOnSpotify: 'Listen on Spotify',
  playVideo: 'Play video',
  playOnVimeo: 'Play on Vimeo',
  next: 'Next',
  back: 'Back',
  teamAugmentation: 'Team augmentation',
  services: 'Services',
  viewOnMap: 'View on map',
  watchVideo: 'Watch video'
};
