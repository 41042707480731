import { PagesLinks, Breakpoints, BannerWithVideoContent } from '@/types';

export const hero: BannerWithVideoContent = {
  headline: {
    before: 'Software Engineering\nTeams That Deliver',
    subtitle: 'We connect exceptional talents with progressive businesses.'
  },
  videoId: '683803397',
  button: { text: "Let's talk", href: PagesLinks.CONTACT, isLink: true },
  video: {
    width: 2016,
    height: 720,
    thumbnail: {
      src: '/about/banner/new/thumbnail-about.jpg',
      noThumbnail: true
    },
    alt: 'First frame of the about banner video',
    srcset: [
      { width: 0, src: ['/about/banner/new/video-768.webm', '/about/banner/new/video-768.mp4'] },
      { width: Breakpoints.SMALL, src: ['/about/banner/new/video-1025.webm', '/about/banner/new/video-1025.mp4'] },
      { width: Breakpoints.MEDIUM, src: ['/about/banner/new/video-1792.webm', '/about/banner/new/video-1792.mp4'] }
    ]
  }
};
