















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';

export const DcwBadgeList = defineComponent({
  name: 'DcwBadgeList',
  props: {
    title: {
      type: String,
      required: false
    },
    badges: {
      type: Array as PropType<string[]>,
      required: true
    },
    colors: {
      type: Object as PropType<{ border: string; content: string }>,
      required: false
    }
  },
  setup(props) {
    const style = computed(() => ({ color: props.colors?.content, borderColor: props.colors?.border }));

    return { style };
  }
});

export default DcwBadgeList;
