



























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwClutchWidget, DcwTextClipboard } from '../';
import DCLogo from '@/assets/logo_dc_white.svg';
import { CompanyDetails, PagesLinks } from '@/types';

export const DcwFooterAbout = defineComponent({
  name: 'DcwFooterAbout',
  components: {
    DCLogo,
    DcwClutchWidget,
    DcwTextClipboard
  },
  props: {
    content: {
      type: Object as PropType<CompanyDetails>,
      required: true
    }
  },
  setup() {
    return { PagesLinks };
  }
});

export default DcwFooterAbout;
