import { useInjectStrict, useLocales } from './';
import { Toast, ToastVariants } from '@/types';
import { toastKey } from '@/consts';

interface ToastOptions {
  message?: string;
  delay?: number;
}

type CallToast = (o?: ToastOptions) => void;
interface UseToast {
  successToast: CallToast
  errorToast: CallToast
  primaryToast: CallToast
}

export const useToast = (): UseToast => {
  const toast = useInjectStrict<Toast>(toastKey);
  const { translation } = useLocales();

  const showToast = (text: string, variant: ToastVariants, delay: number) => {
    toast.text = text;
    toast.variant = variant;
    toast.visible = true;
    toast.delay = delay;
  };

  const successToast = ({ message = translation.toasts.formSuccess, delay = 2000 }: ToastOptions = {}) => {
    showToast(message, ToastVariants.SUCCESS, delay);
  };

  const errorToast = ({ message = translation.toasts.formError, delay = 2000 }: ToastOptions = {}) => {
    showToast(message, ToastVariants.ERROR, delay);
  };

  const primaryToast = ({ message = translation.toasts.default, delay = 2000 }: ToastOptions = {}) => {
    showToast(message, ToastVariants.DEFAULT, delay);
  };

  return { successToast, errorToast, primaryToast };
};
