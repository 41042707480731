/* eslint-disable max-len */
import { OurStorySectionContent } from '@/types';

export const story: OurStorySectionContent = {
  headline: {
    before: 'Our',
    highlight: 'Story',
    subtitle:
      "Everyone has that world-changing lightbulb moment, but without the skillset to pull it off, great ideas fall by the wayside. This time it didn't happen. In 2017, Digital Colliers was founded by two brothers and their good friend with the ambition to seek change in the IT industry. They started as a small team specialized in building websites and quickly became team providers forming platforms for world-renowned companies."
  },
  quote: {
    content: 'I never dreamed about success. I worked for it.',
    author: 'Estée Lauder'
  },
  items: [
    {
      title: 'Mission',
      text:
        'Our mission is to exceed the expectations\nof the companies we work with by providing\nthem with extensive services and top\nspecialists in the industry.'
    },
    {
      title: 'Philosophy',
      text:
        'We take pride in every person working at\nDigital Colliers being a cunning, independent,\nand agile individual who follows our\n fundamental Born2solve principle.'
    }
  ]
};
