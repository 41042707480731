import { offices } from '../offices';
import { FooterContent } from '@/types';

export const footer: FooterContent = {
  title: 'Des équipes de développement agiles pour les leaders du marché mondial',
  copy: '© 2021 Digital Colliers Sp. z o.o. Tous les droits sont réservés.',
  companyDetails: {
    name: 'Digital Colliers Sp. z o.o.',
    email: 'info@digitalcolliers.com',
    address: {
      firstLine: 'Konarskiego 18c',
      secondLine: '44-100 Gliwice, Pologne'
    },
    taxiIdentifiers: {
      vatId: 'EU Steuer-ID PL9691635555',
      krs: 'KRS-ID 0000768764'
    }
  },
  locations: {
    title: 'Digital Colliers Standorte',
    list: [
      offices.cracow,
      offices.warsaw,
      offices.gliwice,
      offices.paris,
      offices.hamburg,
      offices.berlin,
      offices.vienna,
      offices.toronto
    ]
  }
};
