























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwSeniorityLevels, DcwButton } from '@atoms';
import {
  PagesLinks,
  HeadlineTypes,
  ButtonVariants,
  JobOfferWithId,
  SeniorityLevels,
  JobOfferItemVariant,
  HeadlineSubtitleSizes,
  HeadlineSubtitleSpacing
} from '@/types';
import { desktopNavigateTo, formatSalary } from '@/helpers';
import { useLocales } from '@/composables';

export const DcwOfferItem = defineComponent({
  name: 'DcwOfferItem',
  components: { DcwNewHeadline, DcwSeniorityLevels, DcwButton },
  props: {
    content: {
      type: Object as PropType<JobOfferWithId>,
      required: true
    },
    variant: {
      type: String as PropType<JobOfferItemVariant>,
      default: JobOfferItemVariant.CARD
    },
    hasHoverEffect: {
      type: Boolean,
      default: true
    },
    showLevelsInList: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const { translation } = useLocales();
    const salaryInformation = formatSalary(props.content.salaryRanges[props.content.seniorityLevel]);
    const fullOfferLink = `${PagesLinks.JOBS}/${props.content.id}`;

    const buttonProps = {
      isLink: true,
      href: fullOfferLink,
      text: translation.buttons.jobDescription,
      variant: ButtonVariants.TERTIARY
    };

    const headlineProps = {
      before: props.content.name,
      subtitle: translation.phrases.fullyRemote,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H4,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM,
      isTinted: true
    };

    const clickHandler = () => {
      desktopNavigateTo(fullOfferLink, false);
    };

    return {
      translation,
      buttonProps,
      clickHandler,
      headlineProps,
      SeniorityLevels,
      salaryInformation,
      JobOfferItemVariant
    };
  }
});

export default DcwOfferItem;
