/* eslint-disable max-len */
import { PagesLinks, TAPageSectionIds, TeamAugmentationExplanationContent } from '@/types';

export const explanation: TeamAugmentationExplanationContent = {
  headline: {
    before: 'What is',
    highlight: 'Team Augmentation',
    subtitle:
      "A dedicated software development team, created specifically for your project. Engaged in executing the company vision on the same level as the rest of the in-house engineers. Fully encapsulated, and integrated into the organization processes, values, methodologies, tools. That's our definition of the team augmentation model."
  },
  button: {
    text: 'Book a free consultation',
    href: `${PagesLinks}/#${TAPageSectionIds.CTA}`
  },
  details: {
    clientName: 'Client Team',
    companyName: 'Digital Colliers',
    teams: [
      {
        title: 'Team A',
        image: {
          src: '/team-augmentation/explanation/team-a.png',
          width: 176,
          height: 176,
          alt: 'Team A'
        }
      },
      {
        title: 'Team B',
        image: {
          src: '/team-augmentation/explanation/team-b.png',
          width: 272,
          height: 176,
          alt: 'Team B'
        }
      },
      {
        title: 'Team C',
        image: {
          src: '/team-augmentation/explanation/team-c.png',
          width: 272,
          height: 176,
          alt: 'Team C'
        }
      }
    ]
  }
};
