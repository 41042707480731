/* eslint-disable max-len */
import { CountryNames, OfficeModel } from '@/types';

export const paris: OfficeModel = {
  name: 'Boulevard Haussmann',
  country: CountryNames.FRANCE,
  city: 'Paris',
  address: {
    first: '106 Boulevard Haussmann',
    second: '75008'
  },
  map:
    'https://www.google.com/maps/place/106+Bd+Haussmann,+75008+Paris,+%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D0%B8%D1%8F/@48.8746643,2.3193245,17z/data=!3m1!4b1!4m5!3m4!1s0x47e66fcae51ebaf1:0x1d209811fe33bb0e!8m2!3d48.8746608!4d2.3215132'
};
