




import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { CountryNames } from '@/types';

import USA from '@/assets/flags/usa.svg';
import DE from '@/assets/flags/de.svg';
import FR from '@/assets/flags/fr.svg';
import PL from '@/assets/flags/pl.svg';
import AT from '@/assets/flags/at.svg';
import CA from '@/assets/flags/canada.svg';
import AU from '@/assets/flags/aus.svg';
import UA from '@/assets/flags/ua.svg';
import UK from '@/assets/flags/uk.svg';

const FlagIcons = {
  [CountryNames.POLAND]: PL,
  [CountryNames.FRANCE]: FR,
  [CountryNames.GERMANY]: DE,
  [CountryNames.AUSTRIA]: AT,
  [CountryNames.CANADA]: CA,
  [CountryNames.USA]: USA,
  [CountryNames.AUSTRALIA]: AU,
  [CountryNames.UKRAINE]: UA,
  [CountryNames.UK]: UK
};

export const DcwFlag = defineComponent({
  name: 'DcwFlag',
  props: {
    name: {
      type: String as PropType<CountryNames>,
      required: true
    }
  },
  setup() {
    return { FlagIcons };
  }
});

export default DcwFlag;
