/* eslint-disable max-len */
import { TextPageContent } from '@/types';

export const privacyPolicy: TextPageContent = {
  title: 'Privacy Policy',
  metaDescription:
    'In order to fulfill the obligations resulting from GDPR, the Service provider hereby presents to you all sufficient information about scope and legal basis...',
  sections: [
    {
      title: 'General Provisions',
      header: { before: 'General Provisions' },
      id: 'section1',
      html: `
      <p>
        In order to fulfil the obligations resulting from GDPR, the Service provider hereby presents to you all sufficient information about scope and legal basis of processing of your personal data, as well as Service provider’s contact details, to help you understand how your data will be processed and assure you that your data will be protected with all sufficient and effective means.
      </p>

      <p>
        All capitalized terms in this Privacy Policy shall have the meanings given to them in the Terms & Conditions, unless otherwise stated in the Privacy Policy.
      </p>`
    },
    {
      title: 'Controller',
      header: { before: 'Controller' },
      id: 'section2',
      html: `
      <p>
        The controller of your personal data is the Service Provider (hereinafter: <strong>us</strong>).
      </p>

      <p>You can contact us as follows:</p>
      <ul>
        <li>
          by letter to the address: ul. Stanisława Konarskiego 18c, 44-100 Gliwice, Poland
        </li>
        <li>
          by e-mail:
          <a href="mailto:info@digitalcolliers.com">
            info@digitalcolliers.com
          </a>
        </li>
      </ul>`
    },
    {
      title: 'Purposes and the Legal Basis for Processing Your Personal Data',
      header: { before: 'Purposes and the Legal Basis for Processing Your Personal Data' },
      id: 'section3',
      html: `
      <p>
        Your personal data, i.e. your name and e-mail address, will be processed for the following purposes and based on the following legal grounds:
      </p>

      <ol>
        <li>
          processing is necessary in order to take steps at your request prior to entering into a contract (art. 6 sec. 1 (b) GDPR), which includes:

          <ol type="a">
            <li>proceed your application in our recruitment process;</li>
          </ol>
        </li>

        <li>
          your consent to process personal data (art. 6 sec. 1 (a) GDPR), which includes :

          <ol type="a">
            <li>
              receive and process applications via apply forms that contain information not required during recruitment process, and also for future recruitments;
            </li>
          </ol>
        </li>

        <li>
          processing is necessary for the purposes of our legitimate interests (art.
          6 sec. 1 (f) GDPR), which includes:
          <ol type="a">
            <li>
              contacting with you after you have filled the contact form available on the Website;
            </li>
            <li>
              replying to your e-mail sent to us;
            </li>
            <li>
              processing of filed complaints;
            </li>
            <li>
              taking any necessary steps connected with any possible claims due to us or against us;
            </li>
            <li>
              performing analyses and statistics that will allow us to better adapt the content and Services provided to users' needs and expectations.
            </li>
          </ol>
        </li>
      </ol>`
    },
    {
      title: 'Voluntary Provision of Personal Data',
      header: { before: 'Voluntary Provision of Personal Data' },
      id: 'section4',
      html: `
      <p>
        Providing your personal data is voluntary, but failure to provide them may prevent us from providing Services electronically via our website or answer your questions.
      </p>
      `
    },
    {
      title: 'Recipents of Your Personal Data',
      header: { before: 'Recipents of Your Personal Data' },
      id: 'section5',
      html: `
      <p>
        Your personal data will be transferred to the public authorities, at their request and in accordance with the obligations arising from the generally applicable law.
      </p>
      `
    },
    {
      title: 'Transferring Data Outside the European Economic Area',
      header: { before: 'Transferring Data Outside the European Economic Area' },
      id: 'section6',
      html: `
      <p>
        Your personal data will not be transferred outside the European Economic Area. The Website servers are located in Frankfurt, Germany  , but tools used by Controller may transfer data to third countries.
      </p>

      <p>
        We use Google Analytics. Google Analytics may transfer your personal data outside the European Economic Area. The basis of data transfer outside the European Economic Area are
        <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc">
        standard contractual clauses.
        </a>
      </p>
      `
    },
    {
      title: 'The Period of Storage of Your Personal Data',
      header: { before: 'The Period of Storage of Your Personal Data' },
      id: 'section7',
      html: `
      <p>Your personal data will be store for no longer than necessary.</p>

      <p>Your personal data are stored:</p>

      <ol type="a">
        <li>
          if you have applied for the recruitment process - until the recruitment process ends;
        </li>
        <li>
          if we contact you after you fill out a contact form or send an email - until we will contact you or answer your questions, unless storing your data longer is necessary due to the establishment of cooperation or for the establishment, investigation or defense against possible claims;
        </li>
        <li>
          in case of filing a complaint - until its consideration;
        </li>
        <li>
          when processing is based on our legitimate interest - as long as our interest exists or until you object. However, Personal data will be stored until the statute of limitations for potential claims under the law;
        </li>
        <li>
          to the extent that we process your data on the basis of your consent, we will store your data until you withdraw your consent to the processing of Personal data. Withdrawal of consent does not affect the lawfulness of the processing of personal data carried out before the withdrawal of your consent . However, data processed on the basis of consent to participate in future recruitments will not be kept longer than one year.
        </li>
      </ol>`
    },
    {
      title: 'Your Rights Related to the Processing of Personal Data',
      header: { before: 'Your Rights Related to the Processing of Personal Data' },
      id: 'section8',
      html: `
          <p>
            Pursuant to GDPR you have the following rights related to the processing of your personal data:
          </p>

          <ul>
            <li>
              the right to access your personal data (art. 15 of the GDPR),
            </li>
            <li>
              the right to request the rectification of your personal data (art. 16 of the GDPR),
            </li>
            <li>
              the right to request the deletion of your personal data (art. 17 of the GDPR),
            </li>
            <li>
              the right to request the restriction of the processing of your personal data (art. 18 of the GDPR),
            </li>
            <li>
              the right to transfer your personal data, i.e. the right to receive your personal data from me, in a structured, commonly used, machine-readable IT format; you can send this data to another data controller or request that I send your data to another controller, however, I will only do this if such a message is technically possible (art. 20 GDPR),
            </li>
            <li>
              the right to object to the processing of your data on the basis of our legitimate interest, which is not overriding your interest or your rights and freedoms, and the right to object to the processing of your data for direct marketing purposes (art. 21 of the GDPR).
            </li>
          </ul>

          <p>
          To exercise the abovementioned rights, please contact us by e-mail:
            <a href="mailto:info@digitalcolliers.com">info@digitalcolliers.com</a>
            or by traditional letter to the address: ul. Stanisława Konarskiego 18c, 44-100 Gliwice, Poland.
          </p>

          <p>
            You also have the right to lodge a complaint with the supervisory body dealing with the protection of personal data, i.e. the President of the Office for Personal Data Protection (Article 77 GDPR).
          </p>
    `
    },
    {
      title: 'Cookies',
      header: { before: 'Cookies' },
      id: 'section9',
      html: `
      <p>
        Cookies are small files sent to your web browser that allow to read certain information from your device, e.g. whether you are using a computer or smartphone, which allows software to display you respectively a computer or a mobile version. These files do not interfere with the functioning of your device in any way.
      </p>
      `
    },
    {
      title: 'What Cookies Are Used and for Which Purposes?',
      header: { before: 'What Cookies Are Used and for Which Purposes?' },
      id: 'section10',
      html: `
      <p>
        The following types of cookies are used on the Website:
      </p>
      <ul>
        <li>
          <b>“session”</b> cookies which are temporary files that are stored in the User's terminal device until the User leaves the Website or turns off the web browser;
        </li>
        <li>
          <b>“persistent”</b> cookies which are stored in the User's terminal device for the time specified in the parameters of cookies or until the User removes them;
        </li>
        <li>
          <b>“first party”</b> cookies are the cookies placed directly by the Service provider;
        </li>
        <li>
          <b>“third-party”</b> cookies are the cookies placed by external parties, for example Google Analytics;
        </li>
        <li>
          <b>"necessary"</b> cookies that enable the use of services available as part of the website  pages, e.g. authentication cookies used for services that require authentication on the website, cookies that are used to ensure security, e.g. used to detect fraud in the field of authentication,
        </li>
        <li>
          <b>"analytical"</b> cookies that enable the collection of information on the use of the Website for statistical and analytical purposes. By using analytical cookies we create aggregate statistics and analyses that help us understand how our Website is used,
        </li>
        <li>
          <b>"functional"</b> cookies, which allow you to remember the settings of the website selected by the user and personalize the interface, e.g. in terms of the selected language or region, font size, website appearance, etc.
        </li>
      </ul>`
    },
    {
      title: 'How Can You Disable Cookies?',
      header: { before: 'How Can You Disable Cookies?' },
      id: 'section11',
      html: `
      <p>
        You can adjust the scope in which cookies are used on your device by yourself. You can do this by changing your browser settings. Remember, however, that many of the cookies are crucial for the proper display of the Website and the content displayed on it, so blocking such cookies may disrupt the use of Services.
      </p>
      <p>
        Detailed information on disabling cookies in individual browsers can be found by clicking on the appropriate browser below (when clicked, you will be redirected to another page):
      </p>
      <ul>
        <li>
          <a href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox">
          Mozilla Firefox
          </a>
        </li>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&&hl=en">
            Google Chrome
          </a>
        </li>
        <li>
          <a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
            Internet Explorer
          </a>
        </li>
        <li>
          <a href="https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
            Microsoft Edge
          </a>
        </li>
        <li>
          <a href="http://help.opera.com/Linux/9.22/pl/cookies.html">
            Opera
          </a>
        </li>
        <li>
          <a href="https://support.apple.com/et-ee/guide/safari/sfri11471/mac">
            Safari
          </a>
        </li>
      </ul>
      `
    },
    {
      title: 'Google Analytics Tool',
      header: { before: 'Google Analytics Tool' },
      id: 'section12',
      html: `
      <p>
        The Website uses the "Google Analytics" service provided by Google Ireland Ltd. (Gordon House, 4 Barrow Street, Dublin, Ireland) to analyze the use of the Website by Users. The service uses text files stored on your device. It is used on the basis of our legitimate interest within the meaning of Article 6(1)(f) of the GDPR, in order to learn about the Users of the Website and to optimize our offer for those Users (marketing and optimization purposes).
      </p>
      <p>
        You may also prevent the collection of data generated by cookies and related to your use of the Website (including your IP address) to Google and the processing of this data by Google by installing a plug-in provided by Google (when you click on the link, you will be redirected to an external website):
        <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
          https://tools.google.com/dlpage/gaoptout?hl=en
        </a>.
      </p>
      <p>
        Due to the location of Google's servers outside the EEA, it is possible that data may be transferred to third countries. As an appropriate safeguard, the standard contractual clauses pursuant to Article 46 GDPR apply.
      </p>
      <p>
        For more information on how Google uses user data, see
        <a href="https://support.google.com/analytics/answer/6004245?hl=en">here</a>.
      </p>
    `
    },
    {
      title: 'Links to Other Websites',
      header: { before: 'Links to Other Websites' },
      id: 'section14',
      html: `
      <p>
        On the Website you can find external links allowing you to directly reach other websites. Each of the providers specifies the rules of using cookies in their privacy policy, therefore, for security reasons, we recommend that you read the document concerning their privacy policy before using such websites.
      </p>
      `
    },
    {
      title: 'Privacy Policy Changes',
      header: { before: 'Privacy Policy Changes' },
      id: 'section15',
      html: `
      <p>
        In order to ensure that the information provided complies with current legal requirements, we reserve the right to change the Privacy Policy at any time. The reservation also applies to cases when data protection information must be adjusted due to new or changed services and functionalities offered by the website. The change of this Privacy Policy will be made by placing a new text on the website, which will come into force automatically on your next visit to our website.
      </p>`
    }
  ]
};
