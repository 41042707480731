export enum TechnologyNames {
  ANGULAR = 'Angular',
  JAVA = 'Java',
  KOTLIN = 'Kotlin',
  LARAVEL = 'Laravel',
  REACT = 'React',
  PHP = 'PHP',
  VUE_JS = 'Vue.js',
  NODE_JS = 'Node.js',
  REDUX = 'Redux',
  MY_SQL = 'MySQL',
  JAVASCRIPT = 'JavaScript',
  TYPESCRIPT = 'TypeScript',
  SYMFONY = 'Symfony',
  GRAPH_QL = 'GraphQL',
  KUBERNETES = 'Kubernetes',
  SPRING = 'Spring',
  KTOR = 'Ktor',
  DOT_NET = '.NET',
  NUXT_JS = 'Nuxt.js',
  MONGO_DB = 'MongoDB',
  FLUTTER = 'Flutter',
  SOLIDITY = 'Solidity',
  AWS = 'AWS',
  GOOGLE_CLOUD = 'GCP',
  TENSORFLOW = 'Tensorflow',
  RX_JS = 'RxJS',
  RABBIT_MQ = 'RabbitMQ',
  C_SHARP = 'C#',
  JEST = 'Jest',
  HELM = 'Helm',
  DOCKER = 'Docker',
  SCSS = 'SCSS',
  REDIS = 'Redis',
  POSTGRES_SQL = 'PostgreSQL',
  REACT_NATIVE = 'React Native',
  PYTHON = 'Python',
  GO = 'Go',
  DYNAMO_DB = 'DynamoDB',
  REST = 'Rest',
  ML_AI = 'ML & AI',
  TERRAFORM = 'Terraform',
  WEB_3 = 'Web3',
  HARDHAT = 'Hardhat',
  ETHERS = 'Ethers',
  TYPE_CHAIN = 'TypeChain',
  SWIFT = 'Swift',
  SWIFT_UI = 'SwiftUI',
  JSON = 'Json',
  JETPACK_COMPOSE = 'Jetpack Compose',
}

export enum CountryNames {
  POLAND = 'Poland',
  FRANCE = 'France',
  GERMANY = 'Germany',
  AUSTRIA = 'Austria',
  CANADA = 'Canada',
  USA = 'USA',
  AUSTRALIA = 'Australia',
  UKRAINE = 'Ukraine',
  UK = 'UK'
}

export enum CaseTechnologies {
  NODEJS = 'nodejs',
  PHP = 'php',
  JAVA = 'java',
  LARAVEL = 'laravel',
  KOTLIN = 'kotlin',
  ANGULAR = 'angular',
  REACT = 'react',
  VUE = 'vue',
  REDUX = 'redux',
  MY_SQL = 'mysql',
  GRAPHQL = 'graphql',
  SYMFONY = 'symfony',
  TYPESCRIPT = 'typescript',
  JAVASCRIPT = 'javascript',
  KUBERNETES = 'kubernetes',
  NUXT = 'nuxt',
  KTOR = 'ktor',
  MONGODB = 'mongodb'
}

export enum CaseViews {
  CLUTCH = 'clutch',
  BEHANCE = 'behance'
}

export enum TextPageListType {
  STANDARD = 'standard',
  NUMBERS = 'numbers',
  CHECKMARKS = 'checkmarks'
}

export enum SlideCount {
  PREV_PREV = 'prev-prev',
  PREV = 'prev',
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
  FOURTH = 'fourth'
}

export enum ProjectFields {
  SALARY = 'salary',
  CLIENT = 'client',
  INDUSTRY = 'industry',
  STATUS = 'status',
  DURATION = 'duration',
  PROJECT_STATUS = 'projectStatus'
}

export enum JobOfferCategories {
  RECRUITMENT_MARKETING = 'Recruitment / Marketing',
  MANAGEMENT = 'Project & Product Management',
  BACKEND = 'Backend Development',
  FRONTEND = 'Frontend Development',
  DESIGN = 'Product Design',
  MOBILE = 'Mobile',
  OTHER = 'Other'
}

export enum Pages {
  HOME = 'home',
  CONTACT = 'contact',
  CAREER = 'career',
  ABOUT = 'about',
  PRIVACY_POLICY = 'privacyPolicy',
  JOBS = 'jobs',
  PROJECTS = 'projects',
  SUCCESS_STORY = 'successStory',
  SUCCESS_STORIES = 'successStories',
  TERMS = 'terms',
  TEAM_AUGMENTATION = 'teamAugmentation',
  BLOG = 'blog',
  VIDEO_LISTING = 'Video Listing'
}

export enum PagesLinks {
  HOME = '/',
  PRIVACY_POLICY = '/privacy-policy',
  CAREER = '/career',
  CONTACT = '/contact',
  JOBS = '/career/jobs',
  ABOUT = '/about',
  TERMS_OF_SERVICES = '/terms-of-service',
  SITEMAP = '/sitemap.xml',
  ERROR = '/404',
  PROJECTS = '/career/projects',
  SUCCESS_STORIES = '/career/success-stories',
  DATA_PROCESSING = '/data-processing',
  TEAM_AUGMENTATION = '/team-augmentation',
  TECHNOLOGIES = '/technologies',
  BLOG = '/blog',
  UKRAINE = '/help-ukraine',
  VIDEO_LISTING = '/career/video-listing'
}

export enum SocialMediaNames {
  CLUTCH = 'Clutch',
  FACEBOOK = 'Facebook',
  BEHANCE = 'Behance',
  LINKEDIN = 'LinkedIn',
  DRIBBLE = 'Dribble',
  YOUTUBE = 'YouTube',
  INSTAGRAM = 'Instagram',
  TWITTER = 'Twitter',
  VIMEO = 'Vimeo'
}

export type SharableSocialMedia = SocialMediaNames.FACEBOOK | SocialMediaNames.TWITTER | SocialMediaNames.LINKEDIN;

export enum Directions {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left'
}

export enum Pagination {
  PREV = 'prev',
  NEXT = 'next'
}

export enum SeniorityLevels {
  JUNIOR = 'Junior',
  MID = 'Mid',
  SENIOR = 'Senior',
  ARCHITECT = 'Architect'
}

export enum TechnologyLevels {
  NICE_TO_HAVE = 1,
  JUNIOR,
  REGULAR,
  ADVANCED,
  MASTER
}

export enum ListingContentTypes {
  PROJECTS = 'projects',
  JOB_OFFERS = 'job-offers'
}
