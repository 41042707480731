export interface SEOField {
  description: string;
  title: string;
  twitterCard: string;
  image: {
    url: string;
  };
}

export interface BlogImage {
  alt: string | null;
  title: string | null;
  width: number;
  height: number;
  url: string;
}

interface BlogContentItem {
  id: string;
}

export interface BlogTextContent extends BlogContentItem {
  content: string;
}

export interface BlogImageContent extends BlogContentItem {
  caption: string | null;
  source: string | null;
  image: BlogImage;
}

export type BlogBody = BlogTextContent | BlogImageContent;

export const isTextContent = (item: BlogBody): item is BlogTextContent => {
  return 'content' in item;
};
export const isImageContent = (item: BlogBody): item is BlogImageContent => {
  return 'image' in item;
};

export interface BlogPostModel {
  name: string;
  author: string;
  slug: string;
  category: string;
  thumbnail: BlogImage;
  seoDescription: SEOField;
  dateOfPublication: string;
  body: BlogBody[];
  relatedPosts: string[];
}

export type BlogPostWithId = BlogPostModel & { id: string };

export type RelatedPost = Pick<BlogPostModel, 'author' | 'slug' | 'name' | 'dateOfPublication'>;

export type BlogPostWithRelatedPosts = Omit<BlogPostModel, 'relatedPosts'> & {
  relatedPosts: RelatedPost[];
};
