






























import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwScheduleModal } from '../ScheduleModal/DcwScheduleModal.vue';
import { DcwButton, DcwImage, DcwNewHeadline } from '@atoms';
import {
  PersonCard,
  HeadlineTypes,
  ButtonVariants,
  ScheduleModalPeople,
  HeadlineSubtitleSizes,
  HeadlineSubtitleSpacing
} from '@/types';
import LinkedInIcon from '@/assets/brands/linkedin.svg';

export const DcwPersonCard = defineComponent({
  name: 'DcwPersonCard',
  components: { DcwButton, DcwImage, DcwNewHeadline, DcwScheduleModal, LinkedInIcon },
  props: {
    person: {
      type: Object as PropType<PersonCard & { modal?: ScheduleModalPeople }>,
      required: true
    }
  },
  setup(props) {
    const isModalShown = ref(false);

    const headlineProps = computed(() => ({
      isTinted: true,
      styledAs: HeadlineTypes.H4,
      subtitle: props.person.position,
      subtitleSize: HeadlineSubtitleSizes.EXTRA_SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM
    }));

    const buttonProps = {
      variant: ButtonVariants.TERTIARY,
      isLink: !!props.person.button?.href,
      ...props.person.button
    };

    const clickHandler = () => {
      if (props.person.modal) {
        isModalShown.value = true;
      }
    };

    return { headlineProps, isModalShown, buttonProps, clickHandler };
  }
});

export default DcwPersonCard;
