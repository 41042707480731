/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

export const story: SuccessStory = {
  name: 'Bartosz',
  videoId: '710734352',
  images: {
    og: '/covers/success-story/story-2.jpg',
    avatar: {
      src: '/success-stories/story-2/avatar.png',
      alt: 'Bartosz',
      width: 32,
      height: 32
    },
    cover: {
      src: '/success-stories/story-2/cover.jpg',
      alt: 'Bartosz cover',
      height: 608
    },
    slider: {
      src: '/success-stories/story-2/slider.png',
      alt: 'Bartosz',
      width: 317,
      height: 224
    },
    listing: {
      src: '/success-stories/story-2/listing.png',
      alt: 'Bartosz',
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'Aug 7, 2021',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'Head of Recruitment',
  details: `I took on recruitment as my first choice. Turns out recruitment comes very naturally for me
    and in practice, results are relatively good. DC was my choice cause I love the idea of
    remote work. I appreciate the flexibility and also I feel that I make a good match with organizational culture and the rest of the DC team.`,
  shortStory: 'It turns out recruitment comes very naturally for me, and in practice, results are relatively good. ',
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer:
        "Before DC I've been taking on multiple various professions. Among one of many industries, I've had contact with was work on the real estate market where I was dealing with sales and rent of apartments in Cracow. The experiences I've driven from this period are very useful right now while I work in recruitment. It's not that different as somehow you also need to “sell” the position, atmosphere, and whole company to the applicant."
    },
    {
      type: 'text',
      title: 'What do you do in DC?',
      answer:
        "In DC I am the Head of Recruitment. From the very beginning, when someone applies to DC in most cases I'll be the first person to contact the applicant and the one to call him with the final job offer."
    },
    {
      type: 'text',
      title: 'Why did you choose DC?',
      answer:
        "As the IT industry is developing so dynamically I've decided to try my hand. As a person with a non-technical background having more experience dealing with people, I took on recruitment as my first choice. Turns out recruitment comes very naturally for me and in practice, results are relatively good. DC was my choice cause I love the idea of remote work. I appreciate the flexibility and also I feel that I make a good match with organizational culture and the rest of the DC team."
    },
    {
      type: 'image',
      details: 'Paintball battlefield in Tatras',
      img: {
        src: '/success-stories/story-2/image-1.png',
        alt: 'Paintball battlefield in Tatras',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.ZAKOPANE_INTEGRATION_TRIP
    },
    {
      type: 'text',
      title: 'What was the greatest challenge that you had to overcome in DC?',
      answer:
        'A big challenge is the choice of the right person that would fit into the rest of the team. Even though we strive to objectively rank candidates based on preset criteria sometimes it turns out that the best advisor is still simply intuition. Another challenge is rising competition in the market that I naturally have to fight with to get the best candidates. Sometimes I also feel the pressure as I have to satisfy both applicants and the business unit of the company. Each person staying with us for a longer time is however extremely satisfying which makes up for everything in the end.'
    },
    {
      type: 'text',
      title: 'What new things have you learned in DC?',
      answer:
        "Due to the high intensity and number of recruitment calls I had to make, I feel like I've gone through a serious adolescence course. For some time now I've realized that I treat some issues much more seriously. I have a much deeper understanding of business functioning in general. Having collaborated with marketing or finance departments provided me with a whole new perspective of entrepreneurship. I've learned a great, businesslike, practical, and pragmatic approach to work."
    },
    {
      type: 'text',
      title: 'How do you see yourself in retirement?',
      answer:
        "In retirement I see myself living a calm life in a cottage on the edge of a forest realizing myself in all the areas that I don't have time to pursue now."
    },
    {
      type: 'video',
      details: 'Interview with Bartosz',
      thumbnail: {
        src: '/success-stories/story-2/image-2.jpg',
        alt: 'Interview with Bartosz',
        width: 952,
        height: 404
      },
      videoId: '710734352',
      ytLink: YOUTUBE_LINKS.BARTOSZ_INTERVIEW
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Brothers In Arms (Remastered 1996)',
          img: {
            src: '/success-stories/story-2/song-1.png',
            alt: 'Brothers In Arms (Remastered 1996) album cover',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/album/6Pz06FAaeym0JSqVqIkN56',
          ytLink: 'https://www.youtube.com/watch?v=9ykZc5E6UEE'
        },
        {
          title: 'Young Leosia - Stonerki',
          img: {
            src: '/success-stories/story-2/song-2.png',
            alt: 'Young Leosia - Stonerki',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/17WFj5Mbg9gP2NdmYgkCpd',
          ytLink: 'https://www.youtube.com/watch?v=DEnVxBD2Tsg'
        },
        {
          title: 'Hilight tribe - Free Tybet ',
          img: {
            src: '/success-stories/story-2/song-3.png',
            alt: 'Hilight tribe - Free Tybet ',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/album/3CuPsNuhl1iuwDjFvMOLHe',
          ytLink: 'https://www.youtube.com/watch?v=0lBjcaMokvo'
        }
      ]
    }
  ]
};
