import { Context } from '@nuxt/types';
import { LocalStorage, Referrers } from '@/types';

const referrerValues = Object.values(Referrers);
const isReferrer = (ref: unknown): ref is Referrers => referrerValues.includes(ref as Referrers);

export default ({ app, query }: Context) => {
  if (!process.browser || !query.referrer) { return; }
  const { referrer, ...queryRest } = query;

  app.router?.onReady(() => app.router?.replace({ query: queryRest }));

  const name = isReferrer(referrer) ? referrer : Referrers.DC_WEBSITE;
  const entry = JSON.stringify({ name, timestamp: Date.now() });

  localStorage.setItem(LocalStorage.REFERRER, entry);
};
