var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:( _obj = {
    'dcw-new-headline': true
  }, _obj[("dcw-new-headline--color-" + _vm.color)] = true, _obj[("dcw-new-headline--color-tablet-" + _vm.tabletColor)] = _vm.tabletColor, _obj[("dcw-new-headline--color-mobile-" + _vm.mobileColor)] = _vm.mobileColor, _obj )},[_c(_vm.type,{tag:"component",class:( _obj$1 = {
      'dcw-new-headline__title': true,
      'dcw-new-headline__title--bold': _vm.isBold
    }, _obj$1[("dcw-new-headline__title--type-" + _vm.stylingModifier)] = true, _obj$1[("dcw-new-headline__title--type-tablet-" + _vm.tabletStyledAs)] = _vm.tabletStyledAs, _obj$1[("dcw-new-headline__title--type-mobile-" + _vm.mobileStyledAs)] = _vm.mobileStyledAs, _obj$1 )},[(_vm.before || _vm.highlight || _vm.after)?[(_vm.before)?_c('DcwText',{attrs:{"text":_vm.before,"as":"span"}}):_vm._e(),_vm._v(" "),(_vm.highlight)?_c('DcwText',{staticClass:"dcw-new-headline__title-highlight",attrs:{"text":_vm.highlight,"as":"span"}}):_vm._e(),_vm._v(" "),(_vm.after)?_c('DcwText',{attrs:{"text":_vm.after,"as":"span"}}):_vm._e()]:_vm._t("default")],2),_vm._v(" "),(_vm.subtitle)?_c('DcwText',{class:( _obj$2 = {
      'dcw-new-headline__subtitle': true,
      'dcw-new-headline__subtitle--tinted': _vm.isTinted
    }, _obj$2[("dcw-new-headline__subtitle--spacing-" + _vm.subtitleSpacing)] = true, _obj$2[("dcw-new-headline__subtitle--spacing-mobile-" + _vm.mobileSubtitleSpacing)] = _vm.mobileSubtitleSpacing, _obj$2[("dcw-new-headline__subtitle--spacing-tablet-" + _vm.tabletSubtitleSpacing)] = _vm.tabletSubtitleSpacing, _obj$2[("dcw-new-headline__subtitle--size-" + _vm.subtitleSize)] = true, _obj$2[("dcw-new-headline__subtitle--size-mobile-" + _vm.mobileSubtitleSize)] = _vm.mobileSubtitleSize, _obj$2[("dcw-new-headline__subtitle--size-tablet-" + _vm.tabletSubtitleSize)] = _vm.tabletSubtitleSize, _obj$2 ),attrs:{"text":_vm.subtitle}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }