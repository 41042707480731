/* eslint-disable max-len */
import { buttons } from '../buttons';
import { pagesNames } from '../pagesNames';
import { MeetOurTeamSectionContent, PagesLinks, TechnologyColors } from '@/types';

const height = 350;
const width = 320;

const secondButton = {
  to: PagesLinks.CONTACT,
  text: buttons.hireUs
};

const firstButton = {
  to: PagesLinks.CAREER,
  text: buttons.joinUs
};

export const meetOurTeam: MeetOurTeamSectionContent = {
  headline: {
    before: 'Meet Our',
    highlight: 'Teams',
    subtitle: 'We build software engineering teams for world leading product companies and corporations.'
  },
  items: [
    {
      color: TechnologyColors.DEFAULT,
      navText: 'About Us',
      cardContent: {
        highlight: 'A Bit About Us',
        firstButton: {
          to: PagesLinks.CAREER,
          text: pagesNames.career
        },
        secondButton: {
          to: PagesLinks.CONTACT,
          text: pagesNames.contact
        },
        subtitle: 'People are at the heart of what we do and how we approach our work.\nGet to know us here.'
      },
      teamMembers: [
        {
          layers: [1],
          priority: 1,
          offsets: [70],
          image: {
            width,
            height,
            alt: 'Image of team member #1',
            src: '/home/meet-our-teams/team-1/image-1.webp'
          }
        },
        {
          layers: [2],
          priority: 0,
          offsets: [-23, 41, 120],
          image: {
            width,
            height,
            alt: 'Image of team member #2',
            src: '/home/meet-our-teams/team-1/image-2.webp'
          }
        },
        {
          layers: [5],
          priority: 3,
          offsets: [145],
          image: {
            width,
            height,
            alt: 'Image of team member #3',
            src: '/home/meet-our-teams/team-1/image-3.webp'
          }
        },
        {
          layers: [1],
          priority: 2,
          offsets: [144, 144, 144, 180, 146],
          image: {
            width,
            height,
            alt: 'Image of team member #4',
            src: '/home/meet-our-teams/team-1/image-4.webp'
          }
        },
        {
          layers: [1, 1, 5],
          priority: 0,
          offsets: [102, 90, 127, 64],
          image: {
            width,
            height,
            alt: 'Image of team member #5',
            src: '/home/meet-our-teams/team-1/image-5.webp'
          }
        }
      ]
    },
    {
      color: TechnologyColors.VUE,
      navText: 'Vue.js',
      cardContent: {
        highlight: 'Vue.js Team',
        firstButton,
        secondButton,
        subtitle: 'Vue.js offers developers some practical, effort-saving conventions to avoid writing boilerplate code.'
      },
      teamMembers: [
        {
          layers: [1],
          priority: 2,
          offsets: [65],
          image: {
            width,
            height,
            alt: 'Image of team member #1',
            src: '/home/meet-our-teams/team-2/image-1.webp'
          }
        },
        {
          layers: [2],
          priority: 0,
          offsets: [-3, 53, 53, 136],
          image: {
            width,
            height,
            alt: 'Image of team member #2',
            src: '/home/meet-our-teams/team-2/image-2.webp'
          }
        },
        {
          layers: [3],
          priority: 0,
          offsets: [96, 107, 123],
          image: {
            width,
            height,
            alt: 'Image of team member #3',
            src: '/home/meet-our-teams/team-2/image-3.webp'
          }
        },
        {
          layers: [4],
          priority: 3,
          offsets: [144],
          image: {
            width,
            height,
            alt: 'Image of team member #4',
            src: '/home/meet-our-teams/team-2/image-4.webp'
          }
        },
        {
          layers: [5],
          priority: 1,
          offsets: [90, 90, 90, 90, 128],
          image: {
            width,
            height,
            alt: 'Image of team member #5',
            src: '/home/meet-our-teams/team-2/image-5.webp'
          }
        }
      ]
    },
    {
      color: TechnologyColors.JAVA,
      navText: 'Java',
      cardContent: {
        highlight: 'Java Team',
        firstButton,
        secondButton,
        subtitle:
          'Java provides developers with a secure environment and a mature ecosystem of battle-tested tools and various plugins.'
      },
      teamMembers: [
        {
          layers: [1],
          priority: 1,
          offsets: [54],
          image: {
            width,
            height,
            alt: 'Image of team member #1',
            src: '/home/meet-our-teams/team-3/image-1.webp'
          }
        },
        {
          layers: [2],
          priority: 0,
          offsets: [9, 53, 155],
          image: {
            width,
            height,
            alt: 'Image of team member #2',
            src: '/home/meet-our-teams/team-3/image-2.webp'
          }
        },
        {
          layers: [3],
          priority: 2,
          offsets: [137],
          image: {
            width,
            height,
            alt: 'Image of team member #3',
            src: '/home/meet-our-teams/team-3/image-3.webp'
          }
        },
        {
          layers: [4],
          priority: 0,
          offsets: [95, 127, 143],
          image: {
            width,
            height,
            alt: 'Image of team member #4',
            src: '/home/meet-our-teams/team-3/image-4.webp'
          }
        },
        {
          layers: [5],
          priority: 3,
          offsets: [136],
          image: {
            width,
            height,
            alt: 'Image of team member #5',
            src: '/home/meet-our-teams/team-3/image-5.webp'
          }
        }
      ]
    },
    {
      color: TechnologyColors.REACT,
      navText: 'React',
      cardContent: {
        highlight: 'React Team',
        firstButton,
        secondButton,
        subtitle: 'React has been one of the most in demand frontend frameworks and the backbone of many innovative projects.'
      },
      teamMembers: [
        {
          layers: [1],
          priority: 2,
          offsets: [59],
          image: {
            width,
            height,
            alt: 'Image of team member #1',
            src: '/home/meet-our-teams/team-4/image-1.webp'
          }
        },
        {
          layers: [2],
          priority: 0,
          offsets: [-7, 51, 51, 121],
          image: {
            width,
            height,
            alt: 'Image of team member #2',
            src: '/home/meet-our-teams/team-4/image-2.webp'
          }
        },
        {
          layers: [3],
          priority: 0,
          offsets: [94, 102, 118],
          image: {
            width,
            height,
            alt: 'Image of team member #3',
            src: '/home/meet-our-teams/team-4/image-3.webp'
          }
        },
        {
          layers: [4],
          priority: 3,
          offsets: [120],
          image: {
            width,
            height,
            alt: 'Image of team member #4',
            src: '/home/meet-our-teams/team-4/image-4.webp'
          }
        },
        {
          layers: [5],
          priority: 1,
          offsets: [0, 0, 128, 128, 145],
          image: {
            width,
            height,
            alt: 'Image of team member #5',
            src: '/home/meet-our-teams/team-4/image-5.webp'
          }
        }
      ]
    },
    {
      color: TechnologyColors.KOTLIN,
      navText: 'Kotlin',
      cardContent: {
        highlight: 'Kotlin Team',
        firstButton,
        secondButton,
        subtitle:
          'Clear and compact, Kotlin improves productivity with its succinct and intuitive syntax what helps teams to stay efficient.'
      },
      teamMembers: [
        {
          layers: [1],
          priority: 1,
          offsets: [73],
          image: {
            width,
            height,
            alt: 'Image of team member #1',
            src: '/home/meet-our-teams/team-5/image-1.webp'
          }
        },
        {
          layers: [2],
          priority: 3,
          offsets: [127],
          image: {
            width,
            height,
            alt: 'Image of team member #2',
            src: '/home/meet-our-teams/team-5/image-2.webp'
          }
        },
        {
          layers: [3],
          priority: 2,
          offsets: [126],
          image: {
            width,
            height,
            alt: 'Image of team member #3',
            src: '/home/meet-our-teams/team-5/image-3.webp'
          }
        },
        {
          layers: [4],
          priority: 0,
          offsets: [14, 46, 127],
          image: {
            width,
            height,
            alt: 'Image of team member #4',
            src: '/home/meet-our-teams/team-5/image-4.webp'
          }
        },
        {
          layers: [5],
          priority: 0,
          offsets: [105, 125],
          image: {
            width,
            height,
            alt: 'Image of team member #5',
            src: '/home/meet-our-teams/team-5/image-5.webp'
          }
        }
      ]
    },
    {
      color: TechnologyColors.NODE,
      navText: 'Node.js',
      cardContent: {
        highlight: 'Node.js Team',
        firstButton,
        secondButton,
        subtitle: 'Node.js’s event-driven IO model supports the handling of concurrent requests what makes it efficient and fast.'
      },
      teamMembers: [
        {
          layers: [1],
          priority: 2,
          offsets: [76],
          image: {
            width,
            height,
            alt: 'Image of team member #1',
            src: '/home/meet-our-teams/team-6/image-1.webp'
          }
        },
        {
          layers: [2],
          priority: 1,
          offsets: [0, 0, 50, 120],
          image: {
            width,
            height,
            alt: 'Image of team member #2',
            src: '/home/meet-our-teams/team-6/image-2.webp'
          }
        },
        {
          layers: [3],
          priority: 0,
          offsets: [5, 46, 127],
          image: {
            width,
            height,
            alt: 'Image of team member #3',
            src: '/home/meet-our-teams/team-6/image-3.webp'
          }
        },
        {
          layers: [4],
          priority: 0,
          offsets: [94, 121, 135],
          image: {
            width,
            height,
            alt: 'Image of team member #4',
            src: '/home/meet-our-teams/team-6/image-4.webp'
          }
        },
        {
          layers: [5],
          priority: 3,
          offsets: [121],
          image: {
            width,
            height,
            alt: 'Image of team member #5',
            src: '/home/meet-our-teams/team-6/image-5.webp'
          }
        }
      ]
    },
    {
      color: TechnologyColors.DOT_NET,
      navText: '.NET',
      cardContent: {
        highlight: '.NET Team',
        firstButton,
        secondButton,
        subtitle: 'The .NET framework is intended to be used by most new modern applications created for the Windows platform.'
      },
      teamMembers: [
        {
          layers: [1],
          priority: 0,
          offsets: [-10, 40, 53],
          image: {
            width,
            height,
            alt: 'Image of team member #1',
            src: '/home/meet-our-teams/team-7/image-1.webp'
          }
        },
        {
          layers: [2],
          priority: 3,
          offsets: [147],
          image: {
            width,
            height,
            alt: 'Image of team member #2',
            src: '/home/meet-our-teams/team-7/image-2.webp'
          }
        },
        {
          layers: [3],
          priority: 1,
          offsets: [147],
          image: {
            width,
            height,
            alt: 'Image of team member #3',
            src: '/home/meet-our-teams/team-7/image-3.webp'
          }
        },
        {
          layers: [4],
          priority: 2,
          offsets: [114],
          image: {
            width,
            height,
            alt: 'Image of team member #4',
            src: '/home/meet-our-teams/team-7/image-4.webp'
          }
        },
        {
          layers: [5],
          priority: 0,
          offsets: [100, 115, 112, 141],
          image: {
            width,
            height,
            alt: 'Image of team member #5',
            src: '/home/meet-our-teams/team-7/image-5.webp'
          }
        }
      ]
    },
    {
      color: TechnologyColors.ANGULAR,
      navText: 'Angular',
      cardContent: {
        highlight: 'Angular Team',
        firstButton,
        secondButton,
        subtitle: 'Angular is an extensible toolset for building the framework most suited to your application development.'
      },
      teamMembers: [
        {
          layers: [1],
          priority: 3,
          offsets: [45],
          image: {
            width,
            height,
            alt: 'Image of team member #1',
            src: '/home/meet-our-teams/team-8/image-1.webp'
          }
        },
        {
          layers: [2],
          priority: 0,
          offsets: [0, 38, 53, 53, 138],
          image: {
            width,
            height,
            alt: 'Image of team member #2',
            src: '/home/meet-our-teams/team-8/image-2.webp'
          }
        },
        {
          layers: [3],
          priority: 0,
          offsets: [72, 90, 95],
          image: {
            width,
            height,
            alt: 'Image of team member #3',
            src: '/home/meet-our-teams/team-8/image-3.webp'
          }
        },
        {
          layers: [4],
          priority: 2,
          offsets: [122],
          image: {
            width,
            height,
            alt: 'Image of team member #4',
            src: '/home/meet-our-teams/team-8/image-4.webp'
          }
        },
        {
          layers: [5],
          priority: 1,
          offsets: [0, 0, 152, 169],
          image: {
            width,
            height,
            alt: 'Image of team member #5',
            src: '/home/meet-our-teams/team-8/image-5.webp'
          }
        }
      ]
    },
    {
      color: TechnologyColors.FLUTTER,
      navText: 'Flutter',
      cardContent: {
        highlight: 'Flutter Team',
        firstButton,
        secondButton,
        subtitle: 'Flutter is an open-source framework by Google for building multi-platform applications from a single codebase.'
      },
      teamMembers: [
        {
          layers: [1],
          priority: 1,
          offsets: [0, 40, 40, 70],
          image: {
            width,
            height,
            alt: 'Image of team member #1',
            src: '/home/meet-our-teams/team-9/image-1.webp'
          }
        },
        {
          layers: [2],
          priority: 3,
          offsets: [100, 80, 100, 100, 120],
          image: {
            width,
            height,
            alt: 'Image of team member #2',
            src: '/home/meet-our-teams/team-9/image-2.webp'
          }
        },
        {
          layers: [3],
          priority: 0,
          offsets: [0, 15, 95, 140],
          image: {
            width,
            height,
            alt: 'Image of team member #3',
            src: '/home/meet-our-teams/team-9/image-3.webp'
          }
        },
        {
          layers: [4],
          priority: 0,
          offsets: [80, 100, 110, 130],
          image: {
            width,
            height,
            alt: 'Image of team member #4',
            src: '/home/meet-our-teams/team-9/image-4.webp'
          }
        },
        {
          layers: [5],
          priority: 2,
          offsets: [0, 0, 152, 130],
          image: {
            width,
            height,
            alt: 'Image of team member #5',
            src: '/home/meet-our-teams/team-9/image-5.webp'
          }
        }
      ]
    },
    {
      color: TechnologyColors.SOLIDITY,
      navText: 'Solidity',
      cardContent: {
        highlight: 'Solidity Team',
        firstButton,
        secondButton,
        subtitle: 'Solidity is a contract-oriented programming language. The number of developers is estimated at 200,000.'
      },
      teamMembers: [
        {
          layers: [1],
          priority: 3,
          offsets: [65],
          image: {
            width,
            height,
            alt: 'Image of team member #1',
            src: '/home/meet-our-teams/team-10/image-1.webp'
          }
        },
        {
          layers: [2],
          priority: 0,
          offsets: [0, 38, 53, 53, 138],
          image: {
            width,
            height,
            alt: 'Image of team member #2',
            src: '/home/meet-our-teams/team-10/image-2.webp'
          }
        },
        {
          layers: [3],
          priority: 0,
          offsets: [72, 90, 100],
          image: {
            width,
            height,
            alt: 'Image of team member #3',
            src: '/home/meet-our-teams/team-10/image-3.webp'
          }
        },
        {
          layers: [4],
          priority: 2,
          offsets: [130],
          image: {
            width,
            height,
            alt: 'Image of team member #4',
            src: '/home/meet-our-teams/team-10/image-4.webp'
          }
        },
        {
          layers: [5],
          priority: 1,
          offsets: [0, 0, 152, 120],
          image: {
            width,
            height,
            alt: 'Image of team member #5',
            src: '/home/meet-our-teams/team-10/image-5.webp'
          }
        }
      ]
    }
  ]
};
