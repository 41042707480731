






import { defineComponent } from '@nuxtjs/composition-api';
import { DcwScheduleModalDatePicker } from '../';
import { DcwNewHeadline } from '@atoms';
import { HeadlineTypes } from '@/types';
import { useLocales } from '@/composables';

export const DcwScheduleModalDateStep = defineComponent({
  name: 'DcwScheduleModalDateStep',
  components: { DcwNewHeadline, DcwScheduleModalDatePicker },
  setup() {
    const { translation } = useLocales();

    const headlineProps = {
      before: translation.modals.scheduleModal.selectDay,
      type: HeadlineTypes.H4
    };

    return { headlineProps };
  }
});

export default DcwScheduleModalDateStep;
