/* eslint-disable max-len */
import { PositionFAQSection } from '@/types';

export const faq: PositionFAQSection = {
  headline: {
    before: 'FAQ'
  },
  items: [
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ac posuere urna?',
      answer:
        'Evitae bibendum enim ultrices eu. Proin quis cursus mauris. Aliquam commodo mauris sit amet erat feugiat, non dignissim tortor hendrerit. Proin viverra feugiat velit, at aliquam metus laoreet et. Morbi at blandit erat. Duis hendrerit lorem ut varius suscipit. Fusce elementum tortor lorem.'
    },
    {
      question: 'Proin viverra feugiat velit, at aliquam metus laoreet et. ',
      answer:
        'Evitae bibendum enim ultrices eu. Proin quis cursus mauris. Aliquam commodo mauris sit amet erat feugiat, non dignissim tortor hendrerit. Proin viverra feugiat velit, at aliquam metus laoreet et. Morbi at blandit erat. Duis hendrerit lorem ut varius suscipit. Fusce elementum tortor lorem.'
    },
    {
      question: 'Morbi at blandit erat. Duis hendrerit lorem ut varius suscipit. Fusce elementum tortor lorem,',
      answer:
        'Evitae bibendum enim ultrices eu. Proin quis cursus mauris. Aliquam commodo mauris sit amet erat feugiat, non dignissim tortor hendrerit. Proin viverra feugiat velit, at aliquam metus laoreet et. Morbi at blandit erat. Duis hendrerit lorem ut varius suscipit. Fusce elementum tortor lorem.'
    },
    {
      question: 'Evitae bibendum enim ultrices eu.',
      answer:
        'Evitae bibendum enim ultrices eu. Proin quis cursus mauris. Aliquam commodo mauris sit amet erat feugiat, non dignissim tortor hendrerit. Proin viverra feugiat velit, at aliquam metus laoreet et. Morbi at blandit erat. Duis hendrerit lorem ut varius suscipit. Fusce elementum tortor lorem.'
    },
    {
      question: 'Aliquam commodo mauris sit amet erat feugiat, non dignissim tortor hendrerit.',
      answer:
        'Evitae bibendum enim ultrices eu. Proin quis cursus mauris. Aliquam commodo mauris sit amet erat feugiat, non dignissim tortor hendrerit. Proin viverra feugiat velit, at aliquam metus laoreet et. Morbi at blandit erat. Duis hendrerit lorem ut varius suscipit. Fusce elementum tortor lorem.'
    }
  ]
};
