/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, ProjectIDs, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: false,
  categories: [JobOfferCategories.MOBILE],
  projects: [ProjectIDs.HEALTH_PLATFORM],
  name: 'Senior Android Engineer',
  salaryRanges: salaries.android,
  seniorityLevel: SeniorityLevels.SENIOR,
  referrers: {
    [Referrers.JUST_JOIN_IT]: 198413258,
    [Referrers.DC_WEBSITE]: 198413254,
    [Referrers.OTHER]: 198413255,
    [Referrers.FACEBOOK_ADS]: 192832521,
    [Referrers.GOOGLE_ADS]: 192832520
  },
  description: [
    {
      title: 'Description',
      text: [
        'We are currently looking for an experienced \bAndroid Developer\b to join our team, and help us develop solutions for the German healthcare industry'
      ]
    },
    {
      title: 'Your Role and Responsibilities ',

      items: [
        'Development of new features in one of our growth products with the latest web technologies in a \bwell-maintained codebase\b',
        'Continuous improvement of our \bcontinuous integration\b process',
        'Ensuring our \bquality standards\b by actively participating in \bdaily code reviews\b and \bautomated tests\b',
        'Optimization and scaling of the system in \bservice-oriented architecture\b'
      ]
    },
    {
      title: 'We’re looking for someone who has',

      items: [
        'Exceptional Knowledge of the following technologies: Kotlin, Jetpack Compose, JSON',
        'Initiative and motivation to advance the product, the team, and personal development',
        'At least 3 years of experience in developing advanced mobile applications',
        'Proactive attitude and approach to solving problems',
        'Willingness to be up to date with the newest technologies',
        'Good English skills (B2)'
      ]
    }
  ],
  technologies: [
    { label: 'Kotlin', level: TechnologyLevels.ADVANCED },
    { label: 'Jetpack Compose', level: TechnologyLevels.REGULAR },
    { label: 'JSON', level: TechnologyLevels.REGULAR },
    { label: 'Java', level: TechnologyLevels.NICE_TO_HAVE }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First, \binterview\b to get to know each other and talk about both your and our expectations'
    },
    {
      title: 'Tech Interview',
      description:
        'A 1-hours \btechnical assessment\b where we are going pair programming and talking about the theoretical aspects of both Frontend and Backend'
    },
    {
      title: 'Team Lead Interview',
      description: 'Recruitment Final Stage - \binterview\b with your future Team Leader'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process within \b1 weeks\b'
    }
  ]
};
