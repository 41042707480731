import { ValidationMessageTemplate } from 'vee-validate/dist/types/types';
import { humanFileSize } from '@/helpers';

interface FormErrorMessages {
  messages?: Record<string, ValidationMessageTemplate>
}

const isFile = (maybeFile: unknown): maybeFile is File => maybeFile instanceof File;

export const validations: FormErrorMessages = {
  messages: {
    required: 'This field is required',
    email: 'Email is not valid',
    mobile: 'Please enter a valid phone number',
    checked: 'This field is required',
    file(_: string, params: Record<string, unknown>): string {
      let formats = '';

      Object.entries(params).forEach(([key]) => {
        if (isNaN(Number(key))) {
          return;
        }
        formats += formats ? `, ${params[key]}` : `${params[key]}`;
      });

      return `Upload file${formats ? ' (' + formats + ')' : ''}`;
    },
    maxSize(_, params): string {
      let size = 0;
      Object.entries(params).forEach(([key]) => {
        if (!Array.isArray(params[key]) && !params[key].length) { return; }
        const maybeFile = params[key][0];
        if (!isFile(maybeFile)) { return; }
        size = maybeFile.size;
      });
      return `File too big (${humanFileSize(size)})`;
    }
  }
};
