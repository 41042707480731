/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: false,
  categories: [JobOfferCategories.RECRUITMENT_MARKETING],
  projects: [],
  name: 'Social Media\xA0& Community Manager',
  salaryRanges: salaries.contentManager,
  seniorityLevel: SeniorityLevels.MID,
  referrers: {
    [Referrers.DC_WEBSITE]: 169181272,
    [Referrers.OTHER]: 169181271,
    [Referrers.ROCKET_JOBS]: 168389760,
    [Referrers.FACEBOOK_ADS]: 192832874,
    [Referrers.GOOGLE_ADS]: 192832876
  },
  description: [
    {
      title: 'Description',
      text: [
        'We are looking for a passionate \bcontent manager\b who would like to focus his career on IT-oriented marketing and image creation. Your primary responsibility will be to build, maintain and optimize our communication in social media, website, and other channels that we communicate with.',
        "You will join the Marketing Team at Digital Colliers. We're building it from scratch, which is a good moment to join and become a full-body cast. Currently, we've got a video editor, designer, ads specialist, and Head of the team, Simon.",
        'Our mission is to exceed the expectations of the companies we work with by providing them with extensive services and top specialists in the industry. We build sustainable development teams for our strategic partners, primarily big product companies and corporations.',
        "\bImportant\b: You won't need to set up paid social campaigns. There's a dedicated team to deal with it. However, you will work closely with them to ensure our social media presence is consistent."
      ]
    },
    {
      title: 'Responsibilities',
      items: [
        'Build our employer branding and sales strategy (with a focus on EB)',
        'Take care of our daily social media communication on FB, IG, LINKEDIN',
        'Plan and execute our social media publishing calendar',
        'Write killer recruitment copy for job offers, ads, and website www.digitalcolliers.com',
        'Closely cooperate with our Sales, Recruitment and Marketing teams',
        "Improve engagement with content that speaks to people's",
        'Support project teams in copywriting for new products'
      ]
    },
    {
      title: 'Requirements',
      items: [
        'At least 2 years of experience as a content creator, content editor, content specialist, social media manager, and a marketing specialist in the IT sector',
        'Perfect command of English (C1/C2 level) with a strong focus on grammar and vocabulary',
        'A natural passion for social media and the need to inspire online communication and share knowledge',
        'Good knowledge of advanced copywriting techniques - you need to know how to persuade a reader to take a specific action',
        `Ability to optimize the content created both in terms of SEO and conversion
        knowledge of the IT industry and interest in new technologies (experience in a software company would be an additional asset)`,
        'Experience in crafting short-form copy. You need to be able to write clear, persuasive, and original advertising copy',
        'Creative mind',
        'Strong knowledge of how to adapt copy to suit different media and purposes',
        'Proactive attitude and ability to take ownership of your responsibilities as we want you to tell us what to do, not the other way around'
      ]
    },
    {
      text: [
        'Join our marketing team to craft strong copy, great communication, and support Digital Colliers at becoming a thought leader in the industry by building a robust employer branding strategy and boosting the inbound sales section.'
      ]
    }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First \binterview\b to know each other and talk about yours and ours expectations'
    },
    {
      title: 'Recruitment Task',
      description: 'A short task, creating a short copy based on information about the project'
    },
    {
      title: 'Team Leader Interview',
      description: 'Recruitment Final Stage - interview with your future Team Leader.'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process in one week'
    }
  ]
};
