



































































import { defineComponent, toRef, watch, computed } from '@nuxtjs/composition-api';
import { DcwFormInput, DcwNewHeadline, DcwImage, DcwFormFileUpload } from '@atoms';
import {
  TextInput,
  PersonData,
  RequestStatus,
  HeadlineTypes,
  TextInputTypes,
  HeadlineSubtitleSizes,
  ScheduleMeetingFormData,
  HeadlineSubtitleSpacing,
  UpdateScheduleMeetingFormData
} from '@/types';
import { useInjectStrict, useLocales } from '@/composables';
import {
  ScheduleMeetingFormDataKey,
  UpdateScheduleMeetingFormKey,
  ScheduleMeetingPersonDataKey,
  ScheduleMeetingRequestStatusKey
} from '@/consts';
import { toBytes } from '@/helpers';

export const DcwScheduleModalContact = defineComponent({
  name: 'DcwScheduleModalContact',
  components: {
    DcwFormInput,
    DcwNewHeadline,
    DcwImage,
    DcwFormFileUpload
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { translation } = useLocales();
    const formData = useInjectStrict<Readonly<ScheduleMeetingFormData>>(ScheduleMeetingFormDataKey);
    const updateFormData = useInjectStrict<UpdateScheduleMeetingFormData>(UpdateScheduleMeetingFormKey);
    const personData = useInjectStrict<Readonly<PersonData>>(ScheduleMeetingPersonDataKey);
    const status = useInjectStrict<RequestStatus>(ScheduleMeetingRequestStatusKey);

    const nameModel = toRef(formData, 'name');
    const emailModel = toRef(formData, 'email');
    const linkedinModel = toRef(formData, 'linkedin');
    const topicModel = toRef(formData, 'topic');
    const cvModel = toRef(formData, 'cv');
    const phoneModel = toRef(formData, 'mobile');

    watch(nameModel, () => {
      updateFormData('name', nameModel.value)(formData);
    });
    watch(emailModel, () => {
      updateFormData('email', emailModel.value)(formData);
    });
    watch(linkedinModel, () => {
      updateFormData('linkedin', linkedinModel.value)(formData);
    });
    watch(topicModel, () => {
      updateFormData('topic', topicModel.value)(formData);
    });
    watch(cvModel, () => {
      updateFormData('cv', cvModel.value)(formData);
    });
    watch(phoneModel, () => {
      updateFormData('mobile', phoneModel.value)(formData);
    });

    const isInputDisabled = computed(() => status === RequestStatus.PENDING);

    const headlineProps = {
      isTinted: true,
      type: HeadlineTypes.H4,
      before: personData.name,
      subtitle: personData.position,
      subtitleSize: HeadlineSubtitleSizes.EXTRA_SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.NO
    };

    const mobileFieldProps: TextInput = {
      label: translation.inputFields.mobile,
      type: TextInputTypes.TEL,
      placeholder: translation.inputFields.mobilePlaceholder
    };

    return {
      cvModel,
      toBytes,
      nameModel,
      emailModel,
      topicModel,
      phoneModel,
      personData,
      translation,
      linkedinModel,
      headlineProps,
      TextInputTypes,
      isInputDisabled,
      mobileFieldProps,
      HeadlineSubtitleSizes
    };
  }
});

export default DcwScheduleModalContact;
