import { ProjectSectionsId } from '@/types';

export const layoutCta = {
  headline: {
    before: 'Join the Project',
    subtitle: 'View open enrollments and join the project team for the suitable position.'
  },
  button: {
    text: 'See open positions',
    href: `#${ProjectSectionsId.OPEN_POSITIONS}`
  }
};
