/* eslint-disable max-len */
import { Project, TechnologyNames, ProjectSectionsId } from '@/types';

export const project: Project = {
  name: 'Innovative Lottery SaaS Platform',
  color: '#4F8DF7',
  colors: {
    heroBg: '#F6F9FF',
    light: '#CADDFD',
    primary: '#4F8DF7'
  },
  description:
    'We designed and built a complete software SaaS solution that will enable enterprises \naround the globe to open for new markets and revolutionize the online lottery industry.',
  badges: ['30+ Engineers', '4 Teams'],
  ctaText: {
    career: {
      before: 'Mid and Senior Positions',
      highlight: 'Up\xA0to\xA026\xA0000\xA0PLN\xA0+\xA0VAT'
    },
    home: {
      before: 'We built 4 strong development teams for',
      highlight: 'German Lottery'
    }
  },
  categories: [TechnologyNames.VUE_JS, TechnologyNames.TYPESCRIPT, TechnologyNames.KOTLIN, TechnologyNames.KTOR],
  isRemote: true,
  isNew: true,
  technologies: {
    frontend: [
      TechnologyNames.VUE_JS,
      TechnologyNames.TYPESCRIPT,
      TechnologyNames.NUXT_JS,
      TechnologyNames.GRAPH_QL,
      TechnologyNames.JAVASCRIPT
    ],
    backend: [
      TechnologyNames.KOTLIN,
      TechnologyNames.KTOR,
      TechnologyNames.KUBERNETES,
      TechnologyNames.RABBIT_MQ,
      TechnologyNames.MONGO_DB,
      TechnologyNames.AWS
    ]
  },
  fields: {
    salary: {
      label: 'net B2B',
      value: 'Up to 26 000 PLN'
    },
    client: {
      label: 'Client',
      value: 'Germany'
    },
    industry: {
      label: 'Industry',
      value: ['Lottery', 'B2G']
    },
    status: {
      label: 'Status',
      value: 'MVP'
    },
    duration: {
      label: 'Duration',
      value: 'Long Term'
    },
    projectStatus: {
      label: 'Working Location',
      value: '100% Remote'
    }
  },
  images: {
    main: {
      src: '/projects/innovative-lottery-saas-platform/project.png',
      alt: 'Lottery',
      width: 1173,
      height: 437
    },
    preview: {
      src: '/projects/innovative-lottery-saas-platform/preview.png',
      alt: 'Lottery preview',
      width: 1074,
      height: 626
    }
  },
  sections: {
    description: {
      id: ProjectSectionsId.DESCRIPTION,
      title: 'Project Description',
      headline: {
        before: 'Project Description'
      },
      html: `
        <p>
          This is a long-term <strong>greenfield project</strong> that we are developing from scratch. The platform will be a SaaS
          <br/>
          web application with frontend written in <strong>Vue.js</strong>, which communicates with GraphQL API. App is based
          <br/>
          on <strong>microservices</strong> with backend built in <strong>Kotlin</strong>. We are also responsible for creating the entire
          <br/>
          application interface layer.
        </p>

        <p>
        We want to create a platform that provides a <strong>revolutionary way of creating Lotteries</strong>.
        <br/>
        The idea is to deliver a system that allows customers to create their own games and sell entries.
        <br/>
        Going further we want owners to be able to customize their user's experience by adding extensions
        <br/>
        and managing themes in their custom shops.
        </p>
      `
    },
    technologies: {
      id: ProjectSectionsId.TECHNOLOGIES,
      title: 'Tech Stack',
      headline: {
        before: 'Tech Stack'
      },
      bg: {
        src: '/projects/innovative-lottery-saas-platform/bg.png',
        width: 335,
        height: 320,
        alt: 'decorative image'
      },
      description: {
        before: 'Technology',
        subtitle: `In terms of architecture, the platform we are building is using \bSelf\b
        \bContaining Systems.\b We have microservices but some \bSCSs\b are
        also designed as modular monoliths. The backend side is based
        on \bhexagonal architecture.\b We are trying to build a product that
        is modular, extensible, and scalable. Microservices are managed
        by Kubernetes orchestrator on AWS Cloud, monitored by
        \bGraphing and ELK\b (Elasticsearch Logstash Kibana).`
      },
      frontend: {
        headline: {
          before: 'Frontend',
          subtitle: `The frontend is written in Vue.js, based on a \bmicro-frontends architecture\b along with
            \bTypeScript\b, and \bGraphQL\b which carries communication with the backend side.`
        }
      },
      backend: {
        headline: {
          before: 'Backend',
          subtitle: `Backend is written in Kotlin, we do not use Spring, we use much lighter \bKtor framework\b.
            It's a very nice change from the usual scheme of writing software.`
        }
      }
    },
    howWeWork: {
      id: ProjectSectionsId.HOW_WE_WORK,
      title: 'How do We Work',
      headline: {
        before: 'How do We Work'
      },
      html: `
      <p>
        Our team's organization is based on <strong>Scrum methodology</strong>. We divide work into 2-week sprints with
        <br/>
        daily meetings, retrospection's, and refinements. Every week we also meet up for <strong>architecture calls</strong>.
      </p>
      <p>
        We don't spend time on unnecessary calls that could have been solved on Slack. Sprints are biweekly
        <br/>
        and milestones are agreed with stakeholders and then we open the next quarter.
      </p>
      `
    },
    challenges: {
      id: ProjectSectionsId.CHALLENGES,
      title: 'Challenges',
      headline: {
        before: 'Challenges That You Will Meet'
      },
      html: `
        <ul>
          <li>
            Solve complex architectural problems to deliver scalable and maintainable code
          </li>
          <li>
            One of the biggest challenges while entering the project is to understand the way the business model is being translated into the code.
          </li>
          <li>
            We are also using quite a <strong>nonstandard technology stack</strong> which is very interesting but might be challenging at the beginning.
          </li>
        </ul>
      `
    },
    team: {
      id: ProjectSectionsId.TEAM,
      title: 'Meet Our Team',
      headline: { before: 'Meet Our Team' },
      members: [
        {
          img: {
            src: '/projects/innovative-lottery-saas-platform/team/member-1.png',
            alt: 'Image of Maciej',
            width: 236,
            height: 224
          },
          name: 'Maciej',
          position: 'Head of Development'
        },
        {
          img: {
            src: '/projects/innovative-lottery-saas-platform/team/member-2.png',
            alt: 'Image of Wojciech',
            width: 236,
            height: 224
          },
          name: 'Wojciech',
          position: 'Senior Frontend Developer'
        },
        {
          img: {
            src: '/projects/innovative-lottery-saas-platform/team/member-3.png',
            alt: 'Image of Kamil',
            width: 236,
            height: 224
          },
          name: 'Kamil',
          position: 'Senior Backend Developer'
        }
      ]
    },
    openPositions: {
      id: ProjectSectionsId.OPEN_POSITIONS,
      title: 'Open Positions',
      headline: {
        before: 'Open Positions'
      }
    },
    othersProjects: {
      id: ProjectSectionsId.OTHERS_PROJECTS,
      title: 'Other Projects',
      headline: {
        before: 'Choose From Other',
        highlight: 'Great Projects'
      }
    }
  }
};
