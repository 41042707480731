import { toBase64 } from './toBase64';
import {
  BaseEmailParams,
  ScheduleRecruitmentEmailPhoneParams,
  ScheduleRecruitmentEmailCVParams,
  ScheduleSalesEmailParams,
  SendEmailParams
} from '@/service/email/send-email';
import { ScheduleMeetingFormData, PersonData, MailType } from '@/types';

type ScheduleAMeetingFormDTOMapper = (formData: ScheduleMeetingFormData, personData: PersonData, captchaToken: string)
  => Promise<SendEmailParams>;
type RecruitmentEmailToDTO = (meeting: ScheduleMeetingFormData, person: PersonData, captchaToken: string)
  => Promise<ScheduleRecruitmentEmailCVParams | ScheduleRecruitmentEmailPhoneParams>;
type SalesEmailToDTO = (meeting: ScheduleMeetingFormData, person: PersonData, captchaToken: string) => ScheduleSalesEmailParams;

const prepareBaseFormData = (
  scheduleMeetingFormData: ScheduleMeetingFormData,
  personData: PersonData, captchaToken: string
): BaseEmailParams => ({
  captchaToken,
  type: personData.type,
  name: scheduleMeetingFormData.name,
  mail: scheduleMeetingFormData.email
});

const appendOptionalData = (meetingData: ScheduleMeetingFormData) => {
  const optionalData = <ScheduleRecruitmentEmailPhoneParams> {};
  if (meetingData.time) {
    optionalData.time = meetingData.time;
  }

  if (meetingData.date) {
    optionalData.date = meetingData.date.toDateString();
  }

  if (meetingData.linkedin) {
    optionalData.linkedin = meetingData.linkedin;
  }
  if (meetingData.mobile) {
    optionalData.mobile = meetingData.mobile;
  }

  return optionalData;
};

const recruitmentEmailToDTO: RecruitmentEmailToDTO = async (meetingData, personData, captchaToken) => {
  const formData = prepareBaseFormData(meetingData, personData, captchaToken);
  if (meetingData.cv[0]) {
    const fileData = await toBase64(meetingData.cv[0]);

    if (typeof fileData !== 'string') {
      throw new TypeError('Inside mapScheduleAMeetingFormData function');
    }

    const cv = {
      ContentType: 'application/pdf',
      Base64Content: fileData.split(',')[1],
      Filename: meetingData.cv[0].name
    };

    return { ...formData, cv, ...appendOptionalData(meetingData) };
  }
  return { ...formData, ...appendOptionalData(meetingData) };
};

const salesEmailToDTO: SalesEmailToDTO = (meetingData, personData, captchaToken) => {
  const formData = prepareBaseFormData(meetingData, personData, captchaToken);

  return { ...formData, topic: meetingData.topic, ...appendOptionalData(meetingData) };
};

export const mapScheduleAMeetingFormData: ScheduleAMeetingFormDTOMapper = async (
  scheduleMeetingFormData,
  personData,
  captchaToken
) => {
  if (personData.type === MailType.recruitment || personData.type === MailType.apply) {
    return await recruitmentEmailToDTO(scheduleMeetingFormData, personData, captchaToken);
  }

  return salesEmailToDTO(scheduleMeetingFormData, personData, captchaToken);
};
