import { PositionReasonsToJoinSectionContent } from '@/types';

export const reasonsToJoin: PositionReasonsToJoinSectionContent = {
  headline: {
    before: '6 Reasons To Join Us'
  },
  items: [
    {
      before: 'Flexibility',
      subtitle: 'Lorem ipsum dolor sit amet elit. Maecenas sodales nequeet.'
    },
    {
      before: 'Projects that matter',
      subtitle: 'Lorem ipsum dolor sit amet elit. Maecenas sodales nequeet.'
    },
    {
      before: 'People-first culture',
      subtitle: 'Lorem ipsum dolor sit amet elit. Maecenas sodales nequeet.'
    },
    {
      before: 'Self-improvement',
      subtitle: 'Lorem ipsum dolor sit amet elit. Maecenas sodales nequeet.'
    },
    {
      before: 'Independent teams',
      subtitle: 'Lorem ipsum dolor sit amet elit. Maecenas sodales nequeet.'
    },
    {
      before: 'Perks & benefits',
      subtitle: 'Lorem ipsum dolor sit amet elit. Maecenas sodales nequeet.'
    }
  ]
};
