/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, ProjectIDs, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: true,
  categories: [JobOfferCategories.BACKEND],
  projects: [ProjectIDs.PAYMENT_PLATFORM],
  name: 'Senior Go Software Engineer',
  salaryRanges: salaries.golang,
  seniorityLevel: SeniorityLevels.SENIOR,
  referrers: {
    [Referrers.DC_WEBSITE]: 168574320,
    [Referrers.OTHER]: 168574339,
    [Referrers.CARROT_WORK]: 157591130,
    [Referrers.JUST_JOIN_IT]: 162346640,
    [Referrers.NO_FLUFF_JOBS]: 162413839,
    [Referrers.BULLDOG_JOB_PL]: 162385979,
    [Referrers.FACEBOOK_ADS]: 192832845,
    [Referrers.GOOGLE_ADS]: 192832848
  },
  description: [
    {
      title: 'Description',
      text: [
        'Are you experienced in large-scale product development? We are hiring for \bone of the most valuable fintech companies in Europe.\b The project aims to develop and evolve a Payment Orchestration Platform.',
        'The company is an American \bstart-up located in the heart of New York City\b, which has been recently acquired by a much larger company, looking to improve payment processes for different merchants. They do it using 30 different SaaS products they are actively developing, covering finance, security, and crypto. With 17 locations around the world, they are now working with facebook Netflix, Adidas, and many more big players. In the last 3 years, they have gone from series A to C, now valued at around 15 Billion. Even though the companies are bound together, in practice they are a separate team, and you can really feel the start-up atmosphere. \bA start-up within a scale-up\b you could say.',
        'We are hiring the team responsible for developing a Payment Orchestration Platform on which large online merchants rely to improve their payment processes, allow for optimization, and gain insight into the costs of transactions. It is a \bself-sufficient team\b that operates in a truly agile way. Currently, the cross-functional team consists of 3 Backend Developers, 3 DevOps, 2 Frontend Developers, 2 Data Scientists.',
        'When it comes to technical stack, we are hosted in \bAWS\b, use \bEKS\b and our primary development language is \bGo\b. As we always strive to use the best tool for the job, we use a combination of \bRedis\b, \bDynamoDB\b, and \bPostgres\b for persistence layer, and \bREST\b, \bgRPC\b, and \bqueues\b for communication.',
        'The client is experiencing constant growth in the number of handled transactions, new clients, and integrations. That’s why we pay very close attention to the elegant design and scalability of the platform, and we can provide the developers with everything they need in order to improve the platform. We are looking for dedicated engineers who would like to join us on our journey.'
      ]
    },
    {
      title: 'Your Role and Responsibilities',

      items: [
        'Working together with our existing team on introducing a number of new PSP (Payment Service Providers) Integrations and Fraud detection systems integrations',
        'Increasing test coverage across services',
        'Contributing to performance improvements'
      ]
    },
    {
      title: 'We’re Looking for Someone Who Has',

      items: [
        'At least \ba year\b of software development experience in Go',
        'Knowledge about web-related tech (HTTP, TLS, proxies, API conventions)',
        'Experience with \bRESTful APIs\b and with \bgRPC\b',
        'Experience deploying applications as a part of a \bservice-oriented architecture\b',
        'Curious and unafraid of digging deeper to understand how systems of all kinds work',
        'Keen to communicate with third-party gateways from a technical point of view as well as to maintain the best relationship possible',
        'Knowledge of the payment ecosystem',
        'Experience with working on critical services',
        'Some basic knowledge about ElasticSearch'
      ]
    }
  ],
  technologies: [
    { label: 'Go', level: TechnologyLevels.ADVANCED },
    { label: 'Golang', level: TechnologyLevels.ADVANCED },
    { label: 'AWS', level: TechnologyLevels.REGULAR },
    { label: 'REST', level: TechnologyLevels.REGULAR },
    { label: 'Kubernetes', level: TechnologyLevels.JUNIOR },
    { label: 'gRPC', level: TechnologyLevels.JUNIOR }
  ]
};
