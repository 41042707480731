import NotFoundImg from '@/assets/errors/oooops.svg';
import { PagesLinks, ErrorPage } from '@/types';

export const error404: ErrorPage = {
  subtitle: 'Die von Ihnen gesuchte Seite existiert nicht’',
  description: 'Sie haben nicht gefunden, wonach Sie gesucht haben?',
  firstLink: {
    text: 'Zurück zur Startseite’',
    link: PagesLinks.HOME
  },
  secondLink: {
    text: 'Kontaktieren Sie uns',
    link: PagesLinks.CONTACT
  },
  image: NotFoundImg
};
