import { computed, ComputedRef, ref } from '@nuxtjs/composition-api';
import { JobOfferCategories, ListingFilter } from '@/types';

interface useFiltersProps<T> {
  list: T[];
  field: keyof T;
  defaultFilter?: string;
  isOffers?: boolean;
}

interface UseFilters<T> {
  filters: ListingFilter[];
  filteredList: ComputedRef<T[]>;
  changeFiltersHandler: (selected: number[]) => void;
  resetFilters: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFilters = <T extends Record<string, any>>(props: useFiltersProps<T>): ListingFilter[] => {
  const filtersStrings = props.isOffers
    ? Object.values(JobOfferCategories)
    : ([...new Set(props.list.map((item) => item[props.field]).flat(1))] as string[]).sort();
  const objectOfFilters: Record<number, ListingFilter> = {};

  if (props.defaultFilter) {
    filtersStrings.unshift(props.defaultFilter);
  }

  filtersStrings.forEach((label, index) => {
    if (props.defaultFilter && index === 0) {
      objectOfFilters[index] = { label, count: -1 };

      return;
    }

    const count = props.list.filter((dataItem) => dataItem[props.field].includes(label)).length;

    objectOfFilters[index] = { label, count };
  });

  const arrOfFiltersUsedFilters = Object.values(objectOfFilters).filter((filter) => filter.count !== 0);

  return arrOfFiltersUsedFilters;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFilters = <T extends Record<string, any>>(props: useFiltersProps<T>): UseFilters<T> => {
  const activeFilters = ref<string[]>([]);

  const filters = getFilters(props);

  const filteredList = computed(() => {
    if (props.defaultFilter) {
      if (activeFilters.value.includes(props.defaultFilter) || activeFilters.value.length === 0) {
        return props.list;
      }
    }

    return props.list.filter((item) => item[props.field].some((tag: string) => activeFilters.value.includes(tag)));
  });

  const changeFiltersHandler = (selected: number[] | number) => {
    if (Array.isArray(selected)) {
      activeFilters.value = selected.map((filter) => filters[filter].label);
      return;
    }

    activeFilters.value = [filters[selected].label];
  };

  const resetFilters = () => {
    activeFilters.value = [];
  };

  return { filters, filteredList, changeFiltersHandler, resetFilters };
};
