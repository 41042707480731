import { useMeta } from '@nuxtjs/composition-api';

export interface StyleSheet {
  selectors: string[];
  properties: { key: string; value: string }[];
}

export const useStyleSheet = (styleSheet?: StyleSheet[]) => {
  const meta = useMeta();

  const updateStyleSheet = (_styleSheet: StyleSheet[]) => {
    const cssText = _styleSheet
      .map(({ properties, selectors }) => {
        const selectorsString = selectors.join(',');
        const propertiesString = properties.map(({ key, value }) => `${key}: ${value};`).join('');

        return `${selectorsString} {${propertiesString}}`;
      })
      .join(' ');

    meta.style.value = [{ cssText }];
  };

  if (styleSheet) {
    updateStyleSheet(styleSheet);
  }

  return { updateStyleSheet };
};
