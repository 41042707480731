/* eslint-disable max-len */
import { SectionWithItemsContent } from '@/types';

export const values: SectionWithItemsContent = {
  headline: {
    before: 'Core',
    highlight: 'Values'
  },
  items: [
    {
      title: 'Work as a Team',
      text: `We believe that when different people
      come together with different inputs,
      something magical happens, and the
      output of such friction is truly big.`
    },
    {
      title: 'Focus on the Result',
      text: `Working in a goal-driven environment
      makes for a much more productive and
      enjoyable experience for everyone.
      What counts is the end outcome.`
    },
    {
      title: 'Keep Learning',
      text: `There is always something new to learn,
      both from one other and from our clients,
      and we are ready to grow as individuals
      and as a digital team. `
    },
    {
      title: 'Honesty & Feedback',
      text: `We must not be hesitant to speak
      up and provide frequent, true, honest
      feedback to each other in order to assist
      one other progress.`
    }
  ]
};
