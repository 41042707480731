











































import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { DcwText } from '@/components/Atoms/Text/DcwText.vue';
import { isInEnum } from '@/helpers';
import { HeadlineColor, HeadlineSubtitleSizes, HeadlineSubtitleSpacing, HeadlineTypes } from '@/types';

export const DcwNewHeadline = defineComponent({
  name: 'DcwNewHeadline',
  components: {
    DcwText
  },
  props: {
    type: {
      type: String as PropType<HeadlineTypes>,
      validator: (value: HeadlineTypes) => isInEnum(value, HeadlineTypes),
      default: HeadlineTypes.H3
    },
    styledAs: {
      type: String as PropType<HeadlineTypes>,
      validator: (value: HeadlineTypes) => isInEnum(value, HeadlineTypes),
      required: false
    },
    tabletStyledAs: {
      type: String as PropType<HeadlineTypes>,
      validator: (value: HeadlineTypes) => isInEnum(value, HeadlineTypes),
      required: false
    },
    mobileStyledAs: {
      type: String as PropType<HeadlineTypes>,
      validator: (value: HeadlineTypes) => isInEnum(value, HeadlineTypes),
      required: false
    },
    color: {
      type: String as PropType<HeadlineColor>,
      default: HeadlineColor.DARK
    },
    tabletColor: {
      type: String as PropType<HeadlineColor>,
      default: ''
    },
    mobileColor: {
      type: String as PropType<HeadlineColor>,
      default: ''
    },
    before: {
      type: String,
      default: ''
    },
    highlight: {
      type: String,
      default: ''
    },
    after: {
      type: String,
      default: ''
    },
    isBold: {
      type: Boolean,
      default: false
    },
    subtitleSpacing: {
      type: String as PropType<HeadlineSubtitleSpacing>,
      default: HeadlineSubtitleSpacing.LARGE
    },
    mobileSubtitleSpacing: {
      type: String as PropType<HeadlineSubtitleSpacing>,
      default: ''
    },
    tabletSubtitleSpacing: {
      type: String as PropType<HeadlineSubtitleSpacing>,
      default: ''
    },
    subtitleSize: {
      type: String as PropType<HeadlineSubtitleSizes>,
      default: HeadlineSubtitleSizes.MEDIUM
    },
    mobileSubtitleSize: {
      type: String as PropType<HeadlineSubtitleSizes>,
      default: ''
    },
    tabletSubtitleSize: {
      type: String as PropType<HeadlineSubtitleSizes>,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    isTinted: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const stylingModifier = computed(() => (props.styledAs ? props.styledAs : props.type));

    return { stylingModifier, HeadlineSubtitleSizes };
  }
});

export default DcwNewHeadline;
