


















import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { SwiperPropsOptions } from '@/types';
import 'swiper/swiper-bundle.min.css';

export const DcwSwiper = defineComponent({
  name: 'DcwSwiper',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    options: {
      type: Object as PropType<SwiperPropsOptions<unknown & { uuid?: number }>>,
      required: true
    },
    activeSlide: {
      type: Number,
      required: true
    },
    rerenderOnSlideChange: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const swiperElement = ref<InstanceType<typeof Swiper> | null>(null);

    const slideChangeHandler = () => {
      if (!props.options.onSlideChange || !swiperElement.value?.$swiper) {
        return;
      }
      props.options.onSlideChange(swiperElement.value?.$swiper);
    };

    watch(
      () => props.activeSlide,
      (newSlideIndex: number, oldSlideIndex: number) => {
        if (!swiperElement.value?.$swiper) {
          return;
        }

        if (!props.options.swiperConfig.loop && Math.abs(newSlideIndex - oldSlideIndex) !== 1) {
          swiperElement.value.$swiper.slideTo(newSlideIndex);
          return;
        }

        newSlideIndex > oldSlideIndex ? swiperElement.value.$swiper.slideNext() : swiperElement.value.$swiper.slidePrev();
      }
    );

    const slideChangeTransitionStart = () => {
      if (!props.rerenderOnSlideChange || !swiperElement.value || !swiperElement.value.$swiper) {
        return;
      }

      const $wrapperEl = swiperElement.value.$swiper.$wrapperEl;
      const params = swiperElement.value.$swiper.params;
      $wrapperEl.children('.' + params.slideClass + '.' + params.slideDuplicateClass).each(function () {
        if (!swiperElement.value || !swiperElement.value.$swiper) {
          return;
        }
        // eslint-disable-next-line
        // @ts-ignore
        const idx = this.getAttribute('data-swiper-slide-index');
        // eslint-disable-next-line
        // @ts-ignore
        swiperElement.value.innerHTML = $wrapperEl
          .children('.' + params.slideClass + '[data-swiper-slide-index="' + idx + '"]:not(.' + params.slideDuplicateClass + ')')
          .html();
      });
    };

    const slideChangeTransitionEnd = () => {
      if (!props.rerenderOnSlideChange || !swiperElement.value || !swiperElement.value.$swiper) {
        return;
      }

      swiperElement.value.$swiper.slideToLoop(swiperElement.value.$swiper.realIndex, 0, false);
    };

    return { swiperElement, slideChangeHandler, slideChangeTransitionEnd, slideChangeTransitionStart };
  }
});

export default DcwSwiper;
