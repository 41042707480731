






import { defineComponent } from '@nuxtjs/composition-api';
import PlayIcon from '@/assets/icons/play-icon.svg';
import { useLocales } from '@/composables';

export const DcwPlayButton = defineComponent({
  name: 'DcwPlayButton',
  components: { PlayIcon },
  setup() {
    const { translation } = useLocales();
    return { translation };
  }
});

export default DcwPlayButton;

