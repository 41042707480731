











import { defineComponent } from '@nuxtjs/composition-api';
import { DcwScheduleModalFooter, DcwScheduleModalCalendar, DcwScheduleModalContact } from '../';
import { DcwCloseButton } from '@atoms';
import { CloseScheduleModal } from '@/types';
import { CloseScheduleModalKey } from '@/consts';
import { useInjectStrict } from '@/composables';

export const DcwScheduleModalDesktop = defineComponent({
  name: 'DcwScheduleModal',
  components: { DcwCloseButton, DcwScheduleModalFooter, DcwScheduleModalContact, DcwScheduleModalCalendar },
  setup() {
    const closeModal = useInjectStrict<CloseScheduleModal>(CloseScheduleModalKey);
    return { closeModal };
  }
});

export default DcwScheduleModalDesktop;
