/* eslint-disable max-len */
import { events as allEvents } from '../events';
import { CompanyEventsSectionContent } from '@/types';

export const events: CompanyEventsSectionContent = {
  headline: {
    highlight: 'Explore',
    after: 'Our Timetable',
    subtitle:
      "Together we create a colliers' community that socializes regularly, organizes events,\nand grows! Check out our timetable and stay up to date."
  },
  events: allEvents.common
};
