import { offers as allOffers } from '@/translations/offers';
import { JobsOffersSectionContent, ScheduleModalPeople } from '@/types';
import { convertDataModelToContent } from '@/helpers';

export const offers: JobsOffersSectionContent = {
  headline: {
    before: 'Featured',
    highlight: 'Jobs'
  },
  cta: {
    headline: {
      before: "Let's Talk",
      subtitle: 'We are looking for creative, talented people with a collaborative mindset to create the finest digital products.'
    },
    image: {
      alt: 'Picture of Bartosz Cieśla smiling',
      height: 256,
      width: 400,
      src: '/career/offers/bartosz-bust.png'
    },
    modal: ScheduleModalPeople.bartoszC
  },
  offers: convertDataModelToContent(allOffers)
};
