/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ref, UnwrapRef, computed, watch } from '@nuxtjs/composition-api';
import { AxiosError } from 'axios';
import { RequestStatus } from '@/types';

export const useService = <RequestDTO extends Record<string, any>, ResponseDTO>(
  method: (props: RequestDTO) => Promise<UnwrapRef<ResponseDTO>>,
  initialValue: ResponseDTO | null = null
) => {
  const data = ref<ResponseDTO | null>(initialValue);
  const error = ref<AxiosError | null>(null);
  const loading = ref(false);
  const success = ref(false);

  const status = computed(() => {
    if (loading.value) {
      return RequestStatus.PENDING;
    } else if (error.value) {
      return RequestStatus.ERROR;
    } else if (success.value) {
      return RequestStatus.SUCCESS;
    } else {
      return RequestStatus.DEFAULT;
    }
  });

  const update = (params: RequestDTO) => {
    loading.value = true;

    return method(params)
      .then((response) => {
        error.value = null;
        data.value = response;
        success.value = true;
        return Promise.resolve({ data: data.value });
      })
      .catch((exception: AxiosError) => {
        error.value = exception;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  watch(success, (value) => {
    if (value) {
      setTimeout(() => (success.value = false), 3000);
    }
  });

  return { update, data, loading, error, status };
};
