const sidebarNames = {
  description: 'Description',
  team: 'Meet Our Team',
  benefits: 'Perks & Benefits',
  aboutDC: 'About Digital Colliers',
  recruitment: 'Recruitment process',
  cta: 'Any Questions? Talk to Wiktor',
  aboutProject: 'About the project',
  stack: 'Technology Stack',
  positions: 'Open positions',
  faq: 'FAQ',
  reasonsToJoin: '6 Reasons To Join Us'
};

const tooltip = {
  percentRemote: '100% Remote',
  projectOngoing: 'Ongoing project',
  weAreHiring: "We're hiring"
};

const toasts = {
  default: 'Default message',
  formSuccess: 'Message has been sent',
  formError: 'There was an error when submitting your message, please get in touch directly on info@digitalcolliers.com',
  scheduleSuccess: 'Your call request has been sent to #{target} who will get in touch with you shortly',
  applicationSent: 'Application sent'
};

export const uiComponents = {
  sidebarNames,
  tooltip,
  toasts
};
