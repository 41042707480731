export function humanFileSize(bytes: number, si = false, dp = 1): string {
  const thresh = si ? 1000 : 1024;
  let localBytes = bytes;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    localBytes /= thresh;
    u += 1;
  } while (Math.round(Math.abs(localBytes) * r) / r >= thresh && u < units.length - 1);

  return localBytes.toFixed(dp) + ' ' + units[u];
}
