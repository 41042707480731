import { ref, Ref, computed, ComputedRef, onMounted, useContext } from '@nuxtjs/composition-api';
import { Context } from '@nuxt/types';
import { Breakpoints } from '@/types';

type Predicate = (isMounted: Ref<boolean>, currentWidth: Ref<number>, device: Context['$device']) => boolean;

interface UseBreakpoints {
  currentWidth: Ref<number>;
  isMobile: ComputedRef<boolean>;
  isTablet: ComputedRef<boolean>;
  createBreakpoint: (predicate: Predicate) => ComputedRef<boolean>;
}

type UseBreakpointsFactory = () => () => UseBreakpoints;

const useBreakpointsFactory: UseBreakpointsFactory = () => {
  const currentWidth = ref(process.browser ? window.innerWidth : 0);

  if (process.browser) {
    window.addEventListener('load', () => (currentWidth.value = window.innerWidth));
    window.addEventListener('resize', () => (currentWidth.value = window.innerWidth));
  }

  return () => {
    const isMounted = ref(false);
    const { $device } = useContext();

    onMounted(() => (isMounted.value = true));

    const isMobile = computed(() => isMounted.value ? currentWidth.value < Breakpoints.SMALL : $device.isMobile);
    const isTablet = computed(() => isMounted.value ? currentWidth.value < Breakpoints.MEDIUM : $device.isTablet);

    const createBreakpoint = (predicate: Predicate) => computed(() => predicate(isMounted, currentWidth, $device));

    return { currentWidth, isMobile, isTablet, createBreakpoint };
  };
};

export const useBreakpoints = useBreakpointsFactory();
