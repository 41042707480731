









































import { defineComponent, PropType, ref, useRoute, watch } from '@nuxtjs/composition-api';
import { DcwImage, DcwVimeoPlayer } from '@atoms';
import { DcwVideoModal } from '@molecules';
import { ImageElementAttributes, VideoPlayerButtonSize, VideoPlayerDescPadding, VueVimeoPlayer } from '@/types';
import { useLocales, useVimeoPlayer } from '@/composables';

import PlayIcon from '@/assets/icons/play-icon.svg';

export const DcwVideoPlayer = defineComponent({
  name: 'DcwVideoPlayer',
  components: { DcwImage, DcwVimeoPlayer, DcwVideoModal, PlayIcon },
  props: {
    thumbnail: {
      type: Object as PropType<ImageElementAttributes>,
      required: true
    },
    videoId: {
      type: String,
      required: true
    },
    isPopup: {
      type: Boolean,
      default: false
    },
    isBlurred: {
      type: Boolean,
      default: false
    },
    descPadding: {
      type: String as PropType<VideoPlayerDescPadding>,
      default: VideoPlayerDescPadding.MEDIUM
    },
    buttonSize: {
      type: String as PropType<VideoPlayerButtonSize>,
      default: VideoPlayerButtonSize.SMALL
    },
    isModifyUrl: {
      type: Boolean,
      default: false
    }
  },
  emits: ['play-video'],
  setup(props, ctx) {
    const { translation } = useLocales();
    const route = useRoute();
    const player = ref<VueVimeoPlayer | null>(null);
    const { isVideoPlaying, pause, play } = useVimeoPlayer(player);
    const isModalShown = ref(false);

    const playVideo = () => {
      ctx.emit('play-video');

      if (!props.isPopup) {
        play();
        return;
      }

      isModalShown.value = true;
    };

    watch(
      () => props.isBlurred,
      (newValue) => {
        if (!newValue || props.isPopup) {
          return;
        }

        pause();
      }
    );

    watch(isVideoPlaying, () => {
      if (!props.isModifyUrl) {
        return;
      }

      history.pushState({}, '', route.value.path + `?video=${props.videoId}`);
    });

    return { player, isVideoPlaying, playVideo, translation, isModalShown };
  }
});

export default DcwVideoPlayer;
