import { toronto } from './toronto';
import { vienna } from './vienna';
import { berlin } from './berlin';
import { hamburg } from './hamburg';
import { paris } from './paris';
import { gliwice } from './gliwice';
import { warsaw } from './warsaw';
import { cracow } from './cracow';

export const offices = {
  cracow,
  warsaw,
  gliwice,
  paris,
  hamburg,
  berlin,
  vienna,
  toronto
};
