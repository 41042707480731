import { people } from '../../people';
import { SectionWithPeopleContent } from '@/types';

export const team: SectionWithPeopleContent = {
  headline: {
    before: 'Meet Our Team'
  },
  people: [
    {
      name: people.szymonN.name,
      position: people.szymonN.position,
      linkedinLink: people.szymonN.linkedinLink,
      img: {
        src: '/positions/position-1.png',
        alt: 'Szymon photo',
        width: 236,
        height: 224
      }
    },
    {
      name: people.maciejT.name,
      position: people.maciejT.position,
      linkedinLink: people.maciejT.linkedinLink,
      img: {
        src: '/positions/position-2.png',
        alt: 'Maciej photo',
        width: 236,
        height: 224
      }
    },
    {
      name: people.bartoszC.name,
      position: people.bartoszC.position,
      linkedinLink: people.bartoszC.linkedinLink,
      img: {
        src: '/positions/position-3.png',
        alt: 'Bartosz photo',
        width: 236,
        height: 224
      }
    },
    {
      name: people.wiktorS.name,
      position: people.wiktorS.position,
      linkedinLink: people.wiktorS.linkedinLink,
      img: {
        src: '/positions/position-4.png',
        alt: 'Wiktor photo',
        width: 236,
        height: 224
      }
    },
    {
      name: people.wojciechC.name,
      position: people.wojciechC.position,
      linkedinLink: people.wojciechC.linkedinLink,
      img: {
        src: '/positions/position-5.png',
        alt: 'Wojciech photo',
        width: 236,
        height: 224
      }
    },
    {
      name: people.dominikP.name,
      position: people.dominikP.position,
      linkedinLink: people.dominikP.linkedinLink,
      img: {
        src: '/positions/position-6.png',
        alt: 'Dominik photo',
        width: 236,
        height: 224
      }
    },
    {
      name: people.szymonM.name,
      position: people.szymonM.position,
      linkedinLink: people.szymonM.linkedinLink,
      img: {
        src: '/positions/position-7.png',
        alt: 'Szymon photo',
        width: 236,
        height: 224
      }
    },
    {
      name: people.leopoldG.name,
      position: people.leopoldG.position,
      linkedinLink: people.leopoldG.linkedinLink,
      img: {
        src: '/positions/position-8.png',
        alt: 'Leopold photo',
        width: 236,
        height: 224
      }
    },
    {
      name: 'Place for you',
      position: 'Your position',
      img: {
        src: '/positions/placeholder.png',
        alt: 'You!',
        width: 236,
        height: 224
      }
    }
  ]
};
