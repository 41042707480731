import { Ref, watch, ref, onBeforeUnmount, onMounted } from '@nuxtjs/composition-api';
import BezierEasing from 'bezier-easing';
import { useBreakpoints } from '@/composables';

enum TimetableDirection {
    RIGHT = 'right',
    LEFT = 'left'
}
interface UseTimeCarouselHomepageSettings {
  preventTransitionOnMobile: boolean;
}

export const useTimetableCarouselHomepage = (
  scrolledElementRef: Ref<HTMLElement | null>,
  activeCardIndex: Ref<number>,
  amountOfElements: number,
  settings: UseTimeCarouselHomepageSettings = {
    preventTransitionOnMobile: false
  }
) => {
  const { isMobile, currentWidth } = useBreakpoints();

  const smallCardWidth = 221;

  const criticalCombinedCardsWidth = 1234;

  const getProgress = (duration: number, runTime: number) => {
    const bezierEasing = BezierEasing(0.25, 0.1, 0.25, 1);
    const percentTimeElapsed = runTime / duration;
    return bezierEasing(percentTimeElapsed);
  };

  const onHandleTransition = (
    direction: TimetableDirection,
    isChangeFromIndexZeroToOne?: boolean,
    disableBezier?: boolean
  ): void => {
    if (scrolledElementRef.value !== null) {
      let startTime = 0;

      const initialScrollPosition = scrolledElementRef.value.scrollLeft;

      const gapBetweenElements = window.getComputedStyle(scrolledElementRef.value, null).getPropertyValue('gap');

      const gapAsNumber = parseInt(gapBetweenElements.substring(0, gapBetweenElements.length - 2));

      let totalScroll = direction === TimetableDirection.RIGHT
        ? smallCardWidth + gapAsNumber
        : -smallCardWidth - gapAsNumber;

      if (isChangeFromIndexZeroToOne) {
        const cardBigWidthOnBelow1400px = 744;

        const elementPadding = window.getComputedStyle(scrolledElementRef.value, null).getPropertyValue('padding-left');
        const elementPaddingAsNumber = parseInt(elementPadding.substring(0, elementPadding.length - 2));

        const elementGap = window.getComputedStyle(scrolledElementRef.value, null).getPropertyValue('gap');
        const elementGapAsNumber = parseInt(elementGap.substring(0, elementPadding.length - 2));

        const windowWidth = window.innerWidth;

        totalScroll = smallCardWidth -
            (windowWidth - cardBigWidthOnBelow1400px) / 2 + elementPaddingAsNumber + elementGapAsNumber;
      }

      const scrollOnNextTick = () => {
        const runTime = Date.now() - startTime;

        const durationInMs = 1000;
        const progress = getProgress(durationInMs, runTime);

        const scrollAmt = progress * totalScroll;

        const scrollForThisTick = disableBezier
          ? initialScrollPosition + totalScroll
          : initialScrollPosition + scrollAmt;

        if (scrolledElementRef.value && runTime < durationInMs) {
          scrolledElementRef.value.scrollLeft = scrollForThisTick;

          if (!disableBezier) {
            requestAnimationFrame(() => {
              scrollOnNextTick();
            });
          }
        }
      };

      requestAnimationFrame(() => {
        startTime = Date.now();
        scrollOnNextTick();
      });
    }
  };

  let touchMoveScrollTimeout: ReturnType<typeof setTimeout> | null = null;

  const isTouchMoveScrollInProgress = ref(false);

  const onTouchMoveScroll = () => {
    if (touchMoveScrollTimeout !== null) {
      clearTimeout(touchMoveScrollTimeout);
      touchMoveScrollTimeout = null;
      isTouchMoveScrollInProgress.value = true;
    }
    touchMoveScrollTimeout = setTimeout(() => {
      isTouchMoveScrollInProgress.value = false;
    }, 1000);
  };

  const onTouchMove = () => {
    onTouchMoveScroll();
  };

  onMounted(() => {
    scrolledElementRef.value?.addEventListener('touchmove', onTouchMove);
  });

  onBeforeUnmount(() => {
    scrolledElementRef.value?.removeEventListener('touchmove', onTouchMove);
  });

  watch(
    activeCardIndex,
    (newActiveCardIndex: number, oldActiveCardIndex: number) => {
      const condition = settings.preventTransitionOnMobile
        ? !isMobile.value
        : true;

      if (!condition || !scrolledElementRef.value || isTouchMoveScrollInProgress.value) {
        return;
      }

      const breakpoints = {
        bigDesktop: 2500,
        regularDesktop: 1500,
        desktop: 1100,
        smallDesktop: 1025
      };

      const isNextCardIndexBiggerThatOld = newActiveCardIndex > oldActiveCardIndex;

      const isNextCardIndexSmallerThanArrayLength = activeCardIndex.value <= amountOfElements - 1;

      const isMoveAllowed = () => {
        let threshold = 0;

        if (currentWidth.value >= criticalCombinedCardsWidth) {
          threshold = 1;
        }

        return activeCardIndex.value > threshold;
      };

      const isBezierDisabled = currentWidth.value < breakpoints.smallDesktop;

      if (isNextCardIndexBiggerThatOld && isMoveAllowed() && isNextCardIndexSmallerThanArrayLength) {
        const isChangeFromIndexZeroToOneOnDesktop = newActiveCardIndex === 1 &&
        oldActiveCardIndex === 0 &&
        currentWidth.value > breakpoints.smallDesktop;

        onHandleTransition(TimetableDirection.RIGHT, isChangeFromIndexZeroToOneOnDesktop, isBezierDisabled);
      }

      const isNextCardIndexSmallerThanThreshold = () => {
        const threshold = currentWidth.value < breakpoints.smallDesktop || currentWidth.value > breakpoints.bigDesktop ? 1 : 0;

        return newActiveCardIndex < amountOfElements - threshold;
      };

      if (!isNextCardIndexBiggerThatOld && isNextCardIndexSmallerThanThreshold()) {
        onHandleTransition(TimetableDirection.LEFT, false, isBezierDisabled);
      }
    }
  );

  return { onHandleTransition };
};
