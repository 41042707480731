import {
  SVGIcon,
  VueConstructor,
  HeadlineProps,
  ImageElementAttributes,
  ContentBlockData,
  DefaultSectionContent,
  HeadlineContent,
  OfficeModel,
  PersonModel
} from '@/types';

export interface HWSButtonProps {
  ariaLabel: string;
  icon?: SVGIcon;
  text?: string;
  href: string;
}

export interface HybridWorkTab {
  label: string;
  content: VueConstructor;
  headlines: HeadlineProps[];
}

export type DiscordUser = {
  image: ImageElementAttributes;
  tooltip?: string;
  modifier?: string;
};

export type DiscordChannel = {
  label: string;
  active?: boolean;
  emoji: ImageElementAttributes;
};

export type DiscordChannelGroup = {
  label: string;
  channels: DiscordChannel[];
};

type SlackChannel = {
  label: string;
  active?: boolean;
  notification?: boolean;
};

type SlackChannelGroup = {
  label: string;
  channels: SlackChannel[];
};

export type SlackMessage = {
  image: ImageElementAttributes;
  name: string;
  position: string;
  content: string;
};

export type CityDescription = {
  image: ImageElementAttributes;
  content: ContentBlockData;
};

export type City = {
  office: OfficeModel;
  member: PersonModel;
  images: {
    member: ImageElementAttributes;
    office: ImageElementAttributes;
    map: ImageElementAttributes;
  };
};

export enum HWSTabs {
  COMMUNICATION = 'communication',
  ORGANIZATION = 'organization',
  COWORKING = 'coworking'
}

export enum HWSAsideContentType {
  BUTTON = 'button',
  HEADLINE = 'headline',
  TEXT = 'text'
}

export interface HWSAsideHeadlineContent {
  type: HWSAsideContentType.HEADLINE;
  headline: HeadlineContent;
}

export interface HWSAsideButtonContent {
  type: HWSAsideContentType.BUTTON;
  button: HWSButtonProps;
}

export interface HWSAsideTextContent {
  type: HWSAsideContentType.TEXT;
  text: string;
}

export type HWSAsideContent = (HWSAsideHeadlineContent | HWSAsideTextContent | HWSAsideButtonContent)[];

export interface HWSSectionContent extends DefaultSectionContent {
  tabs: {
    [HWSTabs.COMMUNICATION]: {
      label: string;
      asideContent: HWSAsideContent;
      content: {
        channelGroups: DiscordChannelGroup[];
        users: DiscordUser[];
      };
    };
    [HWSTabs.ORGANIZATION]: {
      label: string;
      asideContent: HWSAsideContent;
      content: {
        channelGroups: SlackChannelGroup[];
        messages: SlackMessage[];
        input: {
          string: string;
          emoji: ImageElementAttributes[];
        };
      };
    };
    [HWSTabs.COWORKING]: {
      label: string;
      asideContent: HWSAsideContent;
      content: {
        headline: HeadlineContent;
        cities: City[];
      };
    };
  };
}
