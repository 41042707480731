/* eslint-disable max-len */
import { TextPageContent } from '@/types';

export const helpUA: TextPageContent = {
  title: 'Helping Ukraine',
  ogCover: '/covers/ua.png',
  metaDescription:
    "This page has been created as a guide to the actions we can do to help Ukraine survive the military attack from Russia. Doesn't matter where we live and how much resources or time we want to allocate - we can always help.",
  sections: [
    {
      title: '',
      header: { before: '' },
      id: 'section-e-0',
      html:
        "This page has been created as a guide to the actions we can do to help Ukraine survive the military attack from Russia. Doesn't matter where we live and how much resources or time we want to allocate - we can always help. Here are a few options:"
    },
    {
      title: 'Financial Support',
      header: { before: 'Financial Support' },
      id: 'section-e-1',
      html: `
      <p>
        A few links to charity fundraisings:
      </p>

      <ul>
        <li>
          <strong>
            (EUR/USD)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://bank.gov.ua/en/news/all/natsionalniy-bank-vidkriv-spetsrahunok-dlya-zboru-koshtiv-na-potrebi-armiyi">
            National Bank of Ukraine
          </a>
          - direct funding of Ukrainian army
        </li>
        <li>
          <strong>
            (USD)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://www.uahospitals.org/">
            UA Hospitals
          </a>
          - supporting hospitals in Ukraine
        </li>
        <li>
          <strong>
            (EUR)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://armysos.com.ua/">
            Army SOS
          </a>
          - citizen initiative of Ukrainian Army support
        </li>
        <li>
          <strong>
            (PLN/USD/EUR)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://www.sunflowerofpeace.com/">
            Sunflower of Peace
          </a>
          - tactical medicine for Ukrainian Army
        </li>
        <li>
          <strong>
            (PLN/USD/EUR)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://novaukraine.org/">
            Nova Ukraine
          </a>
          - humanitarian support for Ukraine
        </li>
        <li>
          <strong>
            (EUR/USD)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://voices.org.ua/en/">
            Voices of Children
          </a>
          - help to overcome war trauma for children
        </li>
        <li>
          <strong>
            (PLN)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://www.siepomaga.pl/ukraina">
            SiePomaga.pl
          </a>
          - biggest fundraising site in Poland, help for Ukrainian war victims
        </li>
        <li>
          <strong>
            (PLN)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://pmm.org.pl/projekty-ukraina">
            Polish Medical Mission
          </a>
          - direct medical help in Ukraine
        </li>
        <li>
          <strong>
            (USD)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://qua.community/news/donation-options/">
            QUA Foundation
          </a>
          - emergency support for the Ukrainian LGBTQ community under Russian aggression
        </li>
        <li>
          <strong>
            (PLN)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://viva.org.uk/appeals/viva-poland-ukraine-animal-rescue/">
            Viva Foundation
          </a>
          - rescue for Ukrainian animals
        </li>
        <li>
          <strong>
            (PLN)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://www.unaweza.org/en/komu-pomagamy/to-help-children-in-ukraine/">
            Unaweza Foundation
          </a>
          - help for Ukrainian children
        </li>
        <li>
          <strong>
            (PLN/USD/EUR)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://www.pah.org.pl/wplac/?enable_cookies=all">
            Polish Humanitarian Action
          </a>
          - direct help for immigrants in Poland
        </li>
        <li>
          <strong>
            (PLN/USD/EUR)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://czescdziewczyny.pl/en">
          "Hi Girls!" Foundation
          </a>
          - help fund for Ukrainian women and girls
        </li>
      </ul>
      `
    },
    {
      title: 'Reliable Information',
      header: { before: 'Reliable Information' },
      id: 'section-e-2',
      html: `
      <p>
        We are not going to tell you what sources you should follow as it's everyone's personal choice. We want however, point out how important it is to follow <strong>information from different sources</strong> and don't share unverified ones to prevent unnecessary spreading panic.
      </p>

      <p>
        Few of our propositions:
      </p>
      <ul>
        <li>
          <strong>(ENG)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/ibtfH">
            Ukrinform
          </a>
        </li>
        <li>
          <strong>(ENG)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/rMi48">
            Radio Free Europe. Radio Liberty
          </a>
        </li>
        <li>
          <strong>(ENG)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/MuFHa">
            Censor.net
          </a>
        </li>
        <li>
          <strong>(ENG)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/C6EfN">
            Twitter: Illia Ponomarenko
          </a>
        </li>
        <li>
          <strong>(PL)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/tOeNg">
            Tygodnik Powszechny: Relacja Pawła Pieniążka
          </a>
        </li>
        <li>
          <strong>(PL)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/oEWF8">
            Gazeta Wyborcza
          </a>
        </li>
        <li>
          <strong>(PL)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/5P5jP">
            Instagram: lukaszbok_kiks
          </a>
        </li>
      </ul>`
    },
    {
      title: 'Showing Support',
      header: { before: 'Showing Support' },
      id: 'section-e-3',
      html: `
      <p>
        Those small actions will help to show whole society and world that we are not indifferent to Ukraine’s situation and Russian aggression:
      </p>
      <ul>
        <li>
          You can participate in rallies and demonstrations organized in your
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/aCfHR">
            cities
          </a>
        </li>
        <li>
          Write to your local, national, and EU politicians and representatives. Demand harsh personal sanctions against Russian oligarchs and regime representatives, as well as harsh national-wide sanctions against Russia.
        </li>
        <li>
          Engage your family, friends and followers on social media to participate in charity actions on local and national level.
        </li>
        <li>
          Add a Ukrainian flag mask to your facebook account profile pic.
        </li>
        <li>
          Don't make generalizations about people behind the situation - remember that not every Russian citizen is supporting Russian government actions.
        </li>
      </ul>`
    },
    {
      title: 'In-kind Collections',
      header: { before: 'In-kind Collections' },
      id: 'section-e-4',
      html: `
      <h3>
        List of provincial collection points
      </h3>

      <ul>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://duw.pl/pl/wolnytekst/18151,Pomagamy-Ukrainie.html">
          Lower Silesia
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-kujawsko-pomorski/lista-adresow-punktow-zbiorek-darow-dla-uchodzcow-z-ukrainy">
            Kuyavia-Pomerania
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.lublin.uw.gov.pl/pomoc_humanitarna_na_Ukrainie">
            Lodzkie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-lubuski/pomagamukrainie">
            Lublin
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-lodzki/pomagamukrainie2">
            Lubusz
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://malopolska.uw.gov.pl/pomagamukrainie">
            Lesser Poland</a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-mazowiecki/pomagamyukrainie/">
            Masovia
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-opolski/pomagamukrainie">
            Opole
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://rzeszow.uw.gov.pl/pomagamukrainie/punkty-zbiorek-darow/">
            Subcarpathia
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-podlaski/pomagamukrainie">
            Podlaskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gdansk.uw.gov.pl/pomagamy-ukrainie">
            Pomerania
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.katowice.uw.gov.pl/pomagamukrainie">
            Silesia
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.kielce.uw.gov.pl/pl/wojewodztwo/pomagam-ukrainie/21162,Wykaz-zbiorek-pomocy-humanitarnej-dla-Ukrainy-w-powiatach-wojewodztwa-swietokrzy.html">
            Holy Cross
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-warminsko-mazurski/pomagam-ukrainie">
            Warmia-Masuriamazurskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.poznan.uw.gov.pl/pomagamukrainie">
            Greater Poland
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.szczecin.uw.gov.pl/?type=article&action=view&id=15190">
            West Pomerania
          </a>
        </li>
      </ul>
      `
    },
    {
      title: 'Transport ',
      header: { before: 'Transport ' },
      id: 'section-e-5',
      html: `
      <p>
        You can offer transport services from Ukrainian territory or border area to one of Polish cities.
        <a target="_blank" rel="noopener noreferrer" href="https://pomagamukrainie.gov.pl/ogloszenia/dodaj-ogloszenie/transport">Sign up here.</a>
      </p>
      `
    },
    {
      title: 'Accommodation',
      header: { before: 'Accommodation' },
      id: 'section-e-6',
      html: `
        <p>
          You can find various safe initiatives to offer shelter for Ukrainians.
        </p>

        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://pomagamukrainie.gov.pl/ogloszenia/dodaj-ogloszenie/noclegi">
              Government Site
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://polacydlaukrainy.pl/">
              Poland for Ukraine project
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://app.ihelpyou.app/war/welcome">
              I help you App
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSfHdVCst9f4TFHtlHVsTjGgefbKLYUgx50rGRagtAlkHh3_Ww/viewform">
              Ukrainian Home Warsaw
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSezqJomyMN-teDRgbFuxCwJHOWgD711_t_hpB9gQl0aaFhQVQ/viewform">
              Shelter for Animals
            </a>
          </li>
        </ul>
      `
    },
    {
      title: 'Volunteering',
      header: { before: 'Volunteering' },
      id: 'section-e-7',
      html: `
        <p>
          As a volunteer, you can directly help coordinate the movement of immigrants from Ukraine. Each contribution is invaluable. Sometimes access to a telephone and computer help at work. Both immigrants and volunteers can also be supported with a warm meal.
        </p>

        <p>
          Cities:
        </p>
        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/pMcLd">
              Warsaw
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/kuLC4">
              Cracow
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/lpSId">
              Wrocław
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.poznan.pl/mim/ngo/news/wsparcie-dla-ukrainy,177212.html">
              Poznań
            </a>
          </li>
        </ul>

        <p>
          Non-governmental Organization:
        </p>
        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://system.erecruiter.pl/FormTemplates/RecruitmentForm.aspx?WebID=36f770dfe5c040a6b4ff45fb10dc89b7&fbclid=IwAR1PcXL3XvINwA7LvOfCadVU_qwyuGBHympinLtyEQQ1B5OW1IKd5Ohj1g0">
              Polish Humanitarian Action
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://ocalenie.org.pl/ukraina-pomoc">
              Ocalenie Foundation
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSca3_oGcq0zs7HT6z8L9zb4HIXo3ZqcXv8G4TjNSRXobjt3sQ/viewform">
              Polish Red Cross
            </a>
          </li>
        </ul>
      `
    },
    {
      title: 'Translations',
      header: { before: 'Translations' },
      id: 'section-e-8',
      html: `
        <p>
          If you are a professional translator willing or you just know Ukrainian and some other language you can sign up through a questionnaire to become volunteer translator. Fill the questionnaire
          (
            <a target="_blank" rel="noopener noreferrer" href="http://www.tlumaczedlaukrainy.pl/">
              source
            </a>
          ).
        </p>
      `
    },
    {
      title: 'Blood Donation',
      header: { before: 'Blood Donation' },
      id: 'section-e-9',
      html: `
        <p>
          Blood reserves will be essential to help soldiers and wounded participating in the war. You can check blood reserves in given cities:
        </p>

        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/bzFOo">
              Warsaw
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/oKpux">
              Cracow
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/j5lYR">
              Wrocław
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/j3Nrm">
              Katowice
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/u4aOk">
              Poznań
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/OrB7A">
              Gdańsk
            </a>
          </li>
        </ul>
      `
    },
    //
    {
      title: '',
      header: { before: '' },
      id: 'section-p-0',
      html:
        '<h4>(Polska wersja)</h4> <br />Ta strona została stworzona, aby wskazać działania, które możemy podjąć, aby pomóc Ukrainie przetrwać atak militarny ze strony Rosji. Nieważne gdzie mieszkamy i ile zasobów lub czasu chcemy przeznaczyć - pomóc możemy zawsze i z każdego zakątka świata. Oto kilka propozycji:'
    },
    {
      title: 'Pomoc Finansowa',
      header: { before: 'Pomoc Finansowa' },
      id: 'section-p-1',
      html: `
      <p>
        Kilka linków do zbiórek charytatywnych w formie finansowej:
      </p>

      <ul>
        <li>
          <strong>
            (EUR/USD)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://bank.gov.ua/en/news/all/natsionalniy-bank-vidkriv-spetsrahunok-dlya-zboru-koshtiv-na-potrebi-armiyi">
            Narodowy Bank Ukrainy
          </a>
          - bezpośrednie wsparcie Armii Ukraińskiej
        </li>
        <li>
          <strong>
            (USD)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://www.uahospitals.org/">
            UA Hospitals
          </a>
          - wsparcie Ukraińskich szpitali
        </li>
        <li>
          <strong>
            (EUR)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://armysos.com.ua/">
            Army SOS
          </a>
          - obywatelska inicjatywa wsparcia Armii Ukraińskiej
        </li>
        <li>
          <strong>
            (PLN/USD/EUR)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://www.sunflowerofpeace.com/">
            Słonecznik Pokoju
          </a>
          - medyczne plecaki taktyczne pierwszej pomocy dla ratowników medycznych i lekarzy na pierwszej linii frontu
        </li>
        <li>
          <strong>
            (PLN/USD/EUR)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://novaukraine.org/">
            Nova Ukraine
          </a>
          - pomoc humanitarna dla Ukrainy
        </li>
        <li>
          <strong>
            (EUR/USD)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://voices.org.ua/en/">
            Voices of Children
          </a>
          - pomoc dzieciom w przezwyciężeniu traumy wojennej
        </li>
        <li>
          <strong>
            (PLN)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://www.siepomaga.pl/ukraina">
            SiePomaga.pl
          </a>
          - największa zbiórka w Polsce, pomoc ofiarom wojny
        </li>
        <li>
          <strong>
            (PLN)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://pmm.org.pl/projekty-ukraina">
            Polska Misja Medyczna
          </a>
          - bezpośrednia pomoc medyczna na Ukrainie
        </li>
        <li>
          <strong>
            (USD)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://qua.community/news/donation-options/">
            Fundacja QUA
          </a>
          - wsparcie kryzysowe dla Ukraińskiej społeczności LGBTQ pod rosyjską opresją
        </li>
        <li>
          <strong>
            (PLN)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://viva.org.uk/appeals/viva-poland-ukraine-animal-rescue/">
            Fundacja Viva
          </a>
          - zbiórka na pomoc dla zwierząt z Ukrainy
        </li>
        <li>
          <strong>
            (PLN)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://www.unaweza.org/en/komu-pomagamy/to-help-children-in-ukraine/">
            Fundacja Unaweza
          </a>
          - pomoc dla Ukraińskich dzieci
        </li>
        <li>
          <strong>
            (PLN/USD/EUR)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://www.pah.org.pl/wplac/?enable_cookies=all">
            Polska Akcja Humanitarna
          </a>
          - bezpośrednia pomoc dla imigrantów w Polsce
        </li>
        <li>
          <strong>
            (PLN/USD/EUR)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="https://czescdziewczyny.pl/en">
          Fundacja "Cześć Dziewczyny"
          </a>
          - fundusz wsparcia dla kobiet i dziewczynek z Ukrainy
        </li>
        <li>
          <strong>(USD)</strong> <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/Z2J28">Fundacja QUA</a> - wsparcie kryzysowe dla Ukraińskiej społeczności LGBTQ pod rosyjską opresją
        </li>
        <li>
          <strong>(PLN)</strong> <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/paP9q">Fundacja Viva</a> - zbiórka na pomoc dla zwierząt z Ukrainy
        </li>
        <li>
          <strong>
            (PLN)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/2MdnS">
            Fundacja Unaweza
          </a>
          - pomoc dla Ukraińskich dzieci
        </li>
        <li>
          <strong>
            (PLN)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/KVNxA">
            Polska Akcja Humanitarna
          </a>
          - bezpośrednia pomoc dla imigrantów w Polsce
        </li>
        <li>
          <strong>
            (PLN/USD/EUR)
          </strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/IOfIV">
            Fundacja "Cześć Dziewczyny"
          </a>
          - fundusz wsparcia dla kobiet i dziewczynek z Ukrainy
        </li>
      </ul>
      `
    },
    {
      title: 'Rzetelne Informacje',
      header: { before: 'Rzetelne Informacje' },
      id: 'section-p-2',
      html: `
      <p>
        Nie zamierzamy nikomu narzucać doboru źródła informacji ponieważ jest to indywidualny wybór każdego z nas. Chcielibyśmy jednak zwrócić uwagę jak ważne jest <strong>śledzenie informacji z różnych źródeł</strong> i nie udostępnianie tych niezweryfikowanych aby zapobiec niepotrzebnemu rozprzestrzenianiu się paniki.
      </p>

      <p>
        Kilka naszych propozycji:
      </p>
      <ul>
        <li>
          <strong>(ENG)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/ibtfH">
            Ukrinform
          </a>
        </li>
        <li>
          <strong>(ENG)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/rMi48">
            Radio Free Europe. Radio Liberty
          </a>
        </li>
        <li>
          <strong>(ENG)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/MuFHa">
            Censor.net
          </a>
        </li>
        <li>
          <strong>(ENG)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/C6EfN">
            Twitter: Illia Ponomarenko
          </a>
        </li>
        <li>
          <strong>(PL)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/tOeNg">
            Tygodnik Powszechny: Relacja Pawła Pieniążka
          </a>
        </li>
        <li>
          <strong>(PL)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/oEWF8">
            Gazeta Wyborcza
          </a>
        </li>
        <li>
          <strong>(PL)</strong>
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/5P5jP">
            Instagram: lukaszbok_kiks
          </a>
        </li>
      </ul>`
    },
    {
      title: 'Okazywanie Wsparcia',
      header: { before: 'Okazywanie Wsparcia' },
      id: 'section-p-3',
      html: `
      <p>
        Te proste czynności pomogą całemu społeczeństwu i pokażą światu brak obojętności wobec sytuacji na Ukrainie i sprzeciw wobec działań Rosji:
      </p>
      <ul>
        <li>
          Możesz uczestniczyć w protestach i demonstracjach organizowanych w
          <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/aCfHR">
            Twoim mieście
          </a>
        </li>
        <li>
          Napisz do władz lokalnych, państwowych oraz Twoich reprezentantów na szczeblu Unijnym i zarządaj surowych sankcje personalnych wobec rosyjskich oligarchów i przedstawicieli reżimu, a także surowych sankcji ogólnonarodowych wobec Rosji.
        </li>
        <li>
          Zaangażuj swoją rodzinę, bliskich oraz obserwujących Twoje social media w uczestniczenie w pomocy na szczeblu lokalnym oraz państwowym.
        </li>
        <li>
          Ustaw na mediach społecznościowych takich jak Facebook maskę na zdjęcie profilowe z Ukraińską flagą.
        </li>
        <li>
          Nie ulegaj generalizacji wobec ludzi stojących za obecną sytuacją - pamiętaj, że nie wszyscy Rosyjscy obywatele popierają działania Rosyjskich liderów i władz.
        </li>
      </ul>`
    },
    {
      title: 'Zbiórki Rzeczowe',
      header: { before: 'Zbiórki Rzeczowe' },
      id: 'section-p-4',
      html: `
      <h3>
        Lista regionalnych punktów zbiórki:
      </h3>

      <ul>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://duw.pl/pl/wolnytekst/18151,Pomagamy-Ukrainie.html">
          Dolnośląskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-kujawsko-pomorski/lista-adresow-punktow-zbiorek-darow-dla-uchodzcow-z-ukrainy">
            Kujawsko Pomorskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.lublin.uw.gov.pl/pomoc_humanitarna_na_Ukrainie">
            Lubelskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-lubuski/pomagamukrainie">
            Lubuskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-lodzki/pomagamukrainie2">
            Łódzkie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://malopolska.uw.gov.pl/pomagamukrainie">
            Małopolskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-mazowiecki/pomagamyukrainie/">
            Mazowieckie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-opolski/pomagamukrainie">
            Opolskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://rzeszow.uw.gov.pl/pomagamukrainie/punkty-zbiorek-darow/">
            Podkarpackie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-podlaski/pomagamukrainie">
            Podlaskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gdansk.uw.gov.pl/pomagamy-ukrainie">
            Pomorskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.katowice.uw.gov.pl/pomagamukrainie">
            Śląskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.kielce.uw.gov.pl/pl/wojewodztwo/pomagam-ukrainie/21162,Wykaz-zbiorek-pomocy-humanitarnej-dla-Ukrainy-w-powiatach-wojewodztwa-swietokrzy.html">
            Świętokrzyskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.gov.pl/web/uw-warminsko-mazurski/pomagam-ukrainie">
            Warmińsko-Mazurskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.poznan.uw.gov.pl/pomagamukrainie">
            Wielkopolskie
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.szczecin.uw.gov.pl/?type=article&action=view&id=15190">
            Zachodniopomorskie
          </a>
        </li>
      </ul>
      `
    },
    {
      title: 'Transport ',
      header: { before: 'Transport ' },
      id: 'section-p-5',
      html: `
      <p>
        Możesz pomóc w transporcie między terytorium Ukrainy lub obszarami granicznymi a Polską.
        <a target="_blank" rel="noopener noreferrer" href="https://pomagamukrainie.gov.pl/ogloszenia/dodaj-ogloszenie/transport">Zapisz się tutaj.</a>
      </p>
      `
    },
    {
      title: 'Zakwaterowanie',
      header: { before: 'Zakwaterowanie' },
      id: 'section-p-6',
      html: `
        <p>
          Istnieje wiele bezpiecznych inicjatyw dających możliwość zaoferowania schronienia obywatelom Ukrainy.
        </p>

        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://pomagamukrainie.gov.pl/ogloszenia/dodaj-ogloszenie/noclegi">
              Główna strona rządowa
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://polacydlaukrainy.pl/">
              Projekt Polska dla Ukrainy
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://app.ihelpyou.app/war/welcome">
              Aplikacja I help You
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSfHdVCst9f4TFHtlHVsTjGgefbKLYUgx50rGRagtAlkHh3_Ww/viewform">
              Dom Ukraiński Warszawa
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSezqJomyMN-teDRgbFuxCwJHOWgD711_t_hpB9gQl0aaFhQVQ/viewform">
              Schronienie dla zwierząt
            </a>
          </li>
        </ul>
      `
    },
    {
      title: 'Wolontariat',
      header: { before: 'Wolontariat' },
      id: 'section-p-7',
      html: `
        <p>
          Jako wolontariusz możesz bezpośrednio pomóc w koordynacji ruchu imigrantów nacierających z Ukrainy. Każdy wkład jest nieoceniony. Czasami w pracy pomagają dostęp do telefonu i komputera. Zarówno imigrantów jak i wolontariuszy można również wesprzeć ciepłym posiłkiem.
        </p>

        <p>
          Miasta:
        </p>
        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/pMcLd">
              Warszawa
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/kuLC4">
              Kraków
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/lpSId">
              Wrocław
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.poznan.pl/mim/ngo/news/wsparcie-dla-ukrainy,177212.html">
              Poznań
            </a>
          </li>
        </ul>

        <p>
          Organizacje Pozarządowe:
        </p>
        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://system.erecruiter.pl/FormTemplates/RecruitmentForm.aspx?WebID=36f770dfe5c040a6b4ff45fb10dc89b7&fbclid=IwAR1PcXL3XvINwA7LvOfCadVU_qwyuGBHympinLtyEQQ1B5OW1IKd5Ohj1g0">
              Polska Akcja Humanitarna
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://ocalenie.org.pl/ukraina-pomoc">
              Fundacja Ocalenie
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSca3_oGcq0zs7HT6z8L9zb4HIXo3ZqcXv8G4TjNSRXobjt3sQ/viewform">
              Polski Czerwony Krzyż
            </a>
          </li>
        </ul>
      `
    },
    {
      title: 'Tłumaczenie',
      header: { before: 'Tłumaczenie' },
      id: 'section-p-8',
      html: `
        <p>
          Jeśli jesteś profesjonalnym tłumaczem lub znasz język Ukraiński i wybrany inny język obcy lub polski możesz zapisać się przez kwestionariusz aby dołączyć do tłumaczy-wolontariuszy.
          (
            <a target="_blank" rel="noopener noreferrer" href="http://www.tlumaczedlaukrainy.pl/">
              source
            </a>
          ).
        </p>
      `
    },
    {
      title: 'Oddawanie Krwi',
      header: { before: 'Oddawanie Krwi' },
      id: 'section-p-9',
      html: `
        <p>
          Zapasy krwi są niezbędne w pomocy żołnierzom i innym rannym ofiarom wojennym. Możesz sprawdzić obecne zapasy kwi w wybranych miastach Polski.
        </p>

        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/bzFOo">
              Warszawa
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/oKpux">
              Kraków
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/j5lYR">
              Wrocław
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/j3Nrm">
              Katowice
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/u4aOk">
              Poznań
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="http://bityl.pl/OrB7A">
              Gdańsk
            </a>
          </li>
        </ul>
      `
    }
  ]
};
