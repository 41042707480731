/* eslint-disable max-len */
import { OfficeModel, CountryNames } from '@/types';

export const berlin: OfficeModel = {
  city: 'Berlin',
  country: CountryNames.GERMANY,
  name: "Ku'Damm Office",
  address: {
    first: 'Kurfürstendamm 11',
    second: '10719'
  },
  map:
    'https://www.google.com/maps/place/Kurf%C3%BCrstendamm+11,+10719+Berlin,+%D0%93%D0%B5%D1%80%D0%BC%D0%B0%D0%BD%D0%B8%D1%8F/@52.504788,13.3314388,17z/data=!3m1!4b1!4m5!3m4!1s0x47a850ff0167b069:0x7661d3e1c47b8292!8m2!3d52.5047848!4d13.3336275'
};
