



















































import { defineComponent, ref, watch, PropType, computed, onMounted } from '@nuxtjs/composition-api';
import { DcwFormError, DcwCloseButton } from '@atoms';
import { humanFileSize } from '@/helpers';
import UploadIcon from '@/assets/icons/upload-arrow.svg';

export const DcwFormFileUpload = defineComponent({
  name: 'DcwFormFileUpload',
  components: { DcwFormError, DcwCloseButton, UploadIcon },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    sizeLimit: {
      type: Number,
      required: true,
      validator: (value: number) => value < ((process.env.maxRequestSize as unknown) as number)
    },
    acceptedExt: {
      type: Array as PropType<string[]>,
      required: true
    },
    value: {
      type: Array as PropType<File[] | null[]>,
      default: () => null
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const uploadedFile = ref<(File | null)[]>([props.value[0]]);
    const fileInputRef = ref<HTMLInputElement>();
    const isUploadHovered = ref(false);
    const validationProvider = ref();

    const accept = props.acceptedExt.map((fileExtension) => `.${fileExtension}`).join(', ');

    const formattedFileName = computed(() => {
      const fileName = uploadedFile.value[0]?.name ?? '';
      if (fileName.length < 22) {
        return fileName;
      }

      const shortName = fileName.slice(0, 18);
      const format = fileName.slice(fileName.lastIndexOf('.'));

      return `${shortName}...${format}`;
    });

    watch(
      () => [...props.value],
      (currentValue) => {
        if (!currentValue.length) {
          removeFile();
        }
      }
    );

    const updateModel = (uploaded: File | null) => {
      uploadedFile.value.splice(0, uploadedFile.value.length);
      uploadedFile.value[0] = uploaded;

      validationProvider.value.reset();

      emit('input', uploadedFile.value);
    };

    const setFile = (event: DragEvent) => {
      const value = event.dataTransfer?.files ? event.dataTransfer.files[0] : null;
      updateModel(value);
    };

    const onFileChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const value = target.files ? target.files[0] : null;

      updateModel(value);
    };

    const removeFile = () => {
      if (fileInputRef.value && fileInputRef.value.files) {
        fileInputRef.value.value = '';
      }

      updateModel(null);
    };

    const connectObserverWithProvider = async () => {
      updateModel(null);
      await validationProvider.value.validate(uploadedFile.value);
      validationProvider.value.reset();
    };

    const triggerInputClick = () => {
      fileInputRef.value?.click();
    };

    onMounted(async () => {
      await connectObserverWithProvider();
    });

    return {
      accept,
      formattedFileName,
      fileInputRef,
      isUploadHovered,
      onFileChange,
      removeFile,
      setFile,
      triggerInputClick,
      uploadedFile,
      validationProvider,
      humanFileSize
    };
  }
});

export default DcwFormFileUpload;
