import { computed, onMounted, Ref, ref, watch } from '@nuxtjs/composition-api';
import { useBreakpoints } from '@/composables';
import { Breakpoints } from '@/types';
import { debounce } from '@/helpers';

interface UseSubMenu {
  isOpen: Ref<boolean>;
  clickHandler: () => void;
  mouseEnterHandler: () => void;
  mouseLeaveHandler: () => void;
  resetSubMenu: () => void;
}

export const useSubMenu = (wrapperRef: Ref<HTMLLIElement | null>): UseSubMenu => {
  const { currentWidth } = useBreakpoints();
  const isOpen = ref(false);
  const isClicked = ref(false);
  const isHovered = ref(false);

  const isMobile = computed(() => currentWidth.value < Breakpoints.MEDIUM);

  const clickHandler = () => {
    if (isHovered.value) {
      isClicked.value = !isClicked.value;
      return;
    }

    isOpen.value = !isOpen.value;
  };

  const mouseEnterHandler = () => {
    if (isMobile.value) {
      return;
    }

    isHovered.value = true;
    isOpen.value = true;
  };

  const mouseLeaveHandler = () => {
    if (isMobile.value) {
      return;
    }

    isHovered.value = false;

    if (isClicked.value) {
      return;
    }

    isOpen.value = false;
  };

  const resetSubMenu = () => {
    isOpen.value = false;
    isClicked.value = false;
    isHovered.value = false;
  };

  watch(currentWidth, (newValue, oldValue) => {
    if (
      (newValue > Breakpoints.MEDIUM && oldValue < Breakpoints.MEDIUM) ||
      (newValue < Breakpoints.MEDIUM && oldValue > Breakpoints.MEDIUM)
    ) {
      resetSubMenu();
    }
  });

  onMounted(() => {
    document.addEventListener('click', (evt: MouseEvent) => {
      if (!isOpen.value || !wrapperRef.value || isMobile.value || !isClicked.value) {
        return;
      }
      const wrapperEl = wrapperRef.value;
      const target = evt.target as HTMLElement;
      if (!target.closest('.' + wrapperEl.classList[0])) {
        resetSubMenu();
      }
    });
  });

  return {
    isOpen,
    clickHandler: debounce(() => clickHandler()),
    mouseEnterHandler: debounce(() => mouseEnterHandler()),
    mouseLeaveHandler: debounce(() => mouseLeaveHandler()),
    resetSubMenu
  };
};
