/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

export const story: SuccessStory = {
  name: 'Kacper',
  images: {
    og: '/covers/success-story/story-3.jpg',
    avatar: {
      src: '/success-stories/story-3/avatar.png',
      alt: 'Kacper',
      width: 32,
      height: 32
    },
    cover: {
      src: '/success-stories/story-3/cover.jpg',
      alt: 'Kacper cover',
      height: 608
    },
    slider: {
      src: '/success-stories/story-3/slider.png',
      alt: 'Kacper',
      width: 317,
      height: 224
    },
    listing: {
      src: '/success-stories/story-3/listing.png',
      alt: 'Kacper',
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'Aug 7, 2021',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'Junior Frontend Developer',
  details: `I stumbled upon DC while looking for job offers on JJIT. The company had good opinions so
    I decided to give it a shot. The recruitment department contacted me very quickly,
    introduced me to the project I was applying to, and prepared a recruitment task for me.
    Personally, as of now, I don't see big competition when it comes to other employers.`,
  shortStory:
    'My story started with two of my cousins working in the IT industry. They were the ones that got me interested in it.',
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer:
        'My story started with two of my cousins working in the IT industry. They were the ones that got me interested in this topic in the first place. Later at some point in my life, I met a group of people with whom we started to work on our first own-created projects. They were my driving force within. Together we were practicing experiential learning and that way I gained my experience in programming.'
    },
    {
      type: 'text',
      title: 'What do you do in DC?',
      answer:
        "In DC I'm a front-end developer. In my daily work, I'm looking for bugs, fixing them, and slowly I'm starting to help my older colleagues from Rocky Team manage some bigger projects."
    },
    {
      type: 'text',
      title: 'What did your recruitment process look like? Why did you choose DC?',
      answer:
        "I stumbled upon DC while looking for job offers on JJIT. The company had good opinions so I decided to give it a shot. The recruitment department contacted me very quickly, introduced me to the project I was applying to, and prepared a recruitment task for me. Personally, as of now, I don't see big competition when it comes to other employers."
    },
    {
      type: 'image',
      details: 'Paintball battlefield in Tatras',
      img: {
        src: '/success-stories/story-3/image-1.png',
        alt: 'Paintball battlefield in Tatras',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.ZAKOPANE_INTEGRATION_TRIP
    },
    {
      type: 'text',
      title: 'What is the learning method that suits you best?',
      answer:
        'At the very beginning of my journey with programming, the most effective method for me was following YouTube tutorials and courses. Later I started to just closely follow code and make my detailed analysis. It stayed like that till today. I look for information and based on them I experiment with the implementation of different methods.'
    },
    {
      type: 'text',
      title: "What are the technologies that you'd like to learn but you lacked the opportunity of so far?",
      answer:
        "Of course, there are plenty of technologies like that. One of them is for example Rust or Golang. I didn't have the opportunity to dive into it very deeply but at first sight, it seems extremely cool."
    },
    {
      type: 'text',
      title: 'What was the greatest challenge that you had to overcome in DC?',
      answer:
        "In my project group at the very beginning, a very big challenge for me was the size of the project. As one may say I was “thrown into the deep” but on the contrary to other environments, I've experienced despite my young age nobody ever mentioned my age. There has always been a real trust which I appreciate. I was also slightly afraid of remote work which was inevitable for me as I come from a rather small town where daily commuting to the workplace would be very much complicated but now I perceive remote work as priceless comfort."
    },
    {
      type: 'text',
      title: 'Where do you see yourself in 10 years?',
      answer:
        "I would guess there is some chance that it won't be in the IT industry. If it is however the IT industry which I hope for I would guess it would still be a software development position. Who knows, maybe one day I would even become a Lead..."
    },
    {
      type: 'image',
      details: 'Żywiec integration trip',
      img: {
        src: '/success-stories/story-3/image-2.png',
        alt: 'Żywiec integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.ZYWIEC_INTEGRATION_TRIP
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Sobel - Fiołkowe Pole',
          img: {
            src: '/success-stories/story-3/song-1.png',
            alt: 'Sobel - Fiołkowe Pole',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/album/2nUmtofQhXViMQP5HeDd0y',
          ytLink: 'https://www.youtube.com/watch?v=1rTV7DCnF3Y'
        },
        {
          title: 'Mata - 100 dni do matury',
          img: {
            src: '/success-stories/story-3/song-2.png',
            alt: 'Mata - 100 dni do matury',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/album/2XEybHzEROxx8nLUOrtSbL',
          ytLink: 'https://www.youtube.com/watch?v=qpi5fMjUvpk'
        },
        {
          title: 'Magiera, Szczyl, Sarius- Naszyjnik',
          img: {
            src: '/success-stories/story-3/song-3.png',
            alt: 'Magiera, Szczyl, Sarius- Naszyjnik',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/5c6tSGBihkJVM8hHxvMcJg',
          ytLink: 'https://www.youtube.com/watch?v=TAwqh3EcpU8'
        }
      ]
    }
  ]
};
