import { SectionWithItemsContent } from '@/types';

export const values: SectionWithItemsContent = {
  headline: {
    before: 'Core',
    highlight: 'Values'
  },
  items: [
    {
      title: 'Born2Solve',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget sit egestas metus, velLorem ipsum dolor sit.'
    },
    {
      title: 'Focus On Clients',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget sit egestas metus, velLorem ipsum dolor sit.'
    },
    {
      title: 'Responsibility',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget sit egestas metus, velLorem ipsum dolor sit.'
    },
    {
      title: 'Freedom Of Choice',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget sit egestas metus, velLorem ipsum dolor sit.'
    }
  ]
};
