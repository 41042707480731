import { OurExpertiseSectionContent } from '@/types';
import { technologiesService } from '@/service/model';

export const ourExpertise: OurExpertiseSectionContent = {
  headline: {
    highlight: 'Core Technologies',
    after: 'That Define Us'
  },
  technologies: technologiesService.content
};
