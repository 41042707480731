











import { computed, defineComponent, toRef, watch } from '@nuxtjs/composition-api';
import { DcwScheduleModalContact } from '../';
import { DcwFormCheckbox, DcwLink } from '@atoms';
import { useInjectStrict, useLocales } from '@/composables';
import {
  PersonData,
  PagesLinks,
  HeadlineTypes,
  RequestStatus,
  ScheduleMeetingFormData,
  DataProcessingSectionsId,
  UpdateScheduleMeetingFormData
} from '@/types';
import {
  ScheduleMeetingFormDataKey,
  UpdateScheduleMeetingFormKey,
  ScheduleMeetingPersonDataKey,
  ScheduleMeetingRequestStatusKey
} from '@/consts';

export const DcwScheduleModalFormStep = defineComponent({
  name: 'DcwScheduleModalFormStep',
  components: { DcwScheduleModalContact, DcwLink, DcwFormCheckbox },
  setup() {
    const personData = useInjectStrict<Readonly<PersonData>>(ScheduleMeetingPersonDataKey);
    const formData = useInjectStrict<Readonly<ScheduleMeetingFormData>>(ScheduleMeetingFormDataKey);
    const updateFormData = useInjectStrict<UpdateScheduleMeetingFormData>(UpdateScheduleMeetingFormKey);
    const status = useInjectStrict<RequestStatus>(ScheduleMeetingRequestStatusKey);
    const { translation } = useLocales();

    const terms = toRef(formData, 'terms');

    const neededCheckboxLinkData = computed(() => {
      if (personData.zone === 'HR') {
        return {
          path: `${PagesLinks.DATA_PROCESSING}#${DataProcessingSectionsId.recruitmentCall}`,
          text: translation.inputFields.dpRecruitmentCall
        };
      } else {
        return {
          path: `${PagesLinks.DATA_PROCESSING}#${DataProcessingSectionsId.businessCall}`,
          text: translation.inputFields.dpBusinessCall
        };
      }
    });

    watch(terms, () => {
      updateFormData('terms', terms.value)(formData);
    });

    return {
      terms,
      status,
      personData,
      PagesLinks,
      translation,
      HeadlineTypes,
      RequestStatus,
      neededCheckboxLinkData,
      DataProcessingSectionsId
    };
  }
});

export default DcwScheduleModalFormStep;
