



















































import { defineComponent, ref, watch, toRef, computed, PropType, onMounted, reactive } from '@nuxtjs/composition-api';
import { DcwExploreOurStoryCard, DcwTimetableCard } from '@molecules';
import { TimetableCard, EventCardDate, TimetableCardVariant, EventCardStates } from '@/types';
import {
  useTimetableDateProgress,
  useTimetableCarouselHomepage, useBreakpoints, useScrollUtilities, useIsBrowserSafari
} from '@/composables';

export const DcwExploreOurStoryTimeline = defineComponent({
  name: 'DcwExploreOurStoryTimeline',
  components: {
    DcwExploreOurStoryCard,
    DcwTimetableCard
  },
  props: {
    activeCardIndex: {
      type: Number,
      required: true
    },
    events: {
      type: Array as PropType<TimetableCard[]>,
      required: true
    }
  },
  emits: ['expandCard'],
  setup(props) {
    const { isSafari } = useIsBrowserSafari();
    const { isMobile, currentWidth } = useBreakpoints();

    const timetableSliderRef = ref<HTMLElement | null>(null);

    const observedElements = toRef(props, 'events');

    const { observer } = useScrollUtilities(timetableSliderRef, observedElements);

    const cardIndexToRef = toRef(props, 'activeCardIndex');

    const eventsToRef = toRef(props, 'events');

    useTimetableCarouselHomepage(
      timetableSliderRef,
      cardIndexToRef,
      eventsToRef.value.length,
      { preventTransitionOnMobile: true }
    );

    const eventsDots = computed<EventCardDate[]>(() =>
      props.events.map((card) => ({ id: card.id, date: card.date }))
    );

    const {
      dateProgressInPixels,
      closestEventIndex
    } = useTimetableDateProgress(timetableSliderRef, eventsDots.value, cardIndexToRef, {
      isHomepageVariant: true,
      mobileGap: 25,
      desktopGap: 64
    });

    const timetableSliderRefWidth = ref(0);

    const setScrollWidthOnResize = () => {
      if (!isMobile.value && timetableSliderRef.value) {
        timetableSliderRefWidth.value = timetableSliderRef.value.scrollWidth || 0;
      } else {
        const widthOfMobileCard = 228;
        const gapBetweenCards = 25;
        const amountOfEvents = props.events.length + 1;
        timetableSliderRefWidth.value = (widthOfMobileCard + gapBetweenCards) * amountOfEvents;
      }
    };

    watch(currentWidth, () => {
      setScrollWidthOnResize();
    });

    onMounted(() => {
      setScrollWidthOnResize();
    });

    const animationSequence = reactive<{
      previous: null | number;
      previousDone: boolean;
      active: null | number;
      activeDone: boolean;
    }>({
      previous: null,
      previousDone: false,
      active: 1,
      activeDone: false
    });

    watch(() => props.activeCardIndex, (newValue, oldValue) => {
      animationSequence.previous = oldValue;
      animationSequence.active = newValue;
      animationSequence.activeDone = false;
      animationSequence.previousDone = false;
    });

    const cardState = (index: number): EventCardStates => {
      if (index === animationSequence.active) {
        return EventCardStates.ACTIVE;
      } else if (index === animationSequence.previous) {
        return EventCardStates.PREVIOUS;
      } else {
        return EventCardStates.INACTIVE;
      }
    };

    const changeCardTransitionState = (index: number) => {
      if (index === animationSequence.active) {
        animationSequence.activeDone = true;
      }
      if (index === animationSequence.previous) {
        animationSequence.previousDone = true;
      }
    };

    const parseDimensionValueToNumber = (value: string) => Number(value.substring(0, value.length - 2));

    const paddingLeft = computed(() => {
      if (!(process.client && timetableSliderRef.value && currentWidth.value > 1234)) {
        return null;
      }

      const gridWrapper = document.querySelector('div.dcw-wide-grid');
      if (!gridWrapper) { return null; }

      const widthOfBigCard = currentWidth.value > 1400 ? 916 : 744;
      const widthOfSmallCard = 221;

      const gridStyles = getComputedStyle(gridWrapper, null);
      const timetableSliderStyles = window.getComputedStyle(timetableSliderRef.value, null);

      const gridWidth = parseDimensionValueToNumber(gridStyles.getPropertyValue('width'));
      const gapBetweenCards = parseDimensionValueToNumber(isSafari.value
        ? timetableSliderStyles.getPropertyValue('grid-row-gap')
        : timetableSliderStyles.getPropertyValue('gap'));

      const pxBigCardNeedsToBeRenderedOnXAxis = (gridWidth * 0.5) - (widthOfBigCard * 0.5);
      const paddingThatShouldBeAppliedToCenterActiveSlide =
      pxBigCardNeedsToBeRenderedOnXAxis - gapBetweenCards - widthOfSmallCard;

      return paddingThatShouldBeAppliedToCenterActiveSlide;
    });

    watch(paddingLeft, (newPadding) => {
      if (!timetableSliderRef.value) {
        return;
      }
      const cssVariableForBeforeElement = '--width-of-pre-data-progress';
      const value = newPadding && newPadding > 560 ? '560px' : `${newPadding}px`;

      newPadding !== null
        ? timetableSliderRef.value.style.setProperty(cssVariableForBeforeElement, value)
        : timetableSliderRef.value.style.removeProperty(cssVariableForBeforeElement);
    });

    const calibrateCardToBeInCenter = ref(false);

    watch(currentWidth, () => {
      if (timetableSliderRef.value) {
        timetableSliderRefWidth.value = timetableSliderRef.value.scrollWidth;
        calibrateCardToBeInCenter.value = true;
        setTimeout(() => {
          calibrateCardToBeInCenter.value = false;
        }, 0);
      }
    });

    const paddingValue = computed(() => {
      return paddingLeft.value && paddingLeft.value > 560 ? '560px' : `${paddingLeft.value}px`;
    });

    return {
      changeCardTransitionState,
      cardState,
      timetableSliderRef,
      dateProgressInPixels,
      paddingLeft,
      closestEventIndex,
      calibrateCardToBeInCenter,
      timetableSliderRefWidth,
      observer,
      isMobile,
      TimetableCardVariant,
      paddingValue
    };
  }
});

export default DcwExploreOurStoryTimeline;
