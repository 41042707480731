/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { apply } from './apply';

const axiosInstance = axios.create();
const API_URL = '/api/apply';

export const applyServiceFactory = () => ({
  apply: apply.bind(null, axiosInstance, API_URL)
});
