export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TAB = 'tab'
}

export enum TextInputTypes {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  TEXTAREA = 'textarea',
  TEL = 'tel'
}

export enum LabelTypes {
  INFO = 'info',
  INVERTED_BACKGROUND = 'inverted-background'
}

export enum TooltipColors {
  WHITE = 'white',
  PRIMARY = 'primary',
  SECONDARY_DARK = 'secondary-dark'
}

export enum SliderDirection {
  NEXT = 'next',
  PREV = 'prev'
}

export enum ContentBlockSpacings {
  EXTRA_SMALL = 'extra-small',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  LARGER = 'larger',
  EXTRA_LARGE = 'extra-large'
}

export enum JobOfferItemVariant {
  CARD = 'card',
  LIST_ITEM = 'list-item'
}

export enum GalleryItemType {
  VIDEO = 'video',
  IMAGE = 'image'
}

export enum ScrollSnapAlign {
  START = 'start',
  CENTER = 'center',
  INITIAL = 'initial'
}

export enum SocialMediaColor {
  WHITE = 'white',
  PRIMARY = 'primary',
  GRAY = 'gray',
  LIGHT = 'light'
}

export enum HeadlineSubtitleSpacing {
  NO = 'no',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extra-large'
}

export enum VideoPlayerDescPadding {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export enum VideoPlayerButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export enum HeaderColors {
  DEFAULT = '',
  WHITE = 'white',
  DARK = 'dark'
}
