/* eslint-disable max-len */
import { videos } from '../../videos';
import { VideoListingIntegrationsContent } from '@/types';

export const integrations: VideoListingIntegrationsContent = {
  headline: {
    highlight: 'Company',
    after: 'Trips',
    subtitle:
      'We believe that behind every success, there is a story. These stories motivate our prospective individuals and offer unlimited courage when facing future challenges.'
  },
  videos: [
    {
      ...videos.integrationCracow,
      description: {
        before: 'Christmas Eve With Digital Colliers Familiy',
        subtitle: 'Crakow, Poland '
      }
    },
    {
      ...videos.integrationItaly,
      description: {
        before: 'Digital Colliers in Bologna, Italy ',
        subtitle: 'Italy'
      }
    },
    {
      ...videos.integrationTatra,
      featured: true,
      description: {
        before: 'Company Integration in the Tatra Mountains!',
        subtitle: 'Tatra Mountains, Poland'
      }
    },
    {
      ...videos.integrationBeskids,
      featured: true,
      description: {
        before: '3 Days Off in the Beskids, Żywiec',
        subtitle: 'Beskids, Poland'
      }
    }
  ]
};
