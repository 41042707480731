






import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { MainGridWrapperElements } from '@/types';
import { isInEnum } from '@/helpers';

export const DcwGridWrapper = defineComponent({
  name: 'DcwGridWrapper',
  props: {
    as: {
      type: String as PropType<MainGridWrapperElements>,
      default: MainGridWrapperElements.SECTION,
      validator: (value: MainGridWrapperElements) => isInEnum(value, MainGridWrapperElements)
    }
  }
});

export default DcwGridWrapper;
