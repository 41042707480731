












import { defineComponent, onBeforeUnmount, onMounted, ref, useContext, watch } from '@nuxtjs/composition-api';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const DcwModal = defineComponent({
  name: 'DcwModal',
  props: {
    isShown: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close-modal'],
  setup(props, { emit }) {
    const { $device } = useContext();
    const escButtonHandler = (evt: KeyboardEvent) => {
      if (props.isShown && evt.key === 'Escape') {
        emit('close-modal');
      }
    };
    onMounted(() => {
      window.addEventListener('keydown', escButtonHandler);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', escButtonHandler);
    });

    const scrollPosition = ref(0);

    const disableScroll = () => {
      const body = document.body;
      if (!$device.isIos) {
        disableBodyScroll(body, { reserveScrollBarGap: true });
        return;
      }

      scrollPosition.value = window.pageYOffset;
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
      body.style.top = `-${scrollPosition.value}px`;
      body.style.width = '100%';
    };

    const enableScroll = () => {
      const body = document.body;
      if (!$device.isIos) {
        enableBodyScroll(body);
        return;
      }

      body.style.removeProperty('overflow');
      body.style.removeProperty('position');
      body.style.removeProperty('top');
      body.style.removeProperty('width');
      window.scrollTo(0, scrollPosition.value);
    };

    watch(
      () => props.isShown,
      (value) => {
        if (value) {
          disableScroll();
        } else {
          enableScroll();
        }
      }
    );

    return {};
  }
});

export default DcwModal;
