














import { defineComponent, PropType, watch } from '@nuxtjs/composition-api';
import Success from '@/assets/icons/toasts/success.svg';
import Error from '@/assets/icons/toasts/error.svg';
import { ToastVariants } from '@/types';
import { isInEnum } from '@/helpers';

export const DcwToast = defineComponent({
  name: 'DcwToast',
  components: { Success, Error },
  props: {
    text: {
      type: String,
      required: true
    },
    variant: {
      type: String as PropType<ToastVariants>,
      validator: (value: ToastVariants) => isInEnum(value, ToastVariants),
      required: false,
      default: ToastVariants.SUCCESS
    },
    delay: {
      type: Number,
      required: false,
      default: 2000
    },
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  emits: ['hide-toast'],
  setup(props, { emit }) {
    watch(() => props.isVisible, (value) => {
      if (value) { setTimeout(() => { emit('hide-toast'); }, props.delay); }
    });
    return { ToastVariants };
  }
});

export default DcwToast;
