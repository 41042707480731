export const validations = {
  messages: {
    required: 'Ce champ est obligatoire',
    email: 'L\'email n\'est pas valid',
    mobile: 'Please enter a valid phone number',
    checked: 'Ce champ est obligatoire',
    file(_: string, params: Record<string, unknown>): string {
      let formats = '';

      Object.entries(params).forEach(([key]) => {
        if (isNaN(Number(key))) {
          return;
        }
        formats += formats ? `, ${params[key]}` : `${params[key]}`;
      });

      return `Télécharger le fichier${formats ? ' (' + formats + ')' : ''}`;
    }
  }
};
