export enum ProjectSelectors {
  HEADER = '.dcw-header',
  HEADER_CLOSE = '.dcw-header--close',
  WIDGET_LIST = '.dcw-lang-widget__list',
  TEXT_PAGE_LAYOUT = '.dcw-text-page-layout',
  TEXT_PAGE_LAYOUT_COMMON = '.dcw-common-layout',
  MAIN = 'main',
  Q_AND_A_SECTION_WRAPPER = 'dcw-q-and-a-section-wrapper',
  BLOG_CONTENT_WRAPPER = 'dcw-blog-post-layout__content'
}

export enum PositionSectionsId {
  DESCRIPTION = 'description',
  TEAM = 'team',
  BENEFITS = 'benefits',
  ABOUT = 'about',
  RECRUITMENT = 'recruitment',
  CTA = 'cta',
  REASONS_TO_JOIN = 'reasons-to-join',
  FAQ = 'faq'
}

export enum ProjectSectionsId {
  DESCRIPTION = 'description',
  TECHNOLOGIES = 'technologies',
  OPEN_POSITIONS = 'open-positions',
  TEAM = 'team',
  CTA = 'cta',
  HOW_WE_WORK = 'how-we-work',
  CHALLENGES = 'challenges',
  OTHERS_PROJECTS = 'others-projects'
}

export enum DataProcessingSectionsId {
  contactForm = 'contact-form-personal-data-processing-information',
  applicationForm = 'application-form-personal-data-processing-information',
  recruitmentCall = 'recruitment-call-form-personal-data-processing-information',
  businessCall = 'business-call-form-personal-data-processing-information'
}

export enum HomePageSectionIds {
  TECHNOLOGIES = 'home-our-technologies',
  MEET_OUR_TEAMS = 'home-meet-our-teams'
}

export enum TAPageSectionIds {
  CTA = 'cta'
}
