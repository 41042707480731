export enum CssVariables {
  COLOR_PRIMARY = '--dcw-color-primary',
  COLOR_SECONDARY_DARK = '--dcw-color-secondary-dark',
  COLOR_PRIMARY_LIGHT = '--dcw-color-primary-light',
  COLOR_BACKGROUND = '--dcw-color-background',
  COLOR_SKY_BLUE = '--dcw-color-sky-blue',
  COLOR_WHITE = '--dcw-color-white',
  COLOR_NAVIGATION_BACKGROUND = '--dcw-navigation-background',
  COLOR_PROJECT_HERO_LINE = '--dcw-color-project-hero-line',
}
