/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: true,
  categories: [JobOfferCategories.DESIGN],
  projects: [],
  name: 'Graphic Designer',
  salaryRanges: salaries.graphicDesigner,
  seniorityLevel: SeniorityLevels.MID,
  referrers: {
    [Referrers.DC_WEBSITE]: 186844741,
    [Referrers.JUST_JOIN_IT]: 186844744,
    [Referrers.OTHER]: 186844740,
    [Referrers.FACEBOOK_ADS]: 192832693,
    [Referrers.GOOGLE_ADS]: 192832696
  },
  description: [
    {
      title: 'Description',
      text: [
        'We are currently looking for an experienced \bGraphic Designer\b to join our team, and help us create eye-catching designs for Lottery industry'
      ]
    },
    {
      title: 'Your Role and Responsibilities ',
      items: [
        'Create high-quality 2D characters, elements, and backgrounds',
        'Conceptualize, present, and execute your ideas from A to Z',
        'Pay high attention to detail and instructions provided, ensure art provided adheres to the art direction for a game',
        'Show efficiency in self-managing time with minimal instruction. Be accountable for the timely and accurate completion of all deliverables',
        'Demonstrate self-sufficiency and ability to think independently to concept and create art for a project that may not have been explicitly defined in advance',
        'Review final products and ensure they are pixel-perfect'
      ]
    },
    {
      title: 'We’re looking for someone who has',
      items: [
        'Experience creating a broad range of illustrative styles in 2D',
        'Excellent knowledge of Adobe CC software',
        'Knowledge of Spine',
        'Strong design, illustration, typography, and animation skills',
        'Proactive attitude and approach to solving problems',
        'Very good \bEnglish\b skills (min. B2)'
      ]
    }
  ],
  technologies: [
    { label: 'Adobe CC software', level: TechnologyLevels.REGULAR },
    { label: 'English', level: TechnologyLevels.REGULAR }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First, \binterview\b with our recruiter.'
    },
    {
      title: 'Last interview',
      description: '\bInterview\b with your future Team Leader.'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process within \b1 week\b'
    }
  ]
};
