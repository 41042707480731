import { useContext } from '@nuxtjs/composition-api';
import { CaptchaActions } from '@/types';
import { isProduction } from '@/helpers';

interface UseCaptcha {
  executeCaptcha: (action: CaptchaActions) => Promise<string>;
}

export const useCaptcha = (): UseCaptcha => {
  const { $recaptcha } = useContext();

  const executeCaptcha = (action: CaptchaActions) => isProduction
    ? $recaptcha.init().then(() => $recaptcha.execute(action))
    : Promise.resolve('');

  return { executeCaptcha };
};
