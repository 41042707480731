import { ImageObject } from 'sharp-loader/src/types';

interface GetSources {
  thumbnail: ImageObject | { url: string } | undefined;
  targets: ImageObject[];
  fallbackTargets: ImageObject[] | [{ url: string }];
}

const imageContextFactory = (): __WebpackModuleApi.RequireContext => {
  return require.context(process.env.NODE_ENV !== 'production'
    ? '@/assets'
    : `!!sharp-loader!@/assets?{
        "outputs":[
          {
            "name": "img/[name].thumb.[contenthash:7].[ext]",
            "format": { "id": "png", "quality": 90 },
            "height": 10,
            "inline": true
          },
          {
            "name": "img/[name].[contenthash:7].[ext]",
            "format": ["webp", "png"],
            "scale": [0.5, 1]
          }
        ]
      }`,
  true, /\.(gif|jpe?g|png|tiff|webp)$/i);
};

export const imageContext = imageContextFactory();

export const getSources = (src: string): GetSources => {
  const sources: ImageObject[] | string = imageContext(`.${src}`);

  if (typeof sources === 'string') {
    return {
      thumbnail: { url: `${sources}?thumb` },
      targets: [],
      fallbackTargets: [{ url: sources }]
    };
  }

  return {
    thumbnail: sources.find(({ name }) => name.includes('.thumb')),
    targets: sources.filter(({ format, name }) => format === 'webp' && !name.includes('.thumb')),
    fallbackTargets: sources.filter(({ format, name }) => format === 'png' && !name.includes('.thumb'))
  };
};
