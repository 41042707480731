/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, ProjectIDs, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: false,
  categories: [JobOfferCategories.FRONTEND],
  projects: [ProjectIDs.ECO_SYSTEM],
  name: 'Senior React Developer',
  salaryRanges: salaries.react,
  seniorityLevel: SeniorityLevels.SENIOR,
  referrers: {
    [Referrers.DC_WEBSITE]: 168574519,
    [Referrers.OTHER]: 168574531,
    [Referrers.CARROT_WORK]: 162384763,
    [Referrers.JUST_JOIN_IT]: 162360138,
    [Referrers.NO_FLUFF_JOBS]: 168574451,
    [Referrers.BULLDOG_JOB_PL]: 162384999,
    [Referrers.FACEBOOK_ADS]: 192884805,
    [Referrers.GOOGLE_ADS]: 192884807
  },
  description: [
    {
      title: 'Description',
      text: [
        'We are currently looking for an experienced \bReact Developer\b to join our team, and help us develop an \becosystem for social finance solutions\b, that will revolutionize banking for Freelancers.',
        'Nowadays, more than \b300 million active freelancers\b - growing to as many as \b2 billion\b by 2035 - power the global, digital, on-demand economy. With our partner, we intend to \bcreate a bank\b that works for them and will revolutionize the industry.',
        'Our partner is an American startup headquartered in beautiful Miami, Florida with the mission of designing financial solutions that align with the organizing principles of the new, remote-first economy. We are currently under the development of \bthree green-field projects\b, connected into one ecosystem that will allow freelancers to acquire financing, get paid faster, and build their businesses from the ground up.'
      ]
    },
    {
      title: 'Your Role and Responsibilities ',

      items: [
        "Working on an innovative \bFin-Tech App\b that's made up of a complex ecosystem of web applications deployed on AWS",
        'Develop a web application to fulfill specific business needs and requirements',
        'Striving for the \bsuperior quality\b of the product',
        'Participate in the project in \bAgile\b methodology and conform to coding standards including GIT workflow',
        'Sharing your ideas, experience with us, and take a voice in discussions on \btechnical and business matters\b'
      ]
    },
    {
      title: 'The Main Programming Languages and Databases Used Are',

      items: [
        'Backend - \bNode JS\b, Python',
        'Responsive Web app - \bReactJS\b',
        'Android & iOS apps - \bReactNative\b',
        'Database - \bMongoDB / DocumentDB\b',
        'Container Management - \bDocker\b'
      ]
    },
    {
      title: "We're Looking for Someone Who Has",

      items: [
        '5 years experience in \bcore JavaScript\b and \bReact.JS\b',
        'Strong experience in \bGraphQL\b',
        'Ability to create custom complex components',
        'Good understanding of \bGraphQL/REST APIs\b for consumption into the Front-end',
        'Proactive attitude and approach to solving problems',
        'Very good \bEnglish\b skills (min. B2/C1)'
      ]
    },
    {
      title: 'What Else Makes Us Tickle',

      items: [
        'You are \bambitious\b, humble and the \bstartup spirit\b attracts you',
        'You have a team spirit and the desire to share your knowledge',
        'You are \b#born2solve\b. When you see a problem, you see its solution'
      ]
    }
  ],
  technologies: [
    { label: 'JavaScript', level: TechnologyLevels.ADVANCED },
    { label: 'React', level: TechnologyLevels.ADVANCED },
    { label: 'GraphQL', level: TechnologyLevels.REGULAR },
    { label: 'REST API', level: TechnologyLevels.REGULAR },
    { label: 'Microservice Architecture', level: TechnologyLevels.REGULAR },
    { label: 'Agile', level: TechnologyLevels.JUNIOR }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First, \binterview\b to get to know each other and talk about both your and our expectations'
    },
    {
      title: 'Tech Interview',
      description:
        'A 2-hours \btechnical assessment\b where we are going pair programming and talking about the theoretical aspects of both Frontend and Backend'
    },
    {
      title: 'Team Lead Interview',
      description: 'Recruitment Final Stage - \binterview\b with your future Team Leader'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process within \b2 weeks\b'
    }
  ]
};
