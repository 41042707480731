import { explanation } from './explanation';
import { companyLocation } from './companyLocation';
import { hero } from './banner';
import { testimonials } from './clientTestimonial';
import { globalWork } from './globalWork';
import { workingWithPC } from './workingWithPC';
import { cooperation } from './cooperation';

export const teamAugmentationPage = {
  explanation,
  companyLocation,
  hero,
  testimonials,
  globalWork,
  workingWithPC,
  cooperation
};
