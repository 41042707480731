/* eslint-disable @typescript-eslint/no-var-requires */
import { JobOffer } from '@/types';

export const offers = {
  // ADD NEW OFFER ON NEXT LINE

  // get 'junior-software-tester'(): JobOffer {
  //   return require('./junior-software-tester').offer;
  // },
  get 'senior-fullstack-engineer'(): JobOffer {
    return require('./senior-fullstack-engineer').offer;
  },
  get 'senior-android-engineer'(): JobOffer {
    return require('./senior-android-engineer').offer;
  },
  get 'junior-vue-developer'(): JobOffer {
    return require('./junior-vue-developer').offer;
  },
  get 'mid-graphic-designer'(): JobOffer {
    return require('./mid-graphic-designer').offer;
  },
  // get 'senior-business-analyst'(): JobOffer {
  //   return require('./senior-business-analyst').offer;
  // },
  get 'junior-product-owner'(): JobOffer {
    return require('./junior-product-owner').offer;
  },
  // get 'senior-content-manager'(): JobOffer {
  //   return require('./senior-content-manager').offer;
  // },
  get 'senior-product-owner'(): JobOffer {
    return require('./senior-product-owner').offer;
  },
  // get 'senior-product-designer'(): JobOffer {
  //   return require('./senior-product-designer').offer;
  // },
  // get 'junior-react-developer'(): JobOffer {
  //   return require('./junior-react-developer').offer;
  // },
  get 'content-manager'(): JobOffer {
    return require('./content-manager').offer;
  },
  // get 'head-of-recruitment'(): JobOffer {
  //   return require('./head-of-recruitment').offer;
  // },
  get 'it-recruitment-specialist'(): JobOffer {
    return require('./it-recruitment-specialist').offer;
  },
  // get 'mid-dot-net-developer'(): JobOffer {
  //   return require('./mid-dot-net-developer').offer;
  // },
  get 'senior-dot-net-developer'(): JobOffer {
    return require('./senior-dot-net-developer').offer;
  },
  get 'junior-backend-developer'(): JobOffer {
    return require('./junior-backend-developer').offer;
  },
  get 'mid-react-developer'(): JobOffer {
    return require('./mid-react-developer').offer;
  },
  // get 'mid-dev-ops-engineer'(): JobOffer {
  //   return require('./mid-dev-ops-engineer').offer;
  // },
  get 'senior-dev-ops-engineer'(): JobOffer {
    return require('./senior-dev-ops-engineer').offer;
  },
  get 'senior-golang-software-engineer'(): JobOffer {
    return require('./senior-golang-software-engineer').offer;
  },
  get 'mid-react-native-developer'(): JobOffer {
    return require('./mid-react-native-developer').offer;
  },
  get 'mid-backend-node-js-developer'(): JobOffer {
    return require('./mid-backend-node-js-developer').offer;
  },
  get 'senior-backend-developer'(): JobOffer {
    return require('./senior-backend-developer').offer;
  },
  // get 'senior-product-owner-pmp'(): JobOffer {
  //   return require('./senior-product-owner-pmp').offer;
  // },
  get 'mid-backend-developer'(): JobOffer {
    return require('./mid-backend-developer').offer;
  },
  get 'senior-vue-developer'(): JobOffer {
    return require('./senior-vue-developer').offer;
  },
  get 'mid-vue-developer'(): JobOffer {
    return require('./mid-vue-developer').offer;
  },
  get 'senior-backend-node-js-developer'(): JobOffer {
    return require('./senior-backend-node-js-developer').offer;
  },
  get 'senior-react-native-developer'(): JobOffer {
    return require('./senior-react-native-developer').offer;
  },
  get 'senior-react-developer'(): JobOffer {
    return require('./senior-react-developer').offer;
  }
};
