/* eslint-disable max-len */
import { CompanyLocationContent } from '@/types';

export const companyLocation: CompanyLocationContent = {
  headline: {
    before: 'Why Poland',
    subtitle:
      "Poland has become an interesting market from an investor's point of view due to its fluid growth which increases development further."
  },
  map: {
    src: '/team-augmentation/company-location/map.png',
    alt: 'Poland map',
    width: 1076,
    height: 834
  },
  details: [
    {
      heading: 'GMT+1',
      headline: {
        before: 'Center of Europe',
        subtitle: 'Our time zone enables effective communication between parties.'
      }
    },
    {
      heading: '25%',
      headline: {
        before: "Europe's IT Talent Pool",
        subtitle: '30 000 ICT students graduating\nevery year from 500+ universities.'
      }
    },
    {
      heading: 'Gold Coin',
      headline: {
        before: 'Cost-Effectiveness',
        subtitle: 'Costs are considerably lower than\nin the western part of Europe.'
      }
    },
    {
      heading: '61.49',
      headline: {
        before: 'English Proficiency Index',
        subtitle: '9th place in Europe in terms of\nhigh English proficiency (61.49).'
      }
    }
  ]
};
