





















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import DcwButton from '@/components/Atoms/Button/DcwButton.vue';
import { ButtonVariants, ErrorPage } from '@/types';

export default defineComponent({
  name: 'DcwErrors',
  components: {
    DcwButton
  },
  props: {
    statusCode: {
      type: Number,
      default: 404
    },
    content: {
      type: Object as PropType<ErrorPage>,
      required: true
    }
  },
  setup(props) {
    const firstLinkProps = {
      href: props.content.firstLink.link,
      text: props.content.firstLink.text,
      variant: ButtonVariants.TERTIARY,
      isLeft: true,
      isLink: true
    };
    const secondLinkProps = {
      href: props.content.secondLink.link,
      text: props.content.secondLink.text,
      isLink: true
    };

    return { firstLinkProps, secondLinkProps };
  }
});
