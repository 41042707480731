


















































































import { defineComponent, PropType, computed, watch, ref, onMounted } from '@nuxtjs/composition-api';
import { DcwImage, DcwLabel, DcwTooltip, DcwButton, DcwEventDot, DcwNewHeadline } from '@atoms';
import { DcwContentBlock } from '@molecules';
import {
  EventDot,
  Directions,
  LabelTypes,
  PagesLinks,
  TimetableCard,
  ButtonVariants,
  ContentBlockSpacings,
  TimetableCardVariant
} from '@/types';
import { isInEnum, formatDateString } from '@/helpers';
import { useBreakpoints, useLocales } from '@/composables';
import { WAVE_EMOJI } from '@/consts';

export const DcwTimetableCard = defineComponent({
  name: 'DcwTimetableCard',
  components: { DcwImage, DcwLabel, DcwButton, DcwTooltip, DcwEventDot, DcwNewHeadline, DcwContentBlock },
  props: {
    data: {
      type: Object as PropType<TimetableCard>,
      required: true
    },
    cardVariant: {
      type: String as PropType<TimetableCardVariant>,
      default: TimetableCardVariant.TWO_COLUMNS,
      validator: (value: TimetableCardVariant) => isInEnum(value, TimetableCardVariant)
    },
    eventDot: {
      type: Object as PropType<EventDot>,
      required: true
    },
    observer: {
      type: Object as PropType<{ observe: IntersectionObserver }>,
      required: true
    }
  },
  setup(props) {
    const { isMobile, currentWidth } = useBreakpoints();

    const primaryButtonText = computed(() => {
      if (process.client && isMobile.value) {
        return 'Read more';
      }
      return props.data.content.firstButton && props.data.content.firstButton.text;
    });

    const timetableCardWrapperRef = ref<HTMLDivElement | null>(null);

    const calibrateCardToBeInCenter = ref(false);

    const toggleCalibrate = () => {
      if (props.eventDot.index === props.eventDot.activeEventIndex) {
        calibrateCardToBeInCenter.value = true;
        setTimeout(() => {
          calibrateCardToBeInCenter.value = false;
        }, 0);
      }
    };

    watch(currentWidth, () => {
      toggleCalibrate();
    });

    onMounted(() => {
      if (timetableCardWrapperRef.value && props.observer && process.client) {
        props.observer.observe.observe(timetableCardWrapperRef.value);
      }

      toggleCalibrate();
    });

    const { translation } = useLocales();

    return {
      isMobile,
      Directions,
      PagesLinks,
      LabelTypes,
      WAVE_EMOJI,
      translation,
      ButtonVariants,
      formatDateString,
      primaryButtonText,
      TimetableCardVariant,
      ContentBlockSpacings,
      timetableCardWrapperRef,
      calibrateCardToBeInCenter
    };
  }
});

export default DcwTimetableCard;
