export enum CaptchaActions {
  APPLY = 'apply',
  EMAIL = 'email'
}

export enum CaptchaLinks {
  TERMS_OF_SERVICE = 'https://policies.google.com/terms',
  PRIVACY_POLICY = 'https://policies.google.com/privacy'
}

export enum CaptchaErrorCodes {
  BAD_REQUEST = 'bad-request',
  INVALID_INPUT_RESPONSE = 'invalid-input-response',
  INVALID_INPUT_SECRET = 'invalid-input-secret',
  MISSING_INPUT_RESPONSE = 'missing-input-response',
  MISSING_INPUT_SECRET = 'missing-input-secret',
  TIMEOUT_OR_DUPLICATE = 'timeout-or-duplicate'
}
