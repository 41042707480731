









import { defineComponent } from '@nuxtjs/composition-api';
import { DcwLinkColumn } from '../';
import { DcwNewHeadline } from '@atoms';
import { HeadlineTypes, HeadlineVariants, LinkColumn, PagesLinks } from '@/types';
import { useLocales } from '@/composables';

export const DcwFooterLinks = defineComponent({
  name: 'DcwFooterLinks',
  components: { DcwLinkColumn, DcwNewHeadline },
  props: {
    title: { type: String, required: true }
  },
  setup(props) {
    const { translation } = useLocales();

    const headlineProps = {
      type: HeadlineTypes.P,
      before: props.title,
      styledAs: HeadlineTypes.H4,
      variant: HeadlineVariants.LIGHT
    };

    const linkColumn: LinkColumn = {
      heading: translation.phrases.company,
      links: [
        {
          text: translation.pagesNames.about,
          path: PagesLinks.ABOUT
        },
        {
          text: translation.pagesNames.teamAugmentation,
          path: PagesLinks.TEAM_AUGMENTATION
        },
        {
          text: translation.pagesNames.career,
          path: PagesLinks.CAREER
        },
        {
          text: translation.pagesNames.contact,
          path: PagesLinks.CONTACT
        },
        {
          text: translation.pagesNames.videoListing,
          path: PagesLinks.VIDEO_LISTING
        }
      ]
    };
    return { headlineProps, linkColumn };
  }
});

export default DcwFooterLinks;
