/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, ProjectIDs, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: false,
  categories: [JobOfferCategories.FRONTEND],
  projects: [ProjectIDs.LOTTERY_PLATFORM],
  name: 'Senior Vue.js Developer',
  salaryRanges: salaries.vue,
  seniorityLevel: SeniorityLevels.SENIOR,
  referrers: {
    [Referrers.DC_WEBSITE]: 156503310,
    [Referrers.OTHER]: 168486577,
    [Referrers.CARROT_WORK]: 78451162,
    [Referrers.JUST_JOIN_IT]: 78451163,
    [Referrers.NO_FLUFF_JOBS]: 78451561,
    [Referrers.BULLDOG_JOB_PL]: 78451160,
    [Referrers.FACEBOOK_ADS]: 192884954,
    [Referrers.GOOGLE_ADS]: 192884956
  },
  description: [
    {
      title: 'Description',
      text: [
        'At Digital Colliers, we are currently looking for an experienced Vue.js Developer to join our team, and help us develop one of the verticals in our core project - The innovative Lottery SaaS Platform)'
      ]
    },
    {
      title: 'Your Role and Responsibilities',
      items: [
        "Working on an innovative lottery SaaS platform that's made up of a complex ecosystem of web applications",
        'Developing new features, solving technical problems, performing code reviews',
        'At the beginning of our cooperation develop Shopfront - a generic frontend that renders dynamically based on the configuration provided from the backend.'
      ]
    },
    {
      title: 'We’re Looking for Someone Who Has',
      items: [
        'Proactive attitude and approach to solving problems',
        'At least 3 years of experience in developing advanced web applications',
        'In-depth knowledge of Nuxt 2 with at least 1 year of experience',
        'Great knowledge of both Vue 2 and Vue 3, with an emphasis on Composition API',
        'Great Knowledge of Typescript',
        'Experience in a microservice architecture',
        'Willingness to be up to date with the newest technologies',
        'Good English skills (min. B2)'
      ]
    },
    {
      title: 'An Additional Plus will be',
      items: [
        'Experience with GraphQL and Apollo',
        'The ability to write E2E tests using Cypress',
        'The ability to write unit-test using Jest'
      ]
    }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First \binterview\b to know each other and talk about yours and ours expectations'
    },
    {
      title: 'Technical Interview',
      description: 'A short \binterview\b, one-hour technical assessment with ours senior developer'
    },
    {
      title: 'Team Leader Interview',
      description: 'Recruitment Final Stage - interview with your future Team Leader.'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process in one week'
    }
  ],
  technologies: [
    { label: 'Vue.js', level: TechnologyLevels.ADVANCED },
    { label: 'JavaScript', level: TechnologyLevels.ADVANCED },
    { label: 'Composition API', level: TechnologyLevels.ADVANCED },
    { label: 'TypeScript', level: TechnologyLevels.REGULAR },
    { label: 'Nuxt 2', level: TechnologyLevels.REGULAR },
    { label: 'GraphQL', level: TechnologyLevels.NICE_TO_HAVE }
  ]
};
