








import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { isInEnum } from '@/helpers';
import { LabelTypes } from '@/types';

export const DcwLabel = defineComponent({
  name: 'DcwLabel',
  props: {
    type: {
      type: String as PropType<LabelTypes>,
      default: LabelTypes.INFO,
      validator: (value: LabelTypes) => isInEnum(value, LabelTypes)
    },
    text: {
      type: String,
      required: true
    }
  }
});

export default DcwLabel;
