






import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { isInEnum } from '@/helpers';
import { HtmlWrappers, TooltipColors, Directions } from '@/types';
import DcwLink from '@/components/Atoms/Link/DcwLink.vue';

export const DcwTooltip = defineComponent({
  name: 'DcwTooltip',
  components: { DcwLink },
  props: {
    direction: {
      type: String as PropType<Directions>,
      default: Directions.LEFT,
      validator: (value: Directions) => isInEnum(value, Directions)
    },
    color: {
      type: String as PropType<TooltipColors>,
      default: TooltipColors.PRIMARY,
      validator: (value: TooltipColors) => isInEnum(value, TooltipColors)
    },
    path: {
      type: String,
      required: false
    },
    as: {
      type: String as PropType<HtmlWrappers>,
      default: HtmlWrappers.DIV
    }
  }
});

export default DcwTooltip;
