/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, JobOfferCategories, ProjectIDs, TechnologyLevels, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: false,
  categories: [JobOfferCategories.MANAGEMENT],
  projects: [ProjectIDs.LOTTERY_PLATFORM],
  name: 'Product Owner',
  salaryRanges: salaries.productOwner,
  seniorityLevel: SeniorityLevels.SENIOR,
  referrers: {
    [Referrers.DC_WEBSITE]: 175020795,
    [Referrers.OTHER]: 175020794,
    [Referrers.CARROT_WORK]: 175020789,
    [Referrers.JUST_JOIN_IT]: 175020797,
    [Referrers.NO_FLUFF_JOBS]: 175020796,
    [Referrers.BULLDOG_JOB_PL]: 175020790,
    [Referrers.FACEBOOK_ADS]: 192884639,
    [Referrers.GOOGLE_ADS]: 192884644
  },
  description: [
    {
      title: 'Description',
      text: [
        `We are currently looking for a \bProduct Owner\b to join our team, and help us develop the server-side of the Innovative Lottery SaaS Platform.
      In this role, \byou will be working closely both with board members, stakeholders, and our development team\b. Your main responsibility will be to be a kind of \bbridge between the world of business and the world of development\b, collecting stakeholders' requirements, smoothly translating them into technical words and helping developers get them into code.`
      ]
    },
    {
      title: 'Responsibilities',
      items: [
        'Gathering, evaluating, and analyzing \bbusiness\b and \buser requirements\b',
        'Building, maintaining and configuring \bproperly defined and detailed epics & user stories\b that follow product strategy',
        "Maintaining our product's \bbacklog\b and \breleases plan\b",
        'Following \blong-term vision\b for product evolution',
        'Making \bproduct development\b status and \bprogress\b visible to necessary stakeholders and realizing their \bROI\b',
        'Managing project/\bproduct scope\b, schedule, dependencies, communication, and stakeholders',
        "Communicating with \bsolid arguments\b the \bproduct's plans\b, \bissues\b, and \bgeneral insights\b to different stakeholders",
        'Keeping an eye for the \bbigger picture\b',
        '\bClose cooperation\b with \bUX team\b',
        '\bNegotiating\b as well as \bcompromising\b when and where needed',
        '\bRunning sprints\b, scrum rituals',
        'Ensuring that the team is on the right track at all times'
      ]
    },
    {
      title: 'Requirements',
      items: [
        'Direct experience in independent IT product management position, confirmed with \bsuccessful implementation\b',
        'Experience and feel for \bproduct prototyping & UX design\b, enough to manage UX & graphic designers so that our products are user friendly and beautiful',
        'Ability to decompose a \bhigh-level concept into business requirements\b and concrete product backlog',
        'Strong \bproject management skills\b (experience with Agile methodology would be a plus)',
        'Great \binterpersonal and communication skills\b, demonstrated by the ability to communicate project progress and results in a clear and interesting way',
        '\bFluent English\b in speech and writing'
      ]
    }
  ],
  technologies: [
    { label: 'Scrum', level: TechnologyLevels.ADVANCED },
    { label: 'Agile', level: TechnologyLevels.ADVANCED },
    { label: 'English', level: TechnologyLevels.ADVANCED }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First, \bshort interview\b with our Head of Recruitment'
    },
    {
      title: 'Second Call',
      description: 'A \b1-hour\b interview with our Head of Development'
    },
    {
      title: 'Final Stage',
      description: 'Recruitment Final Stage - \binterview\b with Board Members'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process within \b2 weeks\b'
    }
  ]
};
