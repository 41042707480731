



































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwSingleLocation } from '../';
import { DcwAccordion } from '@atoms';
import { FooterContent } from '@/types';

export const DcwFooterLocations = defineComponent({
  name: 'DcwFooterLocations',
  components: {
    DcwSingleLocation,
    DcwAccordion
  },
  props: {
    content: {
      type: Object as PropType<FooterContent['locations']>,
      required: true
    }
  }
});

export default DcwFooterLocations;
