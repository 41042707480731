/* eslint-disable max-len */
import { PagesLinks, TechnologyBannerSectionContent } from '@/types';

export const banner: Pick<TechnologyBannerSectionContent, 'headline' | 'firstButton' | 'secondButton'> = {
  headline: {
    before: 'Grow Your Business With',
    highlight: 'Development',
    after: 'Services',
    subtitle:
      'We provide goal-oriented agile teams of engineers and specialists who create\nbespoke software solutions for SMEs and large enterprises worldwide.'
  },
  firstButton: {
    text: "Let's talk",
    href: PagesLinks.CONTACT
  },
  secondButton: {
    text: 'Join us',
    href: PagesLinks.CAREER
  }
};
