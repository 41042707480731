import NotFoundImg from '@/assets/errors/oooops.svg';
import { PagesLinks, ErrorPage } from '@/types';

export const error404: ErrorPage = {
  subtitle: 'The requested page does not exist',
  description: 'Didn’t find what you were looking for?',
  firstLink: {
    text: 'Back to Home',
    link: PagesLinks.HOME
  },
  secondLink: {
    text: 'Contact us',
    link: PagesLinks.CONTACT
  },
  image: NotFoundImg
};
