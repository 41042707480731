/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: false,
  categories: [JobOfferCategories.BACKEND],
  projects: [],
  name: 'Junior Backend Developer (Java / Kotlin)',
  salaryRanges: salaries.backend,
  seniorityLevel: SeniorityLevels.JUNIOR,
  referrers: {
    [Referrers.DC_WEBSITE]: 78528013,
    [Referrers.OTHER]: 78528010,
    [Referrers.CARROT_WORK]: 168595043,
    [Referrers.JUST_JOIN_IT]: 78528016,
    [Referrers.NO_FLUFF_JOBS]: 168594983,
    [Referrers.BULLDOG_JOB_PL]: 78528014,
    [Referrers.GOOGLE_ADS]: 192832778,
    [Referrers.FACEBOOK_ADS]: 192832775
  },
  description: [
    {
      title: 'Description',
      text: [
        'We are currently looking for a \bBackend Developer\b to join our team, and help us create the server-side of our \binternational project in the lottery and gaming industry\b.',
        "In this Software Engineer role, \byou will be working in a young, dynamic, development team while collaborating with our developers, UI/UX designers, POs, QAs, PM, and works in Scrum\b.\nThe goal of the project is to build an \binnovative eCommerce SaaS platform\b operating in the cloud. It's a \bgreenfield project\b that we build from the ground up.",
        "Our developers are always willing to improve and share their knowledge so, if you are passionate about what you do and you're not afraid of challenges come to us and let's create together game-changer projects. For great development, we expect that you will be sharing your knowledge with our developers too and will become a part of our Digital Colliers Family.",
        '\bYou will work with experienced, committed people who will appreciate you, your skills, and your knowledge and give you support when you need it.\b'
      ]
    },
    {
      title: 'Your Role and Responsibilities',
      items: [
        'Working on a worldwide \bSaaS platform\b',
        '\bDeveloping new features, solving technical problems,\b preparing documentation, performing code reviews, writing code and tests',
        'Striving for \bsuperior quality\b',
        'Taking part in daily standups, team meetings, on and team discussions',
        'Working in an \binternational team\b'
      ]
    },
    {
      title: 'Tech stack',
      items: [
        '\bKotlin\b, Ktor, Koin',
        'GraphQL, REST',
        '\bMicroservices Architecture\b',
        'Kubernetes, Helm, GitLab CI/CD, AWS',
        'MongoDB, RabbitMQ',
        'Kotest, MockK, Pact',
        'Hexagonal Architecture',
        'We are open to new ideas!'
      ]
    }
  ],
  technologies: [
    { label: 'Java', level: TechnologyLevels.JUNIOR },
    { label: 'REST', level: TechnologyLevels.NICE_TO_HAVE },
    { label: 'Kotlin', level: TechnologyLevels.NICE_TO_HAVE },
    { label: 'MongoDB', level: TechnologyLevels.NICE_TO_HAVE },
    { label: 'AWS', level: TechnologyLevels.NICE_TO_HAVE },
    { label: 'Hexagonal Architecture', level: TechnologyLevels.NICE_TO_HAVE }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First, short interview to know each other and talk about yours and ours expectations'
    },
    {
      title: 'Recruitment task',
      description: 'The second step will be a short recruitment task'
    },
    {
      title: 'Tech Interview',
      description: 'The last step is a one-hour call with our developer  '
    },
    {
      title: 'Offer',
      description: 'We can complete the entire process in one week'
    }
  ]
};
