import { useMetaDescription, PageMetaData } from './use-meta-description';
import { StyleSheet, useStyleSheet } from './use-style-sheet';
import { useInjectStrict } from './use-inject-strict';
import { BreadcrumbItem, HeaderColors, Pages } from '@/types';
import { BreadcrumbSetKey, SetBreadcrumbs } from '@/plugins/BreadcrumbContext';
import { HeaderActions, HeaderActionsKey } from '@/plugins/HeaderContext';

interface UsePageProps {
  data?: Pages | PageMetaData;
  breadcrumbs?: BreadcrumbItem[];
  styleSheet?: StyleSheet[];
  headerColor?: HeaderColors;
  mobileHeaderColor?: HeaderColors;
  tabletHeaderColor?: HeaderColors;
}

export const usePage = (props: UsePageProps): void => {
  if (props.breadcrumbs) {
    const setBreadcrumbs = useInjectStrict<SetBreadcrumbs>(BreadcrumbSetKey);
    setBreadcrumbs(props.breadcrumbs);
  }

  if (props.data) {
    useMetaDescription(props.data);
  }

  if (props.styleSheet) {
    useStyleSheet(props.styleSheet);
  }

  // TODO - refactor it, it's a bit ugly and not DRY
  if (props.headerColor || props.mobileHeaderColor || props.tabletHeaderColor) {
    const actions = useInjectStrict<HeaderActions>(HeaderActionsKey);

    if (!actions || !actions.setHeaderColor || !actions.setTabletHeaderColor || !actions.setMobileHeaderColor) {
      return;
    }

    if (props.headerColor) {
      actions.setHeaderColor(props.headerColor);
    }

    if (props.mobileHeaderColor) {
      actions.setMobileHeaderColor(props.mobileHeaderColor);
    }

    if (props.tabletHeaderColor) {
      actions.setTabletHeaderColor(props.tabletHeaderColor);
    }
  }
};
