










import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwBlogPost } from '@molecules';
import { BlogPostWithId } from '@/types';

export const DcwBlogPostList = defineComponent({
  name: 'DcwBlogPostList',
  components: { DcwBlogPost },
  props: {
    list: {
      type: Array as PropType<BlogPostWithId[]>,
      required: true
    }
  }
});

export default DcwBlogPostList;
