import { ref, Ref } from '@nuxtjs/composition-api';

const isBrowserSafariFactory = (): ()=>({isSafari: Ref<boolean>}) => {
  const isSafariCondition = () => (process.browser && !!(navigator.vendor &&
    navigator.vendor.includes('Apple') &&
    navigator.userAgent &&
    !navigator.userAgent.includes('CriOS') &&
    !navigator.userAgent.includes('FxiOS')));

  const isSafari = ref(isSafariCondition());

  if (process.browser) {
    window.addEventListener('load', () => {
      isSafari.value = isSafariCondition();
    });
  }

  return () => ({ isSafari });
};

export const useIsBrowserSafari = isBrowserSafariFactory();
