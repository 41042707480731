import { isFutureDate } from '.';

export const getInitialSlideByDate = (slides: { date: string }[]) => {
  const lastSlide = slides.length - 1;
  let neededIndex = 0;

  for (let index = 0; index <= lastSlide; index += 1) {
    if (index === lastSlide) {
      neededIndex = index;
      break;
    }

    if (isFutureDate(slides[index].date)) {
      neededIndex = index;
      break;
    }
  }

  return neededIndex;
};
