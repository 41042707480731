






























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwButton, DcwSimpleIcon } from '@atoms';
import { ButtonVariants, LangLink } from '@/types';
import ArrowDown from '@/assets/icons/streamline-icons/arrow-down.svg';

export const DcwLangWidget = defineComponent({
  name: 'DcwLangWidget',
  components: { DcwButton, DcwSimpleIcon },
  props: {
    links: {
      type: Array as PropType<LangLink[]>,
      required: true
    }
  },
  setup() {
    return { ButtonVariants, ArrowDown };
  }
});

export default DcwLangWidget;
