/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

export const story: SuccessStory = {
  name: 'Filip',
  videoId: '710732708',
  images: {
    og: '/covers/success-story/story-7.jpg',
    avatar: {
      src: '/success-stories/story-7/avatar.png',
      alt: 'Filip',
      width: 32,
      height: 32
    },
    cover: {
      src: '/success-stories/story-7/cover.jpg',
      alt: 'Filip cover',
      height: 608
    },
    slider: {
      src: '/success-stories/story-7/slider.png',
      alt: 'Filip',
      width: 317,
      height: 224
    },
    listing: {
      src: '/success-stories/story-7/listing.png',
      alt: 'Filip',
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'May 12, 2022',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'UI/UX Designer',
  details: `I wanted to work for a bigger company than before. I was looking for a team with
            experienced graphics that I could learn a lot from especially in terms of their attitude to
            work organization. The thing that bought me completely was however the vibe I felt while
            reading brand stories on JJIT. `,
  shortStory: 'I wanted to work for a bigger company than before. I was looking for a team with experienced graphics.',
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer:
        'I remember the day when on one of the online auction services I saw a graphical tablet for the first time. I didn’t even know what it does, but at that time I was so very excited. I was 12 so my dad took me somewhere to help me buy it from a guy. In the beginning, I wanted to be an illustrator and use the tablet to prepare sketches, Cliparts, and general illustrations for games. Things changed when one of my high school friends set up a software house and during one of the parties asked whether I’d like to help him with the design of some apps and websites. Ever since I decided that’s the path I want to continue. '
    },
    {
      type: 'text',
      title: 'What do you do in DC?',
      answer:
        'Technically I am a UI/UX designer but in fact I would describe myself more as a product designer. Basically, I am responsible for the creation of interfaces, interactions, and information architectures of products. This scope is however enriched by participating in concepts and strategies connected to the product or introducing improvements to the product itself during the course of implementation or along the whole product life cycle. I like the fact that I am also connected to this business layer of products. Being given some business assumptions I have to adjust the visual product to the final user.'
    },
    {
      type: 'text',
      title: 'What did your recruitment process look like? Why did you choose DC?',
      answer:
        'I wanted to work for a bigger company than before. I was looking for a team with experienced graphics that I could learn a lot from especially in terms of their attitude to work organization. The thing that bought me completely was however the vibe I felt while reading brand stories on JJIT. '
    },
    {
      type: 'image',
      details: 'Digital Colliers in Office',
      img: {
        src: '/success-stories/story-7/image-1.png',
        alt: 'Digital Colliers in Office',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.DC_IN_OFFICE
    },
    {
      type: 'text',
      title: 'What have you learned in DC?',
      answer:
        'For sure I’ve learned working with a bigger amount of people. Especially working with a higher number of designers that differ from one another. I like that I have a senior in my team that I can pick up from a lot in terms of work methodology.'
    },
    {
      type: 'text',
      title: 'What was the biggest challenge you had to overcome?',
      answer:
        'Before I started work I thought that the biggest challenge would be communication with English-speaking clients. Even though I feel quite comfortable with my English skills, I was afraid that the technical language would be very challenging. With time it turned out however that working in the IT industry strictly correlated with this language, sometimes in fact makes it easier to explain something in English.'
    },
    {
      type: 'text',
      title: 'Where do you see yourself in 10 years?',
      answer:
        'For sure I would like to work in my current profession but perhaps manage the work of some more people. My idea would also be to teach at the university with some practical elements like workshops. I would be this strict professor in an elegant suit that would initially scare students but after all, turned out to be super cool.'
    },
    {
      type: 'text',
      title: 'How do you see yourself in retirement?',
      answer:
        'It could be either an island around Jamaica, Bali, or Thailand. Another option would be to open agrotourism somewhere like Italy and have alpacas. I would also have a tavern where I could prepare amazing coffee and pizza for the customers that came to visit my alpacas!'
    },
    {
      type: 'video',
      details: 'Interview with Filip',
      thumbnail: {
        src: '/success-stories/story-7/image-2.jpg',
        alt: 'Interview with Filip',
        width: 952,
        height: 404
      },
      videoId: '710732708',
      ytLink: YOUTUBE_LINKS.FILIP_INTERVIEW
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Kendrick Lamar - Money Trees',
          img: {
            src: '/success-stories/story-7/song-1.png',
            alt: 'Kendrick Lamar - Money Trees',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/1w3UeP3t7PswU3KvgaUkav?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=hwR_1EP18eo'
        },
        {
          title: 'Linkin Park - Crawling',
          img: {
            src: '/success-stories/story-7/song-2.png',
            alt: 'Linkin Park - Crawling',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/57BrRMwf9LrcmuOsyGilwr?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=Gd9OhYroLN0'
        },
        {
          title: 'System Of A Down - Toxicity',
          img: {
            src: '/success-stories/story-7/song-3.png',
            alt: 'System Of A Down - Toxicity',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/album/6jWde94ln40epKIQCd8XUh?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=iywaBOMvYLI'
        }
      ]
    }
  ]
};
