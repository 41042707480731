import { Translations } from '../english';
import { pagesNames } from './pagesNames';
import { homePage } from './homePage/index';
import { validations } from './validations';
import { careerPage } from './careerPage';
import { contactPage } from './contactPage';
import { ui } from './ui';
import { errorPages } from './errorPages';
import { buttons } from './buttons';
import { phrases } from './phrases';
import { inputFields } from './inputFields';
import { backButtons } from './backButtons';
import { meta } from './meta';
import { uiComponents } from './uiComponents';
import { technologyLevels } from './technologiesLevels';
import { socialMedia } from './socialMedia';
import { aboutPage } from './aboutPage';
import { terms } from './terms';
import { privacyPolicy } from './privacyPolicy';
import { modals } from './modals';
import { captcha } from './captcha';
import { dataProcessing } from './dataProcessing';
import { teamAugmentationPage } from './teamAugmentationPage';
import { events } from './events';
import { listings } from './listings';
import { helpUA } from './helpUA';
import { videoListing } from './video-listing';

export const deTranslations: Translations = {
  videoListing,
  validations,
  homePage,
  contactPage,
  careerPage,
  pagesNames,
  ui,
  errorPages,
  buttons,
  phrases,
  inputFields,
  backButtons,
  ...meta,
  ...uiComponents,
  technologyLevels,
  socialMedia,
  aboutPage,
  privacyPolicy,
  terms,
  modals,
  teamAugmentationPage,
  dataProcessing,
  captcha,
  events,
  listings,
  helpUA
};

export default deTranslations;
