var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"timetableCardWrapperRef",staticClass:"dcw-timetable-card-wrapper",class:[
    {
      'dcw-timetable-card-wrapper--center-card-first': _vm.calibrateCardToBeInCenter && _vm.eventDot.index === 0,
      'dcw-timetable-card-wrapper--center-card': _vm.calibrateCardToBeInCenter && _vm.eventDot.index !== 0
    }
  ],attrs:{"data-index":_vm.eventDot.index}},[(_vm.data.hasWeAreHiringTooltip)?_c('DcwTooltip',{staticClass:"dcw-timetable-card-wrapper__tooltip",attrs:{"path":_vm.PagesLinks.CAREER,"direction":_vm.Directions.BOTTOM}},[_c('span',[_vm._v(_vm._s(_vm.translation.tooltip.weAreHiring))]),_vm._v(" "),_c('DcwImage',_vm._b({staticClass:"dcw-timetable-card-wrapper__tooltip-emoji"},'DcwImage',_vm.WAVE_EMOJI,false))],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"dcw-timetable-card",class:("dcw-timetable-card--" + _vm.cardVariant)},[_c('div',{staticClass:"dcw-timetable-card__description"},[_c('div',{staticClass:"dcw-timetable-card__labels-wrapper"},_vm._l((_vm.data.labels),function(label,index){return _c('DcwLabel',{key:index,attrs:{"text":label,"type":_vm.LabelTypes.INVERTED_BACKGROUND}})}),1),_vm._v(" "),_c('DcwContentBlock',{class:{ 'dcw-timetable-card--no-buttons': _vm.cardVariant === _vm.TimetableCardVariant.SINGLE_COLUMN },attrs:{"content":_vm.data.content,"spacing":_vm.ContentBlockSpacings.LARGE},scopedSlots:_vm._u([{key:"headline",fn:function(){return [_c('DcwNewHeadline',_vm._b({},'DcwNewHeadline',_vm.data.content,false))]},proxy:true}])})],1),_vm._v(" "),_c('div',{staticClass:"dcw-timetable-card__image-wrapper",class:[
        ( _obj = {}, _obj[("dcw-timetable-card__image-wrapper--" + (_vm.data.imagesLayout))] = _vm.data.imagesLayout !== undefined, _obj ),
        ("dcw-timetable-card__image-wrapper--" + (_vm.data.images.length))
      ]},_vm._l((_vm.data.images),function(image,index){return _c('DcwImage',_vm._b({key:index},'DcwImage',image,false))}),1),_vm._v(" "),_c('client-only',[(_vm.cardVariant === _vm.TimetableCardVariant.SINGLE_COLUMN || _vm.isMobile)?_c('div',{staticClass:"dcw-timetable-card__buttons"},[(_vm.data.content.firstButton || _vm.isMobile)?_c('DcwButton',{attrs:{"text":_vm.primaryButtonText,"variant":_vm.ButtonVariants.PRIMARY,"href":_vm.data.link,"target":_vm.data.content.firstButton && _vm.data.content.firstButton.hasTargetBlank ? '_blank' : false,"rel":_vm.data.content.firstButton && _vm.data.content.firstButton.hasTargetBlank ? 'noopener' : false,"aria-label":_vm.data.content.firstButton && _vm.data.content.firstButton.ariaLabel ? _vm.data.content.firstButton.ariaLabel : '',"is-link":""}}):_vm._e(),_vm._v(" "),(_vm.data.content.secondButton)?_c('DcwButton',{attrs:{"text":_vm.data.content.secondButton.text,"variant":_vm.ButtonVariants.TERTIARY,"href":_vm.data.link,"target":_vm.data.content.secondButton.hasTargetBlank ? '_blank' : false,"rel":_vm.data.content.secondButton.hasTargetBlank ? 'noopener' : false,"aria-label":_vm.data.content.secondButton && _vm.data.content.secondButton.ariaLabel ? _vm.data.content.secondButton.ariaLabel : '',"title":_vm.data.content.secondButton && _vm.data.content.secondButton.ariaLabel ? _vm.data.content.secondButton.ariaLabel : '',"is-link":""}}):_vm._e()],1):_vm._e()])],1),_vm._v(" "),_c('DcwEventDot',{staticClass:"dcw-timetable-card-wrapper__event-dot-progress",attrs:{"data":_vm.eventDot}},[_vm._v("\n    "+_vm._s(_vm.formatDateString(_vm.data.date))+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }