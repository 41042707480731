/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, JobOfferCategories, ProjectIDs, TechnologyLevels, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: true,
  categories: [JobOfferCategories.MANAGEMENT],
  projects: [ProjectIDs.LOTTERY_PLATFORM],
  name: 'Junior Product Owner',
  salaryRanges: salaries.productOwner,
  seniorityLevel: SeniorityLevels.JUNIOR,
  referrers: {
    [Referrers.DC_WEBSITE]: 198376906,
    [Referrers.OTHER]: 198376904,
    [Referrers.CARROT_WORK]: 198376907,
    [Referrers.JUST_JOIN_IT]: 198376901,
    [Referrers.NO_FLUFF_JOBS]: 198376908,
    [Referrers.BULLDOG_JOB_PL]: 198376909,
    [Referrers.FACEBOOK_ADS]: 192832824,
    [Referrers.GOOGLE_ADS]: 192832829
  },
  description: [
    {
      title: 'Description',
      text: [
        `We are currently looking for a \bJunior Product Owner\b to join our team, and help us develop the server-side of the Innovative Lottery SaaS Platform.
        In this role, \byou will be working closely both with board members, stakeholders, and our development team\b. Your main responsibility will be to be a kind of \bbridge between the world of business and the world of development\b, collecting stakeholders requirements, smoothly translating them into technical words and helping developers get them into code.`
      ]
    },
    {
      title: 'Responsibilities',
      items: [
        'Gathering, evaluating, and analyzing \bbusiness\b and \buser requirements\b',
        'Building, maintaining and configuring \bproperly defined\b and \bdetailed epics & user stories\b that follow product strategy',
        "Maintaining our product's \bbacklog\b and \breleases plan\b",
        'Following \blong-term vision\b for product evolution',
        'Making \bproduct development status\b and \bprogress\b visible to necessary stakeholders and realizing their \bROI\b',
        'Managing project/\bproduct scope\b, schedule, dependencies, communication, and stakeholders',
        "Communicating with \bsolid arguments\b the \bproduct's plans\b, \bissues\b, and \bgeneral insights\b to different stakeholders",
        'Keeping an eye for the \bbigger picture\b',
        '\bClose cooperation\b with \bUX team\b',
        '\bNegotiating\b as well as \bcompromising\b when and where needed',
        '\bRunning sprints\b, scrum rituals',
        'Ensuring that the team is on the right track at all times'
      ]
    },
    {
      title: 'We’re looking for someone who has',
      items: [
        'The First experience in an independent IT product management position',
        'Feel for \bproduct prototyping & UX design\b, enough to manage UX & graphic designers so that our products are user friendly and beautiful',
        'Ability to decompose a \bhigh-level concept into business requirements\b and concrete product backlog',
        'Strong \bproject management skills\b (experience with Agile methodology would be a plus)',
        'Great \binterpersonal and communication skills\b, demonstrated by the ability to communicate project progress and results in a clear and interesting way',
        '\bFluent English\b in speech and writing'
      ]
    }
  ],
  technologies: [
    { label: 'JIRA', level: TechnologyLevels.JUNIOR },
    { label: 'Scrum', level: TechnologyLevels.JUNIOR },
    { label: 'Agile', level: TechnologyLevels.JUNIOR },
    { label: 'Product Management', level: TechnologyLevels.JUNIOR }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First, \bshort interview\b with our Specialist'
    },
    {
      title: 'Second Call',
      description: 'A \b1-hour\b interview with an experienced Product Owner whom you will work closely with'
    },
    {
      title: 'Final Stage',
      description: 'Recruitment Final Stage - \binterview\b with Board Members'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process within \b2 weeks\b'
    }
  ]
};
