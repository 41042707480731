import { offers } from './jobOffers';
import { projects } from './projects';
import { oneOfUs } from './oneOfUs';
import { hero } from './banner';
import { careerPath } from './careerPath';
import { benefits } from './benefits';
import { successStories } from './successStories';
import { remoteWork } from './remoteWork';
import { integrations } from './integrations';
import { born2Solve } from './born2Solve';
import { events } from './events';
import { hws } from './hws';

export const careerPage = {
  projects,
  offers,
  oneOfUs,
  hero,
  careerPath,
  benefits,
  successStories,
  remoteWork,
  born2Solve,
  integrations,
  events,
  hws
};
