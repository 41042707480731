/* eslint-disable max-len */
import { Testimonial } from '@/types';

const logoSize = { width: 162, height: 56 };
const avatarSize = { width: 54, height: 54 };

export const testimonials: Testimonial[] =
  [
    {
      author: {
        name: 'Mateusz Michalak',
        position: 'Business Manager at devopsbay',
        avatar: {
          src: '/reviews/reviews-1.png',
          alt: 'Mateusz Michalak photo',
          ...avatarSize
        }
      },
      logo: {
        src: '/about/testimonial/devopsbay.png',
        alt: 'Devopsbay',
        ...logoSize
      },
      link: '',
      rating: 4.8,
      content:
        'Outstaffing with Digital Colliers helped us build a new, strong development team in a professional manner with a responsive turnaround time.'
    },
    {
      author: {
        name: 'Paula Petit',
        position: 'CCO at Linum Labs',
        avatar: {
          src: '/reviews/reviews-2.png',
          alt: 'Paula Petit photo',
          ...avatarSize
        }
      },
      logo: {
        src: '/about/testimonial/linumlabs.png',
        alt: 'Linum Labs',
        ...logoSize
      },
      link: '',
      rating: 4.8,
      content:
        "Their expertise and team's experience elevated our cooperation to the next level, which allowed us to create a complete and refined product."
    },
    {
      author: {
        name: 'Bartłomiej Pasella',
        position: 'Technical Director at Glimat',
        avatar: {
          src: '/reviews/reviews-4.png',
          alt: 'Bartłomiej Pasella photo',
          ...avatarSize
        }
      },
      logo: {
        src: '/about/testimonial/glimat.png',
        alt: 'Glimat',
        ...logoSize
      },
      link: 'https://clutch.co/profile/digital-colliers#review-1703480',
      rating: 5,
      content: 'They had an apparent picture of what we needed to achieve and always suggested several thoughtful solutions.'
    },
    {
      author: {
        name: 'Grzegorz Ziółkowski',
        position: 'Owner of CT Group',
        avatar: {
          src: '/reviews/reviews-5.png',
          alt: 'Grzegorz Ziółkowski photo',
          ...avatarSize
        }
      },
      logo: {
        src: '/about/testimonial/ct-group.png',
        alt: 'CT Group',
        ...logoSize
      },
      link: 'https://clutch.co/profile/digital-colliers#review-1733403',
      rating: 4.5,
      content:
        "We needed an application that would manage all of the daily assignments to better organize the cooperation and improve the received orders' distribution. They did it."
    },
    {
      author: {
        name: 'Andrew Keese',
        position: 'Founder of Silver Vine',
        avatar: {
          src: '/reviews/reviews-6.png',
          alt: 'Andrew Keese photo',
          ...avatarSize
        }
      },
      logo: {
        src: '/about/testimonial/silver-vine.png',
        alt: 'Silver vine',
        ...logoSize
      },
      link: 'https://clutch.co/profile/digital-colliers#review-1747542',
      rating: 5,
      content:
        'They demonstrated foresight, planning, and a high level of technical understanding combined with excellent customer service and great communication skills.'
    },
    {
      author: {
        name: 'Amaury Joseph Masbernat',
        position: 'CEO at AMW Health Investment',
        avatar: {
          src: '/reviews/reviews-7.png',
          alt: 'Amaury Joseph Masbernat photo',
          ...avatarSize
        }
      },
      logo: {
        src: '/about/testimonial/amw.png',
        alt: 'AMW Health Investment',
        ...logoSize
      },
      link: '',
      rating: 4.8,
      content:
        "At AMW, we don't own a development department, and that's where Digital Colliers came in. Simple processes, a strong HR team, and a generous pool of specialists gave us a complete team in a few weeks."
    },
    {
      author: {
        name: 'Fabien Muller',
        position: 'Manager at Ricoh Schweiz AG',
        avatar: {
          src: '/reviews/reviews-8.png',
          alt: 'Fabien Muller photo',
          ...avatarSize
        }
      },
      logo: {
        src: '/about/testimonial/ricoh.png',
        alt: 'Ricoh Schweiz AG',
        ...logoSize
      },
      link: '',
      rating: 4.8,
      content:
        'Digital Colliers is like an extension of our team. They have helped us make huge strides on our solutions and achieve a core system that we can depend on.'
    }
  ];
