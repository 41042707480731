import { modelService } from './model';
import { offers as allOffers } from '@/translations/offers';
import { ProjectIDs, JobOfferWithId } from '@/types';

const service = () => {
  const { content: offers, getContentById: getOfferById, getOtherContent: getOtherOffers } = modelService(allOffers)();

  const getOffersByProjectId = (projectId: ProjectIDs): JobOfferWithId[] =>
    offers.filter((offer) => offer.projects.includes(projectId));

  return { offers, getOfferById, getOtherOffers, getOffersByProjectId };
};

export const offersService = service();
