/* eslint-disable max-len */
import { TextPageContent } from '@/types';

export const helpUA: TextPageContent = {
  title: 'HELPING UKRAINE',
  metaDescription:
    'This page has been created as a guide to the actions we can do to help Ukraine survive the military attack from Russia. Doesn’t matter where we live and how much resources or time we want to allocate - we can always help.',
  sections: [
    {
      title: 'Donations',
      header: { before: 'Donations' },
      id: 'section1',
      html: `
      <p>
        A few links to charity fundraisings:
      </p>

      <ul>
        <li>
          <strong>(EUR/USD)</strong> <a href="http://bityl.pl/D9FzB">National Bank of Ukraine</a> - direct funding of Ukrainian army
        </li>
        <li>
          <strong>(USD)</strong> <a href="http://bityl.pl/a9SUg">UA Hospitals</a> - supporting hospitals in Ukraine
        </li>
        <li>
          <strong>(EUR)</strong> <a href="http://bityl.pl/TiHZd">Army SOS</a> - citizen initiative of Ukrainian Army support
        </li>
        <li>
          <strong>(PLN/USD/EUR)</strong> <a href="http://bityl.pl/QUpX3">Sunflower of Peace</a> - tactical medicine for Ukrainian Army
        </li>
        <li>
          <strong>(PLN/USD/EUR)</strong> <a href="http://bityl.pl/50pti">Nova Ukraine</a> - humanitarian support for Ukraine
        </li>
        <li>
          <strong>(EUR/USD)</strong> <a href="http://bityl.pl/yCSqj">Voices of Children</a> - help to overcome war trauma for children
        </li>
        <li>
          <strong>(PLN)</strong> <a href="http://bityl.pl/rLBUy">SiePomaga.pl</a> - biggest fundraising site in Poland, help for Ukrainian war victims
        </li>
        <li>
          <strong>(PLN)</strong> <a href="http://bityl.pl/jHSzR">Polish Medical Mission</a> - direct medical help in Ukraine
        </li>
      </ul>
      `
    },
    {
      title: 'Showing support',
      header: { before: 'Showing support' },
      id: 'section2',
      html: `
      <p>
        Those small actions will help to show whole society and world that we are not indifferent to Ukraine’s situation and Russian aggression:
      </p>
      <ul>
        <li>
          You can participate in rallies and demonstrations organized in your <a href="http://bityl.pl/aCfHR">cities</a>
        </li>
        <li>
          Write to your local, national, and EU politicians and representatives. Demand harsh personal sanctions against Russian oligarchs and regime representatives, as well as harsh national-wide sanctions against Russia.
        </li>
        <li>
          Engage your family, friends and followers on social media to participate in charity actions on local and national level.
        </li>
        <li>
          Add a Ukrainian flag mask to your facebook account profile pic.
        </li>
        <li>
          Don't make generalizations about people behind the situation - remember that not every Russian citizen is supporting Russian government actions.
        </li>
      </ul>`
    },
    {
      title: 'Spreading reliable information',
      header: { before: 'Spreading reliable information' },
      id: 'section3',
      html: `
      <p>
        We are not going to tell you what sources you should follow as it's partially connected to political preferences. We want, however, to point your attention to how important it is to follow <strong>reliable and unbiased information</strong> that will <strong>prevent spreading unnecessary panic</strong>.
      </p>

      <p>
        Here are a few of our propositions:
      </p>
      <ul>
        <li>
          <a href="http://bityl.pl/ibtfH">Ukrinform</a>
        </li>
        <li>
          <a href="http://bityl.pl/rMi48">Radio Free Europe. Radio Liberty</a>
        </li>
      </ul>`
    },
    {
      title: 'Charity collection',
      header: { before: 'Charity collection' },
      id: 'section4',
      html: `
      <h3>
        SEND A PACKAGE
      </h3>

      <ol>
        <li>
          <strong>items needed:</strong> basic necessities + used items (clean/washed and in very good condition)
        </li>
        <li>
          <strong>when:</strong> so far indefinite, please check the source link before sending
        </li>
        <li>
          <strong>address:</strong>
          <address>
            Roger Publishing, <br>
            ul. Wał Miedzeszyński 208 A, HALA C <br>
            O4-866 Warszawa <br>
            Poland <br>
            tel. +48 604 766 664
          </address>
          <a href="http://bityl.pl/1SNrJ">source</a>
        </li>
      </ol>

      <h3>
        OR <br>
        visit collection point in Poland
      </h3>

      <p>
        <strong>
          Attention! Please check the information on the given links on an ongoing basis. Unfortunately, in many Polish cities, collections took place this weekend and we are waiting for information about the next actions
        </strong>
      </p>

      <h3>
        <a href="http://bityl.pl/1SNrJ">
        Warsaw
        </a>
      </h3>

      <ul>
        <li>
          <strong>collection point:</strong> Roger Publishing, Wał Miedzeszyński 208 A, HALA C
        </li>
        <li>
          <strong>hours:</strong> 6AM to 9PM
        </li>
        <li>
          <strong>items needed:</strong>
          Basic necessities and used items in very good condition that are clean, washed so that they can be used straight away
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/sA7ZE">
        Cracow
        </a>
      </h4>

      <ul>
        <li>
          <strong>collection point:</strong> Stadion Miejski im. Henryka Reymana, Reymonta 22
        </li>
        <li>
          <strong>hours:</strong> 12PM to 8PM
        </li>
        <li>
          <strong>items needed:</strong>
          Thermal blanket, sleeping bags, sleeping mats,  mattresses, clothing, raincoats, hygiene and care products, shower gels and soaps, toothpastes, toothbrushes, hairbrushes, man, woman and child underwear, sanitary napkins, baby and adult diapers, kitchen rolls, microfiber towels, reusable dishes: deep plates, spoons, forks, knives,  mugs, antiseptics, facemasks, bottled water,
          ready/easy to cook meals, energy bars, dried fruits, nuts, canned food, noodle, instant oats/flakes, matches, batteries, lighters, candles, medical kits

        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/Q3Oh4">
          Wroclaw
        </a>
      </h4>

      <ul>
        <li>
          <strong>collection point:</strong> every public elementary school
        </li>
        <li>
          <strong>hours:</strong> same as opening hours
        </li>
        <li>
          <strong>items needed:</strong>
          Sleeping bags, blankets, clothes for adults and children, raincoats, hygiene and care products, toothpastes,toothbrushes, hairbrushes, underwear, diapers, kitchen rolls, candles, batteries, lighters
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/57jZB">
          Katowice
        </a>
      </h4>

      <ul>
        <li>
          <strong>collection point:</strong> Biuro Platformy Obywatelskiej, 3 Maja 17/2, (first floor)
        </li>
        <li>
          <strong>hours:</strong> mon-fri, 9AM to 5PM
        </li>
        <li>
          <strong>items needed:</strong>
          Sleeping bags, thermal blankets, linen, mattresses (new), clothes, raincoats, man, woman and child underwear, cleaning products, children and adult diapers,  sanitary napkins, towels, toothbrushes, hair brushes, kitchen rolls, plates, cutlery (preferably single use), facemasks, antiseptics, bottled water, ready and canned food, noodles, dried fruits, energy bars, instant oats/flakes, matches, batteries, flashlights, candles, medical kits
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/qwMY7">
          Łódź
        </a>
      </h4>

      <ul>
        <li>
          <strong>collection point:</strong> Urząd Miasta Łodzi, Piotrkowska 104
        </li>
        <li>
          <strong>hours:</strong> mon-fri, 8AM to 4PM, sat,sun 9AM to 3PM
        </li>
        <li>
          <strong>items needed:</strong>
          Sleeping bags, thermal blankets, linen, mattresses (new), clothes, raincoats, man, woman and child underwear, cleaning products, children and adult diapers,  sanitary napkins, towels, toothbrushes, hair brushes, kitchen rolls, plates, cutlery (preferably single use), facemasks, antiseptics, bottled water, ready and canned food, noodles, dried fruits, energy bars, instant oats/flakes, matches, batteries, flashlights, candles, medical kits
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/YNqH5">
          Bydgoszcz
        </a>
      </h4>

      <ul>
        <li>
          <strong>collection point:</strong> Biuro Polski Czerwony Krzyż Bydgoszcz,. Warmińskiego 10
        </li>
        <li>
          <strong>hours:</strong> change every monday (see source for up to date ones)
        </li>
        <li>
          <strong>items needed:</strong>
          Dressing materials, gloves and masks, long-term food, cosmetics (toothpaste, shower gels, hair shampoo), diapers, sanitary napkins, cleaning products, new quartermaster equipment, sheets, sleeping bags, camp-beds, blankets, new towels
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/yKEQL">
          Gliwice
        </a>
      </h4>

      <ul>
        <li>
          <strong>collection point:</strong> Caritas Diecezji Gliwickiej, Ziemowita 2
        </li>
        <li>
          <strong>hours:</strong> mon-fri, 8AM to 6PM
        </li>
        <li>
          <strong>items needed:</strong>
          blankets, sleeping bags, pillows, duvets, cleaning products, band aids, dressings, syringes, needles, cold packs, infusion fluids, disinfectants, painkillers etc.
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/rfN0k">
          Gdańsk
        </a>
      </h4>

      <ul>
        <li>
          <strong>collection point:</strong> Politechnika Gdańska “Misiówka” (building number 14 on the campus), Narutowicza 11/12
        </li>
        <li>
          <strong>hours:</strong> from 7:30AM everyday
        </li>
        <li>
          <strong>items needed:</strong>
          Sleeping bags, blankets, sheets, duvets, pillows, plates, mugs, glasses, cutlery, electric kettles, diapers and wet tissues, kitchen rolls, toilet papers, hygiene and care products ( sanitary napkins, tampons, hair shampoo, liquid soap, toothpaste etc..), long-term food (noodles, rice, groats, vegetable oils etc.)
        </li>
      </ul>
      `
    },
    {
      title: 'Hosting Ukrainian families',
      header: { before: 'Hosting Ukrainian families' },
      id: 'section5',
      html: `
      <p>
        If you have the capacity and want to host Ukrainian families you can contact the Crisis Intervention Point in Cracow that provides temporary shelter for immigrants from the east border.
      </p>

      <p>
        Fill in this
        <a href="https://docs.google.com/.../1FAIpQLScxvtXtMYPBGA.../viewform">questionnaire</a>
        or call directly:
        <a href="tel:722297725">722 297 725</a>.
      </p>

      <a href="http://bityl.pl/9fOZT">source</a>

      <h3>
        THANK YOU IN ADVANCE FOR EVERYTHING! <br>
        STAY SAFE!
      </h3>
      `
    },
    //
    {
      title: 'Darowizny',
      header: { before: 'Darowizny' },
      id: 'section6',
      html: `
      <p>
        Kilka linków do zbiórek charytatywnych w formie finansowej:
        </p>

        <ul>
        <li>
        <strong>(PLN)</strong> <a href="http://bityl.pl/rLBUy">SiePomaga.pl</a> - największa zbiórka w Polsce, pomoc ofiarom wojny
        </li>
        <li>
          <strong>(PLN)</strong> <a href="http://bityl.pl/jHSzR">Polska Misja Medyczna</a> - bezpośrednia pomoc medyczna na Ukrainie
        </li>
        <li>
          <strong>(PLN/USD/EUR)</strong> <a href="http://bityl.pl/QUpX3">Słonecznik Pokoju</a> - medyczne plecaki taktyczne pierwszej pomocy dla ratowników medycznych i lekarzy na pierwszej linii frontu
        </li>
        <li>
          <strong>(PLN/USD/EUR)</strong> <a href="http://bityl.pl/50pti">Nova Ukraine</a> - pomoc humanitarna dla Ukrainy
        </li>
        <li>
          <strong>(EUR/USD)</strong> <a href="http://bityl.pl/D9FzB">Narodowy Bank Ukrainy</a> - bezpośrednie wsparcie Armii Ukraińskiej
        </li>
        <li>
          <strong>(USD)</strong> <a href="http://bityl.pl/a9SUg">UA Hospitals</a> - wsparcie Ukraińskich szpitali
        </li>
        <li>
          <strong>(EUR)</strong> <a href="http://bityl.pl/TiHZd">Army SOS</a> - obywatelska inicjatywa wsparcia Armii Ukraińskiej
        </li>
        <li>
          <strong>(EUR/USD)</strong> <a href="http://bityl.pl/yCSqj">Voices of Children</a> - pomoc dzieciom w przezwyciężeniu traumy wojennej
        </li>
      </ul>
      `
    },
    {
      title: 'Okazywanie wsparcia',
      header: { before: 'Okazywanie wsparcia' },
      id: 'section7',
      html: `
      <p>
        Te proste czynności pomogą całemu społeczeństwu i pokażą światu brak obojętności wobec sytuacji na Ukrainie i sprzeciw wobec działań Rosji:
      </p>
      <ul>
        <li>
          Możesz uczestniczyć w protestach i demonstracjach organizowanych w Twoim <a href="http://bityl.pl/aCfHR">mieście</a>
        </li>
        <li>
          Napisz do władz lokalnych, państwowych oraz Twoich reprezentantów na szczeblu Unijnym i zarządaj surowych sankcje personalnych wobec rosyjskich oligarchów i przedstawicieli reżimu, a także surowych sankcji ogólnonarodowych wobec Rosji.
        </li>
        <li>
          Zaangażuj swoją rodzinę, bliskich oraz obserwujących Twoje social media w uczestniczenie w pomocy na szczeblu lokalnym oraz państwowym.
        </li>
        <li>
          Ustaw na mediach społecznościowych takich jak Facebook maskę na zdjęcie profilowe z Ukraińską flagą.
        </li>
        <li>
          Nie ulegaj generalizacji wobec ludzi stojących za obecną sytuacją - pamiętaj, że nie wszyscy Rosyjscy obywatele popierają działania Rosyjskich liderów i władz.
        </li>
      </ul>`
    },
    {
      title: 'Rozpowszechnianie rzetelnych informacji',
      header: { before: 'Rozpowszechnianie rzetelnych informacji' },
      id: 'section8',
      html: `
      <p>
        Nie zamierzamy nikomu narzucać doboru źródła informacji ze względu na neutralność polityczną. Chcielibyśmy zwrócić jednak uwagę na znaczenie śledzenia wyłącznie rzetelnych źródeł informacji. Zapobiegnie to rozpowszechnianiu niepotrzebnej paniki.
      </p>

      <p>
      Tutaj znajdziesz kilka naszych propozycji:
      </p>
      <ul>
        <li>
          <strong>(PL)</strong> <a href="http://bityl.pl/tOeNg">Tygodnik Powszechny: Relacja Pawła Pieniążka</a>
        </li>
        <li>
          <strong>(PL)</strong> <a href="http://bityl.pl/oEWF8">Gazeta Wyborcza</a>
        </li>
        <li>
          <strong>(PL)</strong> <a href="http://bityl.pl/5P5jP">Instagram: lukaszbok_kiks</a>
        </li>
        <li>
          <strong>(ENG)</strong> <a href="http://bityl.pl/ibtfH">Ukrinform </a>
        </li>
        <li>
          <strong>(ENG)</strong> <a href="http://bityl.pl/rMi48">Radio Free Europe. Radio Liberty</a>
        </li>
        <li>
          <strong>(ENG)</strong> <a href="http://bityl.pl/MuFHa">Censor.net</a>
        </li>
        <li>
          <strong>(ENG)</strong> <a href="http://bityl.pl/C6EfN">Twitter: Illia Ponomarenko</a>
        </li>
      </ul>`
    },
    {
      title: 'Zbiórki charytatywne',
      header: { before: 'Zbiórki charytatywne' },
      id: 'section9',
      html: `
      <h3>
        WYŚLIJ PACZKĘ
      </h3>

      <ol>
        <li>
          <strong>potrzebne są:</strong>
          artykuły pierwszej potrzeby + przedmioty używane (czyste/wyprane i w bardzo dobrym stanie)
        </li>
        <li>
          <strong>godziny:</strong> do odwołania, najlepiej sprawdzać informacje w linku źródłowym
        </li>
        <li>
          <strong>adres:</strong>
          <address>
            Roger Publishing, <br>
            ul. Wał Miedzeszyński 208 A, HALA C <br>
            O4-866 Warszawa <br>
            tel. +48 604 766 664
          </address>
          <a href="http://bityl.pl/1SNrJ">source</a>
        </li>
      </ol>

      <h3>
        LUB <br>
        odwiedź punkt odbioru w swoim pobliskim mieście
      </h3>

      <p>
        <strong>
          Uwaga! Prosimy na bieżąco sprawdzać informacje w podanych linkach. W wielu polskich miastach zbiórki odbyły się w miniony ten weekend. Czekamy więc na informacje o kolejnych akcjach.
        </strong>
      </p>

      <h4>
        <a href="http://bityl.pl/1SNrJ">
          WARSZAWA
        </a>
      </h4>

      <ul>
        <li>
          <strong>miejsce zbiórki:</strong> Roger Publishing, Wał Miedzeszyński 208 A, HALA C
        </li>
        <li>
          <strong>godziny:</strong> od poniedziałku 28.02.2022 w godzinach 6:00-21:00
        </li>
        <li>
          <strong>potrzebne są:</strong>
          produkty pierwszej potrzeby + rzeczy używane w bardzo dobrym stanie, czyste, wyprane, tak by ich przeznaczenie nie wyklucza ponownego użycia przez inną osobę
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/sA7ZE">
          KRAKÓW
        </a>
      </h4>

      <ul>
        <li>
          <strong>miejsce zbiórki:</strong> Stadion Miejski im. Henryka Reymana, Reymonta 22
        </li>
        <li>
          <strong>godziny:</strong> codziennie w godzinach 12 - 20 (do odwołania)
        </li>
        <li>
          <strong>potrzebne są:</strong>
          koce termiczne, śpiwory, maty do spania (z pokryciem foliowym), materace, ubrania, peleryny przeciwdeszczowe, środki pielęgnacyjne i higieniczne, środki myjące do ciała, pasty do zębów, szczoteczki do zębów, szczotki do włosów, bielizna damska, bielizna męska, bielizna dziecięca, podpaski, pieluchy dziecięce, pieluchy dla dorosłych, ręczniki papierowe, ręczniki z mikrofibry, zestaw wielorazowych naczyń kuchennych: głębokie naczynia, łyżki, widelce, noże, kubki, środki antyseptyczne/spirytus, maseczki wielorazowe, woda, produkty żywnościowe nadające się do szybkiego przygotowania, batony energetyczne, suszone owoce, orzechy, żywność puszkowana, makarony, płatki błyskawiczne, zapałki, baterie, zapalniczki, świece, zestawy medyczne
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/Q3Oh4">
          WROCŁAW
        </a>
      </h4>

      <ul>
        <li>
          <strong>miejsce zbiórki:</strong> każda wrocławska szkoła państwowa
        </li>
        <li>
          <strong>godziny:</strong> takie same jak otwarcia szkoły
        </li>
        <li>
          <strong>potrzebne są:</strong>
          śpiwory, koce, odzież dla dzieci i dorosłych, płaszcze przeciwdeszczowe, środki higieny, osobistej, pasty do zębów, szczoteczki do zębów, szczotki do włosów, bielizna, pieluchy, ręczniki papierowe, świece, baterie, zapalniczki
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/57jZB">
          KATOWICE
        </a>
      </h4>

      <ul>
        <li>
          <strong>miejsce zbiórki:</strong> Biuro Platformy Obywatelskiej, 3 Maja 17/2, (first floor)
        </li>
        <li>
          <strong>hours:</strong> od poniedziałku do piątku w godz. 9:00 - 17:00
        </li>
        <li>
          <strong>potrzebne są:</strong>
          koce termiczne, śpiwory, pościel, materace (w opakowaniach), ubrania, płaszcze przeciwdeszczowe, bieliznę damską, męską i dziecięcą, środki czystości, pieluchy dla dzieci i dorosłych, podpaski, ręczniki, szczotki i szczoteczki, ręczniki papierowe, talerze, sztućce, najlepiej biodegradowalne, sylikonowe lub plastikowe, maseczki, środki antyseptyczne, wodę butelkowaną, dania gotowe i w puszkach, makarony, suszone owoce, batony energetyczne, płatki śniadaniowe, zapałki, baterie, latarki, świece,zestawy wyposażenia medycznego
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/qwMY7">
          ŁÓDŹ
        </a>
      </h4>

      <ul>
        <li>
          <strong>miejsce zbiórki:</strong> Urząd Miasta Łodzi, Piotrkowska 104
        </li>
        <li>
          <strong>hours:</strong> dni robocze 8 - 16, w soboty i w niedziele 9 - 15
        </li>
        <li>
          <strong>potrzebne są:</strong>
          koce termiczne, śpiwory, pościel, materace (w opakowaniach), ubrania, płaszcze przeciwdeszczowe, bieliznę damską, męską i dziecięcą, środki czystości, pieluchy dla dzieci i dorosłych, podpaski, ręczniki, szczotki i szczoteczki, ręczniki papierowe, talerze, sztućce, najlepiej biodegradowalne, sylikonowe lub plastikowe, maseczki, środki antyseptyczne, woda butelkowana, dania gotowe i w puszkach, makarony, suszone owoce, batony energetyczne, płatki śniadaniowe, zapałki, baterie, latarki, świece, zestawy wyposażenia medycznego
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/YNqH5">
          BYDGOSZCZ
        </a>
      </h4>

      <ul>
        <li>
          <strong>miejsce zbiórki:</strong> Biuro Polski Czerwony Krzyż Bydgoszcz,. Warmińskiego 10
        </li>
        <li>
          <strong>hours:</strong> w pn powinien pojawić się post z godzinami na następny tydzień
        </li>
        <li>
          <strong>potrzebne są:</strong>
          materiały opatrunkowe, rękawiczki i maseczki jednorazowe, żywność długoterminową, kosmetyki, pieluchy, podpaski, pasty do zębów itd.), środki czystości, nowy sprzęt kwatermistrzowski, prześcieradła, śpiwory, łóżka polowe, koce, nowe ręczniki
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/yKEQL">
          GLIWICE
        </a>
      </h4>

      <ul>
        <li>
          <strong>miejsce zbiórki:</strong> Caritas Diecezji Gliwickiej, Ziemowita 2
        </li>
        <li>
          <strong>hours:</strong> od poniedziałku do piątku w godz. 8.00-18.00
        </li>
        <li>
          <strong>potrzebne są:</strong>
          koce, śpiwory, poduszki, kołdry, środki czystości wszelkiego rodzaju, opatrunki, bandaże, strzykawki, igły, kompresy, płyny infuzyjne, dezynfekujące, środki przeciwbólowe itp.
        </li>
      </ul>

      <h4>
        <a href="http://bityl.pl/rfN0k">
          GDAŃSK
        </a>
      </h4>

      <ul>
        <li>
          <strong>miejsce zbiórki:</strong> Politechnika Gdańska “Misiówka” (building number 14 on the campus), Narutowicza 11/12
        </li>
        <li>
          <strong>hours:</strong> od godziny 7.30 ,w każdy dzień od poniedziałku do piątku
        </li>
        <li>
          <strong>potrzebne są:</strong>
          śpiwory, koce, pościele, kołdry, poduszki, talerze, kubki, szklanki, sztućce, czajniki elektryczne, pieluszki i chusteczki nawilżane dla dzieci, ręczniki papierowe, papier toaletowy, środki higieny osobistej (podpaski, tampony, szampony, mydło w płynie, pasty do zębów itp.), jedzenie z długim terminem żywności (makaron, ryż, kasza, oleje itp.)
        </li>
      </ul>
      `
    },
    {
      title: 'Goszczenie Ukraińskich rodzin',
      header: { before: 'Goszczenie Ukraińskich rodzin' },
      id: 'section10',
      html: `
      <p>
        Jeśli masz możliwość przyjąć do siebie jedną z Ukraińskich rodzin, możesz skontaktować się z Punktem Interwencji Kryzysowej w Krakowie, który zapewnia tymczasowe schronienie dla imigrantów ze wschodniej granicy.
      </p>

      <p>
        Wypełnij
        <a href="https://docs.google.com/.../1FAIpQLScxvtXtMYPBGA.../viewform">ten formularz</a>
        lub zadzwoń bezpośrednio:
        <a href="tel:722297725">722 297 725</a>.
      </p>

      <a href="http://bityl.pl/9fOZT">source</a>

      <h3>
        Z GÓRY DZIĘKUJEMY ZA OKAZANĄ POMOC! <br>
        SŻYCZYMY WSZYSTKIM BEZPIECZEŃSTWA I POMYŚLNOŚCI!
      </h3>
      `
    }
  ]
};
