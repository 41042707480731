import { ErrorPage, PagesLinks } from '@/types';
import WrongImg from '@/assets/errors/oh_noo.svg';

export const error500: ErrorPage = {
  subtitle: 'Le serveur a répondu avec une erreur',
  description: 'Vous n\'avez pas trouvéé ce que vous recherchiez?',
  firstLink: {
    text: 'Retour à l\'accueil',
    link: PagesLinks.HOME
  },
  secondLink: {
    text: 'Rejoignez-nous et réparez-le',
    link: PagesLinks.JOBS
  },
  image: WrongImg
};
