export enum HeadlineVariants {
  LIGHT = 'light',
  BLUE = 'blue',
  DARK = 'dark'
}

export enum HeadlineColor {
  LIGHT = 'light',
  BLUE = 'blue',
  DARK = 'dark'
}

export enum HeadlineSubtitleSizes {
  NO = 'no',
  EXTRA_SMALL = 'extra-small',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export enum HeadlineTypes {
  P = 'p',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6'
}
