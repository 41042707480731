import { useContext } from '@nuxtjs/composition-api';
import { LangLink, Locales } from '@/types';
import { enTranslations, Translations } from '@/translations/english';
import { deTranslations } from '@/translations/german';
import { frTranslations } from '@/translations/french';

interface UseLocales {
  translation: Translations;
  locale: Locales;
  domain: string;
  links: LangLink[];
}

export const TRANSLATIONS: Record<Locales, Translations> = {
  [Locales.EN]: enTranslations,
  [Locales.DE]: deTranslations,
  [Locales.FR]: frTranslations
};

const DOMAINS = {
  [Locales.EN]: process.env.DOMAIN_EN,
  [Locales.DE]: process.env.DOMAIN_DE,
  [Locales.FR]: process.env.DOMAIN_FR
};

export const useLocales = (): UseLocales => {
  const context = useContext();
  const locale = context.i18n.locale as Locales;

  const domain = DOMAINS[locale];
  if (!domain) {
    throw new Error('No domain detected for selected locale');
  }

  const links = [
    {
      text: Locales.EN.toUpperCase(),
      href: 'https://' + process.env.DOMAIN_EN,
      locale: Locales.EN,
      isCurrent: locale === Locales.EN
    },
    {
      text: Locales.DE.toUpperCase(),
      href: 'https://' + process.env.DOMAIN_DE,
      locale: Locales.DE,
      isCurrent: locale === Locales.DE
    },
    {
      text: Locales.FR.toUpperCase(),
      href: 'https://' + process.env.DOMAIN_FR,
      locale: Locales.FR,
      isCurrent: locale === Locales.FR
    }
  ];

  return {
    translation: TRANSLATIONS[locale],
    locale,
    domain,
    links
  };
};
