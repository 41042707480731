/* eslint-disable @typescript-eslint/no-var-requires */
import { ProjectIDs, Project } from '../../types';

export const projects = {
  get [ProjectIDs.STOREFRONT](): Project {
    return require('./headless-storefront-sdk-for-gaming').project;
  },
  get [ProjectIDs.LOTTERY_PLATFORM](): Project {
    return require('./innovative-lottery-saas-platform').project;
  },
  get [ProjectIDs.MONITORING_PLATFORM](): Project {
    return require('./predictive-monitoring-platform').project;
  },
  get 'ecosystem-for-social-finance-solution'(): Project {
    return require('./ecosystem-for-social-finance-solution').ecoSystemForSocialFinanceSolution;
  },
  get [ProjectIDs.PAYMENT_PLATFORM](): Project {
    return require('./payment-orchestration-platform').project;
  },
  get [ProjectIDs.HEALTH_PLATFORM](): Project {
    return require('./digital-healthcare-platform').project;
  }
  // get [ProjectIDs.AUTOMATED_EXCHANGE](): Project {
  //   return require('./automated-exchange').project;
  // }
};
