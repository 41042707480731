/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

export const story: SuccessStory = {
  name: 'Jan',
  images: {
    og: '/covers/success-story/story-5.jpg',
    avatar: {
      src: '/success-stories/story-5/avatar.png',
      alt: 'Jan',
      width: 32,
      height: 32
    },
    cover: {
      src: '/success-stories/story-5/cover.jpg',
      alt: 'Jan cover',
      height: 608
    },
    slider: {
      src: '/success-stories/story-5/slider.png',
      alt: 'Jan',
      width: 317,
      height: 224
    },
    listing: {
      src: '/success-stories/story-5/listing.png',
      alt: 'Jan',
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'Aug 7, 2021',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'Business Manager',
  details: `My first contact with Digital Colliers was at a networking event in Tenerife. After that,
    we stayed in touch and started developing some ideas for the way we could collaborate.
    For me, the reasons why I prefer DC over other companies are relatively young but still an
    ultra-competent team and the ability to work remotely. `,
  shortStory: 'I decided to do yet another internship this time in an agency developing mobile applications.',
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer:
        "I'm used to traveling quite a lot. At one point in time, I had an opportunity to do an internship in Singapore where I practiced sales and marketing skills. After that, I decided to take up university where my major was International Management. At the end of my studies, I decided to do yet another internship this time in an agency developing mobile applications. That's how I got into the IT industry and then I kept on studying and eventually, I met Digital Colliers."
    },
    {
      type: 'text',
      title: 'What do you do in DC?',
      answer:
        'In Digital Colliers I work as a sales and development specialist. I am directly responsible for company growth so I try to find business opportunities that could be new customers, interesting conferences, or investment paths.'
    },
    {
      type: 'text',
      title: 'What did your recruitment process look like? Why did you choose DC?',
      answer:
        'My first contact with Digital Colliers was at a networking event in Tenerife. After that, we stayed in touch and started developing some ideas for the way we could collaborate. For me, the reasons why I prefer DC over other companies are relatively young but still an ultra-competent team and the ability to work remotely. I love that I can live anywhere on the planet and not have to worry about my job.'
    },
    {
      type: 'image',
      details: 'Bologna integration trip',
      img: {
        src: '/success-stories/story-5/image-1.png',
        alt: 'Bologna integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.BOLOGNA_INTEGRATION_TRIP
    },
    {
      type: 'text',
      title: 'What is the learning method that suits you best?',
      answer:
        'I study a lot at university and surprisingly it works for me. I have studied at many universities and I always take courses that are in my interest. At the moment they are mainly IT-related. Apart from that, I attend many conferences where people are able to exchange their knowledge and experience from a very practical point of view. '
    },
    {
      type: 'text',
      title: 'What new things have you learned in DC?',
      answer:
        'One thing would be how to operate in an agile team that is operating completely remote but still being efficient. Second is gaining a lot of knowledge not only in the area of sales but also in terms of the IT industry and software development in general. '
    },
    {
      type: 'text',
      title: 'Where do you see yourself in 10 years?',
      answer:
        "I am pretty sure it's gonna be the IT industry. My ultimate goal is to contribute to creating a better society where we digitize many processes and make people’s lives easier."
    },
    {
      type: 'image',
      details: 'Żywiec integration trip',
      img: {
        src: '/success-stories/story-5/image-2.png',
        alt: 'Żywiec integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.ZYWIEC_INTEGRATION_TRIP
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Milky Chance - Road Tripping Radio ',
          img: {
            src: '/success-stories/story-5/song-1.png',
            alt: 'Milky Chance - Road Tripping Radio ',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/playlist/6ZL1jiHon1fEASt5NQBvq9',
          ytLink: 'https://www.youtube.com/watch?v=Q3b3igd3zUg&ab_channel=MilkyChanceOfficial'
        },
        {
          title: 'O.A.R. - Heard The World',
          img: {
            src: '/success-stories/story-5/song-2.png',
            alt: 'O.A.R. - Heard The World',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/album/1ZijxBukKTeyPeKAPzRLrl?highlight=spotify:track:6ZzTiTso9R5CICos7UbSfA',
          ytLink: 'https://www.youtube.com/watch?v=QU2bC07gq6E'
        },
        {
          title: 'Tash Sultana - Jungle',
          img: {
            src: '/success-stories/story-5/song-3.png',
            alt: 'Tash Sultana - Jungle',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/album/4r2ptyNGtHhPSMvjUusNPY',
          ytLink: 'https://www.youtube.com/watch?v=Vn8phH0k5HI'
        }
      ]
    }
  ]
};
