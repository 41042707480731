





import { defineComponent } from '@nuxtjs/composition-api';
import DcwSimpleIcon from '../SimpleIcon/DcwSimpleIcon.vue';
import CrossMark from '@/assets/icons/streamline-icons/cross-mark.svg';

export const DcwCloseButton = defineComponent({
  name: 'DcwCloseButton',
  components: { DcwSimpleIcon },
  props: {
    size: {
      type: [String, Number],
      default: 18
    }
  },
  emits: ['close-button-clicked'],
  setup() {
    return { CrossMark };
  }
});

export default DcwCloseButton;
