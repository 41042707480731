import { LocaleBannerSectionContent, ScheduleModalPeople } from '@/types';

export const localeBanner: LocaleBannerSectionContent = {
  headline: {
    before: 'Wir arbeiten im Moment an der',
    highlight: ' deutschen Version',
    after: 'der Website...',
    subtitle: 'Wenn Sie mit uns auf Deutsch sprechen möchten, können Sie einen Termin mit dem CTO ausmachen.'
  },
  buttonText: 'Termin vereinbaren',
  person: {
    images: {
      mobile: {
        src: '/locales/de/banner-mobile.png',
        alt: 'on the photo Szymon Nowak, Chief Technology Officer',
        width: 88,
        height: 88
      },
      desktop: {
        src: '/locales/de/banner.png',
        alt: 'on the photo Szymon Nowak, Chief Technology Officer',
        width: 389,
        height: 501
      }
    },
    data: {
      before: 'Szymon Nowak',
      subtitle: 'Chief Technology Officer'
    },
    modal: ScheduleModalPeople.szymonN
  }
};
