import Vue from 'vue';

Vue.directive('safari-overflow-container', {
  bind: (element, bind, vnode) => {
    if (vnode.context?.$device.isIos || vnode.context?.$device.isSafari) {
      if (bind.value) {
        element.classList.add(bind.value);
        return;
      }

      element.classList.add('overflow-safari-container');
    }
  }
});
