/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
/* This is Example Service on which you should base all other service implementations.
The whole folder should be removed when we will have 2-3 services implemented. */
import axios from 'axios';
import { sendEmail } from './send-email';

const axiosInstance = axios.create();
const API_MAIL = '/api/mail';

export const emailServiceFactory = () => {
  return {
    sendEmail: sendEmail.bind(null, axiosInstance, API_MAIL)
  };
};
