








































import { defineComponent, toRef, watch } from '@nuxtjs/composition-api';
import { DcwFormCheckbox, DcwLink, DcwFormButton } from '@atoms';
import { useInjectStrict, useLocales } from '@/composables';
import {
  CloseScheduleModal,
  PagesLinks,
  RequestStatus,
  ScheduleMeetingFormData,
  UpdateScheduleMeetingFormData,
  CustomEvents,
  PersonData,
  DataProcessingSectionsId
} from '@/types';
import {
  ScheduleMeetingFormDataKey,
  UpdateScheduleMeetingFormKey,
  ScheduleMeetingRequestStatusKey,
  CloseScheduleModalKey,
  ScheduleMeetingPersonDataKey
} from '@/consts';

export const DcwScheduleModalFooter = defineComponent({
  name: 'DcwScheduleModalFooter',
  components: {
    DcwFormCheckbox,
    DcwLink,
    DcwFormButton
  },
  setup() {
    const { translation } = useLocales();
    const formData = useInjectStrict<Readonly<ScheduleMeetingFormData>>(ScheduleMeetingFormDataKey);
    const updateFormData = useInjectStrict<UpdateScheduleMeetingFormData>(UpdateScheduleMeetingFormKey);
    const status = useInjectStrict<RequestStatus>(ScheduleMeetingRequestStatusKey);
    const closeModal = useInjectStrict<CloseScheduleModal>(CloseScheduleModalKey);
    const personData = useInjectStrict<Readonly<PersonData>>(ScheduleMeetingPersonDataKey);

    const terms = toRef(formData, 'terms');

    watch(terms, () => {
      updateFormData('terms', terms.value)(formData);
    });

    return {
      terms,
      PagesLinks,
      status,
      translation,
      RequestStatus,
      closeModal,
      CustomEvents,
      personData,
      DataProcessingSectionsId
    };
  }
});

export default DcwScheduleModalFooter;
