export const YOUTUBE_LINKS = Object.freeze({
  BOLOGNA_INTEGRATION_TRIP: 'https://vimeo.com/671092459',
  ZAKOPANE_INTEGRATION_TRIP: 'https://vimeo.com/615553876',
  ZYWIEC_INTEGRATION_TRIP: 'https://vimeo.com/615544783',
  LONDON_TECH_EVENT: 'https://vimeo.com/725255719',
  PABIANICE_INTEGRATION_TRIP: 'https://vimeo.com/732454129',
  CHRISTMAS: 'https://vimeo.com/671099894',
  DC_IN_OFFICE: 'https://vimeo.com/683803397',
  FILIP_INTERVIEW: 'https://vimeo.com/710732708',
  BARTOSZ_INTERVIEW: 'https://vimeo.com/710734352',
  WOJCIECH_INTERVIEW: 'https://vimeo.com/710735110'
});
