import { person as bartoszC } from './bartosz-c';
import { person as karolS } from './karol-s';
import { person as leopoldG } from './leopold-g';
import { person as lukaszS } from './luasz-s';
import { person as maciejT } from './maciej-t';
import { person as szymonM } from './szymon-m';
import { person as szymonN } from './szymon-n';
import { person as wiktorS } from './wiktor-s';
import { person as janE } from './jan-e';
import { person as dominikP } from './dominik-p';
import { person as wojciechC } from './wojciech-c';
import { person as wojciechK } from './wojciech-k';
import { person as przemyslawD } from './przemyslaw-d';

export const people = {
  bartoszC,
  karolS,
  leopoldG,
  lukaszS,
  maciejT,
  szymonM,
  szymonN,
  wiktorS,
  janE,
  dominikP,
  wojciechC,
  wojciechK,
  przemyslawD
};
