/* eslint-disable max-len */
import { Project, TechnologyNames, ProjectSectionsId } from '@/types';

export const ecoSystemForSocialFinanceSolution: Project = {
  name: 'Social Finance Ecosystem Solution',
  color: '#4DAF74',
  colors: {
    heroBg: '#EDF7F1',
    light: '#CAE7D5',
    primary: '#4DAF74'
  },
  description:
    'The project is focused on building one of three platforms of a bigger ecosystem that will allow freelancers to acquire financing, and build their businesses from the ground up.',
  badges: ['30+ Engineers', '4 Teams'],
  ctaText: {
    career: {
      before: 'Junior, Mid and Senior Positions',
      highlight: 'Up\xA0to\xA030\xA0000\xA0PLN\xA0+\xA0VAT'
    },
    home: {
      before: 'We built 4 strong development teams for',
      highlight: 'German Lottery'
    }
  },
  categories: [TechnologyNames.REACT, TechnologyNames.REACT_NATIVE, TechnologyNames.NODE_JS, TechnologyNames.PYTHON],
  isRemote: true,
  isNew: true,
  technologies: {
    frontend: [
      TechnologyNames.REACT,
      TechnologyNames.REACT_NATIVE,
      TechnologyNames.TYPESCRIPT,
      TechnologyNames.JAVASCRIPT,
      TechnologyNames.GRAPH_QL
    ],
    backend: [
      TechnologyNames.NODE_JS,
      TechnologyNames.PYTHON,
      TechnologyNames.MONGO_DB,
      TechnologyNames.DOCKER,
      TechnologyNames.AWS
    ]
  },
  fields: {
    salary: {
      label: 'net B2B',
      value: 'Up to 30 000 PLN'
    },
    client: {
      label: 'Client',
      value: 'USA'
    },
    industry: {
      label: 'Industry',
      value: ['Banking', 'Commerce']
    },
    status: {
      label: 'Project phase',
      value: 'MVP'
    },
    duration: {
      label: 'Duration',
      value: 'Long Term'
    },
    projectStatus: {
      label: 'Working Location',
      value: '100% Remote'
    }
  },
  images: {
    main: {
      src: '/projects/ecosystem-for-social-finance-solution/project.png',
      alt: 'Social Finance Ecosystem Solution',
      width: 871,
      height: 438
    },
    preview: {
      src: '/projects/ecosystem-for-social-finance-solution/preview.png',
      alt: 'Social Finance Ecosystem Solution preview',
      width: 1074,
      height: 626
    }
  },
  sections: {
    description: {
      id: ProjectSectionsId.DESCRIPTION,
      title: 'Project Description',
      headline: {
        before: 'Project Description'
      },
      html: `
        <p>
          Digital technologies have done more than just speed up business, as usual, they have shifted the rules of the
          <br/>
          game — from scarcity to sharing, centralized to decentralized, and closed to open networks. Unfortunately,
          <br/>
          today's banks and their financial offerings were <strong>purpose-built</strong> for an industrial economy rulebook that
          <br/>
          no longer applies.
        </p>
        <p>
          Nowadays, more than <strong>300 million active freelancers</strong> – growing to as many as <strong>2 billion</strong> by 2035 – power the
          <br/>
          global, digital, on-demand economy. We intend to <strong>create a bank</strong> that works for them and will revolutionize
          <br/>
          the industry.
        </p>
        `
    },
    technologies: {
      id: ProjectSectionsId.TECHNOLOGIES,
      title: 'Tech Stack',
      headline: {
        before: 'Tech Stack'
      },
      bg: {
        src: '/projects/ecosystem-for-social-finance-solution/bg.png',
        width: 335,
        height: 320,
        alt: 'decorative image'
      },
      description: {
        before: 'Technology',
        subtitle: `For this project, we have chosen \bMERN Stack\b and \bmicroservice\b
        architecture. The MERN architecture allows you to easily construct
        a \b3-tier architecture\b (frontend, backend, database) entirely using
        \bJavaScript\b and \bJSON\b. It works great because we can keep
        service up and running quickly with minimum effort and create a
        very \bscalable pipeline\b for data processing and \bdata visualization\b.`
      },
      frontend: {
        headline: {
          before: 'Frontend',
          subtitle: `\bReact's\b strong suit is handling stateful, \bdata-driven\b interfaces with minimal code and minimal
            pain, and it has all the bells and whistles you'd expect from a modern web framework.`
        }
      },
      backend: {
        headline: {
          before: 'Backend',
          subtitle: `\bNode.js\b as a runtime environment for \bserver-side JavaScript\b along with \bExpress.js\b framework
            used to write a server app that will run on Node and connect it to with React frontend.`
        }
      }
    },
    howWeWork: {
      id: ProjectSectionsId.HOW_WE_WORK,
      title: 'How do We Work',
      headline: {
        before: 'How do We Work'
      },
      html: `
      <p>
        We've have established a great <strong>remote-first</strong> work culture, working as a team, bringing together developers
        <br/>
        from all over the world, while still focusing on inclusivity and progress. When it comes to our work style, we
        <br/>
        follow a <strong>goal-driven</strong> approach. The team holds <strong>daily scrum</strong> meetings to plan their work for the next day in
        <br/>
        order to maximize the chances of achieving the sprint goal.
      </p>
      `
    },
    challenges: {
      id: ProjectSectionsId.CHALLENGES,
      title: 'Challenges',
      headline: {
        before: 'Challenges That You Will Meet'
      },
      html: `
        <ul>
          <li>
            Working on an innovative Fin-Tech App that's made up of a complex ecosystem of web applications deployed on AWS.
          </li>
          <li>
            Develop web applications to fulfill specific business needs and requirements
          </li>
          <li>
            Striving for the superior quality of the product.
          </li>
          <li>
            Participate in the project in Agile methodology and conform to coding standards including GIT workflow.
          </li>
          <li>
            Sharing your ideas, experience, and take a voice in discussions on technical and business matters
          </li>
        </ul>
      `
    },
    team: {
      id: ProjectSectionsId.TEAM,
      title: 'Meet Our Team',
      headline: { before: 'Meet Our Team' },
      members: [
        {
          img: {
            src: '/projects/ecosystem-for-social-finance-solution/team/member-1.png',
            alt: 'Image of Mateusz',
            width: 236,
            height: 224
          },
          name: 'Mateusz',
          position: 'Senior Frontend Developer'
        },
        {
          img: {
            src: '/projects/ecosystem-for-social-finance-solution/team/member-2.png',
            alt: 'Image of Hubert',
            width: 236,
            height: 224
          },
          name: 'Hubert',
          position: 'Mid Frontend Developer'
        },
        {
          img: {
            src: '/projects/ecosystem-for-social-finance-solution/team/member-3.png',
            alt: 'Image of Marek',
            width: 236,
            height: 224
          },
          name: 'Marek',
          position: 'Senior Backend Developer'
        }
      ]
    },
    openPositions: {
      id: ProjectSectionsId.OPEN_POSITIONS,
      title: 'Open Positions',
      headline: {
        before: 'Open Positions'
      }
    },
    othersProjects: {
      id: ProjectSectionsId.OTHERS_PROJECTS,
      title: 'Other Projects',
      headline: {
        before: 'Choose From Other',
        highlight: 'Great Projects'
      }
    }
  }
};
