export enum HtmlWrappers {
  SECTION = 'section',
  ARTICLE = 'article',
  FOOTER = 'footer',
  HEADER = 'header',
  SPAN = 'span',
  DIV = 'div',
  OL = 'ol',
  UL = 'ul',
  LI = 'li',
  A = 'a'
}

export enum HtmlInlineElements {
  P = 'p',
  A = 'a',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6'
}
