import { PagesLinks, Breakpoints, BannerWithVideoContent } from '@/types';

export const hero: BannerWithVideoContent = {
  headline: {
    before: "Let's Create the Digital\xA0Future",
    subtitle: 'Become a member of our growing team. We welcome the very best talents.'
  },
  videoId: '671092459',
  button: { text: 'See available jobs', href: PagesLinks.JOBS, isLink: true },
  video: {
    width: 2016,
    height: 720,
    thumbnail: {
      src: '/career/hero/new/thumbnail-career.jpg',
      noThumbnail: true
    },
    alt: 'First frame of the career banner video',
    srcset: [
      { width: 0, src: ['/career/hero/new/video-768.webm', '/career/hero/new/video-768.mp4'] },
      { width: Breakpoints.SMALL, src: ['/career/hero/new/video-1025.webm', '/career/hero/new/video-1025.mp4'] },
      { width: Breakpoints.MEDIUM, src: ['/career/hero/new/video-1792.webm', '/career/hero/new/video-1792.mp4'] }
    ]
  }
};
