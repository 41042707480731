













import { defineComponent } from '@nuxtjs/composition-api';
import { DcwCloseButton, DcwModal, DcwVimeoPlayer } from '@atoms';

export const DcwVideoModal = defineComponent({
  name: 'DcwVideoModal',
  components: { DcwModal, DcwVimeoPlayer, DcwCloseButton },
  props: {
    isShown: {
      type: Boolean,
      default: false
    },
    videoId: {
      type: String,
      required: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    isCloseButtonVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close-modal']
});

export default DcwVideoModal;
