import { safeJsonParse } from './safe-json-parse';
import { LocalStorage, ReferrerEntry } from '@/types';

export const getReferrer = () => {
  const millisecondsInHour = 1000 * 60 * 60;

  const entry = localStorage.getItem(LocalStorage.REFERRER);
  if (!entry) { return null; }

  const referrer = safeJsonParse<ReferrerEntry>(entry, (obj): obj is ReferrerEntry =>
    typeof obj === 'object' && obj !== null && typeof obj.name === 'string' && typeof obj.timestamp === 'number'
  );
  if (!referrer || Date.now() - referrer.timestamp > millisecondsInHour) { return null; }

  return referrer.name;
};
