/* eslint-disable max-len */
import { people } from '../people';
import { ContactSectionContent } from '@/types';

const imageSize = {
  height: 224,
  width: 293
};

export const contact: ContactSectionContent = {
  headline: {
    before: 'Contact via',
    highlight: 'E-mail',
    subtitle:
      "We're excited to see your interest in partnering with us, leave us a message and we'll get back to you as soon as possible."
  },
  formHeading: {
    before: 'Send Us a',
    highlight: 'Message'
  },
  people: {
    heading: {
      before: 'Make an',
      highlight: 'Appointment'
    },
    cards: {
      first: {
        model: people.wiktorS,
        text: "Don't hesitate to get in touch with our recruiting team. We will be happy to assist you.",
        image: {
          ...imageSize,
          src: '/contact/contact/image-1.png',
          alt: 'Wiktor Stefański smiling',
          noThumbnail: true
        }
      },
      second: {
        model: people.lukaszS,
        text: 'I would be glad to discuss your requirements and potential business opportunities.',
        image: {
          ...imageSize,
          src: '/contact/contact/image-2.png',
          alt: 'Lukasz Sobieraj smiling',
          noThumbnail: true
        }
      }
    }
  }
};
