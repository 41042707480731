/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, ProjectIDs, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: true,
  categories: [JobOfferCategories.FRONTEND],
  projects: [ProjectIDs.LOTTERY_PLATFORM],
  name: 'Junior Vue Developer',
  salaryRanges: salaries.vue,
  seniorityLevel: SeniorityLevels.JUNIOR,
  referrers: {
    [Referrers.JUST_JOIN_IT]: 78451217,
    [Referrers.OTHER]: 78451220,
    [Referrers.FACEBOOK_ADS]: 192832754,
    [Referrers.GOOGLE_ADS]: 192832757,
    [Referrers.DC_WEBSITE]: 192885462
  },
  description: [
    {
      title: 'Description',
      text: [
        "We are a Cracow-based software house that provides web development services to customers worldwide. We are currently looking for a \bJunior Frontend Developer\b to join a project that we develop for our client from Germany. The goal of the project is to build an innovative eCommerce SaaS platform operating in the cloud. It's a \bgreenfield project\b that we build from the ground up. The platform will be a complex ecosystem of web applications. Our team has a wide range of responsibilities in the project including \bbuilding\b both \bfrontend\b and \bbackend\b of all applications, \bdesigning\b the \bUI\b and branding of the product, defining business requirements as well as doing market research and preparing concepts. The team consists of frontend developers, backend developers, UI/UX designers, POs, QAs, PM and works in \bScrum\b.",
        "\bAs a member of our team\b, we expect from you basic general knowledge of web development and, ideally, \bminimum 1 year of commercial experience\b in building \bmodern web applications\b. Your duties will include bug-fixing and developing new features. You'll be supported and mentored by experienced colleagues. We'll fully support your development path and will help you identify both your potential and areas for improvement. Most importantly, we expect from you eagerness and willingness to learn and grow. We're open especially to long-term cooperation and, ideally, we'd like to have you on board for the years to come."
      ]
    },
    {
      title: 'Challenge',
      items: [
        "Working on a \bworldwide SaaS platform\b that's made up of a complex \becosystem of web applications\b",
        '\bGreenfield\b project built from the ground up',
        'Developing \bnew features\b, solving \btechnical problems\b, preparing \bdocumentation\b, performing \bcode reviews\b, writing \bclean code\b and \btests\b',
        'Striving for \bsuperior quality\b',
        'Taking part in \bdaily standups, team meetings\b, and \bteam discussions\b',
        'Working in a modern collaborative development environment',
        'Working in an \binternational team\b in an \bEnglish-speaking environment\b (proficient English is a must)'
      ]
    },
    {
      title: 'Tech Stack',
      items: [
        'Vue.js, Composition API, Vuex',
        'Microfrontends Architecture',
        'TypeScript',
        'Sass',
        'GraphQL and REST',
        'GitLab CI/CD, AWS',
        'We are open to new ideas!'
      ]
    },
    {
      title: 'What we offer',
      items: [
        'Stable and long-term cooperation',
        'Work in a \bflat-structure company\b with a \byoung team\b and \bcasual atmosphere\b',
        'Work in an \binternational team\b in the spirit of \bAgile\b',
        'Work under the supervision of our specialists',
        "Ability to demonstrate one's \bown initiative\b and discuss proposed ideas",
        'Work in our \boffice\b in the very \bmain square in Cracow\b or \bfully-remotely\b',
        '\bFlexible\b full-time \bworking hours\b with respect to your work-life balance',
        '\b20 days of paid vacation\b on B2B and UZ',
        'A wide range of benefits and perks (see the following section)'
      ]
    }
  ],
  technologies: [
    { label: 'JavaScript', level: TechnologyLevels.REGULAR },
    { label: 'Vue.js', level: TechnologyLevels.REGULAR },
    { label: 'TypeScript', level: TechnologyLevels.JUNIOR },
    { label: 'HTML', level: TechnologyLevels.JUNIOR },
    { label: 'CSS', level: TechnologyLevels.JUNIOR }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'A \b20 minutes\b call with our recruiter'
    },
    {
      title: 'Recruitment Task',
      description: 'Recruitment task for check the candidate’s knowledge of the technologies'
    },
    {
      title: 'Dev Call',
      description: 'One-hour technical assessment with'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process in \b1 week\b'
    }
  ]
};
