import { offices } from '../offices';
import { FooterContent } from '@/types';

export const footer: FooterContent = {
  title: 'Agile Engineering Teams for World-Leading Product Companies and Corporations',
  copy: '© 2021 Digital Colliers Sp. z o.o. All rights reserved.',
  companyDetails: {
    name: 'Digital Colliers Sp. z o.o.',
    email: 'info@digitalcolliers.com',
    address: {
      firstLine: 'Konarskiego 18c',
      secondLine: '44-100 Gliwice, Poland'
    },
    taxiIdentifiers: {
      vatId: 'EU VAT ID PL9691635555',
      krs: 'KRS ID 0000768764'
    }
  },
  locations: {
    title: 'Find Us Here',
    list: [
      offices.cracow,
      offices.warsaw,
      offices.gliwice,
      offices.paris,
      offices.hamburg,
      offices.berlin,
      offices.vienna,
      offices.toronto
    ]
  }
};
