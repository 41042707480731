export enum TechnologyColors {
  ANGULAR = '#FF5151',
  JAVA = '#EC5E5E',
  KOTLIN = '#C757B9',
  VUE = '#41B883',
  REACT = '#42C9E8',
  NODE = '#539E43',
  DOT_NET = '#008ED3',
  FLUTTER = '#6ECFF8',
  DEFAULT = '#4368EA',
  SOLIDITY = '#1C1C1C'
}
