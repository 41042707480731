/* eslint-disable max-len */
import { videos } from '../../videos';
import { VideoListingIntegrationsContent } from '@/types';

export const integrations: VideoListingIntegrationsContent = {
  headline: {
    highlight: 'Company',
    after: 'Trips',
    subtitle: `Doing business, making connections, generating leads, and… retreating together.
      Press “play” and join us on our company trips.`
  },
  videos: [
    {
      ...videos.integrationPabianic,
      description: {
        before: 'Office, Golf & Casino',
        subtitle: 'Pabianice, Poland'
      }
    },
    {
      ...videos.integrationCracow,
      description: {
        before: 'Christmas Eve With Digital Colliers Family',
        subtitle: 'Kraków, Poland '
      }
    },
    {
      ...videos.integrationItaly,
      featured: true,
      description: {
        before: 'Digital Colliers in Bologna, Italy ',
        subtitle: 'Italy'
      }
    },
    {
      ...videos.integrationTatra,
      featured: true,
      description: {
        before: 'Company Integration in the Tatra Mountains!',
        subtitle: 'Tatra Mountains, Poland'
      }
    },
    {
      ...videos.integrationBeskids,
      featured: true,
      description: {
        before: '3 Days Off in the Beskids, Żywiec',
        subtitle: 'Beskids, Poland'
      }
    },
    {
      ...videos.integrationLondon,
      featured: true,
      description: {
        before: 'Tech Event in heart of London',
        subtitle: 'London, UK'
      }
    }
  ]
};
