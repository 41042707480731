/* eslint-disable max-len */
import { pagesNames } from '../pagesNames';
import { testimonials } from '../ui/testimonials';
import { PagesLinks, ReviewsSectionContent } from '@/types';

export const reviews: ReviewsSectionContent = {
  headline: {
    before: 'What ',
    highlight: 'Our Clients',
    after: 'Say',
    subtitle: `We believe that a closer understanding of the customers needs defines a successful cooperation. See for yourself how
      our clients feel about our work culture.`
  },
  button: {
    text: pagesNames.contact,
    href: PagesLinks.CONTACT
  },
  slides: testimonials
};
