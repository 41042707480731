import { CareerBenefitsSectionContent } from '@/types';

export const benefits: CareerBenefitsSectionContent = {
  headline: {
    before: 'We Give',
    highlight: 'More Benefits'
  },
  items: [
    {
      text: 'Innovative &\nGreenfield Projects',
      icon: {
        src: '/common/benefits/benefit-1.png',
        alt: 'Greenfield Projects',
        width: 48,
        height: 48
      }
    },
    {
      text: '100% Remote\nWork Scheme',
      icon: {
        src: '/common/benefits/benefit-2.png',
        alt: '100% Remote',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Conferences &\nBusiness Trips',
      icon: {
        src: '/common/benefits/benefit-3.png',
        alt: 'Conferences and Business Trips',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Flexible\nWorking Hours',
      icon: {
        src: '/common/benefits/benefit-4.png',
        alt: 'Flexible working hours',
        width: 48,
        height: 48
      }
    },
    {
      text: 'High\nSalary',
      icon: {
        src: '/common/benefits/benefit-5.png',
        alt: 'High Salary',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Personal Office\nDevice Equipment',
      icon: {
        src: '/common/benefits/benefit-6.png',
        alt: 'Personal equipment',
        width: 48,
        height: 48
      }
    },
    {
      text: '20 Days of\nPaid Vacation',
      icon: {
        src: '/common/benefits/benefit-7.png',
        alt: 'paid vacations',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Multisport &\nSocial Activities',
      icon: {
        src: '/common/benefits/benefit-9.png',
        alt: 'Multisport',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Training &\nMentoring',
      icon: {
        src: '/common/benefits/benefit-10.png',
        alt: 'Mentoring',
        width: 48,
        height: 48
      }
    },
    {
      text: 'International\nDiverse Team',
      icon: {
        src: '/common/benefits/benefit-11.png',
        alt: 'International Team',
        width: 48,
        height: 48
      }
    },
    {
      text: 'International\nIntegration Trips',
      icon: {
        src: '/common/benefits/benefit-12.png',
        alt: 'Integration Trips',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Structured Career\nProgression',
      icon: {
        src: '/common/benefits/benefit-13.png',
        alt: 'Structured Career Progression',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Custom Slack\nEmoji Icon',
      icon: {
        src: '/common/benefits/benefit-14.png',
        alt: 'Custom Prersonal Emoji icon',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Shared Offices\nAround the World',
      icon: {
        src: '/common/benefits/benefit-15.png',
        alt: 'Chair icon',
        width: 48,
        height: 48
      }
    }
  ]
};
