/* eslint-disable max-len */
import { CompanyEvent, CompanyEventGalleryGrid } from '@/types';

const twoInBoxRes = {
  width: 168,
  height: 306
};

const personImage = {
  width: 355,
  height: 368
};

const bigInRowRes = {
  width: 336,
  height: 153
};

const oneImageRes = {
  width: 336,
  height: 306
};

const masonrySmallRes = {
  width: 163,
  height: 153
};

export const common: CompanyEvent[] = [
  {
    date: '2020-11-01',
    category: ['People'],
    headline: {
      before: `Paweł Kozera
      Joined Our Team`,
      subtitle: `I got interested in business when
      I got an opportunity to participate
      in a 3 year business project in
      high school.`
    },
    button: {
      href: '/career/success-stories/pawel-6',
      text: 'Read Success Story'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.STORY,
      images: [
        {
          ...personImage,
          alt: 'Paweł Kozera Joined Our Team',
          src: '/events/common/event-1/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-12-19',
    category: ['Event', 'Company'],
    headline: {
      before: 'DC Support\nSzlachetna Paczka',
      subtitle:
        'During Christmas, we decided to support the Szlachetna Paczka initiative We collected some money and helped to pack things.'
    },
    button: {
      href: 'https://www.facebook.com/DigitalColliers/posts/228875082246001',
      text: 'See post on Facebook'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.THREE_MASONRY,
      images: [
        {
          ...bigInRowRes,
          alt: 'DC Support Szlachetna Paczka',
          src: '/events/common/event-2/image-3.png'
        },
        {
          ...masonrySmallRes,
          alt: 'DC Support Szlachetna Paczka',
          src: '/events/common/event-2/image-1.png'
        },
        {
          ...masonrySmallRes,
          alt: 'DC Support Szlachetna Paczka',
          src: '/events/common/event-2/image-2.png'
        }
      ]
    }
  },
  {
    date: '2021-01-02',
    category: ['Event', 'Business'],
    headline: {
      before: 'We Have Started\nManaging New App',
      subtitle: `Our potential goal for successive years is to create an Innovative Lottery SaaS platform that will be
      a game changer for the industry.`
    },
    button: {
      href: '/career/projects/innovative-lottery-saas-platform',
      text: 'Project description'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'We Have Started Managing New App',
          src: '/events/common/event-3/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-01-10',
    category: ['Charity', 'Company'],
    headline: {
      before: 'Mountain Climbing \n for Charity',
      subtitle:
        'Digital Colliers decided to join the annual charity fundraising in Poland! We climbed Kasprowy and thus donated 1987 PLN to WOŚP.'
    },
    button: {
      href: 'https://www.instagram.com/digitalcolliers/',
      text: 'Instagram'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.TWO_IN_ROWS,
      images: [
        {
          ...bigInRowRes,
          alt: 'Szymon & Łukasz on top of Kasprowy Wierch',
          src: '/events/common/event-4/image-1.png'
        },
        {
          ...bigInRowRes,
          alt: 'Szymon & Łukasz Climbed K1 for WOŚP',
          src: '/events/common/event-4/image-2.png'
        }
      ]
    }
  },
  {
    date: '2021-01-27',
    category: ['People'],
    headline: {
      before: `Kamil Foremny
      Joined Our Team `,
      subtitle: `Being a Senior Developer at Digital
        Colliers is a wonderful thing. But
        the beginnings were not always
        so easy. `
    },
    button: {
      href: '/career/success-stories/kamil-4',
      text: 'Read Success Story'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.STORY,
      images: [
        {
          ...personImage,
          alt: 'Kamil Foremny Joined Our Team',
          src: '/events/common/event-5/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-03-26',
    category: ['Event', 'Achievements'],
    headline: {
      before: `Q1 Celebration
      With Online Pizza`,
      subtitle: `We did a big review of our work and progress followed by celebration with pizza and a few games
        Remote work has no real limits!`
    },
    button: {
      href: 'https://www.instagram.com/digitalcolliers/',
      text: 'Instagram'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Q1 Celebration With Online Pizza',
          src: '/events/common/event-6/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-04-10',
    category: ['Integration', 'People'],
    headline: {
      before: '3 Days Off in the\nBeskids, Żywiec',
      subtitle: `Moments like that stay with us
        forever. Integration trip to Żywiec
        was a perfect counterbalance to our
        daily remote work `
    },
    button: {
      href: 'https://vimeo.com/615544783',
      text: 'Watch video'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.FOUR_MASONRY,
      images: [
        {
          ...masonrySmallRes,
          alt: '3 Days Off in the Beskids, Żywiec',
          src: '/events/common/event-7/image-1.png'
        },
        {
          ...masonrySmallRes,
          alt: '3 Days Off in the Beskids, Żywiec',
          src: '/events/common/event-7/image-2.png'
        },
        {
          ...masonrySmallRes,
          alt: '3 Days Off in the Beskids, Żywiec',
          src: '/events/common/event-7/image-3.png'
        },
        {
          ...masonrySmallRes,
          alt: '3 Days Off in the Beskids, Żywiec',
          src: '/events/common/event-7/image-4.png'
        }
      ]
    }
  },
  {
    date: '2021-05-07',
    category: ['Academy', 'Company'],
    headline: {
      before: `Vue.js Lectures
      With Maciej`,
      subtitle: `We have the knowledge and no intentions to keep it to ourselves.
        This time introduction to Kotlin was prepared by Maciek.`
    },
    button: {
      href: '/career',
      text: 'Go to Career'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.TWO_IN_BOX,
      images: [
        {
          ...oneImageRes,
          alt: 'Language Courses in Digital Colliers',
          src: '/events/common/event-8/image-1.png'
        },
        {
          ...oneImageRes,
          alt: 'Language Courses in Digital Colliers',
          src: '/events/common/event-8/image-2.png'
        }
      ]
    }
  },
  {
    date: '2021-05-28',
    category: ['Project', 'Business'],
    headline: {
      before: 'Innovative Solution\nfor Banking Industry',
      subtitle: `As digital technologies shifted rules of the game we want to push banking sector into the age of
        on-demand economy.`
    },
    button: {
      href: '/career/projects/ecosystem-for-social-finance-solution',
      text: 'Project description'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'We undertook Cooperation With',
          src: '/events/common/event-9/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-06-03',
    category: ['People', 'Achievements'],
    headline: {
      before: `Chinese Courses
      in Digital Colliers`,
      subtitle:
        'Constant development is a part of our mission statement. Hence why our employees successfully participate in language courses.'
    },
    button: {
      href: 'https://www.facebook.com/DigitalColliers/photos/a.134563081677202/323285106138331/',
      text: 'Read more'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Merry Christmas!',
          src: '/events/common/event-10/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-06-15',
    category: ['People'],
    headline: {
      before: `Bartek and His
      Success Story`,
      subtitle: `It turns out recruitment comes
      very naturally for me, and in
      practice, results are
      relatively good. `
    },
    button: {
      href: '/career/success-stories/bartosz-2',
      text: 'Read Success Story'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.STORY,
      images: [
        {
          ...personImage,
          alt: 'Szlachetna Paczka!',
          src: '/events/common/event-11/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-06-22',
    category: ['People', 'Achievements'],
    headline: {
      before: 'We Won Scientific\nMarathon!',
      subtitle:
        'Our employee led his scientific team to victory by creating paper on new working methods and future office spaces. Congrats!'
    },
    button: {
      href: 'https://www.facebook.com/DigitalColliers/posts/315311743602334',
      text: 'See post on Facebook'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'We Won Scientific Marathon',
          src: '/events/common/event-12/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-06-25',
    category: ['People', 'Event'],
    headline: {
      before: 'Digital Colliers on the Battlefield',
      subtitle: 'We do know how to cooperate. Take a look at how we tried out competing with each other!'
    },
    button: {
      href: 'https://vimeo.com/615553876',
      text: 'See video'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.THREE_MASONRY,
      images: [
        {
          ...bigInRowRes,
          alt: 'We Won Scientific Marathon!',
          src: '/events/common/event-13/image-3.png'
        },
        {
          ...masonrySmallRes,
          alt: 'We Won Scientific Marathon!',
          src: '/events/common/event-13/image-1.png'
        },
        {
          ...masonrySmallRes,
          alt: 'We Won Scientific Marathon!',
          src: '/events/common/event-13/image-2.png'
        }
      ]
    }
  },
  {
    date: '2021-07-02',
    category: ['Event', 'Business'],
    headline: {
      before: 'Internal Hackathon\nfor Project Teams',
      subtitle:
        'After the fight on battlefield it was the time to take up rivalization on digital arena. Time for an internal Hackathon for project teams!'
    },
    button: {
      href: 'https://www.facebook.com/DigitalColliers/posts/372143724585802',
      text: 'Read more'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.THREE_MASONRY,
      images: [
        {
          ...bigInRowRes,
          alt: 'Internal Hackathon for Project Teams',
          src: '/events/common/event-14/image-1.png'
        },
        {
          ...masonrySmallRes,
          alt: 'Internal Hackathon for Project Teams',
          src: '/events/common/event-14/image-2.png'
        },
        {
          ...masonrySmallRes,
          alt: 'Internal Hackathon for Project Teams',
          src: '/events/common/event-14/image-3.png'
        }
      ]
    }
  },
  {
    date: '2021-07-19',
    category: ['Project', 'Business'],
    headline: {
      before: 'Setting Standard\nin Online Gambling',
      subtitle:
        'Starting from scratch we are developing a new Shopfront greenfield project to set a new standard in the online lottery industry.'
    },
    button: {
      href: '/career/projects/headless-storefront-sdk-for-gaming',
      text: 'Project description'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Setting Standard in Online Gambling',
          src: '/events/common/event-15/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-08-06',
    category: ['Academy', 'Company'],
    headline: {
      before: `Vue.js Lectures
      With Maciej`,
      subtitle:
        'As we have some real experts in our team, we share the knowledge. Maciek, our frontend developer, prepared an intro to Vue.js.'
    },
    button: {
      href: '/career',
      text: 'Go to Career'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.TWO_IN_BOX,
      images: [
        {
          ...twoInBoxRes,
          alt: 'Vue.js Lectures With Maciej',
          src: '/events/common/event-16/image-2.png'
        },
        {
          ...twoInBoxRes,
          alt: 'Vue.js Lectures With Maciej',
          src: '/events/common/event-16/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-09-07',
    category: ['Charity', 'People'],
    headline: {
      before: "We've Adopted This\nCute, Little Dog!",
      subtitle: `Meet Fifi - the youngest member of Digital Colliers squad, adopted from one of Cracow's shelters
        DCpets family is growing!`
    },
    button: {
      href: '/career',
      text: 'Go to Career'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Tenerife Networking Business Meeting',
          src: '/events/common/event-17/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-09-10',
    category: ['People', 'Event'],
    headline: {
      before: `The Company's
      Fifth Birthday!`,
      subtitle: 'Oh we love to celebrate! Happy birthday to the whole Digital Colliers team!'
    },
    button: {
      href: '/about',
      text: 'About'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: "The Company's Fifth Birthday!",
          src: '/events/common/event-18/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-09-12',
    category: ['People'],
    headline: {
      before: `Kamil and His
      Chess & Lever Story`,
      subtitle: 'The first thing I programmed was a chess-computer game so I could teach myself how to win with my father'
    },
    button: {
      href: '/career/success-stories/kamil-1',
      text: 'Read Success Story'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.STORY,
      images: [
        {
          ...personImage,
          alt: 'Kamil joined to us',
          src: '/events/common/event-19/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-09-01',
    category: ['Project', 'Business'],
    headline: {
      before: 'We Evolve Global\nPayment Platforms',
      subtitle:
        'Our goal is to help global enterprises improve their payment processes and enable seamless, cutting-edge tech payment experiences to customers.'
    },
    button: {
      href: '/career/projects/payment-orchestration-platform',
      text: 'Project description'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'We Evolve Global Payment Platforms',
          src: '/events/common/event-20/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-10-01',
    category: ['People', 'Event'],
    headline: {
      before: 'Italian Integration\nTrip in Bologna',
      subtitle:
        'This fall as the destination for our integration trip we choose Italy. Part of our team went on a mission to conquer Bologna!'
    },
    button: {
      href: 'https://vimeo.com/671092459',
      text: 'Watch video'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Italian Integration Trip in Bologna',
          src: '/events/common/event-21/image-1.png'
        }
      ]
    }
  },
  {
    date: '2021-10-23',
    category: ['People', 'Event'],
    headline: {
      before: 'Project Teams Fights in',
      highlight: '#hackathon',
      subtitle: 'Yet another time we gathered our project teams for a fierce competition in an internal Hackathon challenge'
    },
    button: {
      href: '/career/jobs',
      text: 'Job offers'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Project Teams Fights in',
          src: '/events/common/event-22/image-1.png'
        }
      ]
    }
  },
  // {
  //   date: '2021-12-12',
  //   category: ['Charity', 'Company'],
  //   headline: {
  //     before: 'Another Year With\nSzlachetna Paczka',
  //     subtitle:
  //       'Big hearts of DC have no limits! Thanks to your team’s engagement, second year in a row we managed to support a family in need'
  //   },
  //   button: {
  //     href: '#',
  //     text: 'See facebook post'
  //   },
  //   gallery: {
  //     grid: CompanyEventGalleryGrid.THREE_MASONRY,
  //     images: [
  //       {
  //         ...bigInRowRes,
  //         alt: 'Another Year With Szlachetna Paczka',
  //         src: '/events/common/event-23/image-1.png'
  //       },
  //       {
  //         ...masonrySmallRes,
  //         alt: 'Another Year With Szlachetna Paczka',
  //         src: '/events/common/event-23/image-2.png'
  //       },
  //       {
  //         ...masonrySmallRes,
  //         alt: 'Another Year With Szlachetna Paczka',
  //         src: '/events/common/event-23/image-3.png'
  //       }
  //     ]
  //   }
  // },
  {
    date: '2021-12-13',
    category: ['People', 'Business'],
    headline: {
      before: `Delegation Trip to
      the United States`,
      subtitle:
        'Our frontend developer - Kamil had an amazing opportunity to travel to Chicago to meet with one of our crucial clients. What a view!'
    },
    button: {
      href: 'https://www.instagram.com/digitalcolliers/',
      text: 'See Instagram'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.TWO_IN_ROWS,
      images: [
        {
          ...bigInRowRes,
          alt: 'Delegation Trip',
          src: '/events/common/event-24/image-1.png'
        },
        {
          ...bigInRowRes,
          alt: 'Delegation Trip',
          src: '/events/common/event-24/image-2.png'
        }
      ]
    }
  },
  {
    date: '2021-12-17',
    category: ['People', 'Event'],
    headline: {
      before: 'Christmas Eve with\nDigital Colliers',
      subtitle:
        'As Christmas time approached it was the final time to gather and mix the family atmosphere with a solid dose of fun. Take at look at DC Christmas!'
    },
    button: {
      href: 'https://vimeo.com/671099894',
      text: 'Watch Video'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Christmas Eve with Digital Colliers',
          src: '/events/common/event-25/image-1.png'
        }
      ]
    }
  },
  {
    date: '2022-01-10',
    category: ['Project', 'Business'],
    headline: {
      before: 'New Big Fintech\nIndustry Project',
      subtitle:
        'This project aims to develop from scratch an application that will enable SMEs leaders to monitor and forecast their cash flow.'
    },
    button: {
      href: '/career/projects/predictive-monitoring-platform',
      text: 'Project description'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'new project',
          src: '/events/common/event-26/image-1.png'
        }
      ]
    }
  },
  {
    date: '2022-01-18',
    category: ['People'],
    headline: {
      before: `Kacper - Young,
      Talented and Bright`,
      subtitle: `My story started with two of my
        cousins working in the IT industry.
        They were the ones that got me
        interested in it.`
    },
    button: {
      href: '/career/success-stories/kacper-3',
      text: 'Read Success Story'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.STORY,
      images: [
        {
          ...personImage,
          alt: 'new member',
          src: '/events/common/event-27/image-1.png'
        }
      ]
    }
  },
  {
    date: '2022-01-21',
    category: ['People', 'Business'],
    headline: {
      before: 'Interview Series',
      subtitle: `We prepared an interview series where we share our personal view on work and daily life in DC team.
        A great chance to get to know us!`
    },
    button: {
      href: 'https://www.facebook.com/DigitalColliers/',
      text: 'See post on Facebook'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'new interview',
          src: '/events/common/event-28/image-1.png'
        }
      ]
    }
  },
  {
    date: '2022-02-01',
    category: ['Company', 'Academy'],
    headline: {
      before: 'Born2Solve Academy',
      subtitle: `Born2Solve started as a joke among our teams. Soon we will turn it into academy dedicated for IT
        community all around the world!`
    },
    button: {
      href: '/career#born-2-solve',
      text: 'Born2Solve Academy'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'academy',
          src: '/events/common/event-29/image-1.png'
        }
      ]
    }
  }
];
