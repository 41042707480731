













import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwButton, DcwNewHeadline, DcwText, DcwImage } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineSubtitleSpacing, HeadlineTypes, PagesLinks, SuccessStory } from '@/types';
import { useLocales } from '@/composables';

export const DcwSuccessStoryItem = defineComponent({
  name: 'DcwSuccessStoryItem',
  components: { DcwButton, DcwNewHeadline, DcwText, DcwImage },
  props: {
    story: {
      type: Object as PropType<SuccessStory & { id: string }>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();

    const buttonProps = {
      text: translation.buttons.readMore,
      href: `${PagesLinks.SUCCESS_STORIES}/${props.story.id}`,
      isLink: true
    };

    const headlineProps = {
      before: props.story.name,
      subtitle: props.story.position,
      isTinted: true,
      type: HeadlineTypes.H2,
      styledAs: HeadlineTypes.H4,
      subtitleSize: HeadlineSubtitleSizes.EXTRA_SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM
    };

    return { buttonProps, headlineProps };
  }
});

export default DcwSuccessStoryItem;
