



























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwOfferItem } from '@molecules';
import { JobOfferItemVariant, JobOfferWithId } from '@/types';

export const DcwOffersList = defineComponent({
  name: 'DcwOffersList',
  components: { DcwOfferItem },
  props: {
    list: {
      type: Array as PropType<JobOfferWithId[]>,
      required: true
    },
    itemsType: {
      type: String as PropType<JobOfferItemVariant>,
      default: JobOfferItemVariant.CARD
    }
  },
  setup(props) {
    const isOneOfTheLatest = (index: number) =>
      [props.list.length, props.list.length - 1, props.list.length - 2].includes(index + 1);

    const isMultipleOf = (value: number, index: number) => props.list.length % value === 0 && isOneOfTheLatest(index);

    return { isMultipleOf, isOneOfTheLatest, JobOfferItemVariant };
  }
});

export default DcwOffersList;
