// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/font/Gilroy-Regular/Gilroy-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/font/Gilroy-Medium/Gilroy-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/font/Gilroy-SemiBold/Gilroy-SemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/font/Gilroy-Bold/Gilroy-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/font/Gilroy-ExtraBold/Gilroy-ExtraBold.woff2");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html{font-family:\"Gilroy\",-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,Helvetica,Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\";font-size:10px;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;color:var(--dcw-color-secondary-dark);--dcw-grid-padding:24px;--dcw-grid-padding-large:64px;--dcw-default-border-radius:40px;--dcw-input-border-radius:8px}@media(max-width:767.98px){html{--dcw-default-border-radius:20px}}body{font-size:14px;line-height:24px;font-weight:500}main{overflow-x:hidden}::-moz-placeholder{all:unset;color:var(--dcw-color-secondary-light)}:-ms-input-placeholder{all:unset;color:var(--dcw-color-secondary-light)}.dcw-custom-input-placeholder,::placeholder{all:unset;color:var(--dcw-color-secondary-light)}input,textarea{caret-color:var(--dcw-color-secondary)}input,label,textarea{color:var(--dcw-color-secondary)}:focus{outline-style:none}@font-face{font-family:\"Gilroy\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");font-weight:400;font-display:swap}@font-face{font-family:\"Gilroy\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");font-weight:500;font-display:swap}@font-face{font-family:\"Gilroy\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");font-weight:600;font-display:swap}@font-face{font-family:\"Gilroy\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\");font-weight:700;font-display:swap}@font-face{font-family:\"Gilroy\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\");font-weight:800;font-display:swap}a{color:var(--dcw-color-secondary-dark);text-decoration:none}ul{padding-left:0;margin-left:0;list-style:none}.overflow-safari-container:after{content:\"\";padding:.02px}a,button{-webkit-tap-highlight-color:transparent}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
