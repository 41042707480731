import { people } from '../people';
import { PagesLinks, JoinUsSectionContent } from '@/types';

export const joinUs: JoinUsSectionContent = {
  cards: {
    firstCard: {
      headline: {
        before: 'Scale Your',
        highlight: 'Business',
        subtitle: `We're happy to contact, and provide
        you with all the relevant information.
        Let's talk business opportunities.`
      },
      button: {
        text: 'Contact',
        href: PagesLinks.CONTACT
      },
      person: {
        model: people.leopoldG,
        images: {
          full: { height: 322, width: 342, src: '/common/join-us/image-1.png', alt: 'Picture of Léopold Gaillard smiling' },
          min: { height: 62, src: '/common/join-us/image-1-min.png', alt: 'Picture of Léopold Gaillard smiling' }
        }
      }
    },
    secondCard: {
      headline: {
        before: 'Become',
        highlight: 'One of Us',
        subtitle: `Become a member of our growing team,
        and build the digital future with us.
        Start progressing your career.`
      },
      button: {
        text: 'Career',
        href: PagesLinks.CAREER
      },
      person: {
        model: people.bartoszC,
        images: {
          full: { height: 322, width: 342, src: '/common/join-us/image-2.png', alt: 'Picture of Bartosz Cieśla smiling' },
          min: { height: 62, src: '/common/join-us/image-2-min.png', alt: 'Picture of Bartosz Cieśla smiling' }
        }
      }
    }
  }
};
