/* eslint-disable max-len */
import { Project, TechnologyNames, ProjectSectionsId } from '@/types';

export const project: Project = {
  name: 'Headless Storefront SDK for Gaming',
  color: '#953D96',
  colors: {
    heroBg: '#FAF6FA',
    light: '#C5B8D5',
    primary: '#953D96'
  },
  description:
    "It's a long-term greenfield project that we are developing from scratch.\nWe are setting a new standard in online gambling, making it more convenient\xA0&\xA0intuitive.",
  badges: ['30+ Engineers', '4 Teams'],
  ctaText: {
    career: {
      before: 'Junior, Mid and Senior Positions',
      highlight: 'Up\xA0to\xA026\xA0000\xA0PLN\xA0+\xA0VAT'
    },
    home: {
      before: 'We built 4 strong development teams for',
      highlight: 'German Lottery'
    }
  },
  categories: [TechnologyNames.VUE_JS, TechnologyNames.TYPESCRIPT, TechnologyNames.NODE_JS, TechnologyNames.RABBIT_MQ],
  isRemote: true,
  isNew: true,
  technologies: {
    frontend: [
      TechnologyNames.VUE_JS,
      TechnologyNames.TYPESCRIPT,
      TechnologyNames.NUXT_JS,
      TechnologyNames.SCSS,
      TechnologyNames.JEST,
      TechnologyNames.GRAPH_QL
    ],
    backend: [
      TechnologyNames.NODE_JS,
      TechnologyNames.RABBIT_MQ,
      TechnologyNames.DOCKER,
      TechnologyNames.KUBERNETES,
      TechnologyNames.HELM,
      TechnologyNames.REDIS
    ]
  },
  fields: {
    salary: {
      label: 'net B2B',
      value: 'Up to 26 000 PLN'
    },
    client: {
      label: 'Client',
      value: 'Germany'
    },
    industry: {
      label: 'Industry',
      value: ['Gaming']
    },
    status: {
      label: 'Project phase',
      value: 'MVP'
    },
    duration: {
      label: 'Duration',
      value: 'Long Term'
    },
    projectStatus: {
      label: 'Working Location',
      value: '100% Remote'
    }
  },
  images: {
    main: {
      src: '/projects/headless-storefront-sdk-for-gaming/project.png',
      alt: 'Shopfront',
      width: 941,
      height: 443
    },
    preview: {
      src: '/projects/headless-storefront-sdk-for-gaming/preview.png',
      alt: 'Shopfront preview',
      width: 1074,
      height: 626
    }
  },
  sections: {
    description: {
      id: ProjectSectionsId.DESCRIPTION,
      title: 'Project Description',
      headline: {
        before: 'Project Description'
      },
      html: `
        <p>
          Shopfront is a long-term greenfield project that we are developing from scratch. We are setting a
          <br/>
          new standard in online lottery industry, making it more convenient & intuitive. With our storefront, enterprise
          <br/>
          customers can launch their <strong>platforms</strong> in weeks instead of months, and start earning money much quicker.
        </p>
        <p>
          <strong>For this project, we are developing</strong> custom SDK's. They will give <strong>business partners</strong> the possibility to quickly
          <br/>
          set up their <strong>own shops</strong> and extend core functionalities or even create new UI components from scratch.
          <br/>
          Because of that, in the project, we value good practices and test coverage, therefore we have <strong>Quality Gates</strong>
          <br/>
          that enforce ratings (reliability, security, security review, and maintainability) based on metrics on overall code
          <br/>
          and new code. We are responsible for creating the entire application <strong>in TDD approach</strong>.
        </p>
        `
    },
    technologies: {
      id: ProjectSectionsId.TECHNOLOGIES,
      title: 'Tech Stack',
      headline: {
        before: 'Tech Stack'
      },
      bg: {
        src: '/projects/headless-storefront-sdk-for-gaming/bg.png',
        width: 335,
        height: 320,
        alt: 'decorative image'
      },
      description: {
        before: 'Technology',
        subtitle:
          'Storefront is a UI-agnostic frontend framework that renders every page from the \bJSON configuration\b making it fast and convenient. We made it possible to work with any UI components, as long as it has been written in \bVue.js\b. You can write your own UI components or mix it with already written components by us.'
      },
      frontend: {
        headline: {
          before: 'Frontend',
          subtitle: `Thanks to low coupling and high cohesion, we used a set of modular features
            glued together in a wrapper using interfaces and factories \bpowered by Nuxt.js.\b`
        }
      },
      backend: {
        headline: {
          before: 'Backend',
          subtitle: "The \bspeed and flexibility\b wouldn't be possible without the great technologies that power it."
        }
      }
    },
    howWeWork: {
      id: ProjectSectionsId.HOW_WE_WORK,
      title: 'How do We Work',
      headline: {
        before: 'How do We Work'
      },
      html: `
      <p>
        We have a daily meeting where developers can share what they are working on and discuss any problems
        <br/>
        they might have encountered. Sprints are biweekly and milestones are agreed upon with stakeholders every
        <br/>
        quarter. We don't spend time in unnecessary meetings or calls that could be a message on Slack.
      </p>
      `
    },
    challenges: {
      id: ProjectSectionsId.CHALLENGES,
      title: 'Challenges',
      headline: {
        before: 'Challenges That You Will Meet'
      },
      html: `
        <ul>
          <li>
            Going global and international development
          </li>
          <li>
            Solve complex architectural problems to deliver scalable and maintainable code
          </li>
          <li>
            Building extensions for Storefront as well as developer-facing SDK's
          </li>
          <li>
            Building core functionalities of Storefront
          </li>
          <li>
            Striving for the superior quality of the product
          </li>
        </ul>
      `
    },
    team: {
      id: ProjectSectionsId.TEAM,
      title: 'Meet Our Team',
      headline: { before: 'Meet Our Team' },
      members: [
        {
          img: {
            src: '/projects/headless-storefront-sdk-for-gaming/team/member-1.png',
            alt: 'Image of Wojciech',
            width: 236,
            height: 224
          },
          name: 'Wojciech',
          position: 'Team Leader'
        },
        {
          img: {
            src: '/projects/headless-storefront-sdk-for-gaming/team/member-2.png',
            alt: 'Image of Kamil',
            width: 236,
            height: 224
          },
          name: 'Kamil',
          position: 'Mid Frontend Developer'
        },
        {
          img: {
            src: '/projects/headless-storefront-sdk-for-gaming/team/member-3.png',
            alt: 'Image of Arek',
            width: 236,
            height: 224
          },
          name: 'Arek',
          position: 'Mid Frontend Developer'
        }
      ]
    },
    openPositions: {
      id: ProjectSectionsId.OPEN_POSITIONS,
      title: 'Open Positions',
      headline: {
        before: 'Open Positions'
      }
    },
    othersProjects: {
      id: ProjectSectionsId.OTHERS_PROJECTS,
      title: 'Other Projects',
      headline: {
        before: 'Choose From Other',
        highlight: 'Great Projects'
      }
    }
  }
};
