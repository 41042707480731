









import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SVGIcon } from '@/types';

export const DcwSimpleIcon = defineComponent({
  name: 'DcwSimpleIcon',
  props: {
    size: {
      type: [String, Number, Array],
      default: 16
    },
    icon: {
      type: Object as PropType<SVGIcon>,
      required: true
    }
  }
});

export default DcwSimpleIcon;
