


















import { defineComponent } from '@nuxtjs/composition-api';
import { DcwScheduleModalDatePicker, DcwScheduleModalTimePicker } from '../';
import { DcwNewHeadline } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineTypes } from '@/types';
import { useLocales } from '@/composables';

export const DcwScheduleModalCalendar = defineComponent({
  name: 'DcwScheduleModalCalendar',
  components: { DcwNewHeadline, DcwScheduleModalDatePicker, DcwScheduleModalTimePicker },
  setup() {
    const { translation } = useLocales();

    const headlineProps = {
      subtitle: translation.modals.scheduleModal.scheduleCall,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { HeadlineTypes, headlineProps, translation };
  }
});

export default DcwScheduleModalCalendar;
