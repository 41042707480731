











import { defineComponent } from '@nuxtjs/composition-api';
import Arrow from '@/assets/arrow.svg';
import DcwSimpleIcon from '@/components/Atoms/SimpleIcon/DcwSimpleIcon.vue';

export const DcwSliderArrow = defineComponent({
  name: 'DcwSliderArrow',
  components: { DcwSimpleIcon },
  props: {
    isLeft: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { Arrow };
  }
});

export default DcwSliderArrow;
