/* eslint-disable max-len */
import { CountryNames, OfficeModel } from '@/types';

export const cracow: OfficeModel = {
  city: 'Cracow',
  country: CountryNames.POLAND,
  name: 'Cracow Main Square',
  address: {
    first: 'Rynek Główny 8',
    second: '31-042'
  },
  map:
    'https://www.google.com/maps/place/Rynek+Główny+8,+31-042+Kraków/@50.060806,19.935990[…]65b12058e99ef:0x87b869282e0b2a3d!8m2!3d50.060806!4d19.9381789'
};
