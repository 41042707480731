









































import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { EventDot } from '@/types';
import { CALENDAR_EMOJI } from '@/consts';

export const DcwEventDot = defineComponent({
  name: 'DcwEventDot',
  props: {
    data: {
      type: Object as PropType<EventDot>,
      required: true
    },
    isHomepageVariant: {
      type: Boolean,
      required: false
    },
    isActive: {
      type: Boolean,
      required: false
    },
    canForceCollapse: {
      type: Boolean,
      required: false
    },
    isLastOne: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const calculateDotWidth = computed<string | null>(() => props.data.index === props.data.closestEventIndex
      ? props.data.dateProgress + 'px'
      : null
    );

    const isDotIndexMatchingClosestEventIndex = computed(
      () => props.data.index === props.data.closestEventIndex
    );

    const isDotIndexMatchingActiveEventIndex = computed(
      () => props.data.index === props.data.activeEventIndex
    );

    const isDotIndexLowerOrEqualActiveEventIndex = computed(
      () => props.data.index <= props.data.activeEventIndex
    );

    const isDotIndexHigherThanActiveEventIndex = computed(
      () => props.data.index > props.data.activeEventIndex
    );

    const isDotIndexLowerThanActiveEventIndex = computed(
      () => props.data.index < props.data.activeEventIndex
    );

    const isDotIndexLowerThanClosestEventIndex = computed(
      () => props.data.index < props.data.closestEventIndex
    );

    return {
      calculateDotWidth,
      CALENDAR_EMOJI,
      isDotIndexMatchingClosestEventIndex,
      isDotIndexMatchingActiveEventIndex,
      isDotIndexLowerOrEqualActiveEventIndex,
      isDotIndexHigherThanActiveEventIndex,
      isDotIndexLowerThanActiveEventIndex,
      isDotIndexLowerThanClosestEventIndex
    };
  }
});

export default DcwEventDot;
