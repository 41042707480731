export enum Breakpoints {
  EXTRA_SMALL = 320,
  SMALL_MOBILE = 360,
  MOBILE = 576,
  SMALL = 768,
  MEDIUM = 1025,
  MEDIUM_DESKTOP = 1280,
  LARGE = 1408,
  EXTRA_LARGE = 1568,
  FULL_HD = 1920,
}
