/* eslint-disable @typescript-eslint/no-var-requires */
import { post as post1 } from './post-1';
import { post as post2 } from './post-2';
import { post as post3 } from './post-3';
import { post as post4 } from './post-4';
import { post as post5 } from './post-5';
import { BlogPostModel } from '@/types';

export const blogPosts = {
  get [post1.slug](): BlogPostModel {
    return post1;
  },
  get [post2.slug](): BlogPostModel {
    return post2;
  },
  get [post3.slug](): BlogPostModel {
    return post3;
  },
  get [post4.slug](): BlogPostModel {
    return post4;
  },
  get [post5.slug](): BlogPostModel {
    return post5;
  }
};
