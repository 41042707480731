




















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline } from '@atoms';
import { HeadlineSubtitleSizes, HeadlineSubtitleSpacing, HeadlineTypes, PersonModel } from '@/types';
import LinkedInIcon from '@/assets/brands/linkedin.svg';

export const DcwPersonData = defineComponent({
  name: 'DcwPersonData',
  components: { DcwNewHeadline, LinkedInIcon },
  props: {
    person: {
      type: Object as PropType<PersonModel>,
      required: true
    },
    hasLinkedin: {
      type: Boolean,
      default: false
    },
    headlineLevel: {
      type: String as PropType<HeadlineTypes>,
      default: HeadlineTypes.H3
    },
    hasSurname: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const headlineProps = {
      type: props.headlineLevel,
      isTinted: true,
      styledAs: HeadlineTypes.H5,
      subtitle: props.person.position,
      subtitleSize: HeadlineSubtitleSizes.EXTRA_SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.MEDIUM
    };

    return { headlineProps };
  }
});

export default DcwPersonData;
