import LinkedInIcon from '@/assets/brands/linkedin.svg';
import VimeoIcon from '@/assets/brands/vimeo.svg';
import ClutchIcon from '@/assets/brands/clutch-icon.svg';
import InstagramIcon from '@/assets/brands/instagram.svg';
import FacebookFIcon from '@/assets/brands/facebook-f.svg';
import YouTubeIcon from '@/assets/brands/youtube.svg';
import { SocialMediaNames } from '@/types';

export const socialMedia = {
  clutch: {
    name: SocialMediaNames.CLUTCH,
    icon: ClutchIcon,
    url: 'https://clutch.co/profile/digital-colliers#summary'
  },
  facebook: {
    name: SocialMediaNames.FACEBOOK,
    icon: FacebookFIcon,
    url: 'https://www.facebook.com/DigitalColliers/'
  },
  linkedIn: {
    name: SocialMediaNames.LINKEDIN,
    icon: LinkedInIcon,
    url: 'https://www.linkedin.com/company/digital-colliers/'
  },
  vimeo: {
    name: SocialMediaNames.VIMEO,
    icon: VimeoIcon,
    url: 'https://vimeo.com/digitalcolliers'
  },
  instagram: {
    name: SocialMediaNames.INSTAGRAM,
    icon: InstagramIcon,
    url: 'https://www.instagram.com/digitalcolliers/'
  },
  youtube: {
    name: SocialMediaNames.YOUTUBE,
    icon: YouTubeIcon,
    url: 'https://www.youtube.com/channel/UClCaEfMCDTJxSGynwnKBRNA'
  }
};
