/* eslint-disable max-len */
import { PagesLinks, JobOfferOverviewSectionContent } from '@/types';

export const overview: JobOfferOverviewSectionContent = {
  mainHeadline: {
    before: 'Overview'
  },
  locationHeadline: {
    before: '100% Remote',
    subtitle: 'You can work 100% remotely from anywhere in the world. '
  },
  salariesHeadline: {
    before: 'Salary Ranges',
    subtitle: 'We actually do want you to get as much as possible since we want to see great results.'
  },
  engagementHeadline: {
    before: 'Engagement Type',
    subtitle:
      'We offer B2B or contract of mandate with 20 paid vacation days. However, if proposed agreements are a hindrance, do not hesitate. We will figure it out.'
  },
  location: {
    description: 'Connect with us from sunny Tenerife or one of our co-working offices.',
    button: {
      text: 'Shared workspace offices',
      to: PagesLinks.CONTACT
    }
  },
  engagement: {
    badges: ['B2B', 'Contract of mandate'],
    vacationsText: '20 paid vacation days.'
  },
  image: {
    src: '/map/job-offer.png',
    alt: 'Our Offices',
    width: 437,
    height: 312
  }
};
