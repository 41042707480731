



















































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { DcwScheduleModalFooter, DcwScheduleModalDateStep, DcwScheduleModalTimeStep, DcwScheduleModalFormStep } from '../';
import { DcwButton, DcwCloseButton, DcwFormButton, DcwNewHeadline, DcwImage } from '@atoms';
import {
  PersonData,
  Pagination,
  CustomEvents,
  RequestStatus,
  HeadlineTypes,
  CloseScheduleModal,
  HeadlineSubtitleSizes,
  HeadlineSubtitleSpacing
} from '@/types';
import { CloseScheduleModalKey, ScheduleMeetingPersonDataKey, ScheduleMeetingRequestStatusKey } from '@/consts';
import { useInjectStrict, useLocales } from '@/composables';

enum ScheduleMeetingSteps {
  SELECT_DATE = 'DcwScheduleModalDateStep',
  SELECT_TIME = 'DcwScheduleModalTimeStep',
  CONTACT_FROM = 'DcwScheduleModalFormStep'
}

export const DcwScheduleModalMobile = defineComponent({
  name: 'DcwScheduleModal',
  components: {
    DcwImage,
    DcwButton,
    DcwFormButton,
    DcwCloseButton,
    DcwNewHeadline,
    DcwScheduleModalFooter,
    DcwScheduleModalDateStep,
    DcwScheduleModalTimeStep,
    DcwScheduleModalFormStep
  },
  props: {
    step: {
      type: Number,
      required: true
    }
  },
  emits: ['change-step'],
  setup(props) {
    const status = useInjectStrict<RequestStatus>(ScheduleMeetingRequestStatusKey);
    const personData = useInjectStrict<Readonly<PersonData>>(ScheduleMeetingPersonDataKey);
    const closeModal = useInjectStrict<CloseScheduleModal>(CloseScheduleModalKey);
    const { translation } = useLocales();

    const activeComponent = computed(() => {
      if (props.step === 1) {
        return ScheduleMeetingSteps.SELECT_DATE;
      }
      if (props.step === 2) {
        return ScheduleMeetingSteps.SELECT_TIME;
      }
      if (props.step === 3) {
        return ScheduleMeetingSteps.CONTACT_FROM;
      }

      return ScheduleMeetingSteps.CONTACT_FROM;
    });

    const headlineProps = {
      isTinted: true,
      before: personData.name,
      type: HeadlineTypes.H4,
      subtitle: personData.position,
      subtitleSize: HeadlineSubtitleSizes.EXTRA_SMALL,
      subtitleSpacing: HeadlineSubtitleSpacing.NO
    };

    return {
      status,
      closeModal,
      personData,
      Pagination,
      translation,
      CustomEvents,
      HeadlineTypes,
      headlineProps,
      activeComponent
    };
  }
});

export default DcwScheduleModalMobile;
