import { modelService } from './model';
import { successStory } from '@/translations/successStory';
import { technologies } from '@/translations/technologies';
import { projects } from '@/translations/projects';
import { blogPosts } from '@/translations/blog';
import { videos } from '@/translations/videos';

export { offersService } from './offers';
export const blogService = modelService(blogPosts)();
export const projectsService = modelService(projects)();
export const technologiesService = modelService(technologies)();
export const successStoriesService = modelService(successStory)();
export const videosService = modelService(videos)();
