


































import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, PropType, ref } from '@nuxtjs/composition-api';
import { useLocales } from '@/composables';
import { getReadingTime, generateShareLink, isInEnum } from '@/helpers';
import { ProjectSelectors, PagesLinks, Badge, SocialMediaNames, SVGIcon, SharableSocialMedia } from '@/types';
import Twitter from '@/assets/success-stories/social-media/twitter.svg';
import Linkedin from '@/assets/success-stories/social-media/linkedin.svg';
import Facebook from '@/assets/success-stories/social-media/facebook.svg';

export const DcwPageInfo = defineComponent({
  name: 'DcwPageInfo',
  props: {
    details: {
      type: Array as PropType<string[]>,
      required: true
    },
    badges: {
      type: Array as PropType<Badge[]>,
      default: () => []
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    contentWrapperClass: {
      type: String as PropType<ProjectSelectors>,
      validator: (value: ProjectSelectors) => isInEnum(value, ProjectSelectors),
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();

    const pageInfoRef = ref<HTMLDivElement | null>(null);
    const pageOffset = ref(0);

    const scrollProgress = computed(() => {
      if (!process.browser || !document || !pageInfoRef?.value) {
        return 0;
      }
      const target = document.getElementsByClassName(props.contentWrapperClass)[0] as HTMLDivElement;
      if (!target || pageOffset.value + pageInfoRef.value.clientHeight <= target.offsetTop) {
        return 0;
      }

      const progress = Math.ceil(
        ((pageOffset.value - target.offsetTop + pageInfoRef.value.clientHeight) / target.clientHeight) * 100
      );

      return progress < 100 ? progress : 100;
    });

    const socialMedias: { name: SharableSocialMedia; icon: SVGIcon }[] = [
      {
        name: SocialMediaNames.FACEBOOK,
        icon: Facebook
      },
      {
        name: SocialMediaNames.TWITTER,
        icon: Twitter
      },
      {
        name: SocialMediaNames.LINKEDIN,
        icon: Linkedin
      }
    ];

    const handleScroll = () => {
      pageOffset.value = window.scrollY;
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    const wrapperKey = ref('initialKey');
    nextTick(() => {
      wrapperKey.value = 'nextTick';
    });

    return { translation, scrollProgress, getReadingTime, PagesLinks, pageInfoRef, generateShareLink, socialMedias, wrapperKey };
  }
});

export default DcwPageInfo;
