/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

export const story: SuccessStory = {
  name: 'Kamil',
  images: {
    og: '/covers/success-story/story-4.jpg',
    avatar: {
      src: '/success-stories/story-4/avatar.png',
      alt: 'Kamil',
      width: 32,
      height: 32
    },
    cover: {
      src: '/success-stories/story-4/cover.jpg',
      alt: 'Kamil cover',
      height: 608
    },
    slider: {
      src: '/success-stories/story-4/slider.png',
      alt: 'Kamil',
      width: 317,
      height: 224
    },
    listing: {
      src: '/success-stories/story-4/listing.png',
      alt: 'Kamil',
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'Aug 7, 2021',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'Senior Backend Developer',
  details: `The actual reason I chose DC, in the end, is that someone sold me the project very well.
    The goal was to create something that nobody has ever done before and that was and
    that was the exact challenge I needed. I don't regret choosing DC in a bit. What I like is
    that I can also observe the whole company's growth and experience that on my own.`,
  shortStory: 'Being a Senior Developer at Digital Colliers is a wonderful thing. But the beginnings were not always so easy.',
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer:
        "I've always known I would have something to do with IT. Everything started when the first PSX console got released. At that time my brother showed me Quake 2. When I saw this graphic I told myself “I want to know how it is being done”. The real programming adventure started however as I took on university studies. In the beginning, I experimented with frontend but I quickly realized that it's not my cup of tea. When I stumbled upon Java I created my first own game and that's how I got into the backend."
    },
    {
      type: 'text',
      title: 'What do you do in DC?',
      answer:
        'Second I am a backend developer which is slowly transitioning into a slightly higher position. I am actually becoming a little Tułaza! Apart from that, I am also trying to get into DevOps.'
    },
    {
      type: 'text',
      title: 'Why did you choose DC?',
      answer:
        "In my last company, I was working for 3 years. At some point, I felt that there was nothing more I could learn there. I decided to look for something new. Apart from DC, I had quite a lot of job offers but I narrowed down my choice to 3 offers. The actual reason I chose DC, in the end, is that someone sold me the project very well. The goal was to create something that nobody has ever done before and that was and that was the exact challenge I needed. I don't regret choosing DC in a bit. What I like is that I can also observe the whole company's growth and experience that on my own. "
    },
    {
      type: 'image',
      details: 'Paintball battlefield in Tatras',
      img: {
        src: '/success-stories/story-4/image-1.png',
        alt: 'Paintball battlefield in Tatras',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.ZAKOPANE_INTEGRATION_TRIP
    },
    {
      type: 'text',
      title: 'What was the greatest challenge that you had to overcome in DC?',
      answer:
        "I would say that my biggest challenge is right ahead of me at the moment as I am adapting to a new position. I hope it won't sound overconfident but even though challenging I really do believe I will do well."
    },
    {
      type: 'text',
      title: 'What is good architecture for you?',
      answer:
        'For me, the best architecture is one that seems non-complicated for someone seeing it for the first time. I always imagine that someone would suddenly join a project, look at architecture and he needs to understand it in 5 minutes time. If it would be like that it means that the job is well done. Good architecture needs to be thus easy and clear. The less complicated the better.'
    },
    {
      type: 'text',
      title: "What are the technologies that you'd like to learn but you lacked the opportunity so far?",
      answer:
        'I guess I would like to get into DevOps even more. In DC I actually do have the opportunity to do it. Lately, we created very nice DevOps mechanisms. What I like very much is that as developers we create our own growth path. If we want to learn a new language we do have a chance to do that. Everything is just a matter of creativity. If you can find a useful implication of something new you will always have a green light to do it.'
    },
    {
      type: 'text',
      title: 'Where do you see yourself in 10 years?',
      answer:
        "That's a very good question. I haven't really found my final path yet. Maybe I will stay with development and maybe I will get into architecture, DevOps, or project management. Maybe I will become a business analyst or maybe I will try to realize some of my own business ideas. Time will tell but I am open to everything at the moment. Starting in DC I wouldn't even imagine having such a responsible position and here I am now!"
    },
    {
      type: 'image',
      details: 'Żywiec integration trip',
      img: {
        src: '/success-stories/story-4/image-2.png',
        alt: 'Żywiec integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.ZYWIEC_INTEGRATION_TRIP
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Kat - Łza dla cieniów minionych',
          img: {
            src: '/success-stories/story-4/song-1.png',
            alt: 'Kat - Łza dla cieniów minionych',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/0sEc7dzGAYl5qoIYalwu94',
          ytLink: 'https://www.youtube.com/watch?v=37Lv-5TOtAo'
        },
        {
          title: 'Pink Floyd - Comfortably numb',
          img: {
            src: '/success-stories/story-4/song-2.png',
            alt: 'Pink Floyd - Comfortably numb',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/6Rcv8LeOnK2i2MZ19n2fl4',
          ytLink: 'https://www.youtube.com/watch?v=_FrOQC-zEog'
        },
        {
          title: 'Eagles - Hotel California',
          img: {
            src: '/success-stories/story-4/song-3.png',
            alt: 'Eagles - Hotel California',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/40riOy7x9W7GXjyGp4pjAv',
          ytLink: 'https://www.youtube.com/watch?v=EqPtz5qN7HM'
        }
      ]
    }
  ]
};
