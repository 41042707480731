import { hydrateWhenVisible } from 'vue-lazy-hydration';
import { isProduction } from '.';

export const getHydratedSection = <T>(section: T): T => {
  if (!isProduction) {
    return section;
  }

  return hydrateWhenVisible(section);
};
