import { LocaleBannerSectionContent, ScheduleModalPeople } from '@/types';

export const localeBanner: LocaleBannerSectionContent = {
  headline: {
    before: 'Nous travaillons actuellement sur la',
    highlight: ' Version Française',
    after: 'du site...',
    subtitle: 'Si vous souhaitez nous parler en français, vous pouvez prendre rendez-vous avec le Business partner.'
  },
  buttonText: 'Prendre rendez-vous',
  person: {
    images: {
      mobile: {
        src: '/locales/fr/banner-mobile.png',
        alt: 'Léopold Gaillard',
        width: 88,
        height: 88
      },
      desktop: {
        src: '/locales/fr/banner.png',
        alt: 'Léopold Gaillard',
        width: 419,
        height: 501
      }
    },
    data: {
      before: 'Léopold Gaillard',
      subtitle: 'Business Manager'
    },
    modal: ScheduleModalPeople.leopoldG
  }
};
