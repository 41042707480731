/* eslint-disable max-len */
import { TextPageContent } from '@/types';

export const terms: TextPageContent = {
  title: 'Conditions d\'utilisation',
  metaDescription: "This T&C specify terms of providing the Services, in particular rules for the Website's operation, procedures and terms of concluding the Agreements and...",
  sections: [
    {
      title: 'Definitions',
      header: { before: 'Definitions' },
      id: 'section1',
      html: `
        <p>
          Whenever the below capitalized terms are used herein, they shall be understood as follows:
        </p>
        <ol>
          <li>
            <b>Agreement</b> – an e-service agreement concluded between Service provider and the User on terms laid down in
            the T&amp;C;
          </li>
          <li>
            <b>Consumer</b> – a natural person, performing with an entrepreneur a legal act unrelated directly to such
            person’s business or professional activity, who uses the Website;
          </li>
          <li>
            <b>Cut-Off</b> – temporary discontinuation of the operation of the Website or of its particular webpages or
            functions, required for technical reasons e.g. in connection with maintenance, retrofits or repairs of the
            Website;
          </li>
          <li>
            <b>Force Majeure</b> – any external event of an extraordinary or random nature beyond control of Service provider
            and of the User or which cannot be prevented by them, including riots, strikes, armed conflicts, states of
            emergency or natural disasters;
          </li>
          <li>
            <b>GDPR</b> – the Regulation 2016/679 of the European Parliament and of the Council of 27 April 2016 on the
            protection of natural persons with regard to the processing of personal data and on the free movement of such
            data, and repealing Directive 95/46/EC (General Data Protection Regulation);
          </li>
          <li>
            <b>Personal Data</b> – any information relating to an identified or identifiable natural person (‘data subject’);
            an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to
            an identifier such as a name and surname, an identification number, location data, an online identifier or to one
            or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of
            that natural person;
          </li>
          <li>
            <b>Processing</b> – any operations performed on the Personal Data, such as collecting, recording, storing,
            structuring, altering, sharing and erasing, especially the ones performed in any IT systems;
          </li>
          <li>
            <b>T&C</b> – these rules applicable to the Website;
          </li>
          <li>
            <b>Services</b> – the services, specified in the T&C, provided by Service provider for the Users via the Website;
          </li>
          <li>
            <b>Service provider/Controller</b> – Digital Colliers sp. z o.o. located in Gliwice, ul. Stanisława Konarskiego
            18C, 44-100 Gliwice, registered in the Register of Entrepreneurs of the National Court Register kept by the
            District Court in Gliwice, X Economic Department of the National Court Register under KRS: 0000768764, REGON:
            382444548, NIP: 9691635555, share capital 5 000 PLN;
          </li>
          <li>
            <b>User</b> – the user who uses the Website;
          </li>
          <li>
            <b>Website</b> – the website run by Service provider under the URL address <a
              href="https://www.digitalcolliers.com">https://www.digitalcolliers.com</a>.
          </li>
        </ol>
      `
    },
    {
      title: 'General Provisions',
      header: { before: 'General Provisions' },
      id: 'section2',
      html: `
        <ol>
          <li>
            This T&C specify terms of providing the Services, in particular rules for the Website’s operation, procedures
            and terms of concluding the Agreements and terms of access to the Website’s content and use thereof.
          </li>
          <li>
            To use the Website, it is necessary to accept the T&C and satisfy the requirements laid down herein.
          </li>
          <li>
            Service provider is the owner of the Website and is entitled to dispose of the Website’s resources. Contact
            details:
            <ol>
              <li>
                postal address: ul. Stanisława Konarskiego 18c, 44-100 Gliwice, Poland;
              </li>
              <li>
                data for current contact with the Service Provider:
                <ol type="a">
                  <li>
                    e-mail: <a href="info@digitalcolliers.com">info@digitalcolliers.com</a>.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      `
    },
    {
      title: 'Technical Requirements and Rules for Use of the Website',
      header: { before: 'Technical Requirements and Rules for Use of the Website' },
      id: 'section3',
      html: `
        <ol>
          <li>
            In order to use the Website, the User shall have the following:
            <ol>
              <li>
                a computer or mobile device (e.g. smartphone, tablet) connected to the Internet and with software allowing
                for access to the Website;
              </li>
              <li>
                Internet browser with JavaScript and cookies enabled. In order to ensure the highest quality of Services,
                the Internet browser should be updated to the latest version possible.
              </li>
            </ol>
          </li>
          <li>
            Service provider has the right to introduce the Cut-Off. Service provider shall inform the User of any planned
            Cut-Offs that might affect the normal use of the Website by posting information about the planned Cut-Off on the
            Website.
          </li>
          <li>
            In case of the Website’s failure, Service provider shall apply best efforts to promptly restore the Website’s
            functionalities.
          </li>
        </ol>
      `
    },
    {
      title: 'The Services',
      header: { before: 'The Services' },
      id: 'section4',
      html: `
        <ol>
          <li>
            Via the Website, the Service provider shall render the following Services for the Users:
            <ol>
              <li>
                making the content of the Website available at the request of the User;
              </li>
              <li>
                applying for participation in recruitment processes conducted by the Service Provider via the form made
                available on the Website;
              </li>
              <li>
                contacting the Service provider via the form made available on the Website.
              </li>
            </ol>
          </li>
          <li>
            The contract for the provision of Services referred to in §1.1) above between the Service provider and the User
            is concluded at the beginning of browsing of the content of the Website by the User and expires at the moment of
            ending the use of the Website.
          </li>
          <li>
            The contract for the provision of Services referred to in §1.2) and 3) above between the Service provider and
            the User is concluded at the beginning of start filling in the forms made available on the Website.
          </li>
          <li>
            The use of the Services referred to in §1.2) and 3) requires providing Personal data given in the form.
          </li>
          <li>
            The User is obliged in particular to:
            <ol>
              <li>
              provide to the Service provider only true and current User’s data;
              </li>
              <li>
                use the Services offered by the Service provider in a manner consistent with the provisions of applicable law
                and without prejudice to the rights of third parties, in accordance with the provisions of this T&C, as well as
                the customs and rules of social coexistence adopted in this regard, in particular, not to provide illegal content.
              </li>
            </ol>
          </li>
        </ol>`
    },
    {
      title: 'Liability',
      header: { before: 'Liability' },
      id: 'section5',
      html: `
        <ol>
          <li>
            Service provider shall not be liable for:
            <ol>
              <li>
                the non-performance or undue performance of the Agreement resulting from the Force Majeure;
              </li>
              <li>
                the non-performance or undue performance of any obligations under the Agreement to the extent caused by the
                non-performance of any obligations by the User, including but not limited to the use of the Website contrary
                to its purpose;
              </li>
              <li>
                any content posted on the Website by the Users, including for any breach of rights of third parties
                (including copyrights and author’s moral rights) in connection with such content.
              </li>
            </ol>
          </li>
          <li>
            The liability limitations referred to herein shall not apply to the Users who are the Consumers to the extent
            prescribed by mandatory applicable laws.
          </li>
        </ol>`
    },
    {
      title: 'Complaints',
      header: { before: 'Complaints' },
      id: 'section6',
      html: `
      <ol>
        <li>
          A complaint concerning Website operation and provided Services may be filed by e-mail to the address:
          <a href="info@digitalcolliers.com">info@digitalcolliers.com</a> or in writing to the address: ul. Stanisława Konarskiego 18c, 44-100 Gliwice, Poland.
        </li>
        <li>
          In order to expedite the processing of complaint, it is recommended to provide: name, surname, e-mail address,
          description of a case and the request.
        </li>
        <li>
          Service provider is obliged to reply to the complaint within 30 days as of its receipt. In case of the
          Consumers, Service provider shall provide the User with a reply to the complaint in writing or saved on another
          durable medium.
        </li>
      </ol>`
    },
    {
      title: 'Out-of-court Dispute Resolution',
      header: { before: 'Out-of-court Dispute Resolution' },
      id: 'section7',
      html: `
        <ol>
          <li>
            Having exhausted the complaint procedure, the Consumer has the right to resort to out-of-court procedures for
            complaint resolution and pursuit of claims. The Consumer:
            <ol>
              <li>
                has the right to file a request to the Consumer Court of Arbitration by the Trade Inspection to settle a
                dispute under the Agreement;
              </li>
              <li>
                has the right to file a request to the Regional Inspector by the Trade Inspection to instigate mediation
                proceedings to amicably settle a dispute between the Consumer and Service Provider;
              </li>
              <li>
                can receive gratuitous assistance as regards the resolution of a dispute between the Consumer and Service
                Provider, enlisting also the assistance of a local (municipal) consumer ombudsman or a public organisation
                that has consumer protection in its statutory obligations (e.g. the Consumers’ Association, the Polish
                Consumers’ Association). The Consumers’ Association provides advice under a consumer helpline number 801 440
                220 and 22 290 89 16, while the Polish Consumers’ Association may be consulted via e-mail at the address:
                porady@dlakonsumentow.pl.
              </li>
            </ol>
          </li>
          <li>
            The list of institutions dealing with out-of-court resolution of consumer disputes, together with a list of the
            types of relevant cases, may be consulted on the website of the Office of Competition and Consumer Protection at
            the address www.uokik.gov.pl.
          </li>
          <li>
            The Consumer may use out-of-court procedures for complaint resolution and pursuit of claims also via the ODR
            platform, in compliance with the Regulation (EU) No. 524/2013 of the European Parliament and of the Council of 21
            May 2013 on online dispute resolution for consumer disputes and amending Regulation (EC) No. 2006/2004 and
            Directive 2009/22/EC (Regulation on Consumer ODR). When wishing to exercise an option of the out-of-court dispute
            resolution for online purchases, the Consumer may file a complaint via the ODR, i.e. the EU online platform
            available at the address: https://ec.europa.eu/consumers/odr/. The ODR platform shall facilitate the independent,
            impartial, transparent, effective, fast and fair out-of-court resolution of disputes between consumers and traders
            online concerning contractual obligations stemming from online sales or service contracts between a consumer
            resident in the Union and a trader established in the Union.
          </li>
          <li>
            Given that the out-of-court procedures for complaint resolution and pursuit of claims are voluntary, both
            parties must agree thereto.
          </li>
        </ol>`
    },
    {
      title: 'Intellectual Property Rights',
      header: { before: 'Intellectual Property Rights' },
      id: 'section8',
      html: `
      <ol>
        <li>
          The User may not provide through the Website illegal content that violates the rules of social coexistence or
          morality, including, in particular, content of an offensive, pornographic, hateful, vulgar, false or misleading
          nature, containing viruses or malicious software, or violating the rights of third parties, good manners or false
          information detrimental to the good name of the Service provider.
        </li>
        <li>
          Service Provider is the owner of the rights to the Website, including copyrights. All technical solutions,
          graphic elements and other pages of the Website, in particular HTML codes, CSS sheets, JavaScript are subject to
          legal protection, especially from in the copyright context, which Service provider holds. The above also applies
          to all materials and works, which will be made available or transmitted to the Users in the provision of the
          Services. Making such materials available or passing them on to Users does not mean granting or transferring
          rights to these elements, unless something else will result from an explicit statement of the Service provider.
        </li>
        <li>
          Copying reprinting or using in any scope the materials or data available on the Website requires each time the
          written consent of the Service provider. In particular, it is prohibited to download, process and use the data and
          other information available on the Website in order to make it further available on other websites and portals, as
          well as outside the Internet, and in any other way violating the interest of the Service provider.
        </li>
      </ol>`
    },
    {
      title: 'Threats',
      header: { before: 'Threats' },
      id: 'section9',
      html: `
        <ol>
          <li>
            As any other Internet user, the User is exposed to online threats. The main threat for each Internet user
            involves potential infection of their ICT system with software created mainly to cause harm, such as viruses, bugs
            or Trojans. In order to avoid the relevant threats, including the ones appearing at the moment of opening e-mails,
            the Users are recommended to install anti-virus software and firewall on their devices used in order to connect
            with the Website, and update such software on an ongoing basis.
          </li>
          <li>
            Furthermore, the Service provider hereby informs the Users that specific threats related to the use of the
            Website involve actions by hackers aiming at hacking both Service provider’s system (e.g. attacks on its sites)
            and the User’s system.
          </li>
          <li>
            Service provider informs the Users that it applies cutting-edge protection technologies to mitigate the risks
            referred to in points 1-2 above; however, it needs to be emphasized that there are no solutions available that
            would completely and fully protect against the described adverse actions.
          </li>
        </ol>`
    },
    {
      title: 'Amendments to the Terms & Conditions',
      header: { before: 'Amendments to the Terms & Conditions' },
      id: 'section10',
      html: `
        <ol>
          <li>
            Service provider reserves the right to amend the T&C in justified cases, and in particular in the case of:
            <ol>
              <li>
                a change in technical conditions of the Website’s operation, including the ones related to technical or
                technological progress;
              </li>
              <li>
                a change in terms of use of software or use of devices needed to operate the Website, including the ones
                introduced by manufacturers or entities having rights to the software or to the devices in question;
              </li>
              <li>
                decisions or judgments affecting or potentially affecting the T&C or the Website’s operation issued by
                competent public bodies;
              </li>
              <li>
                the Force Majeure;
              </li>
              <li>
                formal or organizational changes with a bearing on the Website’s operation;
              </li>
              <li>
                other justified reasons.
              </li>
            </ol>
          </li>
          <li>
            Service provider shall inform the User of any amendments to the T&C and the scope thereof by placing the
            consolidated text of the T&C on the Website. The amendment to the T&C shall enter into force upon the lapse of 14
            days from the date of posting the text of T&C on the Website. If the User does not agree to the amendment of the
            T&C, the User may resign from the Services by sending resignation by e-mail to the address: <a href="info@digitalcolliers.com">info@digitalcolliers.com</a>
            or in writing to the address: ul. Stanisława Konarskiego 18c, 44-100 Gliwice, Poland. Any amendment to the T&C shall
            not affect the Services rendered prior to the date of its entry into force.
        </li>
    </ol>`
    },
    {
      title: 'Final Provisions',
      header: { before: 'Final Provisions' },
      id: 'section11',
      html: `
      <ol>
        <li>
          This T&C shall apply from December 6, 2021.
        </li>
        <li>
          The User may not transfer any rights or obligations under these T&C to any third parties without Service
          provider’s consent.
        </li>
        <li>
          The performance of the T&C and Agreement shall be governed by the Polish law.
        </li>
        <li>
          Any disputes under the T&C or under the Agreements concluded with Service provider based on the T&C shall be
          settled by the Polish court competent for Service provider’s registered office.
        </li>
        <li>
          Points 3-4 above shall not apply to the Consumers insofar as this results from mandatory applicable laws.
        </li>
      </ol>`
    }
  ]
};
