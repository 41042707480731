







































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwButton, DcwNewHeadline, DcwTooltip, DcwBadgeList, DcwText } from '@atoms';
import { ButtonVariants, Directions, HeadlineTypes, PagesLinks, ProjectWithId, HeadlineContent } from '@/types';
import { getHEXOpacityColor, isInEnum } from '@/helpers';
import { useLocales } from '@/composables';

export const DcwProjectData = defineComponent({
  name: 'DcwProjectData',
  components: { DcwNewHeadline, DcwTooltip, DcwBadgeList, DcwButton, DcwText },
  props: {
    data: {
      type: Object as PropType<ProjectWithId>,
      required: true
    },
    headlineType: {
      type: String as PropType<HeadlineTypes>,
      validator: (value: HeadlineTypes) => isInEnum(value, HeadlineTypes),
      required: true
    },
    hasTooltip: {
      type: Boolean,
      default: false
    },
    ctaText: {
      type: Object as PropType<HeadlineContent>,
      required: true
    },
    badges: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup(props) {
    const { translation } = useLocales();

    const buttonProps = {
      variant: ButtonVariants.PRIMARY,
      text: translation.buttons.findOutMore,
      isLink: true,
      href: PagesLinks.PROJECTS + '/' + props.data.id
    };

    const borderColor = getHEXOpacityColor(props.data.color).thirty;
    const backgroundColor = getHEXOpacityColor(props.data.color).five;

    const technologyBadgeList = {
      title: `${translation.phrases.technologies}:`,
      badges: props.data.categories,
      colors: { border: borderColor, content: props.data.color }
    };

    const industryBadgeList = {
      title: `${props.data.fields.industry.label}:`,
      badges: props.data.fields.industry.value,
      colors: { border: borderColor, content: props.data.color }
    };

    const tooltipText = translation.tooltip.percentRemote;

    const headlineProps = {
      styledAs: HeadlineTypes.H3,
      type: props.headlineType
    };

    return {
      Directions,
      buttonProps,
      borderColor,
      tooltipText,
      headlineProps,
      backgroundColor,
      industryBadgeList,
      technologyBadgeList
    };
  }
});

export default DcwProjectData;
