/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

export const story: SuccessStory = {
  name: 'Maciej',
  images: {
    og: '/covers/success-story/story-10.jpg',
    avatar: {
      src: '/success-stories/story-10/avatar.png',
      alt: 'Maciej',
      width: 32,
      height: 32
    },
    cover: {
      src: '/success-stories/story-10/cover.jpg',
      alt: 'Maciej cover',
      height: 608
    },
    slider: {
      src: '/success-stories/story-10/slider.png',
      alt: 'Maciej',
      width: 317,
      height: 224
    },
    listing: {
      src: '/success-stories/story-10/listing.png',
      alt: 'Maciej',
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'May 12, 2022',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'Senior Backend Developer',
  details: `My first contact with Digital Colliers was at a networking event in Tenerife. After that,
            we stayed in touch and started developing some ideas for the way we could collaborate.
            For me, the reasons why I prefer DC over other companies are relatively young but still an
            ultra-competent team and the ability to work remotely.`,
  shortStory: 'For me, the reason why I prefer DC over other companies is relatively young but still an ultra-competent team.',
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer: `I spent most of my childhood in Kołobrzeg, in the north of Poland. When I finished high school, I went to university in Wroclaw, where I still live today. My first job was in a small IT company when I was 19 years old as a junior programmer. I realized how much pleasure and satisfaction I got from working with software, so I went to study part-time and fully focused on helping companies deliver the best quality software.
        To date I have been involved in quite a few projects of various sizes and durations. Before Digital Colliers, I built a large monitoring platform together with dozens of other developers.`
    },
    {
      type: 'text',
      title: 'What do you do in DC?',
      answer:
        'I am a Software Developer. I do my best to keep the project on the right track from the technical side. I am always willing to help where needed.'
    },
    {
      type: 'text',
      title: 'Why did you choose DC?',
      answer:
        'The atmosphere here! It’s great, believe me. I also very much value the trust. I feel like everyone here has o lot of it towards each other. Next we have perfect technology stack - Kotlin, Ktor, Kubernetes, AWS, Vue.js, MongoDB - we decide what we use. There is also remote work, flexible working hours, I could go on and on…'
    },
    {
      type: 'image',
      details: 'Bologna intergration trip',
      img: {
        src: '/success-stories/story-10/image-1.png',
        alt: 'Bologna integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.BOLOGNA_INTEGRATION_TRIP
    },
    {
      type: 'text',
      title: 'What is your favourite Tech Stack?',
      answer:
        "My favourite tech stack has been changing over time - IT is a dynamic environment at the end of the day. Currently, I’d say it's Kotlin and Golang for languages, Terraform managed AWS for cloud and Kubernetes or Lambdas for deployments, PostgreSQL, MongoDB for databases. Regardless of what I like, I usually try to choose the best tool for the job."
    },
    {
      type: 'text',
      title: 'What is a good code for you?',
      answer:
        "Good code is organized. It’s easily understandable. It's modularized and avoids coupling. It should be cohesive and hermetic in terms of its domain. For code that does more complex domain logic, I prefer to encapsulate it and make it technology agnostic, think: hexagonal, onion, or clean architectures."
    },
    {
      type: 'text',
      title: 'What is good architecture for you?',
      answer:
        'Good architecture, first of all, serves the product and not the other way around. It’s a reflection of our software domain. It consists of components that can be owned by one, small team exclusively, from the analysis until production deployments and maintenance. Good architecture is also documented so that it’s understood by many people in the same way.'
    },
    {
      type: 'text',
      title: "Is there a technology you'd like to get in more but you didn't have an opportunity so far?",
      answer:
        'I’d get into service meshes more: Linkerd or Istio. Practice more blue/green or canary deployments, A/B testing. Moreover, the AWS or GCP always can bring something interesting to learn to the table! '
    },
    {
      type: 'text',
      title: 'How do you see yourself in retirement?',
      answer: `I can see several scenarios...
        Thailand or a nearby island and a house overlooking the ocean; a wooden house in the Swiss Alps; a small country house in southern Spain.... hard to decide! So I guess I'll choose all of these options
        Hopefully, by then I'll still be physically active and can go skiing a few times a year.`
    },
    {
      type: 'image',
      details: 'Żywiec integration trip',
      img: {
        src: '/success-stories/story-10/image-2.png',
        alt: 'Żywiec integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.ZYWIEC_INTEGRATION_TRIP
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Post Malone - "Wow."',
          img: {
            src: '/success-stories/story-10/song-1.png',
            alt: 'Post Malone - "Wow."',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/0fcq51a3gOI6gPvzc0YLsk?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=393C3pr2ioY'
        },
        {
          title: 'Kaleo - "Broken Bones"',
          img: {
            src: '/success-stories/story-10/song-2.png',
            alt: 'Kaleo - "Broken Bones"',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/26tY4Jhe3au8bBSR0tgMdM?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=NOletMMI0B4'
        },
        {
          title: 'Sobel - "Fiołkowe Pole"',
          img: {
            src: '/success-stories/story-10/song-3.png',
            alt: 'Sobel - "Fiołkowe Pole"',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/5bQFAKQF1mhcPPThw97kjw?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=1rTV7DCnF3Y'
        }
      ]
    }
  ]
};
