export enum Locales {
  EN = 'en',
  DE = 'de',
  FR = 'fr'
}

export enum LocalesIso {
  EN = 'en-US',
  DE = 'de-DE',
  FR = 'fr-FR'
}
