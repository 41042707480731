import NotFoundImg from '@/assets/errors/oooops.svg';
import { PagesLinks, ErrorPage } from '@/types';

export const error404: ErrorPage = {
  subtitle: 'La page demandée n\'existe pas’',
  description: 'Vous n\'avez pas trouvé ce que vous cherchiez?',
  firstLink: {
    text: 'Retour à l\'accueil’',
    link: PagesLinks.HOME
  },
  secondLink: {
    text: 'Contactez-nous',
    link: PagesLinks.CONTACT
  },
  image: NotFoundImg
};
