import { Emoji } from '@/types';

export const WAVE_EMOJI: Emoji = Object.freeze({
  width: 17,
  height: 17,
  noThumbnail: true,
  alt: 'Waving emoji',
  src: '/emojis/wave.png'
});

export const HAND_EMOJI = Object.freeze({
  width: 17,
  height: 17,
  noThumbnail: true,
  alt: 'Waving emoji',
  src: '/emojis/hand.png'
});

export const CALENDAR_EMOJI = Object.freeze({
  width: 17,
  height: 17,
  noThumbnail: true,
  alt: 'Calendar emoji',
  src: '/emojis/calendar.png'
});

export const HEADPHONES_EMOJI: Emoji = Object.freeze({
  width: 17,
  height: 17,
  noThumbnail: true,
  alt: 'Headphones emoji',
  src: '/emojis/headphones.png'
});
export const PALETTE_EMOJI: Emoji = Object.freeze({
  width: 17,
  height: 17,
  noThumbnail: true,
  alt: "Artist's palette emoji",
  src: '/emojis/palette.png'
});
export const LAPTOP_EMOJI: Emoji = Object.freeze({
  width: 17,
  height: 17,
  noThumbnail: true,
  alt: 'Laptop emoji',
  src: '/emojis/laptop.png'
});

export const MOUNTAIN_EMOJI: Emoji = Object.freeze({
  width: 17,
  height: 17,
  noThumbnail: true,
  alt: 'Mountain emoji',
  src: '/emojis/mountain.png'
});

export const CHRISTMAS_TREE_EMOJI: Emoji = Object.freeze({
  width: 17,
  height: 17,
  noThumbnail: true,
  alt: 'Christmas tree emoji',
  src: '/emojis/christmasTree.png'
});

export const POLAND_FLAG_EMOJI: Emoji = Object.freeze({
  width: 17,
  height: 17,
  noThumbnail: true,
  alt: 'Poland flag emoji',
  src: '/emojis/polandFlag.png'
});

export const ITALY_FLAG_EMOJI: Emoji = Object.freeze({
  width: 17,
  height: 17,
  noThumbnail: true,
  alt: 'Italy flag emoji',
  src: '/emojis/italyFlag.png'
});
