/* eslint-disable max-len */
import { events } from '../events';
import { TimelineSectionContent } from '@/types';

export const timeline: TimelineSectionContent = {
  headline: {
    highlight: 'Our Story',
    before: 'Explore',
    subtitle:
      "See what we've been up to lately and what's ahead. All this aided\nus to become one of the most influential enterprises, and it's worth exploring."
  },
  events: events.homeEvents
};
