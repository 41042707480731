







import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwNewHeadline, DcwGridWrapper } from '@atoms';
import { HeadlineContent, HeadlineSubtitleSizes, HeadlineTypes, HtmlWrappers } from '@/types';
import { isInEnum } from '@/helpers';

interface SectionWithHtmlContent {
  headline: HeadlineContent;
  html: string;
}

export const DcwSectionWithHtml = defineComponent({
  name: 'DcwSectionWithHtml',
  components: { DcwNewHeadline, DcwGridWrapper },
  props: {
    content: {
      type: Object as PropType<SectionWithHtmlContent>,
      required: true
    },
    titleStyle: {
      type: String as PropType<HeadlineTypes>,
      validator: (value: HeadlineTypes) => isInEnum(value, HeadlineTypes),
      default: HeadlineTypes.H2
    },
    titleLevel: {
      type: String as PropType<HeadlineTypes>,
      validator: (value: HeadlineTypes) => isInEnum(value, HeadlineTypes),
      default: HeadlineTypes.H2
    },
    isGrid: {
      type: Boolean,
      default: false
    },
    wrapperTag: {
      type: String as PropType<HtmlWrappers>,
      validator: (value: HtmlWrappers) => isInEnum(value, HtmlWrappers),
      default: HtmlWrappers.SECTION
    }
  },
  setup(props) {
    const headlineProps = {
      ...props.content.headline,
      type: props.titleLevel,
      styledAs: props.titleStyle,
      subtitleSize: HeadlineSubtitleSizes.SMALL,
      isTinted: true
    };

    return { headlineProps };
  }
});

export default DcwSectionWithHtml;
