import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7cd7c672 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _45f6f74a = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _0137a9b7 = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _a106494c = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _ed4cb160 = () => interopDefault(import('../pages/data-processing.vue' /* webpackChunkName: "pages/data-processing" */))
const _1cbd4ba5 = () => interopDefault(import('../pages/help-ukraine.vue' /* webpackChunkName: "pages/help-ukraine" */))
const _245a834d = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _83b0c9a4 = () => interopDefault(import('../pages/team-augmentation.vue' /* webpackChunkName: "pages/team-augmentation" */))
const _fd0d1b0a = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _7122d0c4 = () => interopDefault(import('../pages/career/jobs/index.vue' /* webpackChunkName: "pages/career/jobs/index" */))
const _4ad58e68 = () => interopDefault(import('../pages/career/projects/index.vue' /* webpackChunkName: "pages/career/projects/index" */))
const _d176a49e = () => interopDefault(import('../pages/career/success-stories/index.vue' /* webpackChunkName: "pages/career/success-stories/index" */))
const _afa089d2 = () => interopDefault(import('../pages/career/video-listing.vue' /* webpackChunkName: "pages/career/video-listing" */))
const _341397a8 = () => interopDefault(import('../pages/career/jobs/_id.vue' /* webpackChunkName: "pages/career/jobs/_id" */))
const _0877ea60 = () => interopDefault(import('../pages/career/projects/_id.vue' /* webpackChunkName: "pages/career/projects/_id" */))
const _6a572f4e = () => interopDefault(import('../pages/career/success-stories/_id.vue' /* webpackChunkName: "pages/career/success-stories/_id" */))
const _8de43cfa = () => interopDefault(import('../pages/blog/_id.vue' /* webpackChunkName: "pages/blog/_id" */))
const _d6b022ea = () => interopDefault(import('../pages/technologies/_id.vue' /* webpackChunkName: "pages/technologies/_id" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _7cd7c672,
    name: "about___en"
  }, {
    path: "/blog",
    component: _45f6f74a,
    name: "blog___en"
  }, {
    path: "/career",
    component: _0137a9b7,
    name: "career___en"
  }, {
    path: "/contact",
    component: _a106494c,
    name: "contact___en"
  }, {
    path: "/data-processing",
    component: _ed4cb160,
    name: "data-processing___en"
  }, {
    path: "/help-ukraine",
    component: _1cbd4ba5,
    name: "help-ukraine___en"
  }, {
    path: "/privacy-policy",
    component: _245a834d,
    name: "privacy-policy___en"
  }, {
    path: "/team-augmentation",
    component: _83b0c9a4,
    name: "team-augmentation___en"
  }, {
    path: "/terms-of-service",
    component: _fd0d1b0a,
    name: "terms-of-service___en"
  }, {
    path: "/career/jobs",
    component: _7122d0c4,
    name: "career-jobs___en"
  }, {
    path: "/career/projects",
    component: _4ad58e68,
    name: "career-projects___en"
  }, {
    path: "/career/success-stories",
    component: _d176a49e,
    name: "career-success-stories___en"
  }, {
    path: "/career/video-listing",
    component: _afa089d2,
    name: "career-video-listing___en"
  }, {
    path: "/career/jobs/:id",
    component: _341397a8,
    name: "career-jobs-id___en"
  }, {
    path: "/career/projects/:id",
    component: _0877ea60,
    name: "career-projects-id___en"
  }, {
    path: "/career/success-stories/:id?",
    component: _6a572f4e,
    name: "career-success-stories-id___en"
  }, {
    path: "/blog/:id",
    component: _8de43cfa,
    name: "blog-id___en"
  }, {
    path: "/technologies/:id?",
    component: _d6b022ea,
    name: "technologies-id___en"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index___de"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index___fr"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
