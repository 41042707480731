export interface GAEventObject{
  _event: string;
  event: string;
  status?: string;
}

export const gaHandler = (event: GAEventObject) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (Array.isArray((window as typeof window & { dataLayer?: any[] }).dataLayer)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as typeof window & { dataLayer: any[] }).dataLayer.push(event);
  }
};
