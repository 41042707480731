/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: false,
  categories: [JobOfferCategories.RECRUITMENT_MARKETING],
  projects: [],
  name: 'IT Recruitment Specialist',
  salaryRanges: salaries.recruitment,
  seniorityLevel: SeniorityLevels.MID,
  referrers: {
    [Referrers.JUST_JOIN_IT]: 169145345,
    [Referrers.HELLO_HR]: 169145361,
    [Referrers.BULLDOG_JOB_PL]: 169145339,
    [Referrers.NO_FLUFF_JOBS]: 169145344,
    [Referrers.CARROT_WORK]: 169145338,
    [Referrers.DC_WEBSITE]: 169145343,
    [Referrers.OTHER]: 169145342,
    [Referrers.FACEBOOK_ADS]: 192832849,
    [Referrers.GOOGLE_ADS]: 192832857
  },
  description: [
    {
      title: 'Description',
      text: [
        'At \bDigital Colliers\b, along with our dynamic growth, we are creating a new Recruitment Team. Our ambition, and - since you may be interested – your potential goal for 2022, is to hire 120 IT Specialists and triple the size of Digital Colliers.'
      ]
    },
    {
      title: 'Your role is crucial for growth because you will',
      items: [
        'Be responsible for the \bfull hiring process\b',
        'Establish performance metrics and drive a high-performance culture',
        'Evaluate and improve the recruiting process',
        'Providing \bexcellent candidate experience\b for developers with tech stack such as Kotlin, Java, JavaScript, .NET, Golang'
      ]
    },
    {
      title: 'Must-Have',
      items: [
        'Proven solid experience in IT recruitment (at least 1 year)',
        'Be self-motivated and feels comfortable working with minimal supervision',
        'Be definitely a “social person”, who is eager to network and talk to others and has enough empathy to understand others needs',
        'Autonomously execute multiple tasks and prioritize deadlines to get the job done',
        'Good communication skills in English (minimum B2).'
      ]
    },
    {
      title: 'What else makes us tickle',
      items: [
        'You are ambitious, humble and the startup spirit attracts you',
        'You have a team spirit and the desire to share your knowledge',
        'You are \b#born2solve\b. When you see a problem, you see its solution.'
      ]
    },
    {
      title: 'How we will support you in achieving success',
      items: [
        'Ensure appropriate budget for advertisements and job-boards (\b80% of our actual\b employees applied to us via job-boards)',
        'Provide projects with the most attractive rates and tech stack on the market',
        'Create a \btruly flexible working culture\b with a strong emphasis on work-life balance',
        'Appreciate your experience and give you the ability to skip several levels of our structure and show your ideas directly to the CEO and CTO',
        'Motivate with \battractive bonuses for placements\b (bonus for each placement + quarterly bonus for team achievements)'
      ]
    }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First, \bshort interview\b to know each other and talk about yours and ours expectations'
    },
    {
      title: 'Quick Task',
      description:
        'A \bquick task\b, which will be selecting candidates suitable for the project on the basis of the provided CVs and details'
    },
    {
      title: 'Simulated interview',
      description:
        'A \bsimulated recruitment interview\b that you will conduct with a developer which you chose in the previous task'
    },
    {
      title: 'Last interview',
      description: 'Recruitment Final Stage - \binterview\b with our Head of Finances.'
    }
  ]
};
