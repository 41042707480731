import { people } from '../people';
import { CountryNames, GlobalWorkSectionContent, PagesLinks } from '@/types';

const mobileImageProperties = {
  width: 64,
  height: 64
};

const desktopImageProperties = {
  width: 280,
  height: 290
};

export const globalWork: GlobalWorkSectionContent = {
  headline: {
    before: 'We Work Global',
    subtitle: "We've worked with clients from all over the world building complete, agile teams."
  },
  button: {
    href: PagesLinks.CONTACT,
    text: "Let's talk"
  },
  bg: {
    src: '/team-augmentation/global-work/bg.png',
    width: 359,
    height: 522,
    alt: 'incredible background image'
  },
  items: [
    {
      ...people.lukaszS,
      flag: CountryNames.UK,
      images: {
        mobile: {
          src: '/team-augmentation/global-work/person-1-avatar.png',
          alt: `Photo of ${people.lukaszS.name} ${people.lukaszS.surname} smiling.`,
          ...mobileImageProperties
        },
        desktop: {
          src: '/team-augmentation/global-work/person-1.png',
          alt: `Photo of ${people.lukaszS.name} ${people.lukaszS.surname} smiling.`,
          ...desktopImageProperties
        }
      }
    },
    {
      ...people.przemyslawD,
      flag: CountryNames.GERMANY,
      images: {
        mobile: {
          src: '/team-augmentation/global-work/person-2-avatar.png',
          alt: `Photo of ${people.przemyslawD.name} ${people.przemyslawD.surname} smiling.`,
          ...mobileImageProperties
        },
        desktop: {
          src: '/team-augmentation/global-work/person-2.png',
          alt: `Photo of ${people.przemyslawD.name} ${people.przemyslawD.surname} smiling.`,
          ...desktopImageProperties
        }
      }
    },
    {
      ...people.leopoldG,
      flag: CountryNames.FRANCE,
      images: {
        mobile: {
          src: '/team-augmentation/global-work/person-3-avatar.png',
          alt: `Photo of ${people.leopoldG.name} ${people.leopoldG.surname} smiling.`,
          ...mobileImageProperties
        },
        desktop: {
          src: '/team-augmentation/global-work/person-3.png',
          alt: `Photo of ${people.leopoldG.name} ${people.leopoldG.surname} smiling.`,
          ...desktopImageProperties
        }
      }
    }
  ]
};
