// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...args: any) => any>(func: F, waitFor = 300) => {
  let timeout: NodeJS.Timeout;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const debounced = (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};
