/* eslint-disable max-len */
import { Born2SolveSectionContent, PagesLinks } from '@/types';
import logo from '@/assets/career/born-2-solve/logo.svg';

export const born2Solve: Born2SolveSectionContent = {
  headline: {
    before: 'Mentoring Program',
    subtitle:
      'Our internal program - Born2Solve Academy, provides mentorship\nand support for our newly joined office members. Grab a hand of an\nexperienced leader from day one and get into projects in entire confidence.'
  },
  videoId: '683803397',
  firstButton: 'Watch Video',
  secondButton: {
    text: 'Join Us',
    href: PagesLinks.JOBS
  },
  images: {
    xs: {
      src: '/career/born-2-solve/team.png',
      width: 587,
      height: 332,
      alt: 'Our Team Will Help You!'
    },
    xl: {
      src: '/career/born-2-solve/team-xl.png',
      width: 714,
      height: 335,
      alt: 'Our Team Will Help You!'
    }
  },
  logo,
  items: [
    {
      before: 'Internal Courses',
      subtitle: 'We offer various programming courses designed by\nour Senior Team Leaders.'
    },
    {
      before: 'Hackathons',
      subtitle: 'Hungry for competition? Check your skills against\nother employees in the quarterly Hackathons.'
    },
    {
      before: 'Full-time Support',
      subtitle: 'Participate in regular discussions with your mentor\nand reach out for any advice you might have!'
    },
    {
      before: 'We are Here for You',
      subtitle: "Your voice is our everything. We offer two-way\nfeedback to discover what's good and what's not."
    }
  ]
};
