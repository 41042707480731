/* eslint-disable max-len */
import { CountryNames, OfficeModel } from '@/types';

export const vienna: OfficeModel = {
  name: 'Graben 19',
  country: CountryNames.AUSTRIA,
  city: 'Vienna',
  address: {
    first: 'Graben 19',
    second: '1010'
  },
  map:
    'https://www.google.com/maps/place/Graben+19,+1010+Wien,+%D0%90%D0%B2%D1%81%D1%82%D1%80%D0%B8%D1%8F/@48.2089613,16.3678583,3a,75y,27.11h,90t/data=!3m6!1e1!3m4!1stwAHcv-ZU8HEHOyZcoh0Bw!2e0!7i13312!8i6656!4m5!3m4!1s0x476d07985e3d560b:0x5db22f3affdde1d3!8m2!3d48.2094433!4d16.3682343'
};
