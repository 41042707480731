import { PositionBenefitsSection } from '@/types';

export const benefits: PositionBenefitsSection = {
  headline: {
    before: 'Perks & Benefits',
    subtitle: 'Everyone in the team is an individual who is a team player. We share our ideas.'
  },
  items: [
    {
      text: '100% Remote\nWork Scheme',
      icon: {
        src: '/common/benefits/benefit-2.png',
        alt: '100% Remote',
        width: 48,
        height: 48
      }
    },
    {
      text: 'International\nIntegration Trips',
      icon: {
        src: '/common/benefits/benefit-12.png',
        alt: 'Integration Trips',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Training &\nMentoring',
      icon: {
        src: '/common/benefits/benefit-10.png',
        alt: 'Mentoring',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Multisport &\nSocial Activities',
      icon: {
        src: '/common/benefits/benefit-9.png',
        alt: 'Multisport',
        width: 48,
        height: 48
      }
    },
    {
      text: '20 Days of\nPaid Vacation',
      icon: {
        src: '/common/benefits/benefit-7.png',
        alt: 'paid vacations',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Structured Career\nProgression',
      icon: {
        src: '/common/benefits/benefit-13.png',
        alt: 'Structured Career Progression',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Personal Office\nDevice Equipment',
      icon: {
        src: '/common/benefits/benefit-6.png',
        alt: 'Personal equipment',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Custom Slack\nEmoji Icon',
      icon: {
        src: '/common/benefits/benefit-14.png',
        alt: 'Emoji',
        width: 48,
        height: 48
      }
    },
    {
      text: 'Flexible\nWorking Hours',
      icon: {
        src: '/common/benefits/benefit-4.png',
        alt: 'Flexible working hours',
        width: 48,
        height: 48
      }
    }
  ]
};
