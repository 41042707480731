

































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { DcwFormError } from '../FormError/DcwFormError.vue';
import { isInEnum } from '@/helpers';
import { TextInputTypes } from '@/types';

export const DcwFormInput = defineComponent({
  name: 'DcwFormInput',
  components: { DcwFormError },
  props: {
    name: {
      type: String
    },
    type: {
      type: String,
      validator: (value: TextInputTypes) => isInEnum(value, TextInputTypes),
      default: TextInputTypes.TEXT,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      required: false
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const isRequired = computed(() => props.rules?.includes('required'));
    const inputValidationProviderRef = ref();

    const handleInputChange = (e: Event) => {
      const target = e.target as HTMLInputElement;
      emit('input', target.value);
      inputValidationProviderRef.value.reset();
    };

    return { TextInputTypes, isRequired, handleInputChange, inputValidationProviderRef };
  }
});

export default DcwFormInput;
