/* eslint-disable max-len */
import { Project, ProjectSectionsId, TechnologyNames } from '@/types';

export const project: Project = {
  name: 'Digital Healthcare Platform',
  color: '#7CBBE7',
  colors: {
    heroBg: '#ECF5FB',
    light: '#D0E4F1',
    primary: '#7CBBE7'
  },
  description:
    `We are developing a cutting-edge project for the healthcare industry. The modern
    interactive tool delivers information and communication for patients in their medical journey.`,
  badges: ['30+ Engineers', '4 Teams'],
  ctaText: {
    career: {
      before: 'Mid and Senior Positions',
      highlight: 'Up\xA0to\xA030\xA0000\xA0PLN\xA0+\xA0VAT'
    },
    home: {
      before: 'We built 4 strong development teams for',
      highlight: 'Fintech Industry'
    }
  },
  categories: [TechnologyNames.VUE_JS, TechnologyNames.TYPESCRIPT, TechnologyNames.KOTLIN, TechnologyNames.JAVA],
  isRemote: true,
  isNew: false,
  technologies: {
    mobile: [
      TechnologyNames.SWIFT,
      TechnologyNames.SWIFT_UI,
      TechnologyNames.JSON,
      TechnologyNames.JAVA,
      TechnologyNames.KOTLIN,
      TechnologyNames.JETPACK_COMPOSE
    ],
    backend: [TechnologyNames.PHP, TechnologyNames.MY_SQL, TechnologyNames.LARAVEL],
    frontend: [TechnologyNames.VUE_JS, TechnologyNames.TYPESCRIPT, TechnologyNames.REACT]
  },
  fields: {
    salary: {
      label: 'net B2B',
      value: 'Up to 30 000 PLN'
    },
    client: {
      label: 'Client',
      value: 'Germany'
    },
    industry: {
      label: 'Industry',
      value: ['Healthcare']
    },
    status: {
      label: 'Project phase',
      value: 'Initiation'
    },
    duration: {
      label: 'Duration',
      value: 'Long Term'
    },
    projectStatus: {
      label: 'Working Location',
      value: '100% Remote'
    }
  },
  images: {
    main: {
      src: '/projects/digital-healthcare-platform/project.png',
      alt: 'project',
      width: 950,
      height: 421
    },
    preview: {
      src: '/projects/digital-healthcare-platform/preview.png',
      alt: 'project preview',
      width: 1074,
      height: 262
    }
  },
  sections: {
    description: {
      id: ProjectSectionsId.DESCRIPTION,
      title: 'Project Description',
      headline: {
        before: 'Project Description'
      },
      html: `
      <p>
        In this project, we are partnering with one of the leading technology providers for the <strong>healthcare</strong> industry in
        <br/>
        Germany. Our job focuses on developing an <strong>online platform and mobile application</strong> that are helping to
        <br/>
        digitally <strong>navigate patients</strong> through their medical care journey.
      </p>

      <p>
        The main product in the project is a downloadable application that connects people undergoing their
        <br/>
        treatment with the <strong>multi-faceted</strong> digital system. Its features allow the users to take up such actions as
        <br/>
        checking the details of their care plan, booking appointments, and digitally registering at the hospital.
        <br/>
        Moreover, during their hospital stay, the application helps them to navigate through the building, provides
        <br/>
        essential information, and even takes care of the patient’s entertainment.
      </p>

      <p>
        By joining our team for this assignment, you will have the opportunity to create a product for the rapidly
        <br/>
        growing, socially significant industry. Due to solid funding and great social significance, healthcare projects
        <br/>
        are also recession-resistant and have a high potential for constant development.
      </p>
    `
    },
    technologies: {
      id: ProjectSectionsId.TECHNOLOGIES,
      title: 'Tech Stack',
      headline: {
        before: 'Tech Stack'
      },
      bg: {
        src: '/projects/digital-healthcare-platform/bg.png',
        width: 335,
        height: 320,
        alt: 'decorative image'
      },
      description: {
        before: 'Technology',
        subtitle: `In terms of architecture, we are building separate teams that
            will focus on Frontend, Backend, and Mobile technologies (both
            Android and iOS). `
      },
      mobile: {
        headline: {
          before: 'Mobile',
          subtitle:
            'Technology stack for iOS mobile consists of Swift, SwiftUI, and JSON. Android mobile is done in Java, Kotlin, Jetpack Compose, and JSON.'
        }
      },
      backend: {
        headline: {
          before: 'Backend',
          subtitle:
            'In the backend we work in 3 core technologies: PHP, MySQL, and Laravel.'
        }
      },
      frontend: {
        headline: {
          before: 'Front',
          subtitle:
            'In the frontend, Vue is the technology that we will work with.'
        }
      }
    },
    howWeWork: {
      id: ProjectSectionsId.HOW_WE_WORK,
      title: 'How do We Work',
      headline: {
        before: 'How do We Work'
      },
      html: `
      <p>
        We work in small, strongly cooperative teams. By creating a working group of 5 people maximum, we create
        <br/>
        the opportunity to do our job transparently and efficiently. From the organizational point of view, we work in
        <br/>
        Scrum. During daily meetings and weekly statuses we discuss progress, milestones, and also problems
        <br/>
        encountered. We don't spend time in unnecessary meetings or calls that could be a message on Slack.
      </p>
      `
    },
    challenges: {
      id: ProjectSectionsId.CHALLENGES,
      title: 'Challenges',
      headline: {
        before: 'Challenges That You Will Meet'
      },
      html: `
      <ul>
        <li>
          Solve complex architectural problems to deliver scalable and maintainable code
        </li>
        <li>
          Meet (and exceed!) the expectations of the international, well-renowned client
        </li>
        <li>
          Develop constantly improving functionalities of the application, as well as the online platform
        </li>
        <li>
          Striving for the superior quality of the product, having in mind the needs of its future users
        </li>
      </ul>
    `
    },
    team: {
      id: ProjectSectionsId.TEAM,
      title: 'Meet Our Team',
      headline: { before: 'Meet Our Team' },
      members: [
        {
          img: {
            src: '/projects/digital-healthcare-platform/team/member-1.png',
            alt: 'Image of Wiktor',
            width: 236,
            height: 224
          },
          name: 'Wiktor',
          position: 'Head of People & Operations'
        },
        {
          img: {
            src: '/projects/digital-healthcare-platform/team/member-2.png',
            alt: 'Image of Krzysztof',
            width: 236,
            height: 224
          },
          name: 'Krzysztof',
          position: 'Team Leader'
        },
        {
          img: {
            src: '/projects/digital-healthcare-platform/team/member-3.png',
            alt: 'Image of Bartosz',
            width: 236,
            height: 224
          },
          name: 'Bartosz',
          position: 'Head of Recruitment'
        }
      ]
    },
    openPositions: {
      id: ProjectSectionsId.OPEN_POSITIONS,
      title: 'Open Positions',
      headline: {
        before: 'Open Positions'
      }
    },
    othersProjects: {
      id: ProjectSectionsId.OTHERS_PROJECTS,
      title: 'Other Projects',
      headline: {
        before: 'Choose From Other',
        highlight: 'Great Projects'
      }
    }
  }
};
