import { PagesLinks, UpHeadingContent } from '@/types';
import UA from '@/assets/flags/ua.svg';

export const upHeading: UpHeadingContent = {
  text: 'Digital Colliers steht an der Seite der Ukraine und ihrer Menschen',
  icon: UA,
  link: {
    path: PagesLinks.UKRAINE,
    text: 'Mach mit'
  },
  color: '#F1D663'
};
