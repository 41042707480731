import { onMounted, ref, Ref } from '@nuxtjs/composition-api';
import { VueVimeoPlayer, CustomEvents } from '@/types';

export const useVimeoPlayer = (player: Ref<VueVimeoPlayer> | Ref<null>) => {
  const isVideoPlaying = ref(false);

  const play = async () => {
    if (!player.value) {
      return;
    }

    await player.value.$refs.player.play();
  };

  const pause = async () => {
    if (!player.value) {
      return;
    }

    await player.value.$refs.player.pause();
  };

  const playPause = async () => {
    if (player.value && !player.value.$listeners[CustomEvents.VIDEO_STARTED_PLAYING] && !isVideoPlaying.value) {
      play();
    }

    if (player.value && !player.value.$listeners[CustomEvents.VIDEO_GOT_PAUSED] && isVideoPlaying.value) {
      pause();
    }

    if (!player.value) {
      return await Promise.reject(new Error('Missing player ref'));
    }
  };

  onMounted(() => {
    if (!player.value) {
      return;
    }

    player.value.$on(CustomEvents.VIDEO_GOT_PAUSED, () => {
      isVideoPlaying.value = false;
    });

    player.value.$on(CustomEvents.VIDEO_STARTED_PLAYING, () => {
      isVideoPlaying.value = true;
    });
  });

  return { playPause, isVideoPlaying, pause, play };
};
