import { othersStories } from './otherStories';
import { testimonials } from './testimonials';
import { footer } from './footer';
import { localeBanner } from './localeBanner';
import { jobOffer } from './jobOffer';
import { joinUs } from './joinUs';
import { layoutCta } from './layoutCta';
import { technologies } from './technologies';
import { textPageCta } from './textPageCta';
import { upHeading } from './upHeading';

export const ui = {
  footer,
  localeBanner,
  jobOffer,
  testimonials,
  joinUs,
  othersStories,
  layoutCta,
  technologies,
  textPageCta,
  upHeading
};
