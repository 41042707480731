














import { defineComponent, ref } from '@nuxtjs/composition-api';
import { CustomEvents, ProjectSelectors } from '@/types';

export const DcwVimeoPlayer = defineComponent({
  name: 'DcwVimeoPlayer',
  props: {
    videoId: {
      type: String,
      required: true
    }
  },
  emits: [CustomEvents.VIDEO_STARTED_PLAYING, CustomEvents.VIDEO_GOT_PAUSED],
  setup() {
    const player = ref(null);

    const hideDcwHeader = (e: {fullscreen: boolean}) => {
      const header = document.querySelector(ProjectSelectors.HEADER) as HTMLHeadingElement;
      if (!header) {
        return;
      }
      header.style.zIndex = e.fullscreen ? '0' : '';
    };

    return { player, CustomEvents, hideDcwHeader };
  }
});

export default DcwVimeoPlayer;

