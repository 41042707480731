import { successStoriesService } from '@/service/model';
/* eslint-disable max-len */
import { SuccessStoriesSectionContent } from '@/types';

export const successStories: SuccessStoriesSectionContent = {
  headline: {
    before: 'Inspiring',
    highlight: 'Stories',
    subtitle:
      'We believe that behind every success, there is a story. These stories motivate our prospective individuals and offer unlimited courage when facing future challenges.'
  },
  stories: successStoriesService.content
};
