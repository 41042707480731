/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

export const story: SuccessStory = {
  name: 'Łukasz',
  images: {
    og: '/covers/success-story/story-8.jpg',
    avatar: {
      src: '/success-stories/story-8/avatar.png',
      alt: 'Łukasz',
      width: 32,
      height: 32
    },
    cover: {
      src: '/success-stories/story-8/cover.jpg',
      alt: 'Łukasz cover',
      height: 608
    },
    slider: {
      src: '/success-stories/story-8/slider.png',
      alt: 'Łukasz',
      width: 317,
      height: 224
    },
    listing: {
      src: '/success-stories/story-8/listing.png',
      alt: 'Łukasz',
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'May 12, 2022',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'Frontend Developer',
  details: `I’ve sent CVs to a couple of companies. One of them was DC which I found through JJIT.
            I got a recruitment task that was quite challenging but luckily I managed. I got a few more
            offers but after simple analysis the choice was obvious. Till today I didn’t change my mind.
            I would not change my decision.`,
  shortStory:
    'Before I started work as an IT developer I was splitting my time between programming and my second passion - music.',
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer:
        'Before I started work as an IT developer I was basically learning every day. Back then I was splitting my time quite evenly between programming and my second passion - music. It actually still continues as I am ongoingly dedicating much time to extending my knowledge in those directions. '
    },
    {
      type: 'text',
      title: 'What do you do in DC?',
      answer:
        'In DC I am a frontend developer. Currently, my team is dealing with one of the biggest projects in the company. We are responsible for creating the visual interface of an app dedicated to creating new shopify-like instances.'
    },
    {
      type: 'text',
      title: 'What did your recruitment process look like? Why did you choose DC?',
      answer:
        'I’ve sent CVs to a couple of companies. One of them was DC which I found through JJIT. I got a recruitment task that was quite challenging but luckily I managed. I got a few more offers but after simple analysis the choice was obvious. Till today I didn’t change my mind. I would not change my decision.'
    },
    {
      type: 'image',
      details: 'Digital Colliers in Office',
      img: {
        src: '/success-stories/story-8/image-1.png',
        alt: 'Digital Colliers in Office',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.DC_IN_OFFICE
    },
    {
      type: 'text',
      title: 'What was the biggest challenge you had to overcome?',
      answer: `The hardest was to grow into the project. More precisely it was challenging to get used to the standards of serious applications in the production phases. The imagination of mass people using the solution you are working with is something you really need to realize. The project itself also had its difficulties. It was only after months that I felt really confident that there is no task that I cannot figure out eventually (except PWAs of course).
        I feel like nothing would be really possible without my teammate’s help. Our team is very responsive in communication and just eager to help each other.`
    },
    {
      type: 'text',
      title: 'What are the technologies that you’d like to learn but you lacked the opportunity of so far?',
      answer: `For sure in the future, I’d like to explore DevOps more. There are certain tools that we use, naming Docker or CI/CD that drove my attention. Especially with Docker, I am creating a certain grind on this topic recently. I am super excited about it and other things related to continuous integration.
        There are also technologies like RabbitMQ message broker that I get to hear about quite often but I don’t know much about them at this point. There is still really a lot to explore.`
    },
    {
      type: 'text',
      title: 'What have you learned in DC?',
      answer:
        'Design patterns. Design patterns and other standards that now allow me to introduce them to my own personal projects. I am able to trust that the things I’m learning from my team have a particular industry-wise high standard. I have at least 3 people with extraordinary experience in my team and it helps a lot. '
    },
    {
      type: 'text',
      title: 'Where do you see yourself in 10 years?',
      answer:
        'I probably see myself abroad. It would definitely be Sweden. It started with my passion for the Swedish language and now I want to take it further. I am allowing myself however the thought that I would eventually come back to Poland. Who knows! In relation to business, I would like to be in a position where creating something new that I have in my head wouldn’t mean a new process of learning something new in terms of development. I want to have solid and complex knowledge that would allow me to be autonomous.'
    },
    {
      type: 'image',
      details: 'Digital Colliers in Office',
      img: {
        src: '/success-stories/story-8/image-2.png',
        alt: 'Digital Colliers in Office',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.DC_IN_OFFICE
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Marauda - RIP',
          img: {
            src: '/success-stories/story-8/song-1.png',
            alt: 'Marauda - RIP',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/2Fgownn5zYk0tnIxDm4Jxx',
          ytLink: 'https://www.youtube.com/watch?v=-lazqMtiSQQ'
        },
        {
          title: 'Monrroe - Dawning',
          img: {
            src: '/success-stories/story-8/song-2.png',
            alt: 'Monrroe - Dawning',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/album/2PZyenM6EShEZl3iKqHn8P',
          ytLink: 'https://www.youtube.com/watch?v=mlQX_MGA1gc'
        },
        {
          title: 'Mechawolf - Troffe Morf',
          img: {
            src: '/success-stories/story-8/song-3.png',
            alt: 'Mechawolf - Troffe Morf',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/album/6F1SDrq5ZvYTxataPHxqd4',
          ytLink: 'https://www.youtube.com/watch?v=eYXZ_EI3YII'
        }
      ]
    }
  ]
};
