import { DateTime } from 'luxon';

export const formatDateString = (date: string): string => {
  const dateTime = DateTime.fromISO(date).setLocale('en');
  return `${dateTime.monthShort} ${dateTime.day}, ${dateTime.year}`;
};

export const getMinDate = (): Date => {
  const tomorrow = DateTime.local().plus({ days: 1 });

  const nextWorkingDay = tomorrow.weekday !== 6 && tomorrow.weekday !== 7
    ? tomorrow
    : tomorrow.weekday === 6
      ? tomorrow.plus({ days: 2 })
      : tomorrow.plus({ days: 1 });

  return nextWorkingDay.toJSDate();
};

export const isFutureDate = (date: string) => {
  const today = DateTime.now();
  const dateISO = DateTime.fromISO(date);

  return today < dateISO;
};
