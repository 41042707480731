import { getSenioritySalary } from './helper';

const contentManager = getSenioritySalary([
  [3000, 5000],
  [5000, 7000],
  [9000, 13000],
  [13000, 19000]
]);

const recruitment = getSenioritySalary([
  [3000, 6000],
  [6000, 12000],
  [12000, 16000],
  [16000, 24000]
]);

const backend = getSenioritySalary([
  [5000, 10000],
  [10000, 20000],
  [20000, 25000],
  [26000, 32000]
]);

const productOwner = getSenioritySalary([
  [6000, 8000],
  [9000, 16000],
  [16000, 30000],
  [30000, 40000]
]);

const react = getSenioritySalary([
  [6000, 12000],
  [12000, 20000],
  [20000, 30000],
  [30000, 40000]
]);

const tester = getSenioritySalary([
  [3000, 4000],
  [6000, 12000],
  [14000, 20000],
  [21000, 25000]
]);

const nodeJS = getSenioritySalary([
  [5000, 10000],
  [10000, 20000],
  [20000, 30000],
  [30000, 40000]
]);

const devOps = getSenioritySalary([
  [9000, 14000],
  [16000, 20000],
  [20000, 30000],
  [40000, 50000]
]);

const dotNet = getSenioritySalary([
  [4000, 6000],
  [8000, 16000],
  [16000, 30000],
  [30000, 40000]
]);

const graphicDesigner = getSenioritySalary([
  [3000, 5000],
  [5000, 8000],
  [10000, 16000],
  [18000, 20000]
]);

const reactNative = getSenioritySalary([
  [5000, 10000],
  [10000, 20000],
  [20000, 30000],
  [30000, 40000]
]);

const vue = getSenioritySalary([
  [3000, 6000],
  [8000, 16000],
  [20000, 24000],
  [24000, 36000]
]);

const android = getSenioritySalary([
  [6000, 12000],
  [12000, 20000],
  [20000, 30000],
  [30000, 40000]
]);

const businessAnalyst = getSenioritySalary([
  [3000, 9000],
  [9000, 16000],
  [16000, 24000],
  [24000, 33000]
]);

const fullstackVuePhp = getSenioritySalary([
  [6000, 12000],
  [12000, 20000],
  [20000, 30000],
  [30000, 40000]
]);

const golang = getSenioritySalary([
  [5000, 10000],
  [10000, 20000],
  [20000, 40000],
  [40000, 50000]
]);

const productDesigner = getSenioritySalary([
  [5000, 8000],
  [10000, 15000],
  [16000, 30000],
  [30000, 40000]
]);

const productManager = getSenioritySalary([
  [5000, 10000],
  [10000, 16000],
  [16000, 30000],
  [30000, 40000]
]);

export const salaries = {
  android,
  backend,
  businessAnalyst,
  contentManager,
  devOps,
  dotNet,
  fullstackVuePhp,
  graphicDesigner,
  recruitment,
  nodeJS,
  productOwner,
  reactNative,
  react,
  vue,
  tester,
  golang,
  productDesigner,
  productManager
};
