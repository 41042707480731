/* eslint-disable max-len */
const pagesDescription: Record<string, string> = {
  home:
    'We design and build custom web software solutions. We set up dedicated product development teams. Our focus throughout the development cycle is always business.',
  contact:
    'Regardless of whether you have a business inquiry or you want to become one of us, our specialists are there ready to answer your questions.',
  jobs:
    'Browse our available jobs and find a position that suits you best. Alternatively, see our available projects and apply directly to them.',
  career:
    'Kick-start or develop your career with us. Browse available projects and apply directly to them. We will find you the right role and career development path.',
  about: 'About page of Digital Colliers website',
  projects: 'Project Listing page description',
  successStory: 'Success Story page description',
  successStories: 'Our Success Stories',
  job: 'We are currently looking for an experienced #{target} to join our team',
  videoListing: 'Video Listing'
};

const pagesTitles: Record<string, string> = {
  home: 'Agile Software Development Teams',
  jobs: 'Jobs',
  career: 'Career',
  contact: 'Contact',
  about: 'About',
  projects: 'Projects',
  successStory: 'Success Story',
  successStories: 'Success Stories',
  teamAugmentation: 'Team Augmentation',
  blog: 'Blog',
  videoListing: 'Video Listing'
};

export const meta = { pagesDescription, pagesTitles };
