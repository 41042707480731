

















































































































import { ref, watch, PropType, computed, reactive, onMounted, defineComponent } from '@nuxtjs/composition-api';
import { DcwImage, DcwLabel, DcwEventDot, DcwButton, DcwTooltip, DcwNewHeadline } from '@atoms';
import { DcwContentBlock } from '@molecules';
import {
  EventDot,
  LabelTypes,
  Directions,
  PagesLinks,
  TimetableCard,
  ButtonVariants,
  EventCardStates,
  TimetableCardVariant,
  ContentBlockSpacings
} from '@/types';
import { formatDateString, isInEnum } from '@/helpers';
import { useBreakpoints, useLocales } from '@/composables';
import { WAVE_EMOJI } from '@/consts';

export const DcwExploreOurStoryCard = defineComponent({
  name: 'DcwExploreOurStoryCard',
  components: { DcwImage, DcwLabel, DcwButton, DcwTooltip, DcwEventDot, DcwNewHeadline, DcwContentBlock },
  props: {
    data: {
      type: Object as PropType<TimetableCard>,
      required: true
    },
    eventDot: {
      type: Object as PropType<EventDot>,
      required: true
    },
    state: {
      type: String as PropType<EventCardStates>,
      required: true,
      validator: (value: EventCardStates) => isInEnum(value, EventCardStates)
    },
    cardIndex: {
      type: Number,
      required: true
    },
    cardVariant: {
      type: String as PropType<TimetableCardVariant>,
      default: TimetableCardVariant.TWO_COLUMNS,
      validator: (value: TimetableCardVariant) => isInEnum(value, TimetableCardVariant)
    },
    isLastOne: {
      type: Boolean,
      required: false
    },
    observer: {
      type: Object as PropType<{ observe: IntersectionObserver }>,
      required: true
    }
  },
  emits: ['transitionDone', 'seeMoreClicked'],
  setup(props, { emit }) {
    const { isMobile, currentWidth } = useBreakpoints();

    const animationState = reactive({
      bigVariantSize: false,
      hideContent: false,
      bigVariantContent: false,
      done: true
    });

    const forceCollapseOfDateProgress = ref(false);

    watch(
      () => props.eventDot,
      (newValue) => {
        if (newValue.index === newValue.closestEventIndex && newValue.activeEventIndex > newValue.closestEventIndex) {
          forceCollapseOfDateProgress.value = true;
          return;
        }

        forceCollapseOfDateProgress.value = false;
      }
    );

    watch(
      () => props.state,
      (newValue) => {
        if (newValue === EventCardStates.ACTIVE) {
          animationState.done = false;
          animationState.hideContent = true;

          setTimeout(() => {
            animationState.bigVariantContent = true;
            animationState.bigVariantSize = true;
          }, 300);

          setTimeout(() => {
            animationState.hideContent = false;
            animationState.done = true;
          }, 600);
        }
        if (newValue === EventCardStates.PREVIOUS) {
          animationState.done = false;
          animationState.hideContent = true;

          setTimeout(() => {
            animationState.bigVariantContent = false;
            animationState.bigVariantSize = false;
          }, 300);

          setTimeout(() => {
            animationState.hideContent = false;
            animationState.done = true;
            emit('transitionDone');
          }, 600);
        }
      }
    );

    const calibrateCardToBeInCenter = ref(false);

    const toggleCalibrate = () => {
      if (props.state === EventCardStates.ACTIVE) {
        calibrateCardToBeInCenter.value = true;
        setTimeout(() => {
          calibrateCardToBeInCenter.value = false;
        }, 0);
      }
    };

    watch(currentWidth, () => {
      toggleCalibrate();
    });

    onMounted(() => {
      if (props.state === 'active') {
        animationState.bigVariantContent = true;
        animationState.bigVariantSize = true;

        animationState.hideContent = false;
        animationState.done = true;
      }
      toggleCalibrate();
    });

    const getWidthOfBigCardForTablet = computed(() => {
      if (currentWidth.value < 1025 && currentWidth.value > 768) {
        const gapBetweenSlides = 24;
        return currentWidth.value - 2 * gapBetweenSlides;
      }
    });

    const timetableCardWrapperRef = ref<HTMLDivElement | null>(null);

    onMounted(() => {
      if (timetableCardWrapperRef.value && props.observer && process.client) {
        props.observer.observe.observe(timetableCardWrapperRef.value);
      }
      toggleCalibrate();
    });

    const { translation } = useLocales();

    return {
      isMobile,
      LabelTypes,
      Directions,
      PagesLinks,
      WAVE_EMOJI,
      translation,
      ButtonVariants,
      animationState,
      formatDateString,
      TimetableCardVariant,
      ContentBlockSpacings,
      timetableCardWrapperRef,
      calibrateCardToBeInCenter,
      forceCollapseOfDateProgress,
      getWidthOfBigCardForTablet
    };
  }
});

export default DcwExploreOurStoryCard;
