

























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { DcwButton, DcwText, DcwImage } from '@atoms';
import { DcwPersonData, DcwVideoModal } from '@molecules';
import { ButtonVariants, PersonSlideProps } from '@/types';
import { desktopNavigateTo } from '@/helpers';
import PlayIcon from '@/assets/icons/streamline-icons/play-icon.svg';

export const DcwPeopleSlide = defineComponent({
  name: 'DcwPeopleSlide',
  components: { DcwButton, DcwText, DcwImage, DcwPersonData, DcwVideoModal, PlayIcon },
  props: {
    slide: {
      type: Object as PropType<PersonSlideProps>,
      required: true
    }
  },
  setup(props) {
    const modalShow = ref(false);

    const buttonProps = {
      text: props.slide.button?.text,
      isLink: true,
      href: props.slide.button?.href,
      variant: ButtonVariants.TERTIARY
    };

    const clickHandler = () => {
      if (props.slide.button?.href) {
        desktopNavigateTo(props.slide.button.href, false);
      }
    };

    return { buttonProps, clickHandler, modalShow };
  }
});

export default DcwPeopleSlide;
