var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"timetableCardWrapperRef",staticClass:"dcw-explore-our-story-card-wrapper",class:[
    { 'dcw-explore-our-story-card-wrapper--big-variant-size': _vm.animationState.bigVariantSize },
    { 'dcw-explore-our-story-card--center-card': _vm.calibrateCardToBeInCenter }
  ],style:([
    _vm.getWidthOfBigCardForTablet && _vm.animationState.bigVariantSize ? { minWidth: (_vm.getWidthOfBigCardForTablet + "px") } : false
  ]),attrs:{"data-index":_vm.eventDot.index}},[_c('transition',{attrs:{"name":"dcw-explore-our-story__fade"}},[(_vm.data.hasWeAreHiringTooltip && !_vm.animationState.hideContent && _vm.animationState.bigVariantSize)?_c('DcwTooltip',{staticClass:"dcw-explore-our-story-card-wrapper__tooltip",attrs:{"path":_vm.PagesLinks.CAREER,"direction":_vm.Directions.BOTTOM}},[_c('span',[_vm._v(_vm._s(_vm.translation.tooltip.weAreHiring))]),_vm._v(" "),_c('DcwImage',_vm._b({staticClass:"dcw-explore-our-story-card-wrapper__tooltip-emoji"},'DcwImage',_vm.WAVE_EMOJI,false))],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"dcw-explore-our-story-card",class:[
      ("dcw-explore-our-story-card--" + _vm.cardVariant),
      { 'dcw-explore-our-story-card--big-variant-size': _vm.animationState.bigVariantSize }
    ],style:([_vm.getWidthOfBigCardForTablet && _vm.animationState.bigVariantSize ? { width: '100%' } : false])},[_c('transition',{attrs:{"name":"dcw-explore-our-story__fade"}},[(!_vm.animationState.hideContent)?_c('div',{staticClass:"dcw-explore-our-story-card__description"},[_c('div',{staticClass:"dcw-explore-our-story-card__labels-wrapper"},_vm._l((_vm.data.labels),function(label,index){return _c('DcwLabel',{key:index,attrs:{"text":label,"type":_vm.LabelTypes.INVERTED_BACKGROUND}})}),1),_vm._v(" "),(_vm.animationState.bigVariantSize)?_c('DcwContentBlock',{attrs:{"content":_vm.data.content,"spacing":_vm.ContentBlockSpacings.LARGE},scopedSlots:_vm._u([{key:"headline",fn:function(){return [_c('DcwNewHeadline',_vm._b({},'DcwNewHeadline',_vm.data.content,false))]},proxy:true}],null,false,4198594846)}):_c('DcwContentBlock',{attrs:{"spacing":_vm.ContentBlockSpacings.LARGE},scopedSlots:_vm._u([{key:"headline",fn:function(){return [_c('DcwNewHeadline',{attrs:{"before":_vm.data.content.before}})]},proxy:true},{key:"secondButton",fn:function(){return [_c('DcwButton',{attrs:{"text":"See more","variant":_vm.ButtonVariants.TERTIARY},nativeOn:{"click":function($event){return _vm.$emit('seeMoreClicked')}}})]},proxy:true}],null,false,995637204)})],1):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"dcw-explore-our-story__fade"}},[(!_vm.animationState.hideContent && _vm.animationState.bigVariantSize)?_c('div',{staticClass:"dcw-explore-our-story-card__image-wrapper",class:[
          ( _obj = {}, _obj[("dcw-explore-our-story-card__image-wrapper--" + (_vm.data.imagesLayout))] = _vm.animationState.bigVariantSize && _vm.data.imagesLayout !== undefined, _obj ),
          ( _obj$1 = {}, _obj$1[("dcw-explore-our-story-card__image-wrapper--" + (_vm.data.images.length))] = _vm.animationState.bigVariantSize, _obj$1 )
        ]},_vm._l((_vm.data.images),function(image,index){return _c('DcwImage',_vm._b({key:index},'DcwImage',image,false))}),1):_vm._e()]),_vm._v(" "),_c('client-only',[_c('transition',{attrs:{"name":"dcw-explore-our-story__fade"}},[(
            !_vm.animationState.hideContent && _vm.cardVariant === _vm.TimetableCardVariant.SINGLE_COLUMN && _vm.animationState.bigVariantSize
          )?_c('div',{staticClass:"dcw-explore-our-story-card__buttons"},[(_vm.data.content.firstButton)?_c('DcwButton',{attrs:{"text":_vm.data.content.firstButton.text,"variant":_vm.ButtonVariants.PRIMARY,"href":_vm.data.content.firstButton.to,"target":_vm.data.content.firstButton.hasTargetBlank ? '_blank' : false,"rel":_vm.data.content.firstButton.hasTargetBlank ? 'noopener' : false,"is-link":""}}):_vm._e(),_vm._v(" "),(_vm.data.content.secondButton)?_c('DcwButton',{attrs:{"text":_vm.data.content.secondButton.text,"variant":_vm.ButtonVariants.TERTIARY,"href":_vm.data.content.secondButton.to,"target":_vm.data.content.secondButton.hasTargetBlank ? '_blank' : false,"rel":_vm.data.content.secondButton.hasTargetBlank ? 'noopener' : false,"is-link":""}}):_vm._e()],1):_vm._e()])],1)],1),_vm._v(" "),_c('DcwEventDot',{staticClass:"dcw-explore-our-story-card-wrapper__event-dot-progress",attrs:{"data":_vm.eventDot,"is-active":_vm.state === 'active',"can-force-collapse":_vm.forceCollapseOfDateProgress,"is-last-one":_vm.isLastOne,"is-homepage-variant":""}},[_vm._v("\n    "+_vm._s(_vm.formatDateString(_vm.data.date))+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }