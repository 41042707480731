import { toBase64 } from './toBase64';

export const convertFileToDTO = async (file: File) => {
  const fileData = await toBase64(file);

  if (typeof fileData !== 'string') {
    throw new TypeError('Inside mapScheduleAMeetingFormData function');
  }

  const convertedFile = {
    ContentType: 'application/pdf',
    Base64Content: fileData.split(',')[1],
    Filename: file.name
  };

  return convertedFile;
};
