/* eslint-disable max-len */
import { CooperationSectionContent } from '@/types';

export const cooperation: CooperationSectionContent = {
  headline: {
    before: 'Cooperation',
    highlight: 'Process',
    subtitle:
      "The process is simple, straightforward, and serves as a essential strategy for our cooperation. We adjust it individually according to the client's needs."
  },
  items: [
    {
      title: 'Kick-off Meeting',
      text: "The first meetings are about setting the requirements for the perfect candidates and understanding projects' needs. Then we proceed with adjusting and integrating the recruitment process to meet your workflow and reach expectations."
    },
    {
      title: 'Recruitment Set-Up',
      text: 'Once the requirements are known, our in-house team of headhunters and recruiters begins a tailored sourcing process. Our goal is to select the right team in terms of professional experience, participation in similar projects, and work culture.'
    },
    {
      title: 'Talent Selection',
      text: 'We select candidates through cognitive tests and coding challenges adequate to the seniority level. Next, depending on the process, the candidate will be interviewed by your team or jump directly into the project.'
    },
    {
      title: 'Onboarding',
      text: 'Now integrate your new team members into your company. Introduce them with your in-house team, run training if necessary, let them become familiar with technical concepts, and plunge into the working environment.'
    },
    {
      title: 'Relationship Management',
      text: 'The dedicated delivery manager will oversee the delivery and workflow of the entire project and clients specific team, including HR Management, IT Operations, Facility Management, Security, Admin, and Finance.'
    }
  ]
};
