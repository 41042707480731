











import { defineComponent } from '@nuxtjs/composition-api';

export const DcwProjectItem = defineComponent({
  name: 'DcwProjectItem',
  props: {
    borderColor: {
      type: String,
      required: true
    }
  }
});

export default DcwProjectItem;
