import { ref, Ref } from '@nuxtjs/composition-api';

interface UsePageLoad {
  isPageLoaded: Ref<boolean>;
}

type UsePageLoadFactory = () => () => UsePageLoad;

const usePageLoadFactory: UsePageLoadFactory = () => {
  const isPageLoaded = ref(false);

  if (process.browser) {
    addEventListener('load', () => (isPageLoaded.value = true));
  }

  return () => ({ isPageLoaded });
};

export const usePageLoad = usePageLoadFactory();
