/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

export const story: SuccessStory = {
  name: 'Kamil',
  images: {
    og: '/covers/success-story/story-1.jpg',
    avatar: {
      src: '/success-stories/story-1/avatar.png',
      alt: 'Kamil',
      width: 32,
      height: 32
    },
    cover: {
      src: '/success-stories/story-1/cover.jpg',
      alt: 'Kamil cover',
      height: 608
    },
    slider: {
      src: '/success-stories/story-1/slider.png',
      alt: 'Kamil',
      width: 317,
      height: 224
    },
    listing: {
      src: '/success-stories/story-1/listing.png',
      alt: 'Kamil',
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'Aug 7, 2021',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'Mid Frontend Developer',
  details: `I wanted to continue my development in the programming area but at the same time,
    I have a traveler's soul so I wanted to work remotely. My previous employers didn't provide
    me with such an opportunity. While looking for a new job I applied to three places out of
    which one was recommended by my friend and that was DC.`,
  shortStory: 'The first thing I programmed was a chess-computer game so I could teach myself how to win with my father.',
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer:
        "I've been into sports ever since I remember. I used to be a calisthenics and gymnastics trainer, I also have some experience with acrobatics. IT since my childhood has always been my second most important passion and recently it has become the leading one. I've been learning to code myself up to a point when I met two guys with whom I made a deal: I would provide them with personal training and they would teach me to code. That's how I started my journey in the IT world that at first led me to develop medical software."
    },
    {
      type: 'text',
      title: 'What do you do in DC?',
      answer: 'In DC I deal mainly with the front-end so I am responsible for the visual user layer.'
    },
    {
      type: 'text',
      title: 'What did your recruitment process look like? Why did you choose DC?',
      answer:
        "I wanted to continue my development in the programming area but at the same time, I have a traveler's soul so I wanted to work remotely. My previous employers didn't provide me with such an opportunity. While looking for a new job I applied to three places out of which one was recommended by my friend and that was DC. Turns out he was right as from the very beginning up until now everything goes seemingly."
    },
    {
      type: 'image',
      details: 'Paintball battlefield in Tatras',
      img: {
        src: '/success-stories/story-1/image-1.png',
        alt: 'Paintball battlefield in Tatras',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.ZAKOPANE_INTEGRATION_TRIP
    },
    {
      type: 'text',
      title: 'What was the greatest challenge that you had to overcome in DC?',
      answer:
        "The hardest part was growing into the first project. Up until now, I was used to daily commuting and I switched to remote work. Spending time alone while coding is something I have to get used to but has its advantages. Lately, I've been also thinking about coworking so I'm quite open to various solutions."
    },
    {
      type: 'text',
      title: 'What new things have you learned in DC?',
      answer:
        "For sure I've learned to code much better. The level of coding in DC is on an extremely high level and the coolest part is that I always have someone to learn from. From another perspective, I've also learned a lot about self-organization and day-planning due to the remote work aspect."
    },
    {
      type: 'text',
      title: "What's your favorite Tech stack?",
      answer:
        "My favorite technologies are most definitely Javascript and Vue.js. Especially Vue.js has been a big discovery for me as previously I've been using primary React technology. Even since my first contact with Vue.js I am very positively surprised."
    },
    {
      type: 'text',
      title: 'How do you see yourself in retirement?',
      answer:
        "I honestly don't know the answer to this question yet. Maybe I'll be sailing alone through the ocean in a canoe and maybe I'll carry on off-roading through Albanian mountains. We will see!"
    },
    {
      type: 'image',
      details: 'Bologna integration trip',
      img: {
        src: '/success-stories/story-1/image-2.png',
        alt: 'Bologna integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.BOLOGNA_INTEGRATION_TRIP
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Fritz & Paul Kalkbrenner - Sky and Sand',
          img: {
            src: '/success-stories/story-1/song-1.png',
            alt: 'Sky and Sand',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/4IsHMzDbRE8q5Z4ALsQj3o?si=6ba5d5129f4544bb',
          ytLink: 'https://youtu.be/XINlEYXA3k0'
        },
        {
          title: 'VITALIC - Poison Lips',
          img: {
            src: '/success-stories/story-1/song-2.png',
            alt: 'Poison Lips',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/2BIC788t4sLhFM8MpZIclI?si=2b95a2704daf46b8',
          ytLink: 'https://www.youtube.com/watch?v=I2dfGC1oziE&ab_channel=vitalicofficial'
        },
        {
          title: 'DJ Tiesto - Adagio For Strings',
          img: {
            src: '/success-stories/story-1/song-3.png',
            alt: 'Adagio For Strings',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/74G9CbGMcRwBTZtGTXss55?si=c37726799e6c4dfb',
          ytLink: 'https://youtu.be/8tIgN7eICn4'
        }
      ]
    }
  ]
};
