/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance, AxiosResponse } from 'axios';
import { GqlQuery } from '@/types';
import { JobPostingRecord } from '@/graphql';

export type GetBasicJobsDataResponse = {
  data: { allJobPostings: JobPostingRecord[]};
};

export type GetBasicJobsDataDomainModel = {
  allJobPostings: JobPostingRecord[]
};

const getBasicJobsDataMapper = (
  response: AxiosResponse<GetBasicJobsDataResponse>
): GetBasicJobsDataDomainModel => (response.data.data);

export const getBasicJobsData = (client: AxiosInstance, API_URL: string, params: GqlQuery) =>
  client
    .post<GetBasicJobsDataResponse>(API_URL, JSON.stringify(params))
    .then(getBasicJobsDataMapper);
