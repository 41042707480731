export const videos = {
  whoWeAre: {
    id: '683803397',
    videoId: '683803397',
    thumbnail: {
      src: '/videos/featured/image-1.png',
      alt: 'Video thumbnail',
      width: 1440,
      height: 612
    }
  },
  interviewAndrii: {
    id: '715549838',
    videoId: '715549838',
    thumbnail: { src: '/videos/interviews/image-1.png', alt: 'Video thumbnail', width: 708, height: 300 }
  },
  interviewFilip: {
    id: '710732708',
    videoId: '710732708',
    thumbnail: { src: '/videos/interviews/image-2.png', alt: 'Video thumbnail', width: 708, height: 300 }
  },
  interviewWojciech: {
    id: '710735110',
    videoId: '710735110',
    thumbnail: { src: '/videos/interviews/image-3.png', alt: 'Video thumbnail', width: 708, height: 300 }
  },
  interviewBartosz: {
    id: '710734352',
    videoId: '710734352',
    thumbnail: { src: '/videos/interviews/image-4.jpg', alt: 'Video thumbnail', width: 708, height: 300 }
  },
  interviewSzymon: {
    id: '715548750',
    videoId: '715548750',
    thumbnail: { src: '/videos/interviews/image-5.png', alt: 'Video thumbnail', width: 708, height: 300 }
  },
  integrationCracow: {
    id: '671099894',
    videoId: '671099894',
    thumbnail: { src: '/videos/integrations/image-1.jpg', alt: 'Video thumbnail', width: 1469, height: 624 }
  },
  integrationItaly: {
    id: '671092459',
    videoId: '671092459',
    thumbnail: { src: '/videos/integrations/image-2.jpg', alt: 'Video thumbnail', width: 1469, height: 624 }
  },
  integrationTatra: {
    id: '615553876',
    videoId: '615553876',
    thumbnail: { src: '/videos/integrations/image-3.jpg', alt: 'Video thumbnail', width: 1469, height: 624 }
  },
  integrationBeskids: {
    id: '615544783',
    videoId: '615544783',
    thumbnail: { src: '/videos/integrations/image-4.jpg', alt: 'Video thumbnail', width: 1469, height: 624 }
  },
  integrationLondon: {
    id: '725255719',
    videoId: '725255719',
    thumbnail: { src: '/videos/integrations/image-5.png', alt: 'Video thumbnail', width: 1469, height: 624 }
  },
  integrationPabianic: {
    id: '732454129',
    videoId: '732454129',
    thumbnail: { src: '/videos/integrations/image-6.jpg', alt: 'Video thumbnail', width: 1469, height: 624 }
  }
};
