export enum LocalStorage {
  REFERRER = 'dcw_referrer'
}

export enum Referrers {
  BULLDOG_JOB_PL = 'bulldogjobpl',
  DC_WEBSITE = 'dcwebsite',
  GO_WORK = 'gowork',
  JUST_JOIN_IT = 'justjoinit',
  JUST_JOIN_IT_BIDDA = 'justjoinit_bidda',
  JUST_JOIN_IT_BW = 'justjoinit_bw',
  JUST_JOIN_IT_CFI = 'justjoinit_cfi',
  JUST_JOIN_IT_CW = 'justjoinit_cw',
  JUST_JOIN_IT_GLIMAT = 'justjoinit_glimat',
  JUST_JOIN_IT_WAYF = 'justjoinit_wayf',
  NO_FLUFF_JOBS = 'nofluffjobs',
  PRACUJ_PL = 'pracujpl',
  CARROT_WORK = 'carrotwork',
  OTHER = 'other',
  HELLO_HR = 'hellohr',
  ROCKET_JOBS = 'rocketjobs',
  GOOGLE_ADS = 'googleads',
  FACEBOOK_ADS = 'facebookads',
}
