import { CareerListingContent, TextHeroSectionContent } from '@/types';

const alt = 'Decorative image of some person from our company';

const successStories: TextHeroSectionContent = {
  headline: {
    before: 'Read',
    highlight: '#{target} Inspiring Stories\n',
    after: 'About People of Digital Colliers'
  },
  images: {
    first: {
      src: '/listings/success-stories/image-1.png',
      alt,
      width: 257,
      height: 336
    },
    second: {
      src: '/listings/success-stories/image-2.png',
      alt,
      width: 325,
      height: 339
    }
  }
};

const blog: TextHeroSectionContent = {
  headline: {
    before: 'Stay up to Date With\n',
    highlight: 'Technological News',
    subtitle: 'Being a Senior Developer at Digital Colliers is a wonderful thing.'
  },
  images: {
    first: {
      src: '/listings/blog/image-1.png',
      alt,
      width: 306,
      height: 376
    },
    second: {
      src: '/listings/blog/image-2.png',
      alt,
      width: 370,
      height: 376
    }
  }
};

const career: CareerListingContent = {
  headline: {
    firstItem: 'Jobs',
    secondItem: 'Projects',
    highlight: 'Choose Your Career Path'
  },
  images: {
    first: {
      src: '/listings/career/image-1.png',
      alt,
      width: 325,
      height: 358
    },
    second: {
      src: '/listings/career/image-2.png',
      alt,
      width: 540,
      height: 364
    }
  }
};

export const listings = { successStories, blog, career };
