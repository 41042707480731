/* eslint-disable max-len */
import { socialMedia } from '../socialMedia';
import { people } from '../people';
import { PagesLinks, HomeHeroSectionContent } from '@/types';
import Partner1 from '@/assets/home/hero/partner-1.svg';
import Partner2 from '@/assets/home/hero/partner-2.svg';
import Partner3 from '@/assets/home/hero/partner-3.svg';
import Partner4 from '@/assets/home/hero/partner-4.svg';
import Partner5 from '@/assets/home/hero/partner-5.svg';
import Clutch from '@/assets/clutch.svg';
import Linkedin from '@/assets/linkedin.svg';
import ClutchIcon from '@/assets/clutch-icon.svg';
import ClutchMobile from '@/assets/clutch-mobile.svg';

export const hero: HomeHeroSectionContent = {
  description: {
    before: 'Agile Development Teams\nReady to Boost',
    highlight: 'Your Business',
    subtitle:
      'We provide goal-oriented agile teams of engineers and specialists who create\nbespoke software solutions for SMEs and large enterprises worldwide.',
    firstButton: { text: "Let's talk", to: PagesLinks.CONTACT },
    secondButton: { text: 'Join us', to: PagesLinks.CAREER }
  },
  person: {
    model: people.wiktorS,
    img: {
      width: 469,
      height: 486,
      alt: 'Wiktor Stefański',
      src: '/home/hero/image-1.png'
    }
  },
  footer: {
    links: {
      before: [
        { href: socialMedia.clutch.url, ariaLabel: 'Digital Colliers Clutch', icon: ClutchIcon },
        { href: socialMedia.linkedIn.url, ariaLabel: 'Digital Colliers LinkedIn', icon: Linkedin }
      ],
      after: {
        href: socialMedia.clutch.url,
        ariaLabel: 'Digital Colliers Clutch',
        icons: { desktop: Clutch, mobile: ClutchMobile }
      }
    },
    partners: [Partner1, Partner2, Partner3, Partner4, Partner5]
  }
};
