import { CountryNames, OfficeModel } from '@/types';

export const toronto: OfficeModel = {
  name: '292 Adelaide Street West',
  country: CountryNames.CANADA,
  city: 'Toronto',
  address: {
    first: '292 Adelaide Street',
    second: 'ON M5V 1P6'
  },
  map: 'https://www.google.com/maps/search/Toronto++292+Adelaide+St+W,+ON+M5V+1P6,+Canada/@52.504788,13.3314388,17z'
};
