





















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { MainGridWrapperElements, SocialMediaColor, SocialMediaLink } from '@/types';

export const DcwSocialMedia = defineComponent({
  name: 'DcwSocialMedia',
  props: {
    hasLine: {
      type: Boolean,
      default: false
    },
    isVertical: {
      type: Boolean,
      default: false
    },
    links: {
      type: Array as PropType<SocialMediaLink[]>,
      required: true
    },
    color: {
      type: String as PropType<SocialMediaColor>,
      default: SocialMediaColor.GRAY
    }
  },
  setup() {
    return { MainGridWrapperElements };
  }
});

export default DcwSocialMedia;
