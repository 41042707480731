import { BlogSectionContent } from '@/types';

const imgParameters = {
  width: 426,
  height: 175
};

export const blog: BlogSectionContent = {
  headline: {
    before: 'Read Our',
    highlight: 'Blog'
  },
  button: {
    text: 'See more',
    href: 'https://medium.com/@digitalcolliers'
  },
  items: [
    {
      title: 'How to Create a Perfectly Sized Software Development Team?',
      label: 'Team Augmentation ',
      author: 'Szymon Nowak',
      date: 'Aug 7 , 2020',
      link: 'https://medium.com/@digitalcolliers/how-to-create-a-perfectly-sized-software-development-team-8a5d9c630865',
      img: {
        ...imgParameters,
        src: '/home/blog/post-1.png',
        alt: 'This is decorational image for blog post on medium: "How to Create a Perfectly Sized Software Development Team?'
      }
    },
    {
      title: 'How to Successfully Manage an Offshore Software Development Team',
      label: 'Offshore Development',
      author: 'Szymon Nowak',
      date: 'Aug 6, 2020',
      link: 'https://medium.com/@digitalcolliers/how-to-successfully-manage-an-offshore-software-development-team-db9ae695ec38',
      img: {
        ...imgParameters,
        src: '/home/blog/post-2.png',
        alt:
          'This is decorational image for blog post on medium: "How To Successfully Manage An Offshore Software Development Team'
      }
    },
    {
      title: 'The Benefits of Outsourcing Software Development to Poland',
      label: 'Outsourcing',
      author: 'Łukasz Sobieraj',
      date: 'Aug 6, 2020',
      link: 'https://medium.com/@digitalcolliers/the-benefits-of-outsourcing-software-development-to-poland-c3f08153a806',
      img: {
        ...imgParameters,
        src: '/home/blog/post-3.png',
        alt: 'This is decorational image for blog post on medium: "The Benefits of Outsourcing Software Development to Poland'
      }
    }
  ]
};
