import { blog } from './blog';
import { reviews } from './reviews';
import { hero } from './hero';
import { services } from './services';
import { ourExpertise } from './ourExpertise';
import { meetOurTeam } from './meetOurTeam';
import { ourSolutionFor } from './ourSolutionFor';
import { map } from './map';
import { timeline } from './timeline';
import { projects } from './projects';
import { weWorkFor } from './weWorkFor';
import { caseStudies } from './caseStudies';

export const homePage = {
  caseStudies,
  weWorkFor,
  hero,
  reviews,
  blog,
  services,
  ourExpertise,
  meetOurTeam,
  ourSolutionFor,
  map,
  timeline,
  projects
};
