/* eslint-disable max-len */
import { TechnologyModel, TechnologyNames, TechnologyColors } from '@/types';

const name = TechnologyNames.ANGULAR;
const alt = `Member of ${name} team`;

export const technology: TechnologyModel = {
  images: {
    banner: {
      src: '/technology-page/banner/angular.png',
      height: 548,
      width: 699,
      alt: `Modern ${name} Image`
    }
  },
  name,
  color: TechnologyColors.ANGULAR,
  description:
    'Clean, refactored code that meets global standards. Easy to work on for each experienced developer!Clean, refactored code that meets global standards.',
  extendTeam: {
    headline: {
      before: 'Extend You',
      highlight: 'Backend Team',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pretium nisl at nulla'
    },
    banner: {
      headline: {
        before: 'Best Java Developers',
        subtitle:
          'Building java to support your backend teams is super.. tium nisl at nulla pellentesque vulputate. Sed vitae nulla vel diam euismod facilisis.Building java to support your backend teams is super.. tium nisl at nulla pellentesque'
      },
      details: [
        {
          heading: '100%',
          before: 'Remote Teams',
          subtitle: 'Vue earned 91.2% as a framework with the highest satisfaction.'
        },
        {
          heading: '100%',
          before: 'Remote Teams',
          subtitle: 'Vue earned 91.2% as a framework with the highest satisfaction.'
        }
      ],
      team: [
        {
          src: '/technology-page/extend-your-team/member-1.png',
          height: 319,
          width: 300,
          alt
        },
        {
          src: '/technology-page/extend-your-team/member-2.png',
          height: 319,
          width: 300,
          alt
        },
        {
          src: '/technology-page/extend-your-team/member-3.png',
          height: 319,
          width: 300,
          alt
        }
      ]
    }
  },
  advantages: [
    {
      before: '#High quality maintain',
      subtitle:
        'Clean, refactored code that meets global standards. Easy to work on for each experienced developer! Clean, refactored code that meets global standards. '
    },
    {
      before: '#Quality = Reliability',
      subtitle:
        'Clean, refactored code that meets global standards. Easy to work on for each experienced developer! Clean, refactored code that meets global standards. '
    },
    {
      before: '#Open Communication',
      subtitle:
        'Clean, refactored code that meets global standards. Easy to work on for each experienced developer! Clean, refactored code that meets global standards. '
    },
    {
      before: '#High quality maintainable ',
      subtitle:
        'Clean, refactored code that meets global standards. Easy to work on for each experienced developer! Clean, refactored code that meets global standards. '
    },
    {
      before: '#Quality = Reliability',
      subtitle:
        'Clean, refactored code that meets global standards. Easy to work on for each experienced developer! Clean, refactored code that meets global standards. '
    },
    {
      before: '#Open Communication',
      subtitle:
        'Clean, refactored code that meets global standards. Easy to work on for each experienced developer! Clean, refactored code that meets global standards. '
    }
  ]
};
