/* eslint-disable max-len */
import { CooperationSectionContent } from '@/types';

export const cooperation: CooperationSectionContent = {
  headline: {
    before: 'Cooperation',
    highlight: 'Process',
    subtitle:
      "The process is simple, straightforward, and serves as a essential strategy for our cooperation. We adjust it individually according to the client's needs."
  },
  items: [
    {
      title: 'Kick-off Meeting',
      text: 'In the past, we have helped our customers not only to scale their development teams but also to find key staff members willing to stay with our clients for years. We are always open for discussion regarding the take over of our most.'
    },
    {
      title: 'Recruitment Set-Up',
      text: 'In the past, we have helped our customers not only to scale their development teams but also to find key staff members willing to stay with our clients for years. We are always open for discussion regarding the take over of our most.'
    },
    {
      title: 'Talent Selection',
      text: 'In the past, we have helped our customers not only to scale their development teams but also to find key staff members willing to stay with our clients for years. We are always open for discussion regarding the take over of our most.'
    },
    {
      title: 'Onboarding',
      text: 'In the past, we have helped our customers not only to scale their development teams but also to find key staff members willing to stay with our clients for years. We are always open for discussion regarding the take over of our most.'
    },
    {
      title: 'Relationship Management',
      text: 'In the past, we have helped our customers not only to scale their development teams but also to find key staff members willing to stay with our clients for years. We are always open for discussion regarding the take over of our most.'
    }
  ]
};
