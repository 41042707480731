






import { defineComponent } from '@nuxtjs/composition-api';

export const DcwBadge = defineComponent({ name: 'DcwBadge' });

export default DcwBadge;
