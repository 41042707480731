/* eslint-disable max-len */
import { pagesNames } from '../pagesNames';
import { testimonials } from '../ui/testimonials';
import { PagesLinks, ReviewsSectionContent } from '@/types';

export const reviews: ReviewsSectionContent = {
  headline: {
    before: 'What ',
    highlight: 'Our Clients',
    after: 'Say'
  },
  button: {
    text: pagesNames.contact,
    href: PagesLinks.CONTACT
  },
  slides: testimonials
};
