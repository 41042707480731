import { DcInNumbersSectionContent } from '@/types';

export const numbers: DcInNumbersSectionContent = {
  title: 'Digital Colliers in numbers',
  items: [
    {
      number: '7+',
      text: 'Years on\nthe Market'
    },
    {
      number: '120+',
      text: 'People on\nBoard'
    },
    {
      number: '200+',
      text: 'Projects\nDelivered'
    },
    {
      number: '67',
      text: 'Our Current\nNPS Score'
    }
  ]
};
