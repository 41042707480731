




















































































import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { DcwLangWidget, DcwNavigationToggle, DcwSmSubItem, DcwTechnologiesSubItem } from './components';
import { DcwTooltip, DcwImage, DcwGridWrapper, DcwButton, DcwLink } from '@atoms';
import { DcwSubMenu } from '@molecules';
import DCLogo from '@/assets/logo_dc_prefinal.svg';
import { WAVE_EMOJI } from '@/consts';
import { Breakpoints, CssVariables, HeaderColors, MainGridWrapperElements, PagesLinks } from '@/types';
import { useBreakpoints, useLocales, useSubMenu } from '@/composables';
import { isProduction, isUnfinishedSectionEnabled } from '@/helpers';
import ArrowDown from '@/assets/icons/streamline-icons/arrow-down.svg';

export const DcwHeader = defineComponent({
  name: 'DcwHeader',
  components: {
    DCLogo,
    DcwImage,
    DcwTooltip,
    DcwGridWrapper,
    DcwButton,
    DcwSubMenu,
    DcwLangWidget,
    DcwNavigationToggle,
    ArrowDown,
    DcwTechnologiesSubItem,
    DcwSmSubItem,
    DcwLink
  },
  props: {
    color: {
      type: String as PropType<HeaderColors>,
      default: HeaderColors.DEFAULT
    },
    mobileColor: {
      type: String as PropType<HeaderColors>,
      default: HeaderColors.DEFAULT
    },
    tabletColor: {
      type: String as PropType<HeaderColors>,
      default: HeaderColors.DEFAULT
    },
    isWhite: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const subMenuRef = ref<HTMLLIElement | null>(null);
    const { isOpen: servicesMenuOpen, clickHandler: servicesHandler, mouseEnterHandler, mouseLeaveHandler } = useSubMenu(
      subMenuRef
    );
    const { currentWidth } = useBreakpoints();
    const mainMenuOpen = ref(false);
    const { translation, links: languagesLinks } = useLocales();

    const contactButtonProps = {
      text: translation.buttons.contactUs,
      href: isProduction ? 'https://' + process.env.DOMAIN_EN + PagesLinks.CONTACT : PagesLinks.CONTACT,
      isLink: true,
      icon: false
    };

    const handleOpenMenu = () => {
      const isMenuOpen = !mainMenuOpen.value;

      // TODO add selectors
      const main = document.querySelector('main');
      const footer = document.querySelector('.dcw-footer') as HTMLElement;

      if (main && footer) {
        main.style.display = isMenuOpen ? 'none' : '';
        footer.style.display = isMenuOpen ? 'none' : '';
      }

      mainMenuOpen.value = isMenuOpen;
    };

    watch(currentWidth, (newValue) => {
      if (!mainMenuOpen.value || newValue < Breakpoints.MEDIUM) {
        return;
      }

      const main = document.querySelector('main');
      const footer = document.querySelector('.dcw-footer') as HTMLElement;
      mainMenuOpen.value = false;

      if (main && footer) {
        main.style.display = '';
        footer.style.display = '';
      }
    });

    const servicesSubItemProps = {
      headline: {
        before: translation.pagesNames.teamAugmentation,
        subtitle: translation.pagesDescription.teamAugmentation
      },
      link: {
        text: translation.pagesNames.teamAugmentation,
        href: PagesLinks.TEAM_AUGMENTATION
      }
    };

    return {
      servicesMenuOpen,
      mainMenuOpen,
      PagesLinks,
      translation,
      WAVE_EMOJI,
      MainGridWrapperElements,
      isUnfinishedSectionEnabled,
      contactButtonProps,
      languagesLinks,
      handleOpenMenu,
      servicesSubItemProps,
      subMenuRef,
      servicesHandler,
      mouseEnterHandler,
      mouseLeaveHandler,
      CssVariables
    };
  }
});

export default DcwHeader;
