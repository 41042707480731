/* eslint-disable @typescript-eslint/no-var-requires */
import { SuccessStory } from '@/types';

export const successStory = {
  get 'jan-5'(): SuccessStory {
    return require('./jan-5').story;
  },
  get 'kamil-4'(): SuccessStory {
    return require('./kamil-4').story;
  },
  get 'filip-7'(): SuccessStory {
    return require('./filip-7').story;
  },
  get 'kamil-1'(): SuccessStory {
    return require('./kamil-1').story;
  },
  get 'bartosz-2'(): SuccessStory {
    return require('./bartosz-2').story;
  },
  get 'kacper-3'(): SuccessStory {
    return require('./kacper-3').story;
  },
  get 'pawel-6'(): SuccessStory {
    return require('./pawel-6').story;
  },
  get 'wojciech-9'(): SuccessStory {
    return require('./wojciech-9').story;
  },
  get 'lukasz-8'(): SuccessStory {
    return require('./lukasz-8').story;
  },
  get 'maciej-10'(): SuccessStory {
    return require('./maciej-10').story;
  },
  get 'ramune-11'(): SuccessStory {
    return require('./ramune-11').story;
  },
  get 'daniel-12'(): SuccessStory {
    return require('./daniel-12').story;
  },
  get 'krzysztof-13'(): SuccessStory {
    return require('./krzysztof-13').story;
  }
};
