/* eslint-disable max-len */
import { offices } from '../offices';
import { people } from '../people';
import { ContactLocationsSectionContent } from '@/types';

const mapSize = { width: 830, height: 715 };
const cityImageDimensions = { height: 187, width: 464 };
const detailsImageDimensions = { height: 440, width: 256 };

export const locations: ContactLocationsSectionContent = {
  headline: {
    before: 'Shared',
    highlight: 'Workspace',
    after: 'Offices'
  },
  secondHeadline: {
    before: 'Visit Our Flexible Office\nand',
    highlight: 'Meet Us in',
    subtitle:
      'The offices were chosen with care. Each of them satisfies a particular need and is an attractive stop for both partners and employees.'
  },
  locations: [
    {
      office: offices.cracow,
      member: people.wiktorS,
      images: {
        map: {
          ...mapSize,
          src: '/contact/locations/krakow/map.png',
          alt: 'Cracow'
        },
        city: { height: 187, src: '/contact/locations/krakow/city.png', alt: 'City Krakow' },
        member: { ...detailsImageDimensions, src: '/contact/locations/krakow/member.jpg', alt: 'Wiktor Stefański photo' },
        office: { ...detailsImageDimensions, src: '/contact/locations/krakow/location.jpg', alt: 'Office in Cracow' }
      }
    },
    {
      office: offices.warsaw,
      member: people.szymonM,
      images: {
        map: {
          ...mapSize,
          src: '/contact/locations/warsaw/map.png',
          alt: 'Warsaw'
        },
        city: { ...cityImageDimensions, src: '/contact/locations/warsaw/city.png', alt: 'Warsaw city' },
        member: { ...detailsImageDimensions, src: '/contact/locations/warsaw/member.jpg', alt: 'Szymon Morawiec photo' },
        office: { ...detailsImageDimensions, src: '/contact/locations/warsaw/location.jpg', alt: 'Mennica Legacy Tower' }
      }
    },
    {
      office: offices.gliwice,
      member: people.szymonN,
      images: {
        map: {
          ...mapSize,
          src: '/contact/locations/gliwice/map.png',
          alt: 'Gliwice'
        },
        city: { ...cityImageDimensions, src: '/contact/locations/gliwice/city.png', alt: 'Gliwice city' },
        member: { ...detailsImageDimensions, src: '/contact/locations/shared/szymon-nowak.jpg', alt: 'Szymon Nowak photo' },
        office: { ...detailsImageDimensions, src: '/contact/locations/gliwice/location.jpg', alt: 'Technopark Gliwice' }
      }
    },
    {
      office: offices.paris,
      member: people.leopoldG,
      images: {
        map: {
          ...mapSize,
          src: '/contact/locations/paris/map.png',
          alt: 'Paris'
        },
        city: { ...cityImageDimensions, src: '/contact/locations/paris/city.png', alt: 'Paris city' },
        member: { ...detailsImageDimensions, src: '/contact/locations/paris/member.jpg', alt: 'Léopold Gaillard photo' },
        office: { ...detailsImageDimensions, src: '/contact/locations/paris/location.jpg', alt: '106 Boulevard Haussmann' }
      }
    },
    {
      office: offices.hamburg,
      member: people.szymonN,
      images: {
        map: {
          ...mapSize,
          src: '/contact/locations/hamburg/map.png',
          alt: 'Hamburg'
        },
        city: { ...cityImageDimensions, src: '/contact/locations/hamburg/city.png', alt: 'Hamburg city' },
        member: { ...detailsImageDimensions, src: '/contact/locations/shared/szymon-nowak.jpg', alt: 'Szymon Nowak photo' },
        office: { ...detailsImageDimensions, src: '/contact/locations/hamburg/location.jpg', alt: 'Gänsemarkt Office' }
      }
    },
    {
      office: offices.berlin,
      member: people.janE,
      images: {
        map: {
          ...mapSize,
          src: '/contact/locations/berlin/map.png',
          alt: 'Berlin'
        },
        city: { ...cityImageDimensions, src: '/contact/locations/berlin/city.png', alt: 'Berlin city' },
        member: { ...detailsImageDimensions, src: '/contact/locations/berlin/member.jpg', alt: 'Jan Ellwitz photo' },
        office: { ...detailsImageDimensions, src: '/contact/locations/berlin/location.jpg', alt: "Ku'Damm Office" }
      }
    },
    {
      office: offices.vienna,
      member: people.lukaszS,
      images: {
        map: {
          ...mapSize,
          src: '/contact/locations/vienna/map.png',
          alt: 'Vienna'
        },
        city: { ...cityImageDimensions, src: '/contact/locations/vienna/city.png', alt: 'Vienna city' },
        member: { ...detailsImageDimensions, src: '/contact/locations/vienna/member.jpg', alt: 'Łukasz Sobieraj photo' },
        office: { ...detailsImageDimensions, src: '/contact/locations/vienna/location.jpg', alt: 'Graben 19' }
      }
    },
    {
      office: offices.toronto,
      member: people.karolS,
      images: {
        map: {
          ...mapSize,
          src: '/contact/locations/toronto/map.png',
          alt: 'Toronto'
        },
        city: { ...cityImageDimensions, src: '/contact/locations/toronto/city.png', alt: 'Toronto city' },
        member: { ...detailsImageDimensions, src: '/contact/locations/toronto/member.jpg', alt: 'Karol Sobieraj photo' },
        office: {
          ...detailsImageDimensions,
          src: '/contact/locations/toronto/location.jpg',
          alt: '292 Adelaide Street West'
        }
      }
    }
  ]
};
