





import { defineComponent } from '@nuxtjs/composition-api';

export const DcwFormError = defineComponent({ name: 'DcwFormError' });

export default DcwFormError;
