/* eslint-disable max-len */
import { Project, TechnologyNames, ProjectSectionsId } from '@/types';

export const project: Project = {
  name: 'Payment Orchestration Platform',
  color: '#59BEB8',
  colors: {
    heroBg: '#EEF8F8',
    light: '#CDECEA',
    primary: '#59BEB8'
  },
  description:
    'The project aims to develop and evolve a Payment Orchestration Platform to help global enterprises to improve their payment processes and gain insight of the transactions.',
  badges: ['30+ Engineers', '4 Teams'],
  ctaText: {
    career: {
      before: 'Senior Positions',
      highlight: 'Up\xA0to\xA030\xA0000\xA0PLN\xA0+\xA0VAT'
    },
    home: {
      before: 'We built 4 strong development teams for',
      highlight: 'German Lottery'
    }
  },
  categories: [TechnologyNames.GO, TechnologyNames.REACT, TechnologyNames.GRAPH_QL, TechnologyNames.DYNAMO_DB],
  isRemote: true,
  isNew: true,
  technologies: {
    frontend: [TechnologyNames.REACT, TechnologyNames.GRAPH_QL],
    backend: [
      TechnologyNames.GO,
      TechnologyNames.DYNAMO_DB,
      TechnologyNames.KUBERNETES,
      TechnologyNames.REDIS,
      TechnologyNames.REST,
      TechnologyNames.AWS
    ]
  },
  fields: {
    salary: {
      label: 'net B2B',
      value: 'Up to 30 000 PLN'
    },
    client: {
      label: 'Client',
      value: 'USA'
    },
    industry: {
      label: 'Industry',
      value: ['Fintech']
    },
    status: {
      label: 'Project phase',
      value: 'MVP'
    },
    duration: {
      label: 'Duration',
      value: 'Long Term'
    },
    projectStatus: {
      label: 'Working Location',
      value: '100% Remote'
    }
  },
  images: {
    main: {
      src: '/projects/payment-orchestration-platform/project.png',
      alt: 'Payment Orchestration Platform',
      width: 961,
      height: 447
    },
    preview: {
      src: '/projects/payment-orchestration-platform/preview.png',
      alt: 'Payment Orchestration Platform preview',
      width: 708,
      height: 398
    }
  },
  sections: {
    description: {
      id: ProjectSectionsId.DESCRIPTION,
      title: 'Project Description',
      headline: {
        before: 'Project Description'
      },
      html: `
        <p>
          The project aims to develop and evolve a <strong>Payment Orchestration Platform</strong> to help global enterprises like
          <br/>
          Adidas, Netflix, Nike, and many more big players to improve payment processes, allow for optimization,
          <br/>
          and gain insight into the costs of transactions.
        </p>
        <p>
          With this solution, you can <strong>integrate, adapt and manage</strong> your payments with payments <strong>API</strong> and developer
          <br/>
          friendly tools. All this gives customers <strong>seamless payment experiences</strong> with <strong>cutting-edge tech</strong> that also keeps
          <br/>
          it compliant.
        </p>
        <p>
          Our Team is responsible for developing a connected finance business solution for different merchants
          <br/>
          in <strong>30 different SaaS products</strong> covering finance, security, and crypto industries.
        </p>
        `
    },
    technologies: {
      id: ProjectSectionsId.TECHNOLOGIES,
      title: 'Tech Stack',
      headline: {
        before: 'Tech Stack'
      },
      bg: {
        src: '/projects/payment-orchestration-platform/bg.png',
        width: 335,
        height: 320,
        alt: 'decorative image'
      },
      description: {
        before: 'Technology',
        subtitle: `When it comes to technical stack, we are hosted in \bAWS\b, use
          \bEKS\b and our primary development language is \bGo\b. As we
          always strive to use the best tool for the job, we use a
          combination of \bRedis\b, \bDynamoDB\b, and \bPostgres\b for persistence
          layer, and \bREST, gRPC,\b and queues for communication.`
      },
      frontend: {
        headline: {
          before: 'Frontend',
          subtitle: `React is used the UI components and whole UIs – everything that concerns putting together
            visual elements, binding data to those elements, and specifying the logic governing it.`
        }
      },
      backend: {
        headline: {
          before: 'Backend',
          subtitle: `\bGo\b as a main backend technology offers concurrency, dynamically formatted interfaces,
          advanced memory safety, and excellent \bscalability\b. Moreover clear documentation and neat
          code guarantee that you can maintain and update your software \bhassle-free\b.`
        }
      }
    },
    howWeWork: {
      id: ProjectSectionsId.HOW_WE_WORK,
      title: 'How do We Work',
      headline: {
        before: 'How do We Work'
      },
      html: `
      <p>
        The client is <strong>experiencing constant growth</strong> in the number of handled transactions, new clients, and
        <br/>
        integrations. That's why we pay very close attention to the elegant design and scalability of the platform,
        <br/>
        and <strong>we can provide the developers with everything they need</strong> in order to improve the platform.
      </p>
      `
    },
    challenges: {
      id: ProjectSectionsId.CHALLENGES,
      title: 'Challenges',
      headline: {
        before: 'Challenges That You Will Meet'
      },
      html: `
        <ul>
          <li>
            Working together with our existing team on introducing a number of new PSP (Payment Service Providers) Integrations and Fraud detection systems integrations.
          </li>
          <li>
            Increasing test coverage across services.
          </li>
          <li>
            Contributing to performance improvements.
          </li>
        </ul>
      `
    },
    team: {
      id: ProjectSectionsId.TEAM,
      title: 'Meet Our Team',
      headline: { before: 'Meet Our Team' },
      members: [
        {
          img: {
            src: '/projects/payment-orchestration-platform/team/member-1.png',
            alt: 'Image of Kamil',
            width: 236,
            height: 224
          },
          name: 'Kamil',
          position: 'Mid Frontend Developer'
        },
        {
          img: {
            src: '/projects/payment-orchestration-platform/team/member-2.png',
            alt: 'Image of Alex',
            width: 236,
            height: 224
          },
          name: 'Alex',
          position: 'Mid Frontend Developer'
        },
        {
          img: {
            src: '/projects/payment-orchestration-platform/team/member-3.png',
            alt: 'Image of Robert',
            width: 236,
            height: 224
          },
          name: 'Robert',
          position: 'Senior Backend Developer'
        }
      ]
    },
    openPositions: {
      id: ProjectSectionsId.OPEN_POSITIONS,
      title: 'Open Positions',
      headline: {
        before: 'Open Positions'
      }
    },
    othersProjects: {
      id: ProjectSectionsId.OTHERS_PROJECTS,
      title: 'Other Projects',
      headline: {
        before: 'Choose From Other',
        highlight: 'Great Projects'
      }
    }
  }
};
