import { videos } from '../../videos';
import { VideoListingHeroContent } from '@/types';

export const hero: VideoListingHeroContent = {
  headline: {
    before: 'Video Productions',
    subtitle: 'Being a Senior Developer at Digital Colliers is a wonderful thing.'
  },
  video: {
    ...videos.whoWeAre,
    description: {
      before: 'Digital Colliers in Office',
      subtitle: 'Kraków, Poland'
    }
  }
};
