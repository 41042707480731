/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Context } from '@nuxt/types';
import { isProduction } from '@/helpers/environment';
import { HomePageSectionIds, Locales, PagesLinks } from '@/types';
import { isDynamicRouteInvalid, isRouteUnfinished } from '@/helpers';

const routeGuard = ({ redirect, route, i18n }: Context) => {
  const routeName = route.name;

  if (isDynamicRouteInvalid(route.path) || isRouteUnfinished(route.path)) {
    redirect('/404');
  }

  if (route.path === PagesLinks.TECHNOLOGIES || route.path === `${PagesLinks.TECHNOLOGIES}/`) {
    redirect({ path: `${PagesLinks.HOME}#${HomePageSectionIds.TECHNOLOGIES}` });
    return;
  }

  if ((route.name === 'index___de' || route.name === 'index___fr') && i18n.locale === Locales.EN) {
    redirect({ name: 'index___en' });
    return;
  }

  if (i18n.locale === Locales.EN && (routeName?.includes('___' + Locales.DE) || routeName?.includes('___' + Locales.FR))) {
    redirect('/404');
    return;
  }

  if ((i18n.locale === Locales.DE || i18n.locale === Locales.FR) && !routeName?.includes('index___')) {
    redirect('/404');
  }
};

const router = async (context: Context) => {
  if (isProduction && !context.route.hash) {
    if (context.req.headers.host === process.env.DOMAIN_EN) {
      await context.i18n.setLocale(Locales.EN);
    }
    if (context.req.headers.host === process.env.DOMAIN_DE) {
      await context.i18n.setLocale(Locales.DE);
    }
    if (context.req.headers.host === process.env.DOMAIN_FR) {
      await context.i18n.setLocale(Locales.FR);
    }
  }
  routeGuard(context);
};

export default router;
