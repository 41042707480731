






















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { DcwProjectItem } from '@/components/Organisms';
import { DcwImage } from '@atoms';
import { DcwProjectData } from '@molecules';
import { ButtonVariants, PagesLinks, ProjectWithId, HeadlineTypes, ProjectIDs } from '@/types';
import { useLocales } from '@/composables';
import { offersService } from '~/service/model';
import { getHEXOpacityColor } from '~/helpers';

export const DcwProjectsList = defineComponent({
  name: 'DcwProjectsList',
  components: { DcwProjectItem, DcwImage, DcwProjectData },
  props: {
    projects: {
      type: Array as PropType<ProjectWithId[]>,
      required: true
    }
  },
  setup() {
    const { translation } = useLocales();
    const { getOffersByProjectId } = offersService;

    const backButtonProps = {
      isLink: true,
      isLeft: true,
      href: PagesLinks.CAREER,
      variant: ButtonVariants.TERTIARY,
      text: translation.backButtons.career
    };

    const getProjectOffersCount = (id: string): string => {
      const projectOffers = getOffersByProjectId(id as ProjectIDs);
      return `${projectOffers.length} ${
        projectOffers.length === 1 ? translation.phrases.oneActiveJobOffer : translation.phrases.manyActiveJobOffers
      }`;
    };

    return { backButtonProps, HeadlineTypes, getProjectOffersCount, getHEXOpacityColor };
  }
});

export default DcwProjectsList;
