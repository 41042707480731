/* eslint-disable max-len */
import { SeniorityLevels, JobOffer, TechnologyLevels, ProjectIDs, JobOfferCategories, Referrers } from '../../types';
import { salaries } from './salaries';

export const offer: JobOffer = {
  featured: false,
  categories: [JobOfferCategories.BACKEND],
  projects: [ProjectIDs.ECO_SYSTEM],
  name: 'Node.js Developer',
  salaryRanges: salaries.nodeJS,
  seniorityLevel: SeniorityLevels.MID,
  referrers: {
    [Referrers.DC_WEBSITE]: 168374178,
    [Referrers.OTHER]: 168574602,
    [Referrers.CARROT_WORK]: 162384784,
    [Referrers.JUST_JOIN_IT]: 162360272,
    [Referrers.NO_FLUFF_JOBS]: 162413781,
    [Referrers.BULLDOG_JOB_PL]: 162385071,
    [Referrers.FACEBOOK_ADS]: 192884639,
    [Referrers.GOOGLE_ADS]: 192884644
  },
  description: [
    {
      title: 'Description',
      text: [
        'We are currently looking for an experienced \bNode.js Developer\b to join our team, and help us develop an \becosystem for social finance solutions\b, that will revolutionize banking for Freelancers and Digital Nomads.',
        "Nowadays, more than 300 million active freelancers - growing to as many as 2 billion by 2035 but unfortunately, today's banks and their financial offerings were purpose-built for an industrial economy rulebook that no longer applies. Our Client intends to change that.",
        'The company is an American startup headquartered in beautiful Miami, Florida with the mission of designing financial solutions that align with the organizing principles of the new, remote-first economy. They are currently under the development of \bthree green-field projects\b, connected into one ecosystem that will allow freelancers to acquire financing, get paid faster, and build their businesses from the ground up.',
        'They have established a great \bremote-first work culture\b, working as a team, bringing together developers from all over the world, while still focusing on inclusivity and progress. They made sure to develop this culture from the beginning, and that it is now present at all levels of the team.'
      ]
    },
    {
      title: 'Your Role and Responsibilities',
      items: [
        "Working on an innovative \bFin-Tech App\b that's made up of a complex ecosystem of web applications deployed on AWS",
        'Develop a web application to fulfill specific business needs and requirements',
        'Striving for the \bsuperior quality\b of the product',
        'Participate in the project in \bAgile\b methodology and conform to coding standards including \bGIT\b workflow',
        'Sharing your ideas, experience with us, and take a voice in discussions on \btechnical and business matters\b'
      ]
    },
    {
      title: 'The Main Programming Languages and Databases',
      items: [
        'Backend - \bNode JS\b, Python',
        'Responsive Web app - \bReactJS\b',
        'Android & iOS apps - \bReactNative\b',
        'Database - \bMongoDB / DocumentDB\b',
        'Container Management - \bDocker\b'
      ]
    },
    {
      title: "We're Looking for Someone Who Has",
      items: [
        '2 years in \bcore JavaScript\b',
        '2 years on \bMERN Stack (MongoDB, NodeJS)\b',
        'Industry experience in \bNode.JS\b and \bMicroservices Architecture\b',
        'Strong experience in \bGraphQL\b and \bREST API\b development',
        'Proficient in both structured and unstructured data',
        'Proactive attitude and approach to solving problems',
        'Very good \bEnglish\b skills (min. B2/C1)'
      ]
    },
    {
      title: 'What Else Makes Us Tickle',
      items: [
        'You are ambitious, humble and the startup spirit attracts you',
        'You have a team spirit and the desire to share your knowledge',
        'You are \b#born2solve\b. When you see a problem, you see its solution'
      ]
    }
  ],
  technologies: [
    { label: 'JavaScript', level: TechnologyLevels.REGULAR },
    { label: 'Node.js', level: TechnologyLevels.REGULAR },
    { label: 'Microservices', level: TechnologyLevels.JUNIOR },
    { label: 'GraphQL', level: TechnologyLevels.JUNIOR },
    { label: 'REST API', level: TechnologyLevels.JUNIOR },
    { label: 'Mocha', level: TechnologyLevels.NICE_TO_HAVE }
  ],
  recruitment: [
    {
      title: 'First Call',
      description: 'First, \binterview\b to get to know each other and talk about both your and our expectations'
    },
    {
      title: 'Tech Interview',
      description:
        'A 2-hours \btechnical assessment\b where we are going pair programming and talking about the theoretical aspects of both Frontend and Backend'
    },
    {
      title: 'Team Lead Interview',
      description: 'Recruitment Final Stage - \binterview\b with your future Team Leader.'
    },
    {
      title: 'Offer',
      description: 'We are able to complete the entire process within 2 weeks'
    }
  ]
};
