/* eslint-disable max-len */
import { CompanyEvent, CompanyEventGalleryGrid } from '@/types';

const twoInBoxRes = {
  width: 168,
  height: 306
};

const bigInRowRes = {
  width: 336,
  height: 153
};

const oneImageRes = {
  width: 336,
  height: 306
};

const masonrySmallRes = {
  width: 273,
  height: 153
};

export const common: CompanyEvent[] = [
  {
    date: '2020-01-02',
    category: ['People'],
    headline: {
      before: 'Bartosz Cieśla Joined Our Team',
      subtitle: 'It turns out recruitment comes very naturally for me, and in practice, results are relatively good.'
    },
    button: {
      href: '#',
      text: 'Read Success Story'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Bartosz Cieśla Joined Our Team',
          src: '/events/common/event-1/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People'],
    headline: {
      before: 'Kacper Potyrała Joined Our Team',
      subtitle:
        'My story started with two of my cousins working in the IT industry. They were the ones that got me interested in it.'
    },
    button: {
      href: '#',
      text: 'Read Success Story'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Kacper Potyrała Joined Our Team',
          src: '/events/common/event-2/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People'],
    headline: {
      before: 'Kamil Rydzewski Joined Our Team',
      subtitle: 'It turns out recruitment comes very naturally for me, and in practice, results are relatively good.'
    },
    button: {
      href: '#',
      text: 'Read Success Story'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Kamil Rydzewski Joined Our Team',
          src: '/events/common/event-3/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People'],
    headline: {
      before: 'Kamil Foremny Joined Our Team',
      subtitle: 'Being a Senior Developer at Digital Colliers is a wonderful thing. But the beginnings were not always so easy.'
    },
    button: {
      href: '#',
      text: 'Read Success Story'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Kamil Foremny Joined Our Team',
          src: '/events/common/event-4/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People'],
    headline: {
      before: 'Paweł Kozera Joined Our Team',
      subtitle:
        'I got interested in business when I got an opportunity to participate in a 3 year business project in high school.'
    },
    button: {
      href: '#',
      text: 'Read Success Story'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Bartosz Cieśla Joined Our Team',
          src: '/events/common/event-5/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People'],
    headline: {
      before: 'Jan Ellwitz Joined Our Team',
      subtitle:
        'My first contact with Digital Colliers was at a networking event in Tenerife. Started developing some ideas for the way we could collaborate.'
    },
    button: {
      href: '#',
      text: 'Read Success Story'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Jan Ellwitz Joined Our Team',
          src: '/events/common/event-6/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People'],
    headline: {
      before: 'Celebration Time',
      subtitle:
        "A long-term relationship with clients it's something we strongly believe in.But it's not about becoming dependent on each other."
    },
    button: {
      href: '#',
      text: 'Read more'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.TWO_IN_ROWS,
      images: [
        {
          ...bigInRowRes,
          alt: 'Celebration Time',
          src: '/events/common/event-7/image-1.png'
        },
        {
          ...bigInRowRes,
          alt: 'Celebration Time',
          src: '/events/common/event-7/image-2.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People', 'Achievements'],
    headline: {
      before: 'Language Courses in Digital Colliers',
      subtitle:
        'Constant development is a part of our mission statement. As a part of that our employees participate in language courses achieving great'
    },
    button: {
      href: '#',
      text: 'Read more'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Language Courses in Digital Colliers',
          src: '/events/common/event-8/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People', 'Company'],
    headline: {
      before: 'We undertook Cooperation With',
      subtitle:
        "A long-term relationship with clients it's something we strongly believe in. But it's not about becoming dependent on each other."
    },
    button: {
      href: '#',
      text: 'Carrot Work'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.TWO_IN_ROWS,
      images: [
        {
          ...bigInRowRes,
          alt: 'We undertook Cooperation With',
          src: '/events/common/event-9/image-1.png'
        },
        {
          ...bigInRowRes,
          alt: 'We undertook Cooperation With',
          src: '/events/common/event-9/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People'],
    headline: {
      before: 'Merry Christmas!',
      subtitle: "Christmas's spirit came around to our employees."
    },
    button: {
      href: '#',
      text: 'Play video'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.THREE_MASONRY,
      images: [
        {
          ...bigInRowRes,
          alt: 'Merry Christmas!',
          src: '/events/common/event-10/image-1.png'
        },
        {
          ...masonrySmallRes,
          alt: 'Merry Christmas!',
          src: '/events/common/event-10/image-2.png'
        },
        {
          ...masonrySmallRes,
          alt: 'Merry Christmas!',
          src: '/events/common/event-10/image-3.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['Charity', 'Charity'],
    headline: {
      before: 'Szlachetna Paczka!',
      subtitle: 'Last Christmas, we decided to support the Szlachetna Paczka initiative'
    },
    button: {
      href: '#',
      text: 'Play video'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.THREE_MASONRY,
      images: [
        {
          ...bigInRowRes,
          alt: 'Szlachetna Paczka!',
          src: '/events/common/event-11/image-1.png'
        },
        {
          ...masonrySmallRes,
          alt: 'Szlachetna Paczka!',
          src: '/events/common/event-11/image-2.png'
        },
        {
          ...masonrySmallRes,
          alt: 'Szlachetna Paczka!',
          src: '/events/common/event-11/image-3.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['Charity', 'Company'],
    headline: {
      before: 'We Support WOŚP',
      subtitle: 'Digital Colliers decided to join the annual charity fundraise in Poland!'
    },
    button: {
      href: '#',
      text: 'Play video'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.TWO_IN_ROWS,
      images: [
        {
          ...bigInRowRes,
          alt: 'We Support WOŚP',
          src: '/events/common/event-12/image-1.png'
        },
        {
          ...bigInRowRes,
          alt: 'We Support WOŚP',
          src: '/events/common/event-12/image-2.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People', 'Achievements'],
    headline: {
      before: 'We Won Scientific Marathon!',
      subtitle:
        'Our employee lead his team to victory creating scientific paper on new working methods and future office spaces. Congrats!'
    },
    button: {
      href: '#',
      text: 'See post on facebook'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'We Won Scientific Marathon!',
          src: '/events/common/event-13/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['Academy', 'Company'],
    headline: {
      before: 'Vue.js Lectures With Maciej',
      subtitle:
        'As we have some real experts in our team, we share the knowledge. Maciek, our frontend developer, prepared an intro to Vue.js.'
    },
    button: {
      href: '#',
      text: 'Go to career'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.TWO_IN_BOX,
      images: [
        {
          ...twoInBoxRes,
          alt: 'Vue.js Lectures With Maciej',
          src: '/events/common/event-14/image-1.png'
        },
        {
          ...twoInBoxRes,
          alt: 'Vue.js Lectures With Maciej',
          src: '/events/common/event-14/image-2.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['Integration', 'People'],
    headline: {
      before: 'That Was Great Time in Żywiec',
      subtitle:
        'Moments like that stay with us forever. Integration trip to Żywiec was a perfect counterbalance to our daily remote work'
    },
    button: {
      href: '#',
      text: 'Watch video on Youtube'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.FOUR_MASONRY,
      images: [
        {
          ...masonrySmallRes,
          alt: 'That Was Great Time in Żywiec',
          src: '/events/common/event-15/image-1.png'
        },
        {
          ...masonrySmallRes,
          alt: 'That Was Great Time in Żywiec',
          src: '/events/common/event-15/image-2.png'
        },
        {
          ...masonrySmallRes,
          alt: 'That Was Great Time in Żywiec',
          src: '/events/common/event-15/image-3.png'
        },
        {
          ...masonrySmallRes,
          alt: 'That Was Great Time in Żywiec',
          src: '/events/common/event-15/image-4.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['Event', 'Achievements'],
    headline: {
      before: 'Q1 Celebration With Online Pizza',
      subtitle:
        'We did a big review of our work and progress and then we celebrated with pizza and a few games Remote work has no real limits!'
    },
    button: {
      href: '#',
      text: 'See facebook post'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.TWO_IN_ROWS,
      images: [
        {
          ...bigInRowRes,
          alt: 'Q1 Celebration With Online Pizza',
          src: '/events/common/event-16/image-1.png'
        },
        {
          ...bigInRowRes,
          alt: 'Q1 Celebration With Online Pizza',
          src: '/events/common/event-16/image-2.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['Event', 'Business'],
    headline: {
      before: 'Tenerife Networking Business Meeting',
      subtitle:
        'We love expanding our network by creating business meetings. This time we took opportunity to meet up with special community from Tenerife!'
    },
    button: {
      href: '#',
      text: 'See more'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Tenerife Networking Business Meeting',
          src: '/events/common/event-17/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['Project', 'Business'],
    headline: {
      before: 'We Have Started Managing New App',
      subtitle:
        'Our potential goal for successive years is to create an Innovative Lottery SaaS platform that will be a game changer for the industry.'
    },
    button: {
      href: '#',
      text: 'Project description'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'We Have Started Managing New App',
          src: '/events/common/event-18/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['Project', 'Business'],
    headline: {
      before: 'Innovative Fintech Cash-Flow App',
      subtitle:
        'This project aims to develop from scratch an application that will enable SMEs leaders to monitor and forecast their cash flow.'
    },
    button: {
      href: '#',
      text: 'Project description'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: 'Innovative Fintech Cash-Flow App',
          src: '/events/common/event-19/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People', 'Event'],
    headline: {
      before: 'Integration Trip Zakopane #2',
      subtitle: 'Picture can say more than words and imagine we have a whole video! Such a fun time!'
    },
    button: {
      href: '#',
      text: 'Play video'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.THREE_MASONRY,
      images: [
        {
          ...bigInRowRes,
          alt: 'Integration Trip Zakopane #2',
          src: '/events/common/event-20/image-1.png'
        },
        {
          ...masonrySmallRes,
          alt: 'Integration Trip Zakopane #2',
          src: '/events/common/event-20/image-2.png'
        },
        {
          ...masonrySmallRes,
          alt: 'Integration Trip Zakopane #2',
          src: '/events/common/event-20/image-3.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['Academy', 'Company'],
    headline: {
      before: 'Kotlin Lectures With Kamil',
      subtitle:
        'As we have some real experts in our team, we share the knowledge. Kamil, our frontend developer, prepared an intro to Kotlin'
    },
    button: {
      href: '#',
      text: 'Go to career'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.TWO_IN_BOX,
      images: [
        {
          ...twoInBoxRes,
          alt: 'Kotlin Lectures With Kamil',
          src: '/events/common/event-21/image-1.png'
        },
        {
          ...twoInBoxRes,
          alt: 'Kotlin Lectures With Kamil',
          src: '/events/common/event-21/image-2.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People', 'Charity'],
    headline: {
      before: "We've Adopted This Cute, Little Dog!",
      subtitle: "Meet Fifi the youngest member of Digital Colliers squad adopter from one of Cracow's shelter"
    },
    button: {
      href: '#',
      text: 'Career'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: "We've Adopted This Cute, Little Dog!",
          src: '/events/common/event-22/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People', 'Event'],
    headline: {
      before: "The Company's Fifth Birthday!",
      subtitle: 'Oh we love to celebrate! Happy birthday to whole Digital Colliers team!'
    },
    button: {
      href: '#',
      text: 'Career'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.ONE_IMAGE,
      images: [
        {
          ...oneImageRes,
          alt: "The Company's Fifth Birthday!",
          src: '/events/common/event-23/image-1.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People', 'Event'],
    headline: {
      before: 'DC on Battlefield',
      subtitle: 'We do know how to cooperate. Take a look at how we tried out competing with each other!'
    },
    button: {
      href: '#',
      text: 'See video'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.THREE_MASONRY,
      images: [
        {
          ...bigInRowRes,
          alt: 'DC on Battlefield',
          src: '/events/common/event-24/image-1.png'
        },
        {
          ...masonrySmallRes,
          alt: 'DC on Battlefield',
          src: '/events/common/event-24/image-2.png'
        },
        {
          ...masonrySmallRes,
          alt: 'DC on Battlefield',
          src: '/events/common/event-24/image-3.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['Business', 'Event'],
    headline: {
      before: 'Internal Hackaton for Project Teams!',
      subtitle:
        'After the fight on battlefield it was the time to take up rivalization on digital arena. Our project teams competed against each other in'
    },
    button: {
      href: '#',
      text: 'Read more'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.THREE_MASONRY,
      images: [
        {
          ...bigInRowRes,
          alt: 'Internal Hackaton for Project Teams!',
          src: '/events/common/event-25/image-1.png'
        },
        {
          ...masonrySmallRes,
          alt: 'Internal Hackaton for Project Teams!',
          src: '/events/common/event-25/image-2.png'
        },
        {
          ...masonrySmallRes,
          alt: 'Internal Hackaton for Project Teams!',
          src: '/events/common/event-25/image-3.png'
        }
      ]
    }
  },
  {
    date: '2020-01-02',
    category: ['People', 'Event'],
    headline: {
      before: 'Bologna Integration Trip',
      subtitle:
        'This fall as the destination for our integration trip we choose Italy. Our whole team is getting ready to conquer Bologna!'
    },
    button: {
      href: '#',
      text: 'Read more'
    },
    gallery: {
      grid: CompanyEventGalleryGrid.TWO_IN_ROWS,
      images: [
        {
          ...bigInRowRes,
          alt: 'Bologna Integration Trip',
          src: '/events/common/event-26/image-1.png'
        },
        {
          ...bigInRowRes,
          alt: 'Bologna Integration Trip',
          src: '/events/common/event-26/image-2.png'
        }
      ]
    }
  }
];
