/* eslint-disable max-len */
export const inputFields = {
  fullName: 'Vollständiger Name',
  fullNamePlaceholder: 'John Doe',
  yourEmail: 'Email',
  yourEmailPlaceholder: 'you@example.com',
  linkedIn: 'LinkedIn',
  linkedInPlaceholder: 'linkedin.com/in/john-doe',
  callSubject: 'Inhalt des Gesprächs',
  callSubjectPlaceholder: 'Business Inquiry',
  message: 'Message',
  messagePlaceholder: 'Tell us about your development needs.',
  terms: 'Ich habe die Nutzungsbedingungen gelesen und akzeptiere sie',
  mobile: 'Mobile',
  mobilePlaceholder: 'Your phone number',
  fileUpload: 'Upload CV',
  fileUploadPlaceholder: 'Drag and drop file here',
  accept: 'I accept',
  dpContact: 'Contact Form Personal Data Processing Information',
  dpRecruitmentCall: 'Recruitment Call Form Personal Data Processing Information',
  dpBusinessCall: 'Business Call Form Personal Data Processing Information',
  dpApplication: 'Application Form Personal Data Processing Information',
  futureRecruitment:
    'I consent to the processing of my personal data, provided in the form and in the attached application documents, including my CV, by Digital Colliers sp. z o.o. located in Gliwice, for use in future recruitments.'
};
