/* eslint-disable max-len */
import { CountryNames, WeWorkForSectionContent } from '@/types';

const width = 808;
const height = 808;

export const weWorkFor: WeWorkForSectionContent = {
  headline: {
    highlight: 'We Work',
    after: 'Across Borders'
  },
  countries: [
    {
      id: 'dach',
      name: 'DACH',
      headline: {
        before: 'Working for',
        highlight: 'DACH',
        subtitle:
          'Having worked with clients from all over the world, we got to know various business cultures and acquired invaluable experience.'
      },
      flag: CountryNames.GERMANY,
      image: {
        width,
        height,
        src: '/home/we-work-for/dach.webp',
        alt: 'DACH'
      },
      information: [
        {
          before: 'German Proficient',
          subtitle: 'Our development teams work in\na german-oriented environment.'
        },
        {
          before: 'Expertise & Knowledge',
          subtitle: 'We are well versed in successfully\n building highly skilled teams.'
        }
      ]
    },
    {
      id: 'usa',
      name: 'USA',
      headline: {
        before: 'Working For',
        highlight: 'USA',
        subtitle:
          'Having worked with clients from all over the world, we got to know various business cultures and acquired invaluable experience.'
      },
      flag: CountryNames.USA,
      image: {
        width,
        height,
        src: '/home/we-work-for/usa.webp',
        alt: 'USA'
      },
      information: [
        {
          before: 'American Clients',
          subtitle: 'In our collaboration, we focus on\n efficiency and simplicity.'
        },
        {
          before: "English Speaking PM's",
          subtitle: 'The key to a successful project is\n the person who will lead it.'
        }
      ]
    },
    {
      id: 'can',
      name: 'Canada',
      headline: {
        before: 'Working For',
        highlight: 'Canada',
        subtitle:
          'Having worked with clients from all over the world, we got to know various business cultures and acquired invaluable experience.'
      },
      flag: CountryNames.CANADA,
      image: {
        width,
        height,
        src: '/home/we-work-for/canada.webp',
        alt: 'Canada'
      },
      information: [
        {
          before: 'Coworking in Toronto',
          subtitle: "Toronto's shared office space\ngives us more networking options."
        },
        {
          before: 'English Proficient',
          subtitle: 'Our development teams work in\n a english-oriented environment.'
        }
      ]
    },
    {
      id: 'aus',
      name: 'Australia',
      headline: {
        before: 'Working For',
        highlight: 'Australia',
        subtitle:
          'Having worked with clients from all over the world, we got to know various business cultures and acquired invaluable experience.'
      },
      flag: CountryNames.AUSTRALIA,
      image: {
        width,
        height,
        src: '/home/we-work-for/australia.webp',
        alt: 'Australia'
      },
      information: [
        {
          before: 'Australian Clients',
          subtitle: 'In our collaboration, we focus on\n efficiency and simplicity.'
        },
        {
          before: 'International Locations',
          subtitle: 'With flexible offices around the\n globe we break down time barriers.'
        }
      ]
    },
    {
      id: 'fr',
      name: 'France',
      headline: {
        before: 'Working For',
        highlight: 'France',
        subtitle:
          'Having worked with clients from all over the world, we got to know various business cultures and acquired invaluable experience.'
      },
      flag: CountryNames.FRANCE,
      image: {
        width,
        height,
        src: '/home/we-work-for/france.webp',
        alt: 'France'
      },
      information: [
        {
          before: 'Time Zone',
          subtitle: 'We have overcome time limit\n barriers by decentralization.'
        },
        {
          before: 'French Team',
          subtitle: 'French team ensures more\n effective cooperation.'
        }
      ]
    },
    {
      id: 'pl',
      name: 'Poland',
      headline: {
        before: 'Working For',
        highlight: 'Poland',
        subtitle:
          'Having worked with clients from all over the world, we got to know various business cultures and acquired invaluable experience.'
      },
      flag: CountryNames.POLAND,
      image: {
        width,
        height,
        src: '/home/we-work-for/poland.webp',
        alt: 'Poland'
      },
      information: [
        {
          before: 'Multiple Offices',
          subtitle: 'Polish offices allow us to have direct\n contact with developments teams.'
        },
        {
          before: 'Polish Core Team',
          subtitle: 'Polish team ensures more\n effective cooperation.'
        }
      ]
    }
  ]
};
