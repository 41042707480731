import { DcInNumbersSectionContent } from '@/types';

export const numbers: DcInNumbersSectionContent = {
  title: 'Digital Colliers in numbers',
  items: [
    {
      number: '4+',
      text: 'Years on\nthe Market'
    },
    {
      number: '120',
      text: 'People\non Board'
    },
    {
      number: '20',
      text: 'Development\nTeams'
    },
    {
      number: '14',
      text: 'Thriving\nProjects'
    }
  ]
};
