/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

export const story: SuccessStory = {
  name: 'Pawel',
  images: {
    og: '/covers/success-story/story-6.jpg',
    avatar: {
      src: '/success-stories/story-6/avatar.png',
      alt: 'Pawel',
      width: 32,
      height: 32
    },
    cover: {
      src: '/success-stories/story-6/cover.jpg',
      alt: 'Pawel cover',
      height: 608
    },
    slider: {
      src: '/success-stories/story-6/slider.png',
      alt: 'Pawel',
      width: 317,
      height: 224
    },
    listing: {
      src: '/success-stories/story-6/listing.png',
      alt: 'Pawel',
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'Aug 7, 2021',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'HR & Marketing Specialist',
  details: `I got interested in business when in high school I got an opportunity to participate in a
    3-year business project carried out by a company from my home city. It inspired me to
    follow this path in my life. As of now apart from working in DC on a daily basis,
    I am also a full-time student. `,
  shortStory:
    'I got interested in business when I got an opportunity to participate in a 3 year business project in high school.',
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer:
        "I got interested in business when in high school I got an opportunity to participate in a 3-year business project carried out by a company from my home city. It inspired me to follow this path in my life. As of now apart from working in DC on a daily basis, I am also a full-time student. I have recently graduated from International Business bachelor and for master level, I've chosen a double diploma major called MERCURI in the area of consumer relationship marketing. The program is planned in a way that I will be changing universities every semester so apart from Poland I would have a chance to study also in France, Germany, and Spain. The good thing about DC is the remote work system which makes me optimistic about managing to work in DC also in the coming years :)"
    },
    {
      type: 'text',
      title: 'What do you do in DC?',
      answer:
        "In DC I split my work into two areas. The first would be Marketing where I'm responsible for social media and digital marketing and the second is HR where I deal mainly with employer branding."
    },
    {
      type: 'text',
      title: 'What did your recruitment process look like? Why did you choose DC?',
      answer:
        "This is a good story. And every good story starts with coincidence. I wasn't even looking for a job! I wanted to do an internship and I was supposed to do it for a Spanish guy working on his startup. There were some complications due to which I met Karol. After 10 minutes of talking with him, I was offered a job I wasn't even asking for. On this day we were actually sitting in a cafe for which I had a CV in my backpack! Anyway the next day I started to work as one of the very first employees of DC and so it continues till today. I am seriously grateful for the fate that brought me to DC. I couldn't imagine myself working anywhere else cause I know that with everybody here we became true friends. We trust each other!"
    },
    {
      type: 'image',
      details: 'Bologna integration trip',
      img: {
        src: '/success-stories/story-6/image-1.png',
        alt: 'Bologna integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.BOLOGNA_INTEGRATION_TRIP
    },
    {
      type: 'text',
      title: 'What was the greatest challenge that you had to overcome in DC?',
      answer:
        "A big challenge is probably also communication as I merge work for two departments and nobody ever fully understands my workload. Also, I'm not sure if I have fully overcome this challenge but another one would surely be time management. I'm trying to combine studies and work and as I always want to give my 120% best I'm getting frustrated when I'm not fully satisfied with either of them. I'm however indescribably grateful to have management that lets me pursue my education while they would probably prefer me to work full-time. It means a world."
    },
    {
      type: 'text',
      title: 'What new things have you learned in DC?',
      answer:
        "That's an easy answer. I've learned to do research. It's like my golden rule now- always look up for answers on the internet before you take someone else's time to ask a question. It saves a lot of time and unnecessary deconcentration. Just google everything you can. The Internet is pretty powerful!"
    },
    {
      type: 'text',
      title: 'How do you see yourself in retirement?',
      answer:
        'For now, I hope I will never retire! I have planned my engagement, wedding, family but I never thought of retiring. If I had to choose, it would probably be in the middle of a big city center where a lot is happening. I love city life and I could surely see myself sipping whisky in a bar surrounded by the younger generation.z '
    },
    {
      type: 'image',
      details: 'Żywiec integration trip',
      img: {
        src: '/success-stories/story-6/image-2.png',
        alt: 'Żywiec integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.ZYWIEC_INTEGRATION_TRIP
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Ed Sheeran - Beautiful people',
          img: {
            src: '/success-stories/story-6/song-1.png',
            alt: 'Ed Sheeran - Beautiful people',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/4evmHXcjt3bTUHD1cvny97',
          ytLink: 'https://www.youtube.com/watch?v=mj0XInqZMHY'
        },
        {
          title: 'Martin Garrix - Scared to be Lonely',
          img: {
            src: '/success-stories/story-6/song-2.png',
            alt: 'Martin Garrix - Scared to be Lonely',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/album/2v9rQe4F8fVSh5v8bAq0jF',
          ytLink: 'https://www.youtube.com/watch?v=e2vBLd5Egnk'
        },
        {
          title: 'PlanBe ft. Tymek - Czarne bluzy ',
          img: {
            src: '/success-stories/story-6/song-3.png',
            alt: 'PlanBe ft. Tymek - Czarne bluzy ',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/album/5m0oe3U56EeIcAwVj61xSU',
          ytLink: 'https://www.youtube.com/watch?v=AJbRM-yPGZU'
        }
      ]
    }
  ]
};
