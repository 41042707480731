/* eslint-disable max-len */
import { testimonials } from '../ui/testimonials';
import { TestimonialSectionContent } from '@/types';

export const testimonial: TestimonialSectionContent = {
  headline: {
    before: 'Our',
    highlight: 'Clients',
    subtitle: 'Our clients include SMBs, corporations, and startups \nseeking team augmentation for their businesses.'
  },
  slides: testimonials
};
