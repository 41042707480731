import { useRoute } from '@nuxtjs/composition-api';

interface UseDynamicPage<T> {
  contentWithId: T & { id: string };
  otherContent: (T & { id: string })[];
}

export const useDynamicPage = <T>(paramName: string, contentWrapper: Record<string, T>): UseDynamicPage<T> => {
  const route = useRoute();
  const param = route.value.params[paramName];

  const data = Object.entries(contentWrapper).find(([key]) => key === param);

  if (!data) {
    throw new Error('Could not find content');
  }

  const contentWithId = { id: param, ...data[1] };

  const otherContent = Object.entries(contentWrapper)
    .filter(([key]) => key !== contentWithId.id)
    .map(([key, otherItem]) => ({ ...otherItem, id: key }));

  return { contentWithId, otherContent };
};
