


















import { defineComponent, ref } from '@nuxtjs/composition-api';
import { DcwTooltip } from '@atoms';
import ClipboardIcon from '@/assets/icons/streamline-icons/clipboard-icon.svg';
import { copyToClipboard } from '@/helpers';
import { TooltipColors, Directions } from '@/types';

export const DcwTextClipboard = defineComponent({
  name: 'DcwTextClipboard',
  components: {
    ClipboardIcon,
    DcwTooltip
  },
  props: {
    text: {
      type: String,
      required: true
    },
    href: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const showTooltip = ref(false);

    const copyTextAndShowTooltip = () => {
      copyToClipboard(props.text);

      if (!showTooltip.value) {
        setTimeout(() => (showTooltip.value = false), 3000);
      }

      showTooltip.value = true;
    };

    return {
      showTooltip,
      copyTextAndShowTooltip,
      TooltipColors,
      Directions
    };
  }
});

export default DcwTextClipboard;
