/* eslint-disable max-len */
import { Project, TechnologyNames, ProjectSectionsId } from '@/types';

export const project: Project = {
  name: 'Predictive Monitoring Platform',
  color: '#262538',
  colors: {
    heroBg: '#F4F4F5',
    light: '#BEBEC3',
    primary: '#262538'
  },
  description:
    'In an uncertain economic environment, we build product that will drive the finance sector to the next level through cash flow with reliable forecasts and real-time monitoring.',
  badges: ['30+ Engineers', '4 Teams'],
  ctaText: {
    career: {
      before: 'Senior Software Craftsman',
      highlight: 'Up\xA0to\xA030\xA0000\xA0PLN\xA0+\xA0VAT'
    },
    home: {
      before: 'We built 4 strong development teams for',
      highlight: 'German Lottery'
    }
  },
  categories: [TechnologyNames.DOT_NET, TechnologyNames.C_SHARP, TechnologyNames.KUBERNETES],
  isRemote: true,
  isNew: true,
  technologies: {
    backend: [
      TechnologyNames.DOT_NET,
      TechnologyNames.C_SHARP,
      TechnologyNames.KUBERNETES,
      TechnologyNames.POSTGRES_SQL,
      TechnologyNames.GOOGLE_CLOUD
    ]
  },
  fields: {
    salary: {
      label: 'net B2B',
      value: 'Up to 30 000 PLN'
    },
    client: {
      label: 'Client',
      value: 'France'
    },
    industry: {
      label: 'Industry',
      value: ['Financial service']
    },
    status: {
      label: 'Project phase',
      value: 'Working App'
    },
    duration: {
      label: 'Duration',
      value: 'Long Term'
    },
    projectStatus: {
      label: 'Working Location',
      value: '100% Remote'
    }
  },
  images: {
    main: {
      src: '/projects/predictive-monitoring-platform/project.png',
      alt: 'monitoring-platform',
      width: 839,
      height: 438
    },
    preview: {
      src: '/projects/predictive-monitoring-platform/preview.png',
      alt: 'monitoring-platform preview',
      width: 1074,
      height: 626
    }
  },
  sections: {
    description: {
      id: ProjectSectionsId.DESCRIPTION,
      title: 'Project Description',
      headline: {
        before: 'Project Description'
      },
      html: `
        <p>
          Predictive Monitoring Platform project is focused on the development of two <strong>new modules</strong> for a extensive
          <br/>
          cash flow management tool, developed by the French, leading company founded in 2016 in Paris. With 120
          <br/>
          million euros raised, since 2019 they have grown up to 200 employees across <strong>5 countries</strong>, and more than 3500
          <br/>
          clients, and a target of 10 000 clients by the end of 2022.
        </p>

        <p>
          Now they are developing two new products <strong>(Payment integration)</strong> in which you will have a huge impact
          <br/>
          on the final product, helping to define its success and making it a great place to work and grow along
          <br/>
          with the company.
        </p>

        <p>
          <strong>Cash flow forecast</strong> and <strong>monitoring</strong> features automate and make your cash organization more reliable without
          <br/>
          any errors. Whole product is powered by artificial intelligence to categorize and classify transactions what
          <br/>
          gives you better visibility on cash flow to anticipate the future and make decisions based on <strong>data in real-time</strong>.
        </p>
        `
    },
    technologies: {
      id: ProjectSectionsId.TECHNOLOGIES,
      title: 'Tech Stack',
      headline: {
        before: 'Tech Stack'
      },
      bg: {
        src: '/projects/predictive-monitoring-platform/bg.png',
        width: 335,
        height: 320,
        alt: 'decorative image'
      },
      description: {
        before: 'Technology',
        subtitle: `To ensure that our application remains efficient and maintainable,
        we have established a true culture of software quality. We focus
        on \bTDD\b and \bautomated tests\b (unitary, EndToEnd, functional,
        integration, acceptance (based on SpecFlow) which are the
        foundation of the project. Also, a \bback architecture\b respecting\nthe \bCQRS\b pattern and \bDDD\b (Domain-Driven Design)
        drives as key project principles.`
      },
      backend: {
        headline: {
          before: 'Backend',
          subtitle: "The speed and flexibility wouldn't be possible without the great technologies that power it."
        }
      }
    },
    howWeWork: {
      id: ProjectSectionsId.HOW_WE_WORK,
      title: 'How do We Work',
      headline: {
        before: 'How do We Work'
      },
      html: `
        <p>
          In our culture of quality, <strong>clean code</strong> is essential. Our developers are the guarantors of quality of our code
          <br/>
          through the application of tests, code reviews and peer programming sessions. We want our developers to be
          <br/>
          proud when they have finished a project, knowing that the code they created is great quality. This is why, even
          <br/>
          during sprints, we don't rush delivery and consecrate 20% of our time to continuous improvement projects.
        </p>
        `
    },
    challenges: {
      id: ProjectSectionsId.CHALLENGES,
      title: 'Challenges',
      headline: {
        before: 'Challenges That You Will Meet'
      },
      html: `
        <ul>
          <li>
            Developing new features and solving technical problems
          </li>
          <li>
            Guarantee the proper integration of current and future functionalities
          </li>
          <li>
            Guarantee quality through the application of tests and code reviews
          </li>
          <li>
            Prioritize the tasks and functionalities to be developed as a team
          </li>
          <li>
            Develop ergonomics and adapt technologies to usage
          </li>
          <li>
            Be proactive and propose new technological solutions to the team
          </li>
        </ul>
      `
    },
    team: {
      id: ProjectSectionsId.TEAM,
      title: 'Meet Our Team',
      headline: { before: 'Meet Our Team' },
      members: [
        {
          img: {
            src: '/projects/predictive-monitoring-platform/team/member-1.png',
            alt: 'Image of Maciej',
            width: 236,
            height: 224
          },
          name: 'Maciej',
          position: 'Senior Backend Developer'
        },
        {
          img: {
            src: '/projects/predictive-monitoring-platform/team/member-2.png',
            alt: 'Image of Daniel',
            width: 236,
            height: 224
          },
          name: 'Daniel',
          position: 'Mid Backend Developer'
        },
        {
          img: {
            src: '/projects/predictive-monitoring-platform/team/member-3.png',
            alt: 'Image of Alexander',
            width: 236,
            height: 224
          },
          name: 'Alexander',
          position: 'Delivery Manager'
        }
      ]
    },
    openPositions: {
      id: ProjectSectionsId.OPEN_POSITIONS,
      title: 'Open Positions',
      headline: {
        before: 'Open Positions'
      }
    },
    othersProjects: {
      id: ProjectSectionsId.OTHERS_PROJECTS,
      title: 'Other Projects',
      headline: {
        before: 'Choose From Other',
        highlight: 'Great Projects'
      }
    }
  }
};
