/* eslint-disable max-len */
import { SharableSocialMedia, SocialMediaNames } from '@/types';

export const generateShareLink = (platform: SharableSocialMedia) => {
  if (!process.browser) { return ''; }

  const ShareLinks: Record<SharableSocialMedia, string> = {
    [SocialMediaNames.FACEBOOK]: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
    [SocialMediaNames.TWITTER]: `https://twitter.com/share?url=${window.location.href}`,
    [SocialMediaNames.LINKEDIN]: `https://www.linkedin.com/shareArticle?url=${window.location.href}`
  };

  return ShareLinks[platform];
};
