/* eslint-disable max-len */
import { RemoteWorkSectionContent } from '@/types';
import IconRemoteOne from '@/assets/career/remote-work/remote-work-1.svg';
import IconRemoteTwo from '@/assets/career/remote-work/remote-work-2.svg';
import IconRemoteThree from '@/assets/career/remote-work/remote-work-3.svg';

const imgResolution = {
  width: 230,
  height: 230
};

export const remoteWork: RemoteWorkSectionContent = {
  headline: {
    highlight: 'Work',
    after: 'from Anywhere',
    subtitle:
      'Take your remote work to another level. We have the capabilities to do it.\nTravel to places you always wanted to and enjoy your time working with us. '
  },
  items: [
    {
      description: {
        before: 'Foreign Clients',
        subtitle: 'By having clients all over the world, our employees can travel and work from stunning locations.'
      },
      icon: IconRemoteOne
    },
    {
      description: {
        before: 'Tech Conferences Abroad',
        subtitle: 'Participate in tech events, business trips, and unfolding meetings around the world.'
      },
      icon: IconRemoteTwo
    },
    {
      description: {
        before: 'International Teams',
        subtitle: "As one of the world's leading software houses, we employ talents from many different countries."
      },
      icon: IconRemoteThree
    }
  ],
  images: [
    {
      from: 'Paris, France',
      image: {
        src: '/career/remote-work/people/paris-1.jpg',
        alt: 'Image from Paris, France',
        ...imgResolution
      }
    },
    {
      from: 'Chicago, USA',
      image: {
        src: '/career/remote-work/people/chicago.jpg',
        alt: 'Image from Chicago, USA',
        ...imgResolution
      }
    },
    {
      from: 'Gdańsk, Poland',
      image: {
        src: '/career/remote-work/people/gdansk.jpg',
        alt: 'Image from Gdańsk, Poland',
        ...imgResolution
      }
    },
    {
      from: 'Accra, Ghana',
      image: {
        src: '/career/remote-work/people/accra.jpg',
        alt: 'Image from Accra, Ghana',
        ...imgResolution
      }
    },
    {
      from: 'Split, Croatia',
      image: {
        src: '/career/remote-work/people/split.jpg',
        alt: 'Image from Split, Croatia',
        ...imgResolution
      }
    },
    {
      from: 'Barcelona, Spain',
      image: {
        src: '/career/remote-work/people/barcelona.jpg',
        alt: 'Image from Barcelona, Spain',
        ...imgResolution
      }
    },
    {
      from: 'Paris, France',
      image: {
        src: '/career/remote-work/people/paris-2.jpg',
        alt: 'Image from Paris, France',
        ...imgResolution
      }
    },
    {
      from: 'Bieszczady, Poland',
      image: {
        src: '/career/remote-work/people/bieszczady.jpg',
        alt: 'Image from Bieszczady, Poland',
        ...imgResolution
      }
    },
    {
      from: 'Punta Cana, Dominicana',
      image: {
        src: '/career/remote-work/people/punta-cana.jpg',
        alt: 'Image from Punta Cana, Dominicana',
        ...imgResolution
      }
    },
    {
      from: 'Cape Greco, Cyprus',
      image: {
        src: '/career/remote-work/people/cape-greco.jpg',
        alt: 'Image from Cape Greco, Cyprus',
        ...imgResolution
      }
    },
    {
      from: 'Zadar, Croatia',
      image: {
        src: '/career/remote-work/people/zadar.jpg',
        alt: 'Image from Zadar, Croatia',
        ...imgResolution
      }
    }
  ]
};
