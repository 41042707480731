/* eslint-disable max-len */
import { WorkingWithPCSectionContent } from '@/types';

export const workingWithPC: WorkingWithPCSectionContent = {
  headline: {
    before: 'Working With',
    highlight: 'Product Companies',
    subtitle: 'Having extensive experience in working with different start-ups and scale-ups, we have learned about their worst bottlenecks, challenges, and engineering dependencies which can hinder the progress of product initiatives. Through the years, we have developed a special model of cooperation, tailored just for the product companies.'
  },
  items: [
    {
      title: 'No Withdrawal',
      text: 'Our goal is to always aim for a successful long-term cooperation. We know how important it is for the engineer to have an in-depth understanding of the product so we never withdraw any of our employees, provided that the client is happy with their services.'
    },
    {
      title: 'Direct Recruitment',
      text: 'In our experience, recruiting explicitly for a well-described position in a particular organization works much better than transferring the candidates from other projects. The outcome is better motivation, coordination, efficiency, and most importantly retention.'
    },
    {
      title: 'Take Over',
      text: 'In the past, we have helped our customers not only to scale their development teams but also to find key staff members willing to stay with our clients for years. We are always open for discussion regarding the take over of our most crucial representatives.'
    },
    {
      title: 'Remote First',
      text: 'Thanks to over two years of managing distributed teams located all over the europe, we have established a set of processes and values allowing us to maintain the company culture and integrity while working remotely, thus increasing the retention.'
    }
  ]
};
