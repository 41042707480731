/* eslint-disable max-len */
import { testimonials as allTestimonials } from '../ui/testimonials';
import { ClientTestimonialContent } from '@/types';

export const testimonials: ClientTestimonialContent = {
  headline: {
    before: 'Client ',
    highlight: 'Success Stories',
    subtitle:
      "We specialize in building teams with specialists which can be seamlessly integrated into our client's organizations."
  },
  slides: allTestimonials
};
