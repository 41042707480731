












import { defineComponent, provide, reactive, ref } from '@nuxtjs/composition-api';
import { ValidationObserver } from 'vee-validate';
import { DcwScheduleModalDesktop, DcwScheduleModalMobile } from '../';
import { useBreakpoints, useService, useToast, useLocales, useCaptcha } from '@/composables';
import { service } from '@/service';
import { formatMessage, gaHandler, getMinDate, mapScheduleAMeetingFormData } from '@/helpers';
import {
  PagesLinks,
  Pagination,
  RequestStatus,
  CaptchaActions,
  CloseScheduleModal,
  ScheduleMeetingFormData,
  UpdateScheduleMeetingFormData
} from '@/types';
import {
  CloseScheduleModalKey,
  ScheduleMeetingFormDataKey,
  UpdateScheduleMeetingFormKey,
  ScheduleMeetingPersonDataKey,
  ScheduleMeetingRequestStatusKey
} from '@/consts';

const initialScheduleMeetingFormData: Omit<ScheduleMeetingFormData, 'date'> = {
  name: '',
  email: '',
  linkedin: '',
  topic: '',
  terms: false,
  time: '',
  cv: [],
  mobile: ''
};

export const DcwScheduleModalVariant = defineComponent({
  name: 'DcwScheduleModelVariant',
  components: {
    DcwScheduleModalDesktop,
    DcwScheduleModalMobile
  },
  props: {
    person: {
      type: String,
      required: true
    }
  },
  emits: ['close-schedule-modal'],
  setup(props, { emit }) {
    const { isMobile } = useBreakpoints();
    const { executeCaptcha } = useCaptcha();
    const { successToast, errorToast } = useToast();
    const { translation } = useLocales();

    const scheduleModalFormRef = ref<HTMLFormElement | null>(null);
    const scheduleModalValidatorRef = ref<InstanceType<typeof ValidationObserver> | null>(null);
    const personData = translation.modals.scheduleModal.people.find((person) => person.name === props.person);
    const scheduleMeetingFormData = reactive<ScheduleMeetingFormData>({ ...initialScheduleMeetingFormData, date: getMinDate() });

    const mobileStep = ref(1);

    const { update, status, error } = useService(service.emailService.sendEmail);

    const updateFormData: UpdateScheduleMeetingFormData = (key, value) => (obj) => {
      obj[key] = value;
    };

    const nextStep = () => {
      if (mobileStep.value === 3) {
        return;
      }
      mobileStep.value += 1;
    };

    const prevStep = () => {
      if (mobileStep.value === 1) {
        return;
      }
      mobileStep.value -= 1;
    };

    const handleChangeStep = (type: Pagination) => {
      type === Pagination.PREV ? prevStep() : nextStep();
    };

    const onSubmit = async () => {
      if (!personData) {
        return;
      }

      const captchaToken = await executeCaptcha(CaptchaActions.EMAIL);
      const formData = await mapScheduleAMeetingFormData(scheduleMeetingFormData, personData, captchaToken);

      await update(formData);

      if (status.value === RequestStatus.SUCCESS) {
        if (window.location.href.includes(PagesLinks.TEAM_AUGMENTATION)) {
          gaHandler({
            _event: 'call-form-success',
            event: 'gtm.linkClick'
          });
        }
        scheduleModalValidatorRef.value?.reset();
        Object.assign(scheduleMeetingFormData, initialScheduleMeetingFormData);
      } else {
        errorToast();
      }
    };

    const closeModal: CloseScheduleModal = (formButton: boolean) => {
      if (error.value) {
        return;
      }
      emit('close-schedule-modal');
      if (formButton && personData) {
        successToast({
          message: formatMessage(translation.toasts.scheduleSuccess, { target: personData.name }),
          delay: 7000
        });
      }
    };

    provide(ScheduleMeetingFormDataKey, scheduleMeetingFormData);
    provide(UpdateScheduleMeetingFormKey, updateFormData);
    provide(ScheduleMeetingPersonDataKey, personData);
    provide(ScheduleMeetingRequestStatusKey, status);
    provide(CloseScheduleModalKey, closeModal);

    return { isMobile, scheduleModalValidatorRef, onSubmit, scheduleModalFormRef, mobileStep, handleChangeStep };
  }
});

export default DcwScheduleModalVariant;
