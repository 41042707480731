/* eslint-disable max-len */
import { PagesLinks, SuccessStory } from '@/types';
import { YOUTUBE_LINKS } from '@/consts';

const ID = 12;
const NAME = 'Daniel';

export const story: SuccessStory = {
  name: `${NAME}`,
  images: {
    og: `/covers/success-story/story-${ID}.jpg`,
    avatar: {
      src: `/success-stories/story-${ID}/avatar.png`,
      alt: `${NAME}`,
      width: 32,
      height: 32
    },
    cover: {
      src: `/success-stories/story-${ID}/cover.jpg`,
      alt: `${NAME} cover`,
      height: 608
    },
    slider: {
      src: `/success-stories/story-${ID}/slider.png`,
      alt: `${NAME}`,
      width: 317,
      height: 224
    },
    listing: {
      src: `/success-stories/story-${ID}/listing.png`,
      alt: `${NAME}`,
      width: 285,
      height: 352
    }
  },
  info: {
    date: 'May 12, 2022',
    badges: [{ name: 'Success Stories', link: PagesLinks.SUCCESS_STORIES }]
  },
  position: 'Frontend Developer',
  details:
    "I have never worked with Vue before but I got a project in Vue so I had to learn it. It wasn’t very hard. Technology is just a tool. The thing that’s important is a mindset focused on solving problems. I joined the project where I didn't fit with the technology stack at all but only after a few days, I was able to work smoothly. ",
  shortStory: "Technology is just a tool. The thing that's important is a mindset focused on solving problems.",
  questionsAndAnswers: [
    {
      type: 'text',
      title: 'What is your story before DC?',
      answer:
        'I didn’t finish high school right away because I started to learn programming. In the meantime, I was working as a barman and finished school on weekends in an extramural high school. As I was passing final exams I was already working in IT. At that time I also met one of our management board members who was starting his own business.'
    },
    {
      type: 'text',
      title: 'How did you first get in contact with DC? What did the recruitment process look like?',
      answer:
        'I was really happy with my previous job in TSH except for the wages. I decided that it was time to look for something new. I managed to get a really good offer from SoftwareMill and just when I was supposed to sign the contract I was contacted by Digital Colliers. I only agreed to consider the offer because the recruitment process was really fast. The very same day I was contacted by the recruitment team and the following day we were already done with a technical recruitment call. The offer was simply better so I took it and I don’t regret it so far.'
    },
    {
      type: 'text',
      title: 'What do you do in DC?',
      answer:
        'On daily basis, I’m a frontend developer working on Digital Colliers’ own product. It’s a greenfield project we are undertaking together with our partner. On the frontend side, I’m participating in development of new features and executing changes in the project introduced by our PM. '
    },
    {
      type: 'image',
      details: 'Bologna intergration trip',
      img: {
        src: `/success-stories/story-${ID}/image-1.png`,
        alt: 'Bologna integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.BOLOGNA_INTEGRATION_TRIP
    },
    {
      type: 'text',
      title: 'What would you recommend about work in Digital Colliers?',
      answer:
        "I am actually recommending Digital Colliers on daily basis. At this moment one of my good friends is even participating in the recruitment process. I mainly recommend DC due to its very good wages. There are very few companies that can compete with DC which is going head-to-head with the western market. But that’s not even the best part! I’ve been changing jobs a few times but in Digital Colliers it’s the first time I feel I want to stay longer. The atmosphere created by the people working here is incredible. Even when we are going for a business meeting in Rome and it feels like trip with friends that I know for years. I love the ability to travel with remote work but I also value that when we have the opportunity people want to meet on their own for example for coworking. Company retreats are just amazing. The vibe, the fact that we are merging business with real fun are the reasons you want to stay in the company. Also don't feel like a part of a machine, I have a real impact on each process happening, my opinions are respected, we work in bleeding edge technologies and we don't have legacy code - those are all the things important for any developer. "
    },
    {
      type: 'text',
      title: 'What have you learned in DC?',
      answer:
        "I have learned Vue. I have never worked with Vue before but I got a project in Vue so I had to learn it. It wasn’t very hard. Technology is just a tool. The thing that’s important is a mindset focused on solving problems. I joined the project where I didn't fit with the technology stack at all but only after a few days, I was able to work smoothly. "
    },
    {
      type: 'text',
      title: 'What is your favorite tech stack?',
      answer:
        'I’m a big fan of React and its ecosystem. I also really like functional programming. I wish we would go in this direction as much as we can. It has to be however, up to the point where it still fits our needs. All the tools we use should help us and not disturb the work. Functional programming has a lot of patterns that we can introduce to our daily development that would help us do the highest quality software and I would really love to seriously start considering it even more.'
    },
    {
      type: 'text',
      title: 'What are the technologies that you’d like to learn but you lacked the opportunity of so far?',
      answer:
        'There is one particular technology that I would like to explore due to the fact that I want to stay up to date with the latest technologies in the world, which I really like. This technology is Solidity, where I would like to learn to write smart contracts. In general, I would like to develop myself in the direction of Web3, which I think is the future. Internet is the future.'
    },
    {
      type: 'text',
      title: 'Where do you see yourself in 10 years?',
      answer:
        'I see myself in Tenerife drinking fresh milk from coconuts. Career-wise I wish to be some kind of consultant and just help people do good software. There is also a chance for me to become a clothes designer or follow one of my other passions, you never know!'
    },
    {
      type: 'image',
      details: 'Żywiec integration trip',
      img: {
        src: `/success-stories/story-${ID}/image-2.png`,
        alt: 'Żywiec integration trip',
        width: 952,
        height: 471
      },
      ytLink: YOUTUBE_LINKS.ZYWIEC_INTEGRATION_TRIP
    },
    {
      type: 'songs',
      title: "3 songs u can't imagine living without",
      songs: [
        {
          title: 'Kendrick Lamar - Money Trees',
          img: {
            src: `/success-stories/story-${ID}/song-1.png`,
            alt: 'Kendrick Lamar - Money Trees',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/0UtnpKaReKUg2GquaSxCyD?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=smqhSl0u_sI'
        },
        {
          title: 'Frank Ocean - Lost',
          img: {
            src: `/success-stories/story-${ID}/song-2.png`,
            alt: 'Frank Ocean - Lost',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://music.youtube.com/watch?v=J3DWAJGaf7o&feature=gws_kp_track',
          ytLink: 'https://www.youtube.com/watch?v=J3DWAJGaf7o'
        },
        {
          title: 'Flatbush Zombies - Afterlife',
          img: {
            src: `/success-stories/story-${ID}/song-3.png`,
            alt: 'Flatbush Zombies - Afterlife',
            height: 126,
            width: 275
          },
          spotifyLink: 'https://open.spotify.com/track/42dLRNmTqdwPZIiG0NRBWH?autoplay=true',
          ytLink: 'https://www.youtube.com/watch?v=fjQo4xqERqk'
        }
      ]
    }
  ]
};
