/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance, AxiosResponse } from 'axios';
import { MailType, SalesMailType } from '@/types';
import { Attachment } from '@/api/mail/types';

export interface BaseEmailParams {
  type: MailType | SalesMailType;
  mail: string;
  name: string;
  captchaToken: string;
}

export interface ContactEmailParams extends BaseEmailParams {
  message: string;
}

export interface ScheduleEmailParams extends BaseEmailParams {
  linkedin?: string;
  date?: string | null;
  time?: string;
}

export interface ApplyFeedbackParams extends BaseEmailParams {
  position: string;
  referrer: string;
}

export interface ScheduleSalesEmailParams extends ScheduleEmailParams {
  topic: string;
}

export interface ScheduleRecruitmentEmailCVParams extends ScheduleEmailParams {
  cv?: Attachment;
}

export interface ScheduleRecruitmentEmailPhoneParams extends ScheduleEmailParams {
  mobile: string;
}

export type SendEmailParams =
  | ContactEmailParams
  | ScheduleSalesEmailParams
  | ScheduleRecruitmentEmailCVParams
  | ScheduleRecruitmentEmailPhoneParams
  | ApplyFeedbackParams;

export interface SendEmailResponse {
  response: {
    status: number;
  };
}

export interface SendEmailDomainModel {
  status: number;
}

const emailMapper = (response: AxiosResponse<SendEmailResponse>): SendEmailDomainModel => {
  return { status: response.data.response.status };
};

export const sendEmail = (client: AxiosInstance, API_URL: string, params: SendEmailParams) =>
  client
    .post<SendEmailResponse>(API_URL, { ...params })
    .then(emailMapper);
