


















import { computed, defineComponent, inject, provide, reactive } from '@nuxtjs/composition-api';
import { DcwFooter, DcwHeader } from '@organisms';
import { DcwToast } from '@atoms';
import { ToastVariants, Toast } from '@/types';
import { toastKey } from '@/consts';
import { HeaderStore, HeaderContextKey } from '@/plugins/HeaderContext';

export default defineComponent({
  components: {
    DcwFooter,
    DcwHeader,
    DcwToast
  },
  setup() {
    const store = inject<HeaderStore>(HeaderContextKey);

    const toast = reactive<Toast>({
      visible: false,
      text: '',
      variant: ToastVariants.DEFAULT,
      delay: 2000
    });

    provide(toastKey, toast);

    const headerColors = computed(() => {
      if (!store) {
        return {};
      }

      return { color: store.color.value, mobileColor: store.mobileColor.value, tabletColor: store.tabletColor.value };
    });

    return { toast, headerColors };
  }
});
