import { people } from '../people';
import { MailType, SalesMailType, ScheduleModal, ScheduleModalPeople } from '@/types';

export const scheduleModal: ScheduleModal = {
  selectDay: 'Select a Day',
  scheduleCall: 'Schedule a quick 15 min-call to discuss possible work opportunities. ',
  headline: {
    before: 'Make an Appointment',
    highlight: 'With Our Employee'
  },
  people: [
    {
      type: SalesMailType.salesLukaszS,
      name: ScheduleModalPeople.lukaszS,
      zone: 'sales',
      position: people.lukaszS.position,
      image: {
        width: 64,
        height: 64,
        alt: 'Łukasz Sobieraj',
        src: '/schedule-contact/lukasz-sobieraj.png'
      }
    },
    {
      type: MailType.recruitment,
      name: ScheduleModalPeople.bartoszC,
      zone: 'HR',
      position: people.bartoszC.position,
      image: {
        width: 64,
        height: 64,
        alt: 'Bartosz Cieśla',
        src: '/schedule-contact/bartosz-ciesla.png'
      }
    },
    {
      type: MailType.recruitment,
      name: ScheduleModalPeople.wiktorS,
      zone: 'HR',
      position: people.wiktorS.position,
      image: {
        width: 64,
        height: 64,
        alt: 'Wiktor Stefański',
        src: '/schedule-contact/wiktor-stefanski.png'
      }
    },
    {
      type: SalesMailType.salesLeopoldG,
      name: ScheduleModalPeople.leopoldG,
      zone: 'sales',
      position: people.leopoldG.position,
      image: {
        width: 64,
        height: 64,
        alt: 'Léopold Gaillard',
        src: '/schedule-contact/leopold-gaillard.png'
      }
    },
    {
      type: SalesMailType.salesSzymonN,
      name: ScheduleModalPeople.szymonN,
      zone: 'sales',
      position: people.szymonN.position,
      image: {
        width: 64,
        height: 64,
        alt: 'Szymon Nowak',
        src: '/schedule-contact/szymon-nowak.png'
      }
    },
    {
      type: SalesMailType.salesPrzemyslawD,
      name: ScheduleModalPeople.przemyslawD,
      zone: 'sales',
      position: people.przemyslawD.position,
      image: {
        width: 64,
        height: 64,
        alt: people.przemyslawD.name,
        src: '/schedule-contact/przemyslaw-d.png'
      }
    }
  ]
};
